import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('div',{class:{
			'border-select-card-hover': hover && !_vm.disabled,
			'border-select-card': !_vm.small,
			'border-select-card-small': _vm.small,
		},style:({
			border: _vm.value ? '1px solid var(--primary-blue)' : '1px solid var(--grey-800)',
			opacity: _vm.disabled ? '0.5' : '1',
			cursor: _vm.disabled ? 'not-allowed' : 'pointer',
			..._vm.containerStyles,
		}),on:{"click":_vm.onSelect}},[_c('list-item',_vm._b({attrs:{"container-styles":{ marginBottom: 0 },"content-container-styles":{ width: '100%', height: '100%' }}},'list-item',{
				...(_vm.icon && {
					icon: _vm.icon,
					'icon-color': _vm.value ? '#6f86ff' : '#575757',
					'icon-size': _vm.small ? '23px' : '26px',
				}),
			},false),[_c('div',{staticClass:"full-width"},[_c('div',{class:{
						'border-select-card-title': !_vm.small,
						'border-select-card-title-small': _vm.small,
					},style:({
						color: _vm.value ? 'var(--primary-blue)' : 'var(--grey-500)',
					})},[_vm._t("title",null,{"hover":hover})],2),(_vm.hasSubTitleSlot)?_c('div',{class:{
						'border-select-card-subTitle': !_vm.small,
						'border-select-card-subTitle-small': _vm.small,
					}},[_vm._t("subTitle",null,{"hover":hover})],2):_vm._e()]),(_vm.hasContentSlot)?_vm._t("content"):_vm._e(),_c('div',{class:{
					'corner-selection-icon': !_vm.small,
					'corner-selection-icon-small': _vm.small,
				}},[_vm._t("leftOfCheckCircle"),(_vm.value)?_c(VIcon,{attrs:{"size":"24","color":"primary"}},[_vm._v(" mdi-check-circle ")]):_c(VIcon,{attrs:{"size":"24","color":"#575757"}},[_vm._v(" mdi-circle-outline ")])],2),(_vm.hasBottomRightContainerSlot)?_c('div',{class:{
					'bottom-right-container': _vm.$vuetify.breakpoint.smAndUp,
					'bottom-right-container-xsOnly': _vm.$vuetify.breakpoint.xsOnly,
				}},[_vm._t("bottomRightContainer",null,{"hover":hover})],2):_vm._e()],2)],1)]}}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }