
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import ConfirmDialog from '@/components/base/ConfirmDialog.vue';
import {
	currencyTokens,
	hundredthsToTokens,
	CHECKOUT_ADD_FUNDS,
	CHECKOUT_ADD_FUNDS_TEAM,
	CHECKOUT_RUNNITS_LIFE_SUPPORT_TOKENS_PACKAGE,
	LIFE_SUPPORT_TOKENS_PACKAGE_AMOUNT,
} from '@run-diffusion/shared';
import { MyBalanceMixin, TeamUserMixin } from '@/mixins';
import PrivateBalancePricingTableDialog from '@/components/PrivateBalancePricingTableDialog.vue';
import TokensSVG from '@/assets/TokensSVG.vue';

export default Vue.extend({
	name: 'AddBalanceFundsButton',
	mixins: [
		MyBalanceMixin,
		TeamUserMixin,
	],
	props: {
		type: {
			type: String,
			required: true,
			validator: (val: string) => ['private', 'team'].includes(val),
		},
		platform: {
			type: String,
			required: true,
			validator: (val: string) => ['rd', 'runnit'].includes(val),
		},
		btnProps: { type: Object, default: () => ({}) },
	},
	data () {
		return {
			LIFE_SUPPORT_TOKENS_PACKAGE_AMOUNT,
			balanceTooHighDialogOpen: false,
			privateBalancePricingTableDialogOpen: false,
		};
	},
	computed: {
		...mapState([
			'authUser',
		]),
		isPrivateBtn () {
			return this.type === 'private';
		},
		isTeamBtn () {
			return this.type === 'team';
		},
		isRdBtn () {
			return this.platform === 'rd';
		},
		isRunnitsBtn () {
			return this.platform === 'runnit';
		},
		adminWarningText () {
			if (this.isRdBtn) {
				return 'Admins can add funds';
			}
			return 'Admins can make these purchases';
		},
		isRdBalanceTooHigh () {
			return !!(
				this.isRdBtn &&
				(
					(this.isPrivateBtn && this.computedUserBalance > 10000) ||
					(this.isTeamBtn && this.computedTeamBalance > 200000)
				)
			);
		},
		isRunnitTokensTooHigh () {
			return !!(
				this.isRunnitsBtn &&
				(
					(this.isPrivateBtn && this.userBalanceAccountRunnitTokensData.combinedTokens > LIFE_SUPPORT_TOKENS_PACKAGE_AMOUNT) ||
					(this.isTeamBtn && this.teamBalanceAccountRunnitTokensData.combinedTokens > LIFE_SUPPORT_TOKENS_PACKAGE_AMOUNT)
				)
			);
		},
		btnDisabled () {
			return this.isRunnitTokensTooHigh;
		},
	},
	methods: {
		...mapActions([
			'updateSnackbar',
			'updateLoader',
		]),
		currencyTokens,
		hundredthsToTokens,
		checkBalanceTooHigh () {
			if (this.isRdBalanceTooHigh || this.isRunnitTokensTooHigh) {
				this.balanceTooHighDialogOpen = true;
				return;
			}
		},
		onBtnContainerClick () {
			if (!this.btnDisabled) return;
			this.checkBalanceTooHigh();
		},
		async onAddFundsClick () {
			if (!this.type) {
				return;
			}
			this.checkBalanceTooHigh();
			// if (this.isRdBtn && this.isPrivateBtn) {
			// 	this.privateBalancePricingTableDialogOpen = true;
			// 	return;
			// }

			await this.doAddFunds();
		},
		async doAddFunds () {
			const routeData = this.$router.resolve({
				name: 'GoToPayment',
				query: {
					...(this.isRdBtn && {
						checkoutAction: this.isTeamBtn ? CHECKOUT_ADD_FUNDS_TEAM : CHECKOUT_ADD_FUNDS,
					}),
					...(this.isRunnitsBtn && {
						checkoutAction: CHECKOUT_RUNNITS_LIFE_SUPPORT_TOKENS_PACKAGE,
					}),
				},
			});
			window.open(routeData.href, '_blank');
			this.$emit('on-add-funds-click');
		},
	},
	components: {
		TokensSVG,
		PrivateBalancePricingTableDialog,
		ConfirmDialog,
	},
});
