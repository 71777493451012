
import Vue from 'vue';
import ImageUpload from "@/views/Runnits/RunnitSettings/ImageInput/ImageUpload.vue";
import RunnitImage from "@/views/Runnits/RunnitImage.vue";
import {
	Avatar,
	AVATAR_USE_CASE,
	IMAGE_UPLOAD_MODE,
	MODEL_ARCHITECTURE,
	MODEL_CREATE_METHOD,
	MODEL_TYPE,
	RUNNIT_NODE_DEF_TAG_TYPE,
	RUNNIT_NODE_FIELD_TYPE,
	RunnitNodeDefTag,
	RUNNITS_ACCESS_LEVEL,
} from "@run-diffusion/shared";
import _trim from 'lodash/trim';
import ActionsIsland from "@/components/ActionsIsland.vue";
import ComplexBackground from "@/components/designElements/ComplexBackground.vue";
import {mapActions, mapState} from "vuex";
import {RunnitsCRUDMixin} from "@/mixins";
import {SNACKBAR_STATUS} from "@/constants/constants";
import {
	RUNNITS_OWNER_SELECTION,
	TRAINING_TYPE_CARDS,
} from "@/views/Runnits/constants";
import ModelUpload from "@/views/Runnits/pages/models/ModelUpload.vue";
import GreyButton from "@/components/base/GreyButton.vue";
import HeroImageGallery from "@/views/Runnits/base/HeroImageGallery.vue";
import {ModelCRUDMixin} from "@/mixins/ModelCRUDMixin";
import {db} from "@/firebase";
import DoubleConfirmDangerDialog from "@/components/base/DoubleConfirmDangerDialog.vue";
import RunnitCarousel from '@/views/Runnits/RunnitCarousel.vue';
import _get from "lodash/get";
import OptionCard from "@/views/Runnits/base/OptionCard.vue";

export default Vue.extend({
	name: 'RunnitModelsUploadDialog',
	props: {},
	mixins: [
		RunnitsCRUDMixin,
		ModelCRUDMixin,
	],
	data() {
		return {
			// Enums
			MODEL_ARCHITECTURE,
			MODEL_TYPE,
			AVATAR_USE_CASE,
			IMAGE_UPLOAD_MODE,
			RUNNITS_ACCESS_LEVEL,
			RUNNITS_OWNER_SELECTION,
			MODEL_CREATE_METHOD,
			TRAINING_TYPE_CARDS,

			// model form object
			model: null,

			modelFile: null,

			formValid: false,
			isUploadingAvatar: false,

			savingModelSettings: false,

			deleteModelDialog: {
				open: false,
				deleting: false,
			},

			trainedImages: [],
		};
	},
	computed: {
		...mapState([
			'modelsState',
			'user',
			'team',
			'publicRunnitNodeDefTags',
			'publicRunnitNodeDefTagsMap',
		]),
		createNewMode () {
			return this.modelsState.modelDialog.mode === 'create';
		},
		editMode () {
			return this.modelsState.modelDialog.mode === 'edit';
		},
		canEdit () {
			if (!this.model) return false;
			return !!(
				this.createNewMode ||
				this.model.userId === this.user.id || // you created it
				this.isTeamAdmin // team admin
			);
		},
		trainingTypeTags () {
			return this.publicRunnitNodeDefTags.filter((tag: RunnitNodeDefTag) => tag.type === RUNNIT_NODE_DEF_TAG_TYPE.TRAINING_TYPE);
		},
		selectedCategory: {
			get() {
				return Object.keys(this.model.tags)
					.map((tagId: string) => this.publicRunnitNodeDefTagsMap[tagId])
					.find((tag: RunnitNodeDefTag) => tag && tag.type === RUNNIT_NODE_DEF_TAG_TYPE.TRAINING_TYPE)?.id;
			},
			set(newTag: string) {
				if (newTag) {
					const preservedTags = Object.keys((this.model.tags || {}))
						.filter((tagId: string) => this.publicRunnitNodeDefTagsMap[tagId]?.type !== RUNNIT_NODE_DEF_TAG_TYPE.TRAINING_TYPE)
						.reduce((acc: any, tagId: string) => {
							acc[tagId] = true;
							return acc;
						}, {});

					this.model = {
						...this.model,
						tagTypes: {
							...this.model.tagTypes,
							[RUNNIT_NODE_DEF_TAG_TYPE.TRAINING_TYPE]: true,
						},
						tags: {
							...preservedTags,
							[newTag]: true,
						},
					};
				}
			}
		}
	},
	watch: {
		'modelsState.modelDialog.open': {
			immediate: true,
			handler(isOpen) {
				if (isOpen) {
					const val = this.modelsState.modelDraft;

					this.model = {
						...this.model,
						...val,
						accessLevel: (_get(val, 'teamId') && _get(val, 'accessLevel')) || this.runnitState.runnitsAccessLevel || RUNNITS_ACCESS_LEVEL.SHARED,
						...(
							_get(val, 'id') ?
								{
									get id() { // the spread operator on the val object removes its id getter
										return _get(val, 'id');
									},
								} :
								{}
						),
					};

					if (this.model.createMethod === MODEL_CREATE_METHOD.RUNNIT) {
						this.getTrainedImages();
					}
				}
			}
		},
		model: {
			handler (newVal) {
				if (newVal) {
					this.updateModelState({
						modelDraft: newVal,
					})
				}
			}
		}
	},
	methods: {
		...mapActions([
			'updateSnackbar',
			'updateModelState',
		]),
		...mapActions('modelUpload', ['startUpload']),
		_trim,
		handleAvatarUpload(avatar: Avatar) {
			this.model = {
				...this.model,
				avatarId: avatar.id,
				avatar: avatar,
			};
		},
		async onUpload() {
			const teamId: string = this.runnitState.runnitsOwnerSelection === RUNNITS_OWNER_SELECTION.TEAM && this.team ? this.team.id : null;

			this.startUpload({
				model: {
					...this.model,
					...(this.model.avatarId ? {
						avatarId: this.model.avatarId,
						avatar: db.doc(`avatars/${this.model.avatarId}`),
					} : {
						avatarId: null,
						avatar: null,
					}),
					createdAt: new Date(),
					createdByUserId: this.user.id,
					userId: this.user.id,
					teamId,
				},
				avatar: this.model.avatar,
				file: this.modelFile
			})

			this.updateSnackbar({
				status: SNACKBAR_STATUS.INFO,
				message: 'Model uploading...',
				show: true,
			});

			this.closeDialog();
		},
		closeDialog () {
			this.trainedImages = [];
			this.modelFile = null;
			this.model = null;
			this.updateModelState({
				modelDialog: {
					...this.modelsState.modelDialog,
					open: false,
				},
				modelDraft: null,
			});
		},
		async onSaveModelSettings () {
			if (!this.canEdit) return;

			try {
				this.savingModelSettings = true;
				const modelRef = db.doc(`models/${this.model.id}`);
				await modelRef
					.update({
						name: this.model.name,
						description: this.model.description,
						triggerWord: this.model.triggerWord,
						accessLevel: this.model.accessLevel,
						architecture: this.model.architecture,
						type: this.model.type,
						tagTypes: this.model.tagTypes,
						tags: this.model.tags,
						...(this.model.avatarId ? {
							avatarId: this.model.avatarId,
							avatar: db.doc(`avatars/${this.model.avatarId}`),
						} : {
							avatarId: null,
							avatar: null,
						}),
					});
				this.closeDialog();
				this.updateSnackbar({
					status: SNACKBAR_STATUS.SUCCESS,
					message: `Success! Model Settings saved`,
					show: true,
				});
			} catch (e) {
				console.error(e);
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: 'Error! Problem saving the Model Settings, please reach out to report issues by clicking the support button in our top toolbar',
					show: true,
				});
			} finally {
				this.savingModelSettings = false;
			}
		},
		async handleDeleteModelConfirm (closeAndResetCallback: Function) {
			if (!this.canEdit) return;
			try {
				this.deleteModelDialog = {
					...this.deleteModelDialog,
					deleting: true,
				};
				const modelRef = db.doc(`models/${this.model.id}`);
				await modelRef
					.update({
						deletedAt: new Date(),
						isDeleted: true,
					});
				await this.updateSnackbar({
					status: SNACKBAR_STATUS.SUCCESS,
					message: `Success! Model deleted`,
					show: true,
				});
			} catch (e) {
				console.error(e);
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: `Error! Problem deleting the Model, please reach out to report issues by clicking the support button in our top toolbar`,
					show: true,
				});
				this.closeDialog();
			} finally {
				if (closeAndResetCallback) {
					closeAndResetCallback();
				}
				this.deleteModelDialog = {
					open: false,
					deleting: false,
				};
			}
		},
		async getTrainedImages () {
			const nodeRunId = this.model.nodeRunId;
			if (!nodeRunId) return;
			const nodeRunRef = db.doc(`runnitNodeRuns/${nodeRunId}`);

			const nodeRunSnapshot = await nodeRunRef.get();
			if (!nodeRunSnapshot.exists) return;
			const nodeRun = nodeRunSnapshot.data();

			const trainedImageInput = nodeRun.fields?.find((field: any) => field.type === RUNNIT_NODE_FIELD_TYPE.IMGS);
			this.trainedImages = nodeRun.inputs[trainedImageInput?.fieldDefUuid]?.uploads || [];
		},
	},
	components: {
		OptionCard,
		DoubleConfirmDangerDialog,
		HeroImageGallery,
		GreyButton,
		ModelUpload,
		ComplexBackground,
		ActionsIsland,
		ImageUpload,
		RunnitImage,
		RunnitCarousel,
	},
});
