
import Vue from 'vue';
import { mapActions } from 'vuex';
import StackLayers from '@/components/base/StackLayers.vue';

export default Vue.extend({
	name: 'PersonalSubscriptionManagement',
	methods: {
		...mapActions([
			'goToStripeSubscriptionPortal',
		]),
		onManageClick () {
			this.$emit('on-manage-subscription');
		},
	},
	components: {
		StackLayers,
	},
});
