import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.isListItem)?_c('list-item',{staticClass:"menu-list-item",attrs:{"icon-color":"var(--blue-grey-200)","icon":"mdi-account-circle-outline"},on:{"click":_vm.openAccountMenu}},[_c('div',{staticClass:"menu-item-color"},[_vm._v(" Account menu ")])]):_c(VTooltip,{attrs:{"bottom":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({style:({
				color: _vm.btnTextColor,
			}),attrs:{"icon":"","large":_vm.large},on:{"click":_vm.openAccountMenu}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-account-circle-outline")])],1)]}}])},[_c('div',{staticClass:"tooltip-max-width"},[_vm._v("Account menu")])])
}
var staticRenderFns = []

export { render, staticRenderFns }