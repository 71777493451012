import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.isSecondaryButton)?_c('base-button',_vm._b({attrs:{"secondary":"","large":_vm.large},on:{"click":_vm.toggleGetSupportDialog}},'base-button',_vm.$attrs,false),[_vm._t("default")],2):(_vm.isPromoButton)?_c('base-button',_vm._b({staticStyle:{"padding":"0 13px 0 10px"},attrs:{"secondary-primary":"","large":_vm.large},on:{"click":_vm.toggleGetSupportDialog}},'base-button',_vm.$attrs,false),[_c(VIcon,{staticClass:"ml-1",attrs:{"left":""}},[_vm._v("mdi-chat-question-outline")]),_vm._t("default")],2):(_vm.isListItem)?_c('list-item',{staticClass:"menu-list-item",attrs:{"icon-color":"var(--blue-grey-200)","icon":"mdi-chat-question-outline"},on:{"click":_vm.toggleGetSupportDialog}},[_c('div',{staticClass:"menu-item-color"},[_vm._v(_vm._s(_vm.LONG_BTN_TEXT))])]):_c(VTooltip,{attrs:{"bottom":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({style:({
					color: _vm.btnTextColor,
				}),attrs:{"icon":"","large":_vm.large},on:{"click":_vm.toggleGetSupportDialog}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-chat-question-outline")])],1)]}}])},[_c('div',{staticClass:"tooltip-max-width"},[_vm._v(_vm._s(_vm.LONG_BTN_TEXT))])]),_c('get-support-dialog',{attrs:{"inside-runnits":_vm.insideRunnits},model:{value:(_vm.getSupportDialogOpen),callback:function ($$v) {_vm.getSupportDialogOpen=$$v},expression:"getSupportDialogOpen"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }