
import Vue from 'vue';
import ListItem from '@/components/base/ListItem.vue';
import { UPSELL_TYPE } from '@/constants/constants';
import { mapState } from 'vuex';
import TeamPicker from '@/components/TeamPicker.vue';

export default Vue.extend({
	name: 'RdBorderCardUpsell',
	props: {
		containerStyles: { type: Object, default: () => {} },
		teamTitle: { type: String, default: '*Active Team Plan Required' },
		creatorsClubTitle: { type: String, default: '*Creator\'s Club Required' },
		upsellType: { type: String, required: true },
		emphasizeRecurringBalance: { type: Boolean, default: false },
		hover: { type: Boolean, default: false },
	},
	computed: {
		...mapState([
			'team',
		]),
		isTeamUpsell () {
			return this.upsellType === UPSELL_TYPE.TEAM;
		},
		isCreatorsClubUpsell () {
			return this.upsellType === UPSELL_TYPE.CREATORS_CLUB;
		},
	},
	components: {
		TeamPicker,
		ListItem,
	},
});
