import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"runnits-home full-height",style:({
		padding: _vm.$vuetify.breakpoint.xsOnly ? '0 15px' : '0 25px',
	})},[_c(VRow,[_c(VCol,{staticStyle:{"padding":"0"},attrs:{"cols":"12"}},[_c('runnits-promo-card',{staticStyle:{"margin-top":"25px","margin-bottom":"15px"}})],1)],1),_c(VRow,{attrs:{"align-content":"center"}},[_c(VCol,{staticStyle:{"padding":"0 0 200px 0"},attrs:{"cols":"12"}},[(_vm.runnitState.runnitsOwnerSelection === _vm.RUNNITS_OWNER_SELECTION.TEAM || _vm.runnits.length || _vm.loadingRunnits)?_c('div',{staticClass:"runnits-section"},[_c('div',{staticClass:"d-flex align-center justify-space-between flex-wrap"},[_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('div',{staticClass:"d-flex align-center",style:({
									flexWrap: _vm.$vuetify.breakpoint.xsOnly ? 'wrap' : 'nowrap',
								})},[_c('div',{staticClass:"runnits-section-title mt-1"},[_vm._v(" "+_vm._s(_vm.runnitState.runnitsOwnerSelection === _vm.RUNNITS_OWNER_SELECTION.TEAM ? 'Team Runnit Boards' : 'Personal Runnit Boards')+" ")]),(_vm.runnitState.runnitsOwnerSelection === _vm.RUNNITS_OWNER_SELECTION.TEAM)?_c(VBtnToggle,{staticClass:"mr-2",class:{
										'ml-6': _vm.$vuetify.breakpoint.smAndUp,
										'mt-5 mb-5 ml-5': _vm.$vuetify.breakpoint.xsOnly,
									},attrs:{"value":_vm.runnitState.runnitsAccessLevel,"mandatory":"","rounded":"","dense":""},on:{"change":_vm.onRunnitsAccessLevelChange}},[_c(VBtn,{attrs:{"value":_vm.RUNNITS_ACCESS_LEVEL.SHARED}},[_c('div',{staticClass:"d-flex align-center pr-1"},[_c(VIcon,{staticClass:"pl-1 pr-2",staticStyle:{"font-size":"22px"}},[_vm._v("mdi-account-multiple-outline")]),_c('div',{staticClass:"d-flex flex-column align-start",staticStyle:{"padding":"0 5px 0 0"}},[_c('div',[_vm._v("Shared")])])],1)]),_c(VBtn,{attrs:{"value":_vm.RUNNITS_ACCESS_LEVEL.PRIVATE}},[_c('div',{staticClass:"d-flex align-center pl-1"},[_c(VIcon,{staticClass:"pl-1 pr-2",staticStyle:{"font-size":"22px"}},[_vm._v("mdi-account-circle-outline")]),_c('div',{staticClass:"d-flex flex-column align-start",staticStyle:{"padding":"0 5px 0 0"}},[_c('div',[_vm._v("Private")])])],1)])],1):_vm._e()],1)]),_c('div',{staticClass:"runnits-section-subTitle"},[_vm._v(" A Runnit Board simplifies your workflow by grouping tools for creating visual assets, making it easy to switch between ideas, concepts, and projects. ")])]),_c('base-button',{staticStyle:{"margin-bottom":"20px"},attrs:{"secondary-primary":""},on:{"click":_vm.onAddRunnitClick}},[_c('div',{staticClass:"d-flex align-center"},[_c(VIcon,{staticStyle:{"color":"var(--grey-400)","margin":"0 10px 0 0","font-size":"20px"}},[_vm._v("mdi-plus-circle-multiple")]),_vm._v(" Add Board ")],1)])],1),((
						_vm.runnitState.runnitsOwnerSelection === _vm.RUNNITS_OWNER_SELECTION.TEAM &&
						!_vm.team
					))?_c('div',{staticClass:"d-flex flex-column justify-center align-center"},[_c('empty-state',{attrs:{"row-class":"mt-12 pt-8 pb-6","icon":"mdi-account-multiple-outline","message":"No team selected!"}}),_c('team-picker',{scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('base-button',_vm._g(_vm._b({staticClass:"mt-6",attrs:{"secondary-primary":""}},'base-button',attrs,false),on),[_vm._v(" Click here to select team ")])]}}],null,false,1189684346)})],1):(_vm.loadingRunnits === null || _vm.loadingRunnits)?_c('div',{staticClass:"d-flex flex-column justify-center align-center",staticStyle:{"min-height":"520px"}},[_c(VProgressCircular,{staticClass:"my-10",attrs:{"indeterminate":true,"size":"100","color":"rgba(255, 255, 255, 0.2)"}}),_c('div',{staticStyle:{"color":"rgba(255, 255, 255, 0.4)"}},[_vm._v("Loading...")])],1):(!_vm.runnits || !_vm.runnits.length)?_c('div',{staticClass:"d-flex flex-column justify-center align-center"},[_c('empty-state',{attrs:{"row-class":"mt-12 pt-8 pb-6","icon":_vm.runnitState.runnitsOwnerSelection === _vm.RUNNITS_OWNER_SELECTION.TEAM && _vm.runnitState.runnitsAccessLevel === _vm.RUNNITS_ACCESS_LEVEL.SHARED ? 'mdi-account-multiple-outline' : 'mdi-account-circle-outline',"message":_vm.runnitState.runnitsOwnerSelection === _vm.RUNNITS_OWNER_SELECTION.TEAM ? `No \'${_vm.runnitState.runnitsAccessLevel === _vm.RUNNITS_ACCESS_LEVEL.SHARED ? 'Shared' : 'Private'}\' Runnits found!` : `No Runnits found!`}})],1):_c('div',{staticClass:"runnit-nodes-container"},[_c('div',{staticClass:"slide-group-container pb-10"},[_c('runnit-carousel',{attrs:{"items":_vm.runnits.slice(0, _vm.computedVisibleRunnits),"include-view-all":"","view-all-text":`View All ${_vm.runnitState.runnitsAccessLevel === _vm.RUNNITS_ACCESS_LEVEL.PRIVATE ? 'Private' : 'Shared'} Runnits`,"view-all-styles":{
								top: 'auto',
								right: 0,
								bottom: '-40px',
							}},on:{"view-all-click":_vm.goToAllRunnits},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('offering-card',{staticClass:"ma-2",staticStyle:{"width":"300px"},attrs:{"disabled":!_vm.runnitsLimitMap[item.id],"select-icon":"mdi-open-in-new","select-text":"Open","show-edit-btn":item.userId === _vm.user.id || _vm.isTeamAdmin,"edit-text":"Configure settings or delete Runnit Board","disabled-text":"Limit reached!","disabled-cta-text":"Learn More","disabled-cta-props":{ secondaryPrimary: true },"title":item.title,"avatar":item.avatar,"description":item.description,"tool-type":_vm.RUNNIT_NODE_DEF_TOOL_TYPE.USER_CREATED},on:{"click":function($event){return _vm.onOfferingCardClick(item.id, null)},"disabled-cta-click":function($event){return _vm.setUpsellDialog({ runnitsIsAtNumRunnitsLimit: true })},"edit-click":function($event){return _vm.onEditRunnitSettings(item)}}})]}}],null,false,2068249858)})],1)])]):_vm._e(),_c('div',{style:({
				marginTop: !!_vm.runnits.length ? '40px' : '30px',
			})},[_c('runnit-tools-gallery')],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }