"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.paginateQuery = exports.asyncForEach = void 0;
const asyncForEach = async (array = [], callback = async () => { }) => {
    for (let index = 0; index < (array || []).length; index++) {
        await callback(array[index], index, array);
    }
};
exports.asyncForEach = asyncForEach;
/*
Helper function to paginate Firestore queries and retrieve all documents
*/
const paginateQuery = async (query, limit = 1000) => {
    const allDocs = [];
    let lastDoc = null;
    while (true) {
        // Apply pagination if there is a last document
        const queryWithPagination = lastDoc
            ? query.limit(limit).startAfter(lastDoc)
            : query.limit(limit);
        const querySnapshot = await queryWithPagination.get();
        // Add retrieved documents to the results
        allDocs.push(...querySnapshot.docs);
        // If no more documents, exit the loop
        if (querySnapshot.docs.length < limit)
            break;
        // Update lastDoc to the last document in this batch
        lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
    }
    return allDocs;
};
exports.paginateQuery = paginateQuery;
