
import Vue from 'vue';
import { RunnitDynamicFieldMixin } from '@/views/Runnits/RunnitSettings/mixins/RunnitDynamicFieldMixin';
import _trim from 'lodash/trim';
import NumberField from '@/components/base/NumberField.vue';
import RunnitSettingHelperBtn from '@/views/Runnits/RunnitSettings/RunnitSettingHelperBtn.vue';
import RunnitDynamicFieldLabelRow from '@/views/Runnits/RunnitSettings/RunnitDynamicFieldLabelRow.vue';

type SeedInputMode =
	'RANDOM' |
	'CUSTOM'
	;
export default Vue.extend({
	name: 'SeedInput',
	mixins: [
		RunnitDynamicFieldMixin,
	],
	props: {
		value: { type: Number, default: null },
		adminOnly: { type: Boolean, default: false },
	},
	data () {
		const SEED_INPUT_MODE: Record<SeedInputMode, SeedInputMode> = {
			RANDOM: 'RANDOM',
			CUSTOM: 'CUSTOM',
		};

		return {
			SEED_INPUT_MODE,
			mode: SEED_INPUT_MODE.RANDOM,
		};
	},
	watch: {
		value: {
			immediate: true,
			handler (newVal: string, oldVal: string) {
				if (newVal !== oldVal) {
					this.initMode(newVal);
				}
			},
		},
	},
	methods: {
		initMode (value: string) {
			const trimmedValue: string = _trim(value);
			this.mode = trimmedValue
				? this.mode = this.SEED_INPUT_MODE.CUSTOM
				: this.SEED_INPUT_MODE.RANDOM;
		},
		onSelectMode (mode: SeedInputMode) {
			const trimmedValue: string = _trim(this.value);

			const oldMode: SeedInputMode = this.mode;
			if (oldMode === mode) return;

			switch (mode) {
				case this.SEED_INPUT_MODE.CUSTOM:
					this.mode = this.SEED_INPUT_MODE.CUSTOM;
					this.onInput(trimmedValue || null);
					break;
				case this.SEED_INPUT_MODE.RANDOM:
					this.mode = this.SEED_INPUT_MODE.RANDOM;
					this.onInput(null);
					break;
				default:
					break;
			}
		},
		onInput (val: number) {
			this.$emit('input', val);
		},
	},
	components: {
		RunnitSettingHelperBtn,
		NumberField,
		RunnitDynamicFieldLabelRow,
	},
});
