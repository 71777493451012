import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"mb-12 pb-12"},[(_vm.selectedStorageType === _vm.SELECTED_STORAGE_TYPE.TEAM)?_c(VRow,_vm._l((_vm.workspaces),function(workspace){return _c(VCol,{key:workspace.id,attrs:{"cols":"12"}},[_c('workspace-card',{attrs:{"workspace":workspace,"selection-mode":"","selected":_vm.selectedWorkspaceId === workspace.id},on:{"on-select":function($event){return _vm.$emit('on-select-workspace', workspace)}}})],1)}),1):_c(VRow,_vm._l((_vm.items),function(item){return _c(VCol,{key:item.value,attrs:{"cols":"12"}},[(!_vm.clubOfferBenefits.isCc && item.value === _vm.STORAGE_OPTION.PRIVATE)?_c('border-select-card',{attrs:{"value":false,"icon":item.icon},on:{"on-click":_vm.doUpsellToCreatorClub},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(item.label)+" ")]},proxy:true},{key:"subTitle",fn:function({ hover }){return [_c('rd-border-card-upsell',{attrs:{"hover":hover,"upsell-type":_vm.UPSELL_TYPE.CREATORS_CLUB,"container-styles":{
							left: '-40px',
							margin: '30px 0 10px 0',
							...(_vm.$vuetify.breakpoint.xsOnly && {
								width: '119%',
							}),
							...(_vm.$vuetify.breakpoint.smAndUp && {
								width: '106%',
							}),
						}}})]}}],null,true)}):_c('storage-option-card',{attrs:{"label":item.label,"icon":item.icon,"description-html":item.descriptionHtml,"selected":!!(_vm.selectedStorageOption && _vm.selectedStorageOption === item.value)},on:{"on-select":function($event){return _vm.onSelectStorageOption(item.value)}}})],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }