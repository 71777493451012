
import Vue from 'vue';
import { mapActions, mapState, mapGetters } from 'vuex';
import { db, functions } from '@/firebase';
import { SNACKBAR_STATUS } from '@/constants/constants';
import _isNil from 'lodash/isNil';
import _keys from 'lodash/keys';
import _get from 'lodash/get';
import { MyBalanceMixin, RunnitsCRUDMixin } from '@/mixins';
import {
	getRunnitNodeRunPrice,
	RunnitNodeStaticFieldsKey,
	RUNNIT_NODE_DEF_PRICING_TYPE,
	RUNNIT_NODE_STATIC_FIELDS_KEY,
	RUNNIT_NODE_STATIC_FIELDS_SOURCE,
	RUNNIT_NODE_DEF_TOOL_APP_TYPE,
} from '@run-diffusion/shared';
import TokensSVG from '@/assets/TokensSVG.vue';
import { RunnitBulkActionsMixin } from '@/mixins/RunnitBulkActionsMixin';
import { RUNNITS_OWNER_SELECTION } from './constants';
import BaseButton from '@/components/base/BaseButton.vue';
import {ModelCRUDMixin} from "@/mixins/ModelCRUDMixin";
import RunnitModelTrainingDialog from "@/views/Runnits/base/RunnitModelTrainingDialog.vue";

export default Vue.extend({
	name: 'RunRunnitButton',
	mixins: [
		MyBalanceMixin,
		RunnitBulkActionsMixin,
		ModelCRUDMixin,
		RunnitsCRUDMixin,
	],
	props: {
		selectedNode: { type: Object, default: null },
		actionsOnly: { type: Boolean, default: false },
		actionText: { type: String, default: 'Runnit' },
		validate: { type: Function, default: null },
		formValid: { type: Boolean, default: true },
	},
	data () {
		return {
			RUNNIT_NODE_STATIC_FIELDS_KEY,
			RUNNIT_NODE_DEF_TOOL_APP_TYPE,
			isModelTrainingDialogOpen: false,
		};
	},
	created () {
		// Add event listeners for keydown when the component is created
		document.addEventListener('keydown', this.handleKeyDown);
	},
	destroyed () {
		// Remove event listeners for keydown when the component is destroyed
		document.removeEventListener('keydown', this.handleKeyDown);
	},
	computed: {
		...mapState([
			'user',
			'team',
			'loadingDraftRunnitNodeRun',
			'draftRunnitNodeRun',
			'runnitState',
			'modelsState',
		]),
		...mapGetters([
			'runnitNodesLimitMap',
		]),
		inputValues() {
			return this.runnitState.inputValues
		},
		staticInputValues() {
			return this.runnitState.staticInputValues
		},
		isToolUnpublished () {
			return this.selectedNode &&
				typeof this.selectedNode.nodeDef !== 'string' && // Nested object is fetched
				(!this.selectedNode.nodeDef.isPublished || !this.selectedNode.nodeDef.publishedAt) &&
				!this.user.isAdmin;
		},
		runnitButtonDisabled () {
			return !!(
				!this.selectedNode ||
				!this.runnitNodesLimitMap[this.selectedNode.id] ||
				_isNil(this.totalRunCost) ||
				this.totalRunCost > this.balanceAccountRunnitTokensData.combinedTokens ||
				this.runnitState.isQueuingDraftRunnitNodeRun ||
				this.disableActions ||
				(!this.user.isAdmin && !_get(this.selectedNode, 'nodeDef.publishedAt')) ||
				(this.actionsOnly && !this.formValid) ||
				this.isToolUnpublished
			);
		},
		disableActions () {
			return !(!this.loadingDraftRunnitNodeRun && this.draftRunnitNodeRun);
		},
		determinedStaticFields () {
			if (!this.selectedNode) return {};
			return (
				this._get(this.selectedNode, 'staticFieldsSource') === RUNNIT_NODE_STATIC_FIELDS_SOURCE.NODE
					? this._get(this.selectedNode, 'staticFields')
					: this._get(this.selectedNode, 'nodeDef.staticFields')
			) || {};
		},
		numResultsStaticInputValue () {
			if (
				this.determinedStaticFields[RUNNIT_NODE_STATIC_FIELDS_KEY.numResults] &&
				this.staticInputValues[RUNNIT_NODE_STATIC_FIELDS_KEY.numResults] > 0
			) {
				return this.staticInputValues[RUNNIT_NODE_STATIC_FIELDS_KEY.numResults];
			}
			return 1;
		},
		totalRunCost () {
			if (
				this.selectedNode &&
				this.selectedNode.nodeDef &&
				typeof this.selectedNode.nodeDef !== 'string' && // Nested object is fetched
				this.selectedNode.nodeDef.pricingType === RUNNIT_NODE_DEF_PRICING_TYPE.COST &&
				this.selectedNode.nodeDef.costPerResult > 0
			) {
				return getRunnitNodeRunPrice(this.selectedNode.nodeDef.costPerResult, this.numResultsStaticInputValue);
			}
			return null; // null means can't get price yet, should disable the Runnit run button
		},
	},
	methods: {
		...mapActions([
			'updateSnackbar',
		]),
		_isNil,
		setSettingsDrawerOpen (value: boolean) {
			this.$emit('set-settings-drawer-open', value);
			if (value) {
				this.setBulkActionMenuOpen(false);
			}
		},
		async onGenerateClick () {
			if (this.runnitButtonDisabled) return;
			if (
				(this.validate && !this.validate()) ||
				!this.draftRunnitNodeRun // This means they haven't touched the form yet, or something...
			) {
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: 'Form invalid, check fields',
					show: true,
				});
				return;
			}

			const onError: Function = (e) => {
				console.error(e);
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: e.message ? `Error executing this runnit: ${e.message}` : 'Error executing this runnit, please reach out to report issues by clicking the support button in our top toolbar',
					show: true,
				});
			};
			try {
				let runRunnitNodeData = {};
				if (this.selectedNode.nodeDef.appType === RUNNIT_NODE_DEF_TOOL_APP_TYPE.TRAINER) {
					if (this.modelsState.modelDraft) {
						this.modelsState.modelDraft.createdAt = new Date();
						const modelAddedRef = await db.collection(`models`).add(this.modelsState.modelDraft);
						runRunnitNodeData = {
							...runRunnitNodeData,
							runnitSenderData: {
								modelId: modelAddedRef.id,
							},
						};
					} else {
						// There should already be a model at this point
						this.updateSnackbar({
							status: SNACKBAR_STATUS.ERROR,
							message: 'Please create a model first',
							show: true,
						});
						return;
					}
				}

				await this.executeRunnit(runRunnitNodeData);
				this.isModelTrainingDialogOpen = true;
			} catch (e) {
				onError(e);
			} finally {
				this.runnitState.isQueuingDraftRunnitNodeRun = false;
			}
		},
		async executeRunnit(runRunnitNodeData: any) {
			const onError: Function = (e) => {
				console.error(e);
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: e.message ? `Error executing this runnit: ${e.message}` : 'Error executing this runnit, please reach out to report issues by clicking the support button in our top toolbar',
					show: true,
				});
			};

			const staticInputValues: Record<RunnitNodeStaticFieldsKey, any> = {};
			_keys(this.determinedStaticFields).forEach((key: RunnitNodeStaticFieldsKey) => {
				// Only send values that are absolutely defined
				staticInputValues[key] = this.staticInputValues[key];
			});

			this.runnitState.isQueuingDraftRunnitNodeRun = true;
			const functionRef = functions
				.httpsCallable('runRunnitNode');
			const { success, message } = (await functionRef({
				...(window.localStorage.getItem('RUNNIT_USE_DEV_SERVER') && {
					useDevServer: true,
				}),
				nodeRunId: this.draftRunnitNodeRun.id,
				inputValues: this.inputValues,
				staticInputValues,
				teamId: this.runnitState.runnitsOwnerSelection === RUNNITS_OWNER_SELECTION.TEAM && this._get(this.team, 'id') || null,
				...runRunnitNodeData,
			})).data;
			if (!success) {
				console.error(`runRunnitNode returned a status of: ${success}`)
				onError(new Error(message));
			} else if (this.$vuetify.breakpoint.xsOnly) {
				this.setSettingsDrawerOpen(false);
			}
		},
		handleKeyDown (event) {
			if (event.shiftKey && event.key === 'Enter') {
				// Shift + Enter to trigger the runnit
				event.preventDefault();
				if (!this.runnitButtonDisabled) {
					this.onGenerateClick();
				}
			}
		},
	},
	components: {
		RunnitModelTrainingDialog,
		TokensSVG,
		BaseButton,
	},
});
