
import Vue from 'vue';
import _isEmpty from 'lodash/isEmpty';
import { mapState } from 'vuex';
import { ROUTER } from '@/router/constants';
import { UPSELL_TYPE } from '@/constants/constants';
import { RUNNITS_OWNER_SELECTION } from '@/views/Runnits/constants';
import TeamPicker from '@/components/TeamPicker.vue';
import BorderSelectCard from '@/components/BorderSelectCard.vue';
import RdBorderCardUpsell from '@/components/RdBorderCardUpsell.vue';
import RunnitsOwnerSelectionCard from '@/views/Runnits/RunnitsOwnerSelectionPicker/RunnitsOwnerSelectionCard.vue';
import { RunnitsOwnerSelection } from '@/store';

export default Vue.extend({
	name: 'RunnitsOwnerSelectionPicker',
	data () {
		return {
			UPSELL_TYPE,
			RUNNITS_OWNER_SELECTION,
		};
	},
	computed: {
		...mapState([
			'user',
			'team',
			'runnitState',
		]),
		runnitsOwnerSelection () {
			return this.runnitState ? this.runnitState.runnitsOwnerSelection : null;
		},
		items () {
			return [
				{
					value: RUNNITS_OWNER_SELECTION.USER,
					label: 'Personal Account',
					icon: 'mdi-account-outline',
					descriptionHtml: `<div>Runnits will be saved under your personal <b>account</b> and will use your personal <b>balance</b></div>`,
				},
				{
					value: RUNNITS_OWNER_SELECTION.TEAM,
					label: 'Team Account',
					icon: 'mdi-account-multiple-outline',
					descriptionHtml: `<div>Runnits will be saved under your team <b>account</b> as either "Private" or "Shared" and will use your team's shared <b>balance</b></div>`,
				},
			];
		},
	},
	methods: {
		_isEmpty,
		doUpsellToTeamPlan () {
			window.open(this.$router.resolve({ name: ROUTER.TEAM_MEMBERS }).href, '_blank');
		},
		onSelect (runnitsOwnerSelection: RunnitsOwnerSelection) {
			this.$emit('on-select', runnitsOwnerSelection);
		},
	},
	components: {
		TeamPicker,
		RdBorderCardUpsell,
		BorderSelectCard,
		RunnitsOwnerSelectionCard,
	},
});
