
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import { ROUTER } from '@/router/constants';
import { RUNNITS_OWNER_SELECTION } from '@/views/Runnits/constants';
import EmptyState from '@/components/states/EmptyState.vue';
import RunnitAccountSelectionDialog from '@/views/Runnits/RunnitAccountSelectionDialog.vue';
import { RunnitsAndRunnitNodeDefsBindingsMixin } from '@/mixins';
import GetSupportBtn from '@/components/Toolbar/GetSupportBtn/GetSupportBtn.vue';
import AccountMenuBtn from '@/components/Toolbar/AccountMenuBtn.vue';

export default Vue.extend({
	name: 'Runnits',
	created () {
		this.updateToolbar({
			insideRunnits: true,
		});
	},
	mixins: [
		RunnitsAndRunnitNodeDefsBindingsMixin,
	],
	computed: {
		...mapState([
			'toolbar',
			'team',
			'runnitState',
		]),
		hideRouterViewForTeam () {
			return !!(
				this.$route.name !== ROUTER.RUNNITS_SUBSCRIPTIONS &&
				this.team &&
				this.runnitState.runnitsOwnerSelection === RUNNITS_OWNER_SELECTION.TEAM &&
				// DEPRECATED - Please stop supporting this soon when we have team Runnit subscriptions done
				!this._get(this.team, 'isActive')
			);
		},
		shouldRemoveSideMargins () {
			return [
				ROUTER.RUNNITS_BOARD,
			].includes(this.$route.name);
		},
	},
	methods: {
		...mapActions([
			'updateToolbar',
		]),
		goToRdTeamPage () {
			const routeData = this.$router.resolve({ name: ROUTER.TEAM_MEMBERS });
			window.open(routeData.href, '_blank');
		},
	},
	components: {
		AccountMenuBtn,
		GetSupportBtn,
		RunnitAccountSelectionDialog,
		EmptyState,
	},
});
