import Vue from 'vue';
import { db, functions } from '@/firebase';
import { ROUTER } from '@/router/constants';
import { mapActions, mapState } from 'vuex';
import {
	Avatar,
	Runnit,
	RunnitNodeDef,
	RunnitNodeFieldType,
	RUNNIT_TYPE,
	RUNNITS_ACCESS_LEVEL, RunnitNodeFieldGroup, RunnitNodeField,
} from '@run-diffusion/shared';
import { SNACKBAR_STATUS } from '@/constants/constants';
import { RUNNITS_OWNER_SELECTION } from '@/views/Runnits/constants';
import _sampleSize from 'lodash/sampleSize';
import { RunnitType } from '@run-diffusion/shared';

export const RunnitsCRUDMixin = Vue.extend({
	computed: {
		...mapState([
			'user',
			'team',
			'runnits',
			'runnitState',
		]),
	},
	methods: {
		...mapActions([
			'updateLoader',
			'updateSnackbar',
			'updateRunnitState',
			'setUpsellDialog',
		]),
		onEditRunnitSettings (runnit: Runnit) {
			if (runnit) {
				this.updateRunnitState({
					runnitDraft: null,
					runnitDraftNodeRef: null,
					runnit,
					configureRunnitOpen: true,
				});
			}
		},
		async onOfferingCardClick (runnitId: string, runnitNodeDef: RunnitNodeDef, queryParams: Record<string, any> = {}) {
			if (runnitId && this._get(runnitNodeDef, 'id')) {
				const addNodeToRunnitFunctionRef = functions
					.httpsCallable('addNodeToRunnit');
				const { success } = (await addNodeToRunnitFunctionRef({
					runnitId: runnitId,
					nodeDefId: runnitNodeDef.id,
				})).data;

				if (!success) {
					await this.updateSnackbar({
						status: SNACKBAR_STATUS.ERROR,
						message: 'Error, problem adding your Runnit Board, please try again. If the problem continues please reach out to us with a support ticket',
						show: true,
					});
					return;
				}

				await this.updateLoader({
					show: false,
					message: null,
				});
			}
			await this.routerPush(this.$route, this.$router, {
				name: ROUTER.RUNNITS_BOARD,
				params: {
					runnitId: runnitId,
				},
				query: queryParams,
			});
		},
		async createRunnitDraft (runnitNodeDef, type: RunnitType) {
			const chosenRandomAvatar = await this.getRandomDefaultAvatar();
			const nowDate: Date = new Date();
			let title = 'Untitled Runnit Board';
			if (runnitNodeDef && runnitNodeDef.title) {
				title = type === RUNNIT_TYPE.SINGLE_TOOL ? runnitNodeDef.title : `My ${runnitNodeDef.title}`
			}

			return {
				createdAt: nowDate,
				deletedAt: null,
				userId: this.user.id,
				...(this.runnitState.runnitsOwnerSelection === RUNNITS_OWNER_SELECTION.TEAM ? {
					teamId: this.team.id,
					accessLevel: this.runnitState.runnitsAccessLevel || RUNNITS_ACCESS_LEVEL.PRIVATE,
				} : {
					teamId: null,
					accessLevel: null,
				}),
				title,
				description: null,
				...(chosenRandomAvatar ? {
					avatarId: chosenRandomAvatar.id,
					avatar: chosenRandomAvatar,
				} : {}),
			};
		},
		canCreateNewRunnit (isSingleTool = false) {
			if (isSingleTool) return true;
			if (
				this.loadingRunnits ||
				(
					this.runnitState.runnitsOwnerSelection === RUNNITS_OWNER_SELECTION.TEAM &&
					!this.team
				)
			) {
				return false;
			}

			if (this._get(this.team, 'isActive')) {
				// DEPRECATED - Please stop supporting this soon when we have team Runnit subscriptions done
				return true;
			}

			const unlimitedRunnits: boolean = !!this._get(this.user, 'clubInfo.limit.unlimitedRunnits');
			const numRunnitsLimit: number = this._get(this.user, 'clubInfo.limit.numRunnitsLimit') || 1;
			const tooManyRunnits: boolean = !unlimitedRunnits && this.runnits.length >= numRunnitsLimit;

			if (
				!this.user.isAdmin &&
				tooManyRunnits
			) {
				this.setUpsellDialog({ runnitsIsAtNumRunnitsLimit: true });
				return false;
			}

			return true;
		},
		async createNewRunnit (runnitDraft: Runnit, runnitNodeDef: RunnitNodeDef, type: RunnitType, queryParams: Record<string, any> = {}) {
			if (!this.canCreateNewRunnit(type === RUNNIT_TYPE.SINGLE_TOOL)) return;

			if (type === RUNNIT_TYPE.SINGLE_TOOL && !runnitNodeDef) {
				console.error('Error opening single use tool, tool not defined');

				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: 'Error, opening the tool, please try again. If the problem continues please reach out to us with a support ticket.',
					show: true,
				});
				return;
			}

			try {
				let message = `Adding a Runnit${this._get(runnitNodeDef, 'title') ? ` with ${this._get(runnitNodeDef, 'title')}` : ''}, please wait...`;
				if (type === RUNNIT_TYPE.SINGLE_TOOL) {
					message = `Setting ${this._get(runnitNodeDef, 'title')} up, please wait...`
				}
				await this.updateLoader({
					show: true,
					message,
				});
				this.addingNewRunnit = true;
				const addedRunnitRef = await db
					.collection('runnits')
					.add({
						...runnitDraft,
						...(runnitDraft.avatarId ? {
							avatarId: runnitDraft.avatarId,
							avatar: db.doc(`avatars/${runnitDraft.avatarId}`)
						} : {
							avatarId: null,
							avatar: null,
						}),
						type: type || RUNNIT_TYPE.WORKFLOW,
						...(type === RUNNIT_TYPE.SINGLE_TOOL && {
							singleToolNodeDefId: runnitNodeDef.id,
							teamId: null,
							accessLevel: null,
						}),
					});
				if (addedRunnitRef && addedRunnitRef.id) {
					await this.onOfferingCardClick(addedRunnitRef.id, runnitNodeDef, queryParams);
				}
			} catch (e) {
				console.error('Error adding runnit', e);

				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: 'Error, problem adding your Runnit Board, please try again. If the problem continues please reach out to us with a support ticket',
					show: true,
				});
			} finally {
				this.addingNewRunnit = false;

				this.updateRunnitState({
					runnitDraft: null,
					runnitDraftNodeRef: null,
				});

				await this.updateLoader({
					show: false,
					message: null,
				});
			}
		},
		async findMatchingSingleToolRunnit (runnitNodeDef: RunnitNodeDef) {
			const runnitRef = db.collection('runnits')
				.where('type', '==', RUNNIT_TYPE.SINGLE_TOOL)
				.where('singleToolNodeDefId', '==', runnitNodeDef.id)
				.where('userId', '==', this.user.id)
				.where('teamId', '==', null)
				.where('deletedAt', '==', null)
				.limit(1);

			let singleToolRunnit: Runnit = null;

			(await runnitRef.get()).forEach(async (doc: any) => {
				if (singleToolRunnit) return;
				const runnit: Runnit = {
					...doc.data(),
					get id () { return doc.id },
				}
				singleToolRunnit = runnit;
			})
			return singleToolRunnit;
		},
		async onAddRunnitClick (runnitNodeDef: RunnitNodeDef, type: RunnitType = RUNNIT_TYPE.WORKFLOW, queryParams: Record<string, any> = {}) {
			if (type === RUNNIT_TYPE.SINGLE_TOOL) {
				const singleToolRunnit = await this.findMatchingSingleToolRunnit(runnitNodeDef);

				if (singleToolRunnit) {
					await this.routerPush(this.$route, this.$router, {
						name: ROUTER.RUNNITS_BOARD,
						params: {
							runnitId: singleToolRunnit.id,
						},
						query: queryParams,
					});
					return;
				}

				// create a new single tool runnit
				const runnitDraft = await this.createRunnitDraft(runnitNodeDef, type);
				await this.createNewRunnit(runnitDraft, runnitNodeDef, RUNNIT_TYPE.SINGLE_TOOL, queryParams);
				return;
			}

			if (!this.canCreateNewRunnit(type === RUNNIT_TYPE.SINGLE_TOOL)) return;

			const runnitDraft = await this.createRunnitDraft(runnitNodeDef, type);

			this.updateRunnitState({
				runnitDraft,
				runnitDraftNodeRef: runnitNodeDef,
				runnit: null,
				configureRunnitOpen: true,
			});
		},
		async getRandomDefaultAvatar () {
			const avatars = [];
			try {
				const avatarsRef = db.collection('avatars')
					.where('deletedAt', '==', null)
					.where('userId', '==', 'DEFAULT')
					.where('useCase', '==', 'RUNNIT_LIB')
					.orderBy('name', 'asc');

				(await avatarsRef.get()).forEach(async (doc: any) => {
					avatars.push({
						...doc.data(),
						get id () { return doc.id },
					} as Avatar);
				});
			} catch (err) {
				console.error('failed to get default avatar images', err);
			}
			return avatars.length ? _sampleSize(avatars, 1)[0] : null;
		},
		getFieldDefUuidByFieldType (nodeDefFields: RunnitNodeDef['fields'], fieldType: RunnitNodeFieldType) {
			for (const groupOrField of nodeDefFields) {
				const group: RunnitNodeFieldGroup = groupOrField as RunnitNodeFieldGroup;
				const field: RunnitNodeField = groupOrField as RunnitNodeField;
				if (group.__rgroup) {
					this.getFieldDefUuidByFieldType(group.fields, fieldType);
				} else if (field.__rfield) {
					if (field.type === fieldType) {
						return field.fieldDefUuid;
					}
				}
			}
		},
	},
});
