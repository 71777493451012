import Vue from 'vue';
import {
	DEFAULT_SHOW_TEXT_COUNTER_AT,
	DEFAULT_TEXT_MAX_LENGTH,
	getTextMaxLengthRule,
} from '@/utils';
import _trim from 'lodash/trim';
import _isNil from 'lodash/isNil';

export const RunnitDynamicFieldMixin = Vue.extend({
	props: {
		field: { type: Object, required: true },
	},
	data () {
		return {
			DEFAULT_SHOW_TEXT_COUNTER_AT,
			DEFAULT_TEXT_MAX_LENGTH,
			rules: {
				requiredTextField: [
					getTextMaxLengthRule(),
					v => !!_trim(v) || 'Required',
				],
				optionalTextField: [
					getTextMaxLengthRule(),
				],
				requiredNumberField: [
					v => !_isNil(v) || 'Required',
				],
				optionalNumberField: [],
				requiredSelectField: [
					v => !_isNil(v) || 'Required',
				],
				optionalSelectField: [],
				requiredRadioGroup: [
					v => !_isNil(v) || 'Required',
				],
				optionalRadioGroup: [],
				requiredFileInput: [
					v => !!v || 'File selection required ',
				],
				optionalFileInput: [],
				requiredInpaintingInput: [
					v => !!v || 'Mask is required',
				],
			},
		};
	},
});
