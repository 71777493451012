import { render, staticRenderFns } from "./TokenCalculationsTable.vue?vue&type=template&id=2077e633&scoped=true"
import script from "./TokenCalculationsTable.vue?vue&type=script&lang=ts"
export * from "./TokenCalculationsTable.vue?vue&type=script&lang=ts"
import style0 from "./TokenCalculationsTable.vue?vue&type=style&index=0&id=2077e633&prod&lang=scss"
import style1 from "./TokenCalculationsTable.vue?vue&type=style&index=1&id=2077e633&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2077e633",
  null
  
)

export default component.exports