
import Vue from 'vue';
import BorderSelectCard from '@/components/BorderSelectCard.vue';
import { mapState } from 'vuex';
import NewUserWelcomeTitle from '@/components/PromoBanners/NewUserWelcomeDialog/NewUserWelcomeTitle.vue';
import { CivitaiModel, CivitaiModelVersion, APP_KEY } from '@run-diffusion/shared';
import { getModelTypeDisplayText } from '@/utils';
import { ROUTER } from '@/router/constants';
import RunnitsVerticalLogoSVG from '@/assets/RunnitsVerticalLogoSVG.vue';

export default Vue.extend({
	name: 'NewUserWelcomeDialog',
	props: {
		value: { type: Boolean, default: false },
		persistent: { type: Boolean, default: false },
		maxWidth: { type: Number, default: 1050 },
	},
	data () {
		return {
			open: false,
			selection: null,
			SELECTION_CHOICES: {
				NOT_NEW: 'NOT_NEW',
				NEW: 'NEW',
			},

			windowView: 0,
			DEFAULT_WELCOME_WINDOW_ITEM: 0,
		};
	},
	computed: {
		...mapState([
			'preloadModel',
		]),
		isAuto1111Recommended () {
			const preloadModelModel: CivitaiModel = this._get(this.preloadModel, 'civitai.model') || null;
			const preloadModelModelVersion: CivitaiModelVersion = this._get(this.preloadModel, 'civitai.modelVersion') || null;
			return !!(
				preloadModelModel &&
				preloadModelModelVersion &&
				(
					![
						'CHECKPOINT',
						'LORA',
					].includes(preloadModelModel.type.toUpperCase()) ||
					!preloadModelModelVersion.baseModel.startsWith('SDXL')
				)
			);
		},
	},
	created () {
		this.open = !!this.value;
	},
	watch: {
		value (newVal: boolean) {
			this.open = !!newVal;
		},
	},
	methods: {
		getModelTypeDisplayText,
		setOpen (val: boolean) {
			this.open = !!val;
			if (this.open !== this.value) {
				this.$emit('input', this.open);
			}
		},
		onCancel () {
			this.setOpen(false);
		},
		onSelection (selection) {
			if (this.disabled) return;
			this.selection = selection;
		},
		onSubmit () {
			if (this.selection === this.SELECTION_CHOICES.NEW) {
				this.routerPush(this.$route, this.$router, { name: ROUTER.RUNNITS_HOME });
				this.onCancel();
			} else {
				this.$emit('on-app-key-select', APP_KEY.AUTO_001);
			}
		},
	},
	components: {
		RunnitsVerticalLogoSVG,
		NewUserWelcomeTitle,
		BorderSelectCard,
	},
});
