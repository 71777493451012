
import Vue from 'vue';
import { mapActions } from 'vuex';
import ListItem from '@/components/base/ListItem.vue';

export default Vue.extend({
	name: 'AccountMenuBtn',
	props: {
		isListItem: { type: Boolean, default: false },
		large: { type: Boolean, default: false },
		btnTextColor: { type: String, default: 'var(--blue-grey-200)' },
	},
	methods: {
		...mapActions([
			'updateToolbar',
		]),
		openAccountMenu () {
			this.updateToolbar({
				mobileMenuOpen: false,
				accountMenuOpen: true,
			});
		},
	},
	components: {
		ListItem,
	},
});
