
import Vue from 'vue';
import { RunnitDynamicFieldMixin } from '@/views/Runnits/RunnitSettings/mixins/RunnitDynamicFieldMixin';
import RunnitModelsDialog from '../base/RunnitModelsDialog.vue';
import RunnitImage from "@/views/Runnits/RunnitImage.vue";
import { db } from "@/firebase";
import { Avatar, Model } from "@run-diffusion/shared";

export default Vue.extend({
	name: 'RunnitModelSelector',
	mixins: [
		RunnitDynamicFieldMixin,
	],
	props: {
		value: { type: [Object, String], default: null },
		field: { type: Object, required: true },
	},
	data () {
		return {
			open: false,
		};
	},
	computed: {
		fieldDisplayArchitecture() {
			return this.field.display?.architecture;
		},
	},
	watch: {
		value: {
			immediate: true,
			async handler (newVal) {
				if (!newVal) {
					return
				}

				if (typeof newVal.model === 'string') {
					try {
						const modelId = newVal.model;
						const modelSnapshot = await db.doc(`models/${modelId}`).get();
						const modelData: Model = modelSnapshot.data() as Model;

						if (modelSnapshot.exists && modelData) {
							const avatarSnapshot = await db.doc(`avatars/${modelData.avatarId}`).get();
							modelData.avatar = avatarSnapshot.data() as Avatar;
							this.onModelSelect(modelData);
						} else {
							this.onModelSelect(null);
						}
					} catch (error) {
						console.error('Error getting model', error);
						this.onModelSelect(null);
					}
				}
			},
		},
	},
	methods: {
		onOpenDialog () {
			this.open = true;
		},
		onModelSelect (model) {
			this.$emit('input', { model });
		},
	},
	components: {
		RunnitImage,
		RunnitModelsDialog,
	},
});
