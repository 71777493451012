import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VDialog,{attrs:{"value":_vm.open,"max-width":_vm.maxWidth,"persistent":_vm.persistent,"overlay-opacity":"0.8"},on:{"input":_vm.setOpen}},[_c('div',{staticClass:"runnit-dialog-outer"},[_c('div',{staticClass:"runnit-dialog-body",style:({
				...(_vm.bodyHeight && {
					height: _vm.bodyHeight,
				}),
			})},[(_vm.bannerSrc && _vm.$vuetify.breakpoint.smAndUp)?_c('div',{staticClass:"runnit-dialog-banner"},[_c('img',{staticStyle:{"height":"184px","border-radius":"var(--global-border-radius)"},attrs:{"src":_vm.bannerSrc,"alt":"Banner"}})]):_vm._e(),_c('div',{staticClass:"py-12 d-flex flex-column"},[_c(VSpacer),(_vm.title)?_c('div',{staticClass:"mb-12 runnit-dialog-title",style:({ fontSize: _vm.$vuetify.breakpoint.smAndUp ? '28px' : '20px' })},[_c('span',{staticClass:"gradient-text"},[_vm._v(_vm._s(_vm.title))])]):_vm._e(),_c('div',{staticClass:"mb-12 runnit-dialog-body-inner"},[_vm._t("default")],2),(_vm.showCancel || _vm.showConfirm)?_c('div',{staticClass:"runnit-dialog-actions",style:(_vm.actionsStyles)},[(_vm.showCancel)?_c('base-button',_vm._b({staticClass:"mx-1",staticStyle:{"min-width":"180px"},on:{"click":_vm.onCancel}},'base-button',{
							..._vm.cancelBtnProps,
						},false),[_vm._v(" "+_vm._s(_vm.cancelText)+" ")]):_vm._e(),(_vm.showConfirm)?_c('base-button',_vm._b({staticClass:"mx-1",staticStyle:{"min-width":"180px"},on:{"click":function($event){return _vm.$emit('on-confirm')}}},'base-button',{
							..._vm.confirmBtnProps,
						},false),[_vm._v(" "+_vm._s(_vm.confirmText)+" ")]):_vm._e()],1):_vm._e(),_c(VSpacer)],1),_c('base-button',{staticStyle:{"position":"absolute","top":"22px","right":"22px"},attrs:{"icon":"","text":""},on:{"click":_vm.onCancel}},[_c(VIcon,{staticStyle:{"color":"rgba(255, 255, 255, 0.6)","font-size":"30px"}},[_vm._v(" mdi-close-circle-outline ")])],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }