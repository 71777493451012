
import Vue from 'vue';
import { mapActions } from 'vuex';
import ListItem from '@/components/base/ListItem.vue';
import GetSupportDialog from '@/components/Toolbar/GetSupportBtn/GetSupportDialog.vue';

export default Vue.extend({
	name: 'GetSupportBtn',
	props: {
		isSecondaryButton: { type: Boolean, default: false },
		isPromoButton: { type: Boolean, default: false },
		isListItem: { type: Boolean, default: false },
		insideRunnits: { type: Boolean, default: false },
		large: { type: Boolean, default: false },
		btnTextColor: { type: String, default: 'var(--blue-grey-200)' },
	},
	data () {
		return {
			LONG_BTN_TEXT: 'Chat w/ Support, FAQs, Resources & Tutorials',
			getSupportDialogOpen: false,
		};
	},
	methods: {
		...mapActions([
			'updateToolbar',
		]),
		toggleGetSupportDialog () {
			this.getSupportDialogOpen = !this.getSupportDialogOpen;
		},
	},
	components: {
		GetSupportDialog,
		ListItem,
	},
});
