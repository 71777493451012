import Vue from 'vue';
import VueRouter, { NavigationGuardNext, RouteConfig } from 'vue-router';
import { Route } from 'vue-router/types/router';
import Sessions from '@/views/Sessions/Sessions.vue';
import Launch from '@/views/Launch/Launch.vue';
import Login from '@/views/Login/Login.vue';
import EmailVerification from '@/views/EmailVerification/EmailVerification.vue';
import GoToPayment from '@/views/GoToPayment/GoToPayment.vue';
import DiscordCallback from '@/views/DiscordCallback/DiscordCallback.vue';
import Team from '@/views/Team/Team.vue';
import TeamReports from '@/views/Reports/TeamReports.vue';
import UserNotActivated from '@/views/UserNotActivated.vue';
import Page404 from '@/views/Page404.vue';
import ServerMaintenance from '@/views/ServerMaintenance.vue';
import Prototyping from '@/views/Prototyping/Prototyping.vue';
import AdminSurveys from '@/views/Admin/AdminSurveys/AdminSurveys.vue';
import AdminUsersManagement from '@/views/Admin/AdminUsersManagement/AdminUsersManagement.vue';
import AdminEditSoftwareOffers from '@/views/Admin/AdminEditSoftwareOffers/AdminEditSoftwareOffers.vue';
import AdminEditAppOffers from '@/views/Admin/AdminEditAppOffers/AdminEditAppOffers.vue';
import AdminSessionsDashboard from '@/views/Admin/AdminSessionsDashboard/AdminSessionsDashboard.vue';
import store from '@/store';
import {
	shouldRedirectToHome,
	shouldRedirectToLogin,
	shouldRedirectFromUserNotActivated,
	shouldRedirectToUserNotActivated,
	shouldRedirectToEmailNotVerified,
} from '@/router/utils';
import { ROUTER } from '@/router/constants';
import _omit from 'lodash/omit';
import _isEqual from 'lodash/isEqual';
import _get from 'lodash/get';
import SetupSession from '@/views/Sessions/SetupSession/SetupSession.vue';
import { getProxyAdminToken, setCivitaiModelId, setCivitaiModelVersionId } from '@/utils';
import WorkshopPage from '@/views/Workshops/WorkshopPage/WorkshopPage.vue';
import WorkshopWaitingLobby from '@/views/Workshops/WorkshopPage/WorkshopWaitingLobby.vue';
import Runnits from '@/views/Runnits/Runnits.vue';
import RunnitsApp from '@/views/Runnits/RunnitsApp.vue';
import RunnitsHome from '@/views/Runnits/pages/RunnitsHome.vue';
import RunnitsLibraryPage from '@/views/Runnits/pages/RunnitLibraryPage.vue';
import RunnitPrivatePage from '@/views/Runnits/pages/RunnitPrivatePage.vue';
import RunnitSharedPage from '@/views/Runnits/pages/RunnitSharedPage.vue';
import RunnitAllToolsPage from '@/views/Runnits/pages/RunnitAllToolsPage.vue';
import RunnitBoard from '@/views/Runnits/RunnitBoard.vue';
import AppMetricsReport from '@/views/Reports/AppMetricsReport.vue';
import ImageGenerationsReport from '@/views/Reports/ImageGenerationsReport.vue';
import UserUsageReport from '@/views/Reports/UserUsageReport.vue';
import ImageAuditReport from '@/views/Reports/ImageAuditReport.vue';
import WorkshopsList from '@/views/Team/WorkshopsList.vue';
import TeamMembers from '@/views/Team/TeamMembers.vue';
import SpacesList from '@/views/Team/SpacesList.vue';
import RunnitsSubscriptionsPage from '@/views/Runnits/pages/RunnitsSubscriptionsPage/RunnitsSubscriptionsPage.vue';
import RunnitModels from "@/views/Runnits/pages/models/RunnitModels.vue";
import RunnitModelsListPage from "@/views/Runnits/pages/models/RunnitModelsListPage.vue";
import RunnitTrainModelOptions from "@/views/Runnits/pages/models/RunnitTrainModelOptions.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
	{
		path: '/',
		name: 'Sessions',
		component: Sessions,
		meta: {
			needsCaptureSignInData: true,
			isSessionPage: true,
			needsEmailVerification: true,
		},
	},
	{
		path: '/setup-session',
		name: 'SetupSession',
		component: SetupSession,
		meta: {
			needsCaptureSignInData: true,
			isSessionPage: true,
			needsEmailVerification: true,
		},
	},
	{
		path: '/another-session',
		name: 'AnotherSession',
		component: Sessions,
		meta: {
			isSessionPage: true,
			needsEmailVerification: true,
		},
	},
	{
		path: '/setup-another-session',
		name: 'SetupAnotherSession',
		component: SetupSession,
		meta: {
			isSessionPage: true,
			needsEmailVerification: true,
		},
	},
	{
		path: '/launch/:sessionId',
		name: 'Launch',
		component: Launch,
		meta: {
			needsCaptureSignInData: true,
			isSessionPage: true,
			needsEmailVerification: true,
		},
	},
	{
		path: '/login',
		name: 'Login',
		component: Login,
		meta: {
			isLoginPage: true,
		},
	},
	{
		path: '/team-signup',
		name: 'TeamSignup',
		component: Login,
		meta: {
			isLoginPage: true,
			forcedLoginRequired: true,
		},
	},
	{
		path: '/email-verification',
		name: 'EmailVerification',
		component: EmailVerification,
		meta: {
			isLoginPage: true,
		},
	},
	{
		path: '/go-to-payment',
		name: 'GoToPayment',
		component: GoToPayment,
		meta: {
			preventLaunchSession: true,
			needsEmailVerification: true,
			noTopBar: true,
			noTopBarPadding: true,
		},
	},
	{
		path: '/discord-callback',
		name: 'DiscordCallback',
		component: DiscordCallback,
		meta: {
			preventLaunchSession: true,
			needsEmailVerification: true,
			noTopBar: true,
			noTopBarPadding: true,
		},
	},
	{
		path: '/team',
		component: Team,
		meta: {
			needsCaptureSignInData: true,
			preventLaunchSession: true,
			needsEmailVerification: true,
		},
		children: [
			{
				path: '/',
				name: ROUTER.TEAM_MEMBERS,
				component: TeamMembers,
				meta: {
					needsCaptureSignInData: true,
					preventLaunchSession: true,
					needsEmailVerification: true,
				},
			},
			{
				path: 'spaces',
				name: ROUTER.SPACES,
				component: SpacesList,
				meta: {
					needsCaptureSignInData: true,
					preventLaunchSession: true,
					needsEmailVerification: true,
				},
			},
			{
				path: 'workshops',
				name: ROUTER.WORKSHOPS,
				component: WorkshopsList,
				meta: {
					needsCaptureSignInData: true,
					preventLaunchSession: true,
					needsEmailVerification: true,
				},
			},
		],
	},
	{
		path: '/team-reporting',
		name: 'TeamReporting',
		component: TeamReports,
		meta: {
			needsCaptureSignInData: true,
			preventLaunchSession: true,
			needsEmailVerification: true,
		},
		children: [
			{
				path: 'app-metrics',
				name: ROUTER.APP_METRICS_REPORT,
				component: AppMetricsReport,
				meta: {
					needsCaptureSignInData: true,
					preventLaunchSession: true,
					needsEmailVerification: true,
				},
			},
			{
				path: 'image-generations',
				name: ROUTER.IMAGE_GENERATIONS_REPORT,
				component: ImageGenerationsReport,
				meta: {
					needsCaptureSignInData: true,
					preventLaunchSession: true,
					needsEmailVerification: true,
				},
			},
			{
				path: 'user-usage',
				name: ROUTER.USER_USAGE_REPORT,
				component: UserUsageReport,
				meta: {
					needsCaptureSignInData: true,
					preventLaunchSession: true,
					needsEmailVerification: true,
				},
			},
			{
				path: 'image-audit',
				name: ROUTER.IMAGE_AUDIT_REPORT,
				component: ImageAuditReport,
				meta: {
					needsCaptureSignInData: true,
					preventLaunchSession: true,
					needsEmailVerification: true,
				},
			},
		],
	},
	{
		path: '/workshop/:workshopId',
		name: 'Workshop',
		component: WorkshopPage,
		meta: {
			needsCaptureSignInData: true,
			preventLaunchSession: true,
			needsEmailVerification: true,
		},
	},
	{
		path: '/join-workshop/:workshopId/join-session/:workshopSessionId',
		name: 'WorkshopWaitingLobby',
		component: WorkshopWaitingLobby,
		meta: {
			isLoginPage: true,
			needsEmailVerification: true,
			pageColor: '#1d1a27', // --dark-purple-2
			noTopBar: true,
		},
	},
	{
		path: '/proto',
		name: 'Prototyping',
		component: Prototyping,
		meta: {
			isInternalAdminPage: true,
			needsEmailVerification: true,
		},
	},
	{
		path: '/runnit',
		alias: [
			'/runnits', // `runnits` is an old route alias
		],
		name: ROUTER.RUNNITS,
		component: Runnits,
		meta: {
			needsCaptureSignInData: true,
			preventLaunchSession: true,
			needsEmailVerification: true,
			isRunnitsSideNavPage: true,
		},
		children: [
			{
				path: 'home',
				name: ROUTER.RUNNITS_HOME,
				component: RunnitsHome,
				meta: {
					needsCaptureSignInData: true,
					preventLaunchSession: true,
					needsEmailVerification: true,
					isRunnitsSideNavPage: true,
				},
			},
			{
				path: 'library',
				name: ROUTER.RUNNITS_LIBRARY,
				component: RunnitsLibraryPage,
				meta: {
					needsCaptureSignInData: true,
					preventLaunchSession: true,
					needsEmailVerification: true,
					isRunnitsSideNavPage: true,
				},
			},
			{
				path: 'private',
				name: ROUTER.RUNNITS_PRIVATE,
				component: RunnitPrivatePage,
				meta: {
					needsCaptureSignInData: true,
					preventLaunchSession: true,
					needsEmailVerification: true,
					isRunnitsSideNavPage: true,
				},
			},
			{
				path: 'shared',
				name: ROUTER.RUNNITS_SHARED,
				component: RunnitSharedPage,
				meta: {
					needsCaptureSignInData: true,
					preventLaunchSession: true,
					needsEmailVerification: true,
					isRunnitsSideNavPage: true,
				},
			},
			{
				path: 'all-tools/:tag?',
				name: ROUTER.RUNNITS_ALL_TOOLS,
				component: RunnitAllToolsPage,
				meta: {
					needsCaptureSignInData: true,
					preventLaunchSession: true,
					needsEmailVerification: true,
					isRunnitsSideNavPage: true,
				},
			},
			{
				path: 'subscriptions',
				name: ROUTER.RUNNITS_SUBSCRIPTIONS,
				component: RunnitsSubscriptionsPage,
				meta: {
					preventLaunchSession: true,
					needsEmailVerification: true,
					isRunnitsSideNavPage: true,
				},
			},
			{
				path: 'models',
				name: ROUTER.RUNNITS_MODELS,
				component: RunnitModels,
				meta: {
					needsCaptureSignInData: true,
					preventLaunchSession: true,
					needsEmailVerification: true,
					isRunnitsSideNavPage: true,
					canAccess: () => store.state.user?.isAdmin || store.state.user?.features?.runnitModels,
				},
				children: [
					{
						path: 'list',
						name: ROUTER.RUNNITS_MODELS_LIST,
						component: RunnitModelsListPage,
						meta: {
							needsCaptureSignInData: true,
							preventLaunchSession: true,
							needsEmailVerification: true,
							isRunnitsSideNavPage: true,
							canAccess: () => store.state.user?.isAdmin || store.state.user?.features?.runnitModels,
						}
					},
					{
						path: 'train',
						name: ROUTER.RUNNITS_TRAIN_MODEL_OPTIONS,
						component: RunnitTrainModelOptions,
						meta: {
							preventLaunchSession: true,
							needsEmailVerification: true,
							isRunnitsSideNavPage: true,
							canAccess: () => store.state.user?.isAdmin || store.state.user?.features?.modelTraining,
						}
					}
				]
			},
			{
				path: ':runnitId',
				component: RunnitsApp,
				meta: {
					needsCaptureSignInData: true,
					preventLaunchSession: true,
					needsEmailVerification: true,
					noTopBar: true,
				},
				children: [
					{
						path: '/',
						name: ROUTER.RUNNITS_BOARD,
						component: RunnitBoard,
						meta: {
							needsCaptureSignInData: true,
							preventLaunchSession: true,
							needsEmailVerification: true,
							noTopBar: true,
						},
					},
				],
			},
		],
	},
	{
		path: '/admin/surveys',
		name: 'AdminSurveys',
		component: AdminSurveys,
		meta: {
			isInternalAdminPage: true,
			needsEmailVerification: true,
		},
	},
	{
		path: '/admin/users-management',
		name: 'AdminUsersManagement',
		component: AdminUsersManagement,
		meta: {
			isInternalAdminPage: true,
			needsEmailVerification: true,
		},
	},
	{
		path: '/admin/sessions-dashboard',
		name: 'AdminSessionsDashboard',
		component: AdminSessionsDashboard,
		meta: {
			isInternalAdminPage: true,
			needsEmailVerification: true,
		},
	},
	{
		path: '/admin/edit-software-offers',
		name: 'AdminEditSoftwareOffers',
		component: AdminEditSoftwareOffers,
		meta: {
			isInternalAdminPage: true,
			needsEmailVerification: true,
		},
	},
	{
		path: '/admin/edit-app-offers',
		name: 'AdminEditAppOffers',
		component: AdminEditAppOffers,
		meta: {
			isInternalAdminPage: true,
			needsEmailVerification: true,
		},
	},
	{
		path: '/user-not-activated',
		name: 'UserNotActivated',
		component: UserNotActivated,
	},
	{
		path: '/server-maintenance',
		name: 'ServerMaintenance',
		component: ServerMaintenance,
		meta: {
			hideAddFunds: true,
		},
	},
	{
		path: '/*',
		name: 'Page404',
		component: Page404,
	},
	// {
	// 	path: '/about',
	// 	name: 'About',
	// 	// route level code-splitting
	// 	// this generates a separate chunk (about.[hash].js) for this route
	// 	// which is lazy-loaded when the route is visited.
	// 	component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
	// },
];

const router = new VueRouter({
	mode: 'history',
	routes,
});

router.beforeEach(async (to: Route, from: Route, next: NavigationGuardNext) => {
	if (shouldRedirectToLogin(to, store.state.user)) {
		// appQueryParamsData that should go to vuex no matter what
		const creatorsClubSignUp: boolean = !!_get(to.query, 'creatorsClubSignUp');
		const openManageMembership: boolean = !!_get(to.query, 'openManageMembership');
		const openManageTeamSubscription: boolean = !!_get(to.query, 'openManageTeamSubscription');
		if (
			creatorsClubSignUp ||
			openManageMembership ||
			openManageTeamSubscription
		) {
			store.commit('updateAppQueryParamsData', {
				...(creatorsClubSignUp && { creatorsClubSignUp }),
				...(openManageMembership && { openManageMembership }),
				...(openManageTeamSubscription && { openManageTeamSubscription }),
			});
		}

		// loginQueryParamsData that should go to vuex no matter what
		const autoLoginSsoProvider: string = _get(to.query, 'autoLoginSsoProvider') as string;
		if (autoLoginSsoProvider) {
			store.commit('updateLoginQueryParamsData', {
				...(autoLoginSsoProvider && { autoLoginSsoProvider }),
			});
		}

		// Remove incoming query parameters
		const toQuery: Record<string, string | (string | null)[]> = _omit(to.query || {}, [
			...(openManageMembership ? ['openManageMembership'] : []),
			...(openManageTeamSubscription ? ['openManageTeamSubscription'] : []),
			...(to.query.autoLoginSsoProvider && typeof to.query.autoLoginSsoProvider === 'string' ? ['autoLoginSsoProvider'] : []),
		]);

		// Add redirect query param
		const updatedTo: any = {
			name: to.name,
			params: to.params,
			hash: to.hash,
			query: toQuery,
		};
		const toFullPath: string = router.resolve(updatedTo).href;
		if (toFullPath !== '/') {
			toQuery.redirect = toFullPath;
		}

		// Next route
		router.replace({
			name: 'Login',
			query: toQuery,
		});
	} else if (shouldRedirectToEmailNotVerified(to, store.state.authUser)) {
		router.replace({
			name: 'EmailVerification',
			query: {
				...(to.fullPath !== '/' && {
					redirect: to.fullPath,
				}),
			},
		});
	} else if (shouldRedirectFromUserNotActivated(to, store.state.user)) {
		router.replace({
			name: 'Sessions',
		});
	} else if (shouldRedirectToUserNotActivated(to, store.state.user)) {
		router.replace({
			name: 'UserNotActivated',
		});
	} else if (shouldRedirectToHome(to, store.state.user)) {
		router.replace({
			path: '/',
			query: to.query,
		});
	} else if (to.name === 'GoToPayment' && getProxyAdminToken() && !confirm(`You are proxying another user. Are you sure you want to go to their payment screen?`)) {
		router.replace({
			name: 'Sessions',
		});
	} else if (to.meta?.canAccess && !to.meta.canAccess()) {
		router.replace({
			path: '/',
			query: to.query,
		});
	}

	// Remove incoming query parameters
	let toQuery: Record<string, string | (string | null)[]> = to.query;
	if (toQuery.modelVersionId && typeof toQuery.modelVersionId === 'string') {
		setCivitaiModelVersionId(toQuery.modelVersionId);
		toQuery = _omit(toQuery, 'modelVersionId');
	}
	if (toQuery.modelId && typeof toQuery.modelId === 'string') {
		setCivitaiModelId(toQuery.modelId);
		toQuery = _omit(toQuery, 'modelId');
	}

	if (!_isEqual(to.query, toQuery)) {
		next({
			name: to.name,
			params: to.params,
			query: toQuery,
		});
	} else {
		next();
	}
});

export default router;
