
import Vue from 'vue';
import { mapState } from 'vuex';
import ImageGallery from '@/components/ImageGallery/ImageGallery.vue';

export default Vue.extend({
	name: 'ImageGalleryDrawer',
	props: {
		value: { type: Boolean, default: false },
		elIdSuffix: { type: String, required: true },
	},
	data () {
		return {
			open: false,
		};
	},
	created () {
		this.open = !!this.value;
	},
	watch: {
		value (newVal: boolean) {
			this.open = !!newVal;
		},
	},
	computed: {
		...mapState([
			'runnitState',
		]),
		isAppDrawerMode () {
			return !!(
				this.$vuetify.breakpoint.xlOnly &&
				this.runnitState.settingsDrawerOpen &&
				!this.runnitState.imageGalleryDialogOpen
			);
		},
	},
	methods: {
		setOpen (val: boolean) {
			this.open = !!val;
			if (this.open !== this.value) {
				this.$emit('input', this.open);
			}
		},
		onCancel () {
			this.setOpen(false);
		},
	},
	components: {
		ImageGallery,
	},
});
