import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"select-container"},[_c('div',{staticClass:"select-container-option",class:{
			'selected-state': _vm.isSelectAllFilledIn
		},on:{"click":_vm.onSelectAll}},[_c('div',{staticClass:"select-container-option-selection"},[_c(VIcon,{staticStyle:{"color":"var(--grey-500)"}},[_vm._v("mdi-apps")])],1),_c('div',{staticClass:"select-container-option-label"},[_vm._v(" All ")])]),_vm._l((_vm.items),function(item){return _c('div',{key:item.id,staticClass:"select-container-option",class:{
			'selected-state': !_vm.isSelectAllFilledIn && _vm.selectedTypesMap[item.id],
		},on:{"click":function($event){return _vm.handleOptionSelection(item)}}},[_c('div',{staticClass:"select-container-option-selection"},[_c(VIcon,[_vm._v(" "+_vm._s(item.icon)+" ")])],1),_c('div',{staticClass:"select-container-option-label no-select"},[_vm._v(" "+_vm._s(item.label)+" ")])])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }