
import Vue from 'vue';
import { ROUTER } from '@/router/constants';
import { mapActions, mapState } from 'vuex';
import { RUNNITS_OWNER_SELECTION } from '@/views/Runnits/constants';

export default Vue.extend({
	name: 'RunnitsFaqs',
	data () {
		return {
			ROUTER,
		};
	},
	computed: {
		...mapState([
			'runnitState',
		]),
	},
	methods: {
		...mapActions([
			'setUpsellDialog',
			'goToStripeSubscriptionPortal',
		]),
		onOpenRunnitPlanDetailDialog () {
			this.setUpsellDialog({
				runnitsPlanDetailsData: {
					open: true,
					scrollToPrivateBalance: this.runnitState.runnitsOwnerSelection === RUNNITS_OWNER_SELECTION.USER,
					scrollToTeamBalance: this.runnitState.runnitsOwnerSelection === RUNNITS_OWNER_SELECTION.TEAM,
				},
			});
		},
	},
});
