
import Vue from 'vue';
import _trim from 'lodash/trim';
import _truncate from 'lodash/truncate';
import { mapActions, mapGetters, mapState } from 'vuex';
import firebase from 'firebase/app';
import { User } from '@run-diffusion/shared';
import { auth, functions } from '@/firebase';
import { EMAIL_REGEX, SNACKBAR_STATUS } from '@/constants/constants';
import ConfirmDialog from '@/components/base/ConfirmDialog.vue';
import PreloadModelPreview from '@/components/PreloadModelPreview.vue';
import RecaptchaCheckbox from '@/components/RecaptchaCheckbox.vue';
import LoadingSVG from '@/assets/LoadingSVG.vue';
import AuthProviderPickers from '@/views/Login/AuthProviderPickers.vue';
import { getAuthProviderDisplayText } from '@/utils';
import { ROUTER } from '@/router/constants';

export default Vue.extend({
	name: 'Login',
	data () {
		return {
			googlePopupOpen: false,
			facebookPopupOpen: false,
			microsoftPopupOpen: false,
			githubPopupOpen: false,
			ssoPopupOpen: false,

			recaptchaResponse: false,
			email: null,
			password: null,
			confirmPassword: null,
			loadingEmailPasswordAuth: false,
			loadingTeamSignUp: false,
			alreadySignedUp: false,
			signUpTeamMember: null,
			teamName: null,
			showPassword1: false,
			showPassword2: false,
			showPassword3: false,

			windowView: 0,
			SIGN_IN_WINDOW_ITEM: 0,
			SIGN_UP_WINDOW_ITEM: 1,
			FORGOT_PASSWORD_WINDOW_ITEM: 2,

			// FORM
			formValid: true,
			rules: {
				required: [
					v => !!_trim(v) || 'Required',
				],
				email: [
					v => !!_trim(v) || 'Email is required',
					v => EMAIL_REGEX.test(_trim(v)) || 'Incorrect email format',
				],
			},
		};
	},
	async mounted () {
		if (this.isNewTeamMemberSignupFlow) {
			await this.getAndLoadTeamSignUp();
		} else {
			await this.automaticSsoLogin();
		}
	},
	watch: {
		user (newVal: User, oldVal: User) {
			if (
				newVal &&
				newVal !== oldVal &&
				(
					!this.isNewTeamMemberSignupFlow ||
					!this.loadingTeamSignUp
				)
			) {
				this.routeAwayAfterSuccessfulLogin(newVal);
			}
		},
		'$route' (newVal) {
			if (
				newVal.name === 'TeamSignup' &&
				newVal.query &&
				newVal.query.teamName &&
				!this.teamName
			) {
				this.teamName = decodeURIComponent(newVal.query.teamName);
			}
		},
	},
	computed: {
		...mapGetters([
			'isLoggingIn',
		]),
		...mapState([
			'user',
			'snackbar',
			'appQueryParamsData',
			'loginQueryParamsData',
		]),
		isNewTeamMemberSignupFlow () {
			return this.$route.name === 'TeamSignup';
		},
		passwordsMatch () {
			return this.password === this.confirmPassword;
		},
		logInDisabled () {
			return !!(
				!this.email ||
				!this.password ||
				this.loadingEmailPasswordAuth ||
				this.loadingTeamSignUp
			);
		},
		signUpDisabled () {
			return !!(
				!this.recaptchaResponse ||
				!this.email ||
				!this.password ||
				!this.confirmPassword ||
				this.alreadySignedUp ||
				!this.passwordsMatch ||
				this.loadingEmailPasswordAuth ||
				this.loadingTeamSignUp
			);
		},
		authProviderSignUpDisabled () {
			return !!(
				this.alreadySignedUp ||
				this.loadingEmailPasswordAuth ||
				this.loadingTeamSignUp
			);
		},
		passwordResetDisabled () {
			return (
				!this.recaptchaResponse ||
				!this.email ||
				!EMAIL_REGEX.test(_trim(this.email))
			);
		},
	},
	methods: {
		...mapActions([
			'setAuthUserCredential',
			'updateSnackbar',
			'updateLoginQueryParamsData',
		]),
		async automaticSsoLogin () {
			const { autoLoginSsoProvider } = this.loginQueryParamsData;
			if (autoLoginSsoProvider) {
				const providerId: string = `saml.${autoLoginSsoProvider.toLowerCase().replace(/^saml\./, '')}`;
				try {
					await this.logInWithThirdPartyAuthProvider(providerId, false);
				} catch (error) {
					this.onThirdPartyAuthError(providerId, error.code, error.message);
				}
			}
		},
		onThirdPartyAuthError (providerId: string, errorCode: string, errorMessage: string) {
			console.log({
				errorCode,
				errorMessage,
			});

			let message: string = 'Oops! Error signing up, try again.';
			if (errorCode === 'auth/popup-closed-by-user') {
				message = `The ${getAuthProviderDisplayText(providerId, false)} popup has been closed by the user before finalizing the operation.`;
			} else if (errorCode === 'auth/account-exists-with-different-credential') {
				message = `An account already exists with this same email but using a different login method. Sign in using the login method associated with this email address.`;
			} else if (errorCode === 'auth/popup-blocked') {
				message = 'Unable to establish a connection with the popup. It may have been blocked by the browser.';
			} else if (errorCode === 'auth/operation-not-allowed') {
				message = 'The identity provider configuration is not found.';
			} else if (errorCode === 'auth/cancelled-popup-request') {
				return;
			} else if (errorCode) {
				message = errorMessage;
			}

			this.updateSnackbar({
				status: SNACKBAR_STATUS.ERROR,
				message,
				show: true,
				timeout: 20000,
			});
		},
		async logInWithThirdPartyAuthProvider (providerId: string, doPopup: boolean) {
			const provider: firebase.auth.OAuthProvider = new firebase.auth.OAuthProvider(providerId);
			provider.addScope('email');
			provider.addScope('profile');

			if (doPopup) {
				const userCredential: firebase.auth.UserCredential = await auth.signInWithPopup(provider);
				this.setAuthUserCredential(userCredential);

				this.updateSnackbar({
					status: SNACKBAR_STATUS.SUCCESS,
					message: `Login Successful! ${userCredential.user.email}`,
					show: true,
					timeout: 5000,
				});
			} else {
				// Do redirect cuz popups get blocked if invoked programmatically
				await auth.signInWithRedirect(provider);
			}
		},
		async continueWithSsoAuth (providerId: string) {
			if (this.authProviderSignUpDisabled || !providerId) {
				return;
			}

			this.ssoPopupOpen = true;

			try {
				await this.logInWithThirdPartyAuthProvider(providerId, true);
			} catch (error) {
				this.onThirdPartyAuthError(providerId, error.code, error.message);
			} finally {
				this.ssoPopupOpen = false;
			}
		},
		async continueWithGoogleAuth () {
			if (this.authProviderSignUpDisabled) return;

			this.googlePopupOpen = true;
			const providerId: string = 'google.com';

			try {
				await this.logInWithThirdPartyAuthProvider(providerId, true);
			} catch (error) {
				this.onThirdPartyAuthError(providerId, error.code, error.message);
			} finally {
				this.googlePopupOpen = false;
			}
		},
		async continueWithFacebookAuth () {
			if (this.authProviderSignUpDisabled) return;

			this.facebookPopupOpen = true;
			const providerId: string = 'facebook.com';

			try {
				await this.logInWithThirdPartyAuthProvider(providerId, true);
			} catch (error) {
				this.onThirdPartyAuthError(providerId, error.code, error.message);
			} finally {
				this.facebookPopupOpen = false;
			}
		},
		async continueWithMicrosoftAuth () {
			if (this.authProviderSignUpDisabled) return;

			this.microsoftPopupOpen = true;
			const providerId: string = 'microsoft.com';

			try {
				await this.logInWithThirdPartyAuthProvider(providerId, true);
			} catch (error) {
				this.onThirdPartyAuthError(providerId, error.code, error.message);
			} finally {
				this.microsoftPopupOpen = false;
			}
		},
		async continueWithGithubAuth () {
			if (this.authProviderSignUpDisabled) return;

			this.githubPopupOpen = true;
			const providerId: string = 'github.com';

			try {
				await this.logInWithThirdPartyAuthProvider(providerId, true);
			} catch (error) {
				this.onThirdPartyAuthError(providerId, error.code, error.message);
			} finally {
				this.githubPopupOpen = false;
			}
		},
		getTruncatePassword () {
			// Passwords just aren't this long. Prevent security attacks.
			return _truncate(this.password, { length: 1000 });
		},
		async logIn () {
			if (this.logInDisabled) return;
			this.loadingEmailPasswordAuth = true;

			try {
				const userCredential: firebase.auth.UserCredential = await auth.signInWithEmailAndPassword(
					_trim(this.email).toLowerCase(),
					this.getTruncatePassword(),
				);
				this.setAuthUserCredential(userCredential);
			} catch (error) {
				const errorCode = error.code;
				const errorMessage = error.message;

				console.log({
					errorCode,
					errorMessage,
				});

				let message: string = 'Oops! Error logging in, try again.';
				if (errorCode === 'auth/user-not-found') {
					message = 'User not found';
				} else if (errorCode === 'auth/wrong-password') {
					message = 'The password is incorrect or you signed up using a different login method';
				} else if (errorCode === 'auth/user-disabled') {
					message = 'User is disabled';
				}

				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message,
					show: true,
					timeout: 10000,
				});
			} finally {
				this.loadingEmailPasswordAuth = false;
			}
		},
		async signUp () {
			if (this.signUpDisabled) return;
			this.loadingEmailPasswordAuth = true;

			try {
				const userCredential: firebase.auth.UserCredential = await auth.createUserWithEmailAndPassword(
					_trim(this.email).toLowerCase(),
					this.getTruncatePassword(),
				);
				this.setAuthUserCredential(userCredential);
				this.updateSnackbar({
					status: SNACKBAR_STATUS.SUCCESS,
					message: 'New account created!',
					show: true,
				});
			} catch (error) {
				const errorCode = error.code;
				const errorMessage = error.message;

				console.log({
					errorCode,
					errorMessage,
				});

				let message: string = 'Oops! Error signing up, try again.';
				if (errorCode === 'auth/email-already-in-use') {
					message = 'Email already in use';
				} else if (errorCode === 'auth/weak-password') {
					message = 'That password is too weak, choose a stronger password';
				}

				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message,
					show: true,
					timeout: 10000,
				});
			} finally {
				this.loadingEmailPasswordAuth = false;
			}
		},
		async handleForgotPassword () {
			const trimmedEmail: string = _trim(this.email).toLowerCase();
			if (trimmedEmail) {
				try {
					const providerIds: string[] = await auth.fetchSignInMethodsForEmail(trimmedEmail);
					if (!providerIds.length || providerIds.includes('password')) {
						await auth.sendPasswordResetEmail(_trim(this.email));

						this.updateSnackbar({
							status: SNACKBAR_STATUS.SUCCESS,
							message: 'Password reset sent, check your email!',
							show: true,
							timeout: 10000,
						});
					} else {
						this.updateSnackbar({
							status: SNACKBAR_STATUS.ERROR,
							message: `Your account does not have Email & Password sign-in credentials. Sign in using the ${providerIds[0]} credentials associated with this email address.`,
							show: true,
							timeout: 10000,
						});
					}
				} catch (error) {
					const errorCode = error.code;
					const errorMessage = error.message;

					console.log({
						errorCode,
						errorMessage,
					});

					let message: string = 'Uh oh! Error sending password reset, try again';
					if (errorCode === 'auth/user-not-found') {
						message = 'User not found';
					} else if (errorCode === 'auth/user-disabled') {
						message = 'User is disabled';
					}
					this.updateSnackbar({
						status: SNACKBAR_STATUS.ERROR,
						message,
						show: true,
					});
				}
			} else {
				this.$refs.passwordResetEmail.focus();
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: 'Please first provide email',
					show: true,
				});
			}
		},
		async getAndLoadTeamSignUp () {
			const { query } = this.$route;
			const teamId: string = this._get(query, 'teamId', null);
			const inviteCode: string = this._get(query, 'inviteCode', null);
			const isExistingUser: string = this._get(query, 'isExistingUser', null);

			if (!teamId || !inviteCode) return;

			this.windowView = isExistingUser ? this.SIGN_IN_WINDOW_ITEM : this.SIGN_UP_WINDOW_ITEM;

			const onError: Function = (e) => {
				console.error('Error loading up team member to sign up: ', e);
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: 'Problem with loading team to sign up, please reach out to report issues by clicking the support button in our top toolbar',
					show: true,
					timeout: 10000,
				});
			};

			this.loadingTeamSignUp = true;
			try {
				const functionRef = functions
					.httpsCallable('getTeamMemberByInviteCode');
				const { success, alreadySignedUp, teamMember, teamName } = (await functionRef({
					teamId,
					inviteCode,
				})).data;

				if (success) {
					this.signUpTeamMember = teamMember;
					this.teamName = teamName;
					this.email = teamMember.email;
					this.alreadySignedUp = alreadySignedUp;

					if (this.alreadySignedUp) return;

					this.updateLoginQueryParamsData({
						teamId,
						inviteCode,
					});
					if (this.user) {
						this.routeAwayAfterSuccessfulLogin(this.user);
					}
				} else {
					onError(new Error('getTeamMemberByInviteCode did not return success'));
				}
			} catch (e) {
				onError(e);
			} finally {
				this.loadingTeamSignUp = false;
			}
		},
		routeAwayAfterSuccessfulLogin (user: User) {
			if (this.isNewTeamMemberSignupFlow) {
				if (this.signUpTeamMember && user.email !== this.signUpTeamMember.email) {
					this.$emit('logout', true);
				} else {
					this.routerReplace(this.$route, this.$router, {
						name: ROUTER.TEAM_MEMBERS,
						query: {
							finishedConnectingTeam: '1',
						},
					});
				}
			} else {
				this.routerReplace(this.$route, this.$router, this.$route.query.redirect || { name: 'Sessions' });
			}
		},
		resetSignedUpAndGoToLogin () {
			this.alreadySignedUp = false;
			this.$router.replace({ 'query': null });
			this.windowView = this.SIGN_IN_WINDOW_ITEM;
		},
	},
	components: {
		AuthProviderPickers,
		LoadingSVG,
		RecaptchaCheckbox,
		ConfirmDialog,
		PreloadModelPreview,
	},
});
