
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import _trim from 'lodash/trim';
import _last from 'lodash/last';
import moment from 'moment-timezone';
import {
	EMAIL_REGEX,
	SNACKBAR_STATUS,
} from '@/constants/constants';
import {
	getAuthProviderDisplayText,
	DEFAULT_SHOW_TEXT_COUNTER_AT,
	DEFAULT_TEXT_MAX_LENGTH,
	getTextMaxLengthRule,
} from '@/utils';
import firebase from 'firebase/app';
import ConfirmDialog from '@/components/base/ConfirmDialog.vue';
import DialogContent from '@/components/base/DialogContent.vue';
import AuthProviderPickers from '@/views/Login/AuthProviderPickers.vue';
import { asyncForEach, StripeCustomer, User } from '@run-diffusion/shared';
import { auth, functions } from '@/firebase';

export default Vue.extend({
	name: 'LoginSettingsDialog',
	props: {
		value: { type: Boolean, default: false },
	},
	data () {
		return {
			DEFAULT_TEXT_MAX_LENGTH,
			DEFAULT_SHOW_TEXT_COUNTER_AT,

			syncingEmailChange: false,
			sendingPasswordReset: false,
			savingEmail: false,

			changeToPasswordProviderDialogOpen: false,
			googlePopupOpen: false,
			facebookPopupOpen: false,
			microsoftPopupOpen: false,
			githubPopupOpen: false,
			ssoPopupOpen: false,

			editEmailFormValid: false,
			emailRules: [
				(v: string) => !!_trim(v) || 'Email is required',
				getTextMaxLengthRule(),
				v => EMAIL_REGEX.test(_trim(v)) || 'Incorrect email format',
			],

			editModePassword: false,
			editModeEmail: false,
			editModeProvider: false,
			email: null,
		};
	},
	computed: {
		...mapState([
			'isAdminProxyingUser',
			'user',
			'authUser',
			'authUserCredential',
			'stripeCustomer',
		]),
		syncEmailChangeDialogOpen () {
			return this.needSyncEmailChange(this.user, this.authUser, this.stripeCustomer);
		},
		lowercasedEmail () {
			return _trim(this.email).toLowerCase();
		},
		anythingLoading () {
			return !!(
				this.sendingPasswordReset ||
				this.savingEmail
			);
		},
		saveEmailDisabled () {
			return !!(
				this.savingEmail ||
				!this.editEmailFormValid ||
				!this.authUser ||
				this.lowercasedEmail === this.authUser.email
			);
		},
		activeAuthProviderId () {
			return (
				this._get(this.authUserCredential, 'additionalUserInfo.providerId') ||
				this._get(this.authUser, 'providerData[0].providerId') ||
				null
			);
		},
		currAuthProviderDisplayData () {
			const result: { long: string, short: string } = {
				long: null,
				short: null,
			};
			if (!this.activeAuthProviderId) return result;
			return {
				long: getAuthProviderDisplayText(this.activeAuthProviderId, false),
				short: getAuthProviderDisplayText(this.activeAuthProviderId, true),
			};
		},
	},
	created () {
		this.initChangeEmailFields(this.authUser);
	},
	watch: {
		async authUser (newVal: firebase.User, oldVal: firebase.User) {
			if (newVal !== oldVal) {
				this.initChangeEmailFields(newVal);
				await this.checkAuthUserEmailAgainstProviderDataEmail(newVal);
			}
		},
	},
	methods: {
		...mapActions([
			'setAuthUserCredential',
			'updateAuthEmail',
			'updateToolbar',
			'updateSnackbar',
		]),
		getAuthProviderDisplayText,
		onCancelEditModeEmail () {
			this.initChangeEmailFields(this.authUser);
			this.editModeEmail = false;
		},
		initChangeEmailFields (authUser: firebase.User) {
			this.email = (authUser && authUser.email) || null;
		},
		async checkAuthUserEmailAgainstProviderDataEmail (authUser: firebase.User) {
			if (!authUser || !authUser.providerData.length) return;
			// Sync the email if the login email changed (could happen if user switches login providers, and links a different email address)
			const newEmailFromProviderData: string = _last(authUser.providerData).email;
			if (newEmailFromProviderData && newEmailFromProviderData !== authUser.email) {
				await this.saveChangeEmail(newEmailFromProviderData);
			}
		},
		onClose () {
			this.editModePassword = false;
			this.editModeEmail = false;
			this.editModeProvider = false;
			this.initChangeEmailFields(this.authUser);
			this.$emit('on-close');
		},
		async saveChangeEmail (email: string) {
			const newEmail: string = _trim(email).toLowerCase();
			if (!newEmail) return;

			try {
				this.savingEmail = true;
				const { success, showReauthenticateDialog } = await this.updateAuthEmail(newEmail);
				if (showReauthenticateDialog) {
					this.openNeedReauthenticateDialog();
				} else if (success) {
					window.location.reload();
				}
			} catch (e) {
				console.error(e);
			} finally {
				this.savingEmail = false;
			}
		},
		needSyncEmailChange (user: User, authUser: firebase.User, stripeCustomer: StripeCustomer) {
			return !!(
				user &&
				authUser &&
				authUser.email &&
				authUser.emailVerified &&
				user.id === authUser.uid &&
				(
					user.email !== authUser.email ||
					(
						stripeCustomer &&
						stripeCustomer.id === authUser.uid &&
						stripeCustomer.email !== authUser.email
					)
				)
			);
		},
		async doSyncEmailChange () {
			if (this.syncingEmailChange) {
				return;
			}

			try {
				this.syncingEmailChange = true;
				const functionRef = functions
					.httpsCallable('syncEmailChange');
				await functionRef();
			} catch (e) {
				console.error(e);
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: 'Error changing your settings, please reach out to report issues by clicking the support button in our top toolbar',
					show: true,
				});
			} finally {
				this.syncingEmailChange = false;
			}
		},
		openNeedReauthenticateDialog () {
			this.updateToolbar({
				needReauthenticateDialogOpen: true,
			});
		},
		async handleChangeToPasswordProvider () {
			await this.handleChangePassword();
			this.changeToPasswordProviderDialogOpen = false;
			this.onClose();
			if (!this.isAdminProxyingUser) {
				this.$emit('logout', false);
			}
		},
		async handleChangePassword () {
			try {
				this.sendingPasswordReset = true;
				await auth.sendPasswordResetEmail(this.authUser.email);

				this.updateSnackbar({
					status: SNACKBAR_STATUS.SUCCESS,
					message: 'Password reset sent, check your email!',
					show: true,
					timeout: 10000,
				});
				this.windowView = this.ACCOUNT_MENU_WINDOW_ITEM;
				this.changeLoginSettingsDialogOpen = false;
			} catch (e) {
				console.error(e);
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: 'Uh oh! Error sending password reset, try again',
					show: true,
				});
			} finally {
				this.sendingPasswordReset = false;
			}
		},
		async changeToEmailPasswordAuth () {
			this.changeToPasswordProviderDialogOpen = true;
		},
		onThirdPartyAuthError (providerId: string, errorCode: string, errorMessage: string) {
			console.log({
				errorCode,
				errorMessage,
			});

			let message: string = 'Oops! Error signing up, try again.';
			if (errorCode === 'auth/popup-closed-by-user') {
				message = `The ${getAuthProviderDisplayText(providerId, false)} popup has been closed by the user before finalizing the operation.`;
			} else if (errorCode === 'auth/account-exists-with-different-credential') {
				message = 'An account already exists with this same email but using a different login method.';
			} else if (errorCode === 'auth/provider-already-linked') {
				message = 'A user can only be linked to one identity for the given provider.';
			} else if (errorCode === 'auth/popup-blocked') {
				message = 'Unable to establish a connection with the popup. It may have been blocked by the browser.';
			} else if (errorCode === 'auth/operation-not-allowed') {
				message = 'The identity provider configuration is not found.';
			} else if (errorCode === 'auth/cancelled-popup-request') {
				return;
			} else if (errorCode) {
				message = errorMessage;
			}

			this.updateSnackbar({
				status: SNACKBAR_STATUS.ERROR,
				message,
				show: true,
				timeout: 20000,
			});
		},
		shouldAbortThirdPartyAuth () {
			let result: boolean = false;
			const { lastSignInTime } = this.authUser.metadata;

			if (this.anythingLoading || !lastSignInTime) {
				result = true;
			} else {
				// Check recent login time, to make sure user has authenticated recently
				result = moment().subtract(20, 'minutes').isAfter(lastSignInTime);
			}

			if (result) this.openNeedReauthenticateDialog();
			return result;
		},
		initOAuthProvider (providerId: string) {
			const provider: firebase.auth.OAuthProvider = new firebase.auth.OAuthProvider(providerId);
			provider.addScope('email');
			provider.addScope('profile');
			return provider;
		},
		async processChangeToThirdPartyAuth (newProviderId: string) {
			const providerIdsToUnlink: string[] = (this.authUser.providerData || []).map(({ providerId }) => providerId);
			const provider: firebase.auth.OAuthProvider = this.initOAuthProvider(newProviderId);
			const userCredential: firebase.auth.UserCredential = await this.authUser.linkWithPopup(provider);
			await asyncForEach(providerIdsToUnlink, async (providerId: string) => {
				try {
					await this.authUser.unlink(providerId);
				} catch (e) {
					console.error(e);
				}
			});
			this.setAuthUserCredential(userCredential);
			this.editModeProvider = false;

			await this.checkAuthUserEmailAgainstProviderDataEmail(userCredential.user);

			this.updateSnackbar({
				status: SNACKBAR_STATUS.SUCCESS,
				message: `Success! You will now sign in using ${getAuthProviderDisplayText(newProviderId, false)}`,
				show: true,
				timeout: 10000,
			});
		},
		async changeToSsoAuth (providerId: string) {
			if (this.shouldAbortThirdPartyAuth()) return;
			try {
				this.ssoPopupOpen = true;
				await this.processChangeToThirdPartyAuth(providerId);
			} catch (error) {
				this.onThirdPartyAuthError(providerId, error.code, error.message);
			} finally {
				this.ssoPopupOpen = false;
			}
		},
		async changeToGoogleAuth () {
			if (this.shouldAbortThirdPartyAuth()) return;
			const providerId: string = 'google.com';
			try {
				this.googlePopupOpen = true;
				await this.processChangeToThirdPartyAuth(providerId);
			} catch (error) {
				this.onThirdPartyAuthError(providerId, error.code, error.message);
			} finally {
				this.googlePopupOpen = false;
			}
		},
		async changeToFacebookAuth () {
			if (this.shouldAbortThirdPartyAuth()) return;
			const providerId: string = 'facebook.com';
			try {
				this.facebookPopupOpen = true;
				await this.processChangeToThirdPartyAuth(providerId);
			} catch (error) {
				this.onThirdPartyAuthError(providerId, error.code, error.message);
			} finally {
				this.facebookPopupOpen = false;
			}
		},
		async changeToMicrosoftAuth () {
			if (this.shouldAbortThirdPartyAuth()) return;
			const providerId: string = 'microsoft.com';
			try {
				this.microsoftPopupOpen = true;
				await this.processChangeToThirdPartyAuth(providerId);
			} catch (error) {
				this.onThirdPartyAuthError(providerId, error.code, error.message);
			} finally {
				this.microsoftPopupOpen = false;
			}
		},
		async changeToGithubAuth () {
			if (this.shouldAbortThirdPartyAuth()) return;
			const providerId: string = 'github.com';
			try {
				this.githubPopupOpen = true;
				await this.processChangeToThirdPartyAuth(providerId);
			} catch (error) {
				this.onThirdPartyAuthError(providerId, error.code, error.message);
			} finally {
				this.githubPopupOpen = false;
			}
		},
	},
	components: {
		AuthProviderPickers,
		DialogContent,
		ConfirmDialog,
	},
});
