import Vue from 'vue';
import {
	currency,
	centsToDollars,
	commaSeparated,
	currencyTokens,
	hundredthsToTokens,
} from '@run-diffusion/shared';
import pluralize from 'pluralize';
import _join from 'lodash/join';
import _truncate from 'lodash/truncate';
import _lowerCase from 'lodash/lowerCase';
import _upperCase from 'lodash/upperCase';
import _startCase from 'lodash/startCase';
import _words from 'lodash/words';
import _upperFirst from 'lodash/upperFirst';
import prettyBytes from 'pretty-bytes';

/*
Filter methods
 */
export const truncateMiddle: (str: string, length: number) => string = (str: string, length: number): string => {
	if (!str || str.length <= length) return str;

	const ellipsis: string = '...';
	const charsToShow: number = length - ellipsis.length;
	const frontChars: number = Math.ceil(charsToShow / 2);
	const backChars: number = Math.floor(charsToShow / 2);

	return _join([
		_truncate(str.slice(0, frontChars), { length: frontChars, omission: '' }),
		ellipsis,
		_truncate(str.slice(str.length - backChars), { length: backChars, omission: '' })
	], '');
}

export const twoDecimals: (val: number) => number = (val: number): number => {
	if (typeof val !== 'number') return val;
	return Math.round(val * 100) / 100;
};

export const titleCase: (val: string, convertUnderscoreAndDashToSpace?: boolean) => string = (val: string, convertUnderscoreAndDashToSpace: boolean = false): string => {
	if (convertUnderscoreAndDashToSpace) return _startCase(val || '');
	return _words(val || '').map(_upperFirst).join(' ');
};

/*
Add filters to Vue
 */
Vue.filter('currency', currency);
Vue.filter('centsToDollars', centsToDollars);
Vue.filter('currencyTokens', currencyTokens);
Vue.filter('hundredthsToTokens', hundredthsToTokens);
Vue.filter('truncate', _truncate);
Vue.filter('truncateMiddle', truncateMiddle);
Vue.filter('prettyBytes', prettyBytes);
Vue.filter('pluralize', pluralize);
Vue.filter('twoDecimals', twoDecimals);
Vue.filter('commaSeparated', commaSeparated);
Vue.filter('lowerCase', _lowerCase);
Vue.filter('upperCase', _upperCase);
Vue.filter('titleCase', titleCase);
