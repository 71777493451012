
import Vue from 'vue';
import {mapActions, mapGetters, mapState} from "vuex";
import {RUNNITS_OWNER_SELECTION} from "@/views/Runnits/constants";
import {setRunnitsTeamAccessLevel} from "@/utils";
import {
	RUNNITS_ACCESS_LEVEL,
	RunnitsAccessLevel,
	RUNNIT_NODE_DEF_TAG_TYPE,
} from "@run-diffusion/shared";
import {ModelCRUDMixin} from "@/mixins/ModelCRUDMixin";
import EmptyState from "@/components/states/EmptyState.vue";
import {RunnitsCRUDMixin} from "@/mixins";
import ModelCreateOptionsDialog from "@/views/Runnits/pages/models/ModelCreateOptionsDialog.vue";
import OfferingCard from "@/components/OfferingCard.vue";
import ModelToolUseDialog from "@/views/Runnits/pages/models/ModelToolUseDialog.vue";
import { ROUTER } from "@/router/constants";

export default Vue.extend({
	name: 'RunnitModelsListPage',
	computed: {
		...mapState([
			'user',
			'team',
			'modelsState',
			'runnitState',
			'loadingModels',
			'publicRunnitNodeDefTagsMap',
		]),
		...mapGetters('modelUpload', [
			'getUploading',
			'allUploads'
		]),
        uploadingModelsForEnvironment() {
			return this.getUploading.filter(upload =>
				this.runnitState.runnitsOwnerSelection === RUNNITS_OWNER_SELECTION.TEAM
					? upload.model.teamId === this.team.id && upload.model.accessLevel === this.runnitState.runnitsAccessLevel
					: !upload.model.teamId
			);
		},
		canAccessTrainer() {
			return this.user?.isAdmin || this.user?.features?.modelTraining;
		}
	},
	mixins: [RunnitsCRUDMixin, ModelCRUDMixin],
	components: {
		ModelToolUseDialog,
		OfferingCard,
		ModelCreateOptionsDialog,
		EmptyState,
	},
	props: {},
	watch: {
		isModelToolUseDialogOpen (newVal) {
			if (!newVal) {
				this.modelSelected = null;
			}
		},
	},
	data () {
		return {
			RUNNIT_NODE_DEF_TAG_TYPE,
			RUNNITS_ACCESS_LEVEL,
			RUNNITS_OWNER_SELECTION,
			loadingRunnits: false,
			modelList: [],
			isModelCreateOptionsDialogOpen: false,
			isModelToolUseDialogOpen: false,
			modelSelected: null,
		};
	},
	methods: {
		...mapActions([
			'bindModels',
			'updateSnackbar',
			'updateModelState',
			'updateRunnitState',
		]),
		...mapActions('modelUpload', [
			'uploadModel',
		]),
		onModelsAccessLevelChange(accessLevel: RunnitsAccessLevel) {
			setRunnitsTeamAccessLevel(accessLevel);
			this.updateRunnitState({
				runnitsAccessLevel: accessLevel,
			});
		},
		onEditModelSettings(model) {
			if (model) {
				this.updateModelState({
					modelDraft: model,
					modelDialog: {
						...this.modelsState.modelDialog,
						open: true,
						mode: 'edit',
					},
				});
			}
		},
		onModelSelect (model) {
			this.modelSelected = model;
			this.isModelToolUseDialogOpen = true;
		},
		openModelCreateOptions() {
			this.isModelCreateOptionsDialogOpen = true;
		},
		async goToTrainModel() {
			if (!this.canAccessTrainer) {
				return;
			}

			await this.routerPush(this.$route, this.$router, { name: ROUTER.RUNNITS_TRAIN_MODEL_OPTIONS });
		},
	}
});
