
import Vue from 'vue';
import { functions } from '@/firebase';
import _trim from 'lodash/trim';
import _values from 'lodash/values';
import { mapActions, mapState } from 'vuex';
import DialogContent from '@/components/base/DialogContent.vue';
import { EMAIL_REGEX, SNACKBAR_STATUS, TEAM_ROLE_DETAILS } from '@/constants/constants';

export default Vue.extend({
	name: 'InviteTeamMember',
	data () {
		const MAX_NUM_EMAILS: number = 100;
		const hasCeilingNumberOfCommas = (str: string, ceiling: number): boolean => {
			if (!str) return false;
			// Count the number of commas in the string
			const commaCount = str.split(',').length - 1;

			// Compare the comma count to the ceiling and return the result
			return (commaCount <= ceiling);
		};
		const isListOfEmails = (str) => {
			if (!str) return false;
			// First, split the string by commas
			const emails = str.split(',');

			// Loop through each email in the list and check if it's valid
			for (let i = 0; i < emails.length; i++) {
				const email = emails[i].trim(); // Remove any leading/trailing spaces

				// Use a regular expression to check if the email is valid
				if (!EMAIL_REGEX.test(_trim(email))) {
					return false; // If any email is invalid, return false
				}
			}

			// If we made it through the loop without finding any invalid emails, return true
			return true;
		};
		return {
			MAX_NUM_EMAILS: MAX_NUM_EMAILS,
			TEAM_ROLE_DETAILS,
			dialogOpen: false,
			inviteDisabled: false,
			inviteLoading: false,

			inviteEmails: null,

			selectedRole: null,

			// FORM
			formValid: true,
			rules: {
				required: [
					v => !!v || 'Required field',
				],
				commaSeparatedList: [
					v => isListOfEmails(v) || 'Must be a comma separated list of emails',
				],
				maxNumberOfEmails: [
					v => hasCeilingNumberOfCommas(v, MAX_NUM_EMAILS) || `Can only put in ${MAX_NUM_EMAILS} emails at a time`,
				],
			},
		};
	},
	computed: {
		...mapState([
			'team',
		]),
	},
	methods: {
		_values,
		...mapActions([
			'updateSnackbar',
		]),
		onResetAndClose () {
			this.dialogOpen = false;
			this.inviteEmails = null;
		},
		async onInviteClick () {
			this.inviteLoading = true;
			const onError: Function = (e) => {
				console.error('Error sending team member invites: ', e);
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: 'Error sending email invites, please try again',
					show: true,
				});
			}

			try {
				const invites: {
					email: string
					role: string
				}[] = [];
				const teamMemberInviteEmails: string[] = this.inviteEmails
					.split(',')
					.map((email) => _trim(email).toLowerCase());

				teamMemberInviteEmails.forEach((email) => {
					invites.push({
						email: email,
						role: this.selectedRole,
					});
				});

				const functionRef = functions
					.httpsCallable('inviteTeamMembers');
				const { success } = (await functionRef({
					teamId: this.team.id,
					invites,
				})).data;

				if (success) {
					this.updateSnackbar({
						status: SNACKBAR_STATUS.SUCCESS,
						message: `Success! ${teamMemberInviteEmails.length} email invites sent`,
						show: true,
					});
					this.$emit('on-success');
				} else {
					onError(new Error('inviteTeamMembers did not return success'));
				}
			} catch (e) {
				onError(e);
			} finally {
				this.inviteLoading = false;
				this.onResetAndClose();
			}
		},
	},
	components: {
		DialogContent,
	},
});
