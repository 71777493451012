
import Vue from 'vue';
import BigNumber from 'bignumber.js';
import _clamp from "lodash/clamp";

export default Vue.extend({
	name: 'NumberWithIncrementer',
	props: {
		value: { type: Number, default: null },
		max: { type: Number, default: null },
		min: { type: Number, default: null },
		step: { type: Number, default: null },
		disabled: { type: Boolean, default: false },
		padding: { type: String, default: '0 14px 0 0' },
		isCurrency: { type: Boolean, default: false },
		condensed: { type: Boolean, default: false },
		numberMinWidth: { type: String, default: () => 'auto' },
		editableNumberInput: { type: Boolean, default: false }
	},
	data() {
		return {
			// localValue controls what’s shown in the text field.
			localValue: this.value !== null ? this.value.toString() : ''
		};
	},
	watch: {
		// Sync localValue with the parent's value if it changes externally.
		value(newVal) {
			this.localValue = newVal !== null ? newVal.toString() : '';
		}
	},
	computed: {
		computedMin(): number {
			return this.min !== null && this.min > 0 ? this.min : 1;
		},
		computedMax(): number {
			return this.max !== null && this.max > 0 ? this.max : Math.max(this.computedMin, 1);
		},
		showMaxWarning(): boolean {
			return this.computedMax > 1 && this.value > 0 && this.value === this.computedMax;
		}
	},
	methods: {
		/**
		 * onInput is called on each keystroke.
		 * It strips non-numeric characters, clamps the value, updates localValue,
		 * forces the underlying input element to update, and emits the clamped value.
		 */
		onInput(newValue: string): void {
			// Remove non-numeric characters.
			let sanitized = newValue.replace(/[^0-9]/g, '');
			// If empty, default to computedMin; otherwise, convert to a number.
			let numeric = sanitized === '' ? this.computedMin : parseInt(sanitized, 10);
			numeric = _clamp(numeric, this.computedMin, this.computedMax);
			const clamped = numeric.toString();
			// Update localValue.
			this.localValue = clamped;

			// Force the underlying input element (via ref) to display the clamped value.
			this.$nextTick(() => {
				const inputEl = (this.$refs.numberInput as any)?.$el?.querySelector('input');
				if (inputEl && inputEl.value !== clamped) {
					inputEl.value = clamped;
				}
			});

			// Emit the clamped value so the parent component updates.
			this.$emit('input', numeric);
		},

		/**
		 * onBlur: When the field loses focus, ensure the value is clamped.
		 */
		onBlur(): void {
			this.onInput(this.localValue);
		},

		/**
		 * onFocus: When the field is focused, force the displayed value
		 * to be the clamped localValue.
		 */
		onFocus(): void {
			this.$nextTick(() => {
				const inputEl = (this.$refs.numberInput as any)?.$el?.querySelector('input');
				if (inputEl && inputEl.value !== this.localValue) {
					inputEl.value = this.localValue;
				}
			});
		},

		/**
		 * onIncrement handles clicks on the increment/decrement buttons.
		 */
		onIncrement(isUp: boolean): void {
			const stepNum = new BigNumber(this.step && this.step > 0 ? this.step : 1);
			const currentVal = new BigNumber(this.value || 0);
			const newVal = isUp ? currentVal.plus(stepNum) : currentVal.minus(stepNum);

			if (newVal.isGreaterThan(this.computedMax) || newVal.isLessThan(this.computedMin)) {
				return;
			}

			const numeric = newVal.toNumber();
			this.$emit('input', numeric);
			this.localValue = numeric.toString();
		}
	}
});
