import { render, staticRenderFns } from "./StepsSlider.vue?vue&type=template&id=d5f28850&scoped=true"
import script from "./StepsSlider.vue?vue&type=script&lang=ts"
export * from "./StepsSlider.vue?vue&type=script&lang=ts"
import style0 from "./StepsSlider.vue?vue&type=style&index=0&id=d5f28850&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d5f28850",
  null
  
)

export default component.exports