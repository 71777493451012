import moment from 'moment-timezone';
import {
	ISO_DATE_PICKER_FORMAT,
	VIEW_DATE_PICKER_FORMAT,
} from '@/utils/timeUtils';

export const isDateStringValidIso = dateString => (
	!!dateString && dateString.length === ISO_DATE_PICKER_FORMAT.length && moment(dateString, ISO_DATE_PICKER_FORMAT, true).isValid()
);
export const isDateStringValidView = (dateString, dateFormat = VIEW_DATE_PICKER_FORMAT) => (
	!!dateString && dateString.length === dateFormat.length && moment(dateString, dateFormat, true).isValid()
);

export const DEFAULT_TEXT_MAX_LENGTH: number = 2000;
export const getShowTextCounterAt: Function = (maxLength: number = DEFAULT_TEXT_MAX_LENGTH): number => (
	maxLength - Math.floor(maxLength * 0.18)
);
export const DEFAULT_SHOW_TEXT_COUNTER_AT: number = getShowTextCounterAt(DEFAULT_TEXT_MAX_LENGTH);
export const getTextMaxLengthRule: Function = (maxLength: number = DEFAULT_TEXT_MAX_LENGTH): (v: any) => boolean | string => {
	return (v: any): boolean | string => (
		!v || v.length <= maxLength || `Must be less than ${maxLength} characters`
	);
};
