
import Vue from 'vue';
import { RunnitDynamicFieldMixin } from '@/views/Runnits/RunnitSettings/mixins/RunnitDynamicFieldMixin';
import { mapState } from 'vuex';
import RunnitImage from "@/views/Runnits/RunnitImage.vue";
import ImageUpload from "@/views/Runnits/RunnitSettings/ImageInput/ImageUpload.vue";
import { IMAGE_UPLOAD_MODE } from "@run-diffusion/shared";
import RunnitDynamicFieldLabelRow from "@/views/Runnits/RunnitSettings/RunnitDynamicFieldLabelRow.vue";
import TrainerUploadEmptyState from "@/views/Runnits/pages/models/TrainerUploadEmptyState.vue";

export default Vue.extend({
	name: 'MultiImageSelect',
	mixins: [
		RunnitDynamicFieldMixin,
	],
	props: {
		value: { type: Object, default: () => ({}) },
		adminOnly: { type: Boolean, default: false },
	},
	data () {
		return {
			IMAGE_UPLOAD_MODE,
			loadingPrompt: false,
		};
	},
	computed: {
		...mapState([
			'runnitState',
		]),
	},
	methods: {
		onUploadComplete (files) {
            const appendToExisting = (this.value?.uploads || []).concat(files);
            this.$emit('input', {uploads: appendToExisting});
		},
        removeUpload (index) {
            const uploads = (this.value?.uploads || []).filter((_, itemIndex) => itemIndex !== index);
            this.$emit('input', {uploads: uploads});
		},
	},
	components: {
		RunnitDynamicFieldLabelRow,
		ImageUpload,
		RunnitImage,
		TrainerUploadEmptyState,
	},
});
