
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import { MyBalanceMixin } from '@/mixins';
import TokensSVG from '@/assets/TokensSVG.vue';
import { RUNNITS_OWNER_SELECTION } from '@/views/Runnits/constants';
import { LIFE_SUPPORT_TOKENS_PACKAGE_AMOUNT } from '@run-diffusion/shared';

export default Vue.extend({
	name: 'RunnitTokens',
	mixins: [
		MyBalanceMixin,
	],
	props: {
		background: { type: String, default: 'rgba(0, 0, 0, 0.6)' },
		tokenSize: { type: Number, default: 12 },
		tokens: { type: Number, default: null },
		isClubTokens: { type: Boolean, default: false }, // If true, go off of User's Club. If false, go off of prop `tokens`.
		needMoreBtnStyle: { type: [String, Object], default: () => ({}) },
	},
	data () {
		return {
			LIFE_SUPPORT_TOKENS_PACKAGE_AMOUNT,
			RUNNITS_OWNER_SELECTION,
		};
	},
	computed: {
		...mapState([
			'runnitState',
			'loadingTeamBalanceAccount',
			'loadingUserBalanceAccount',
		]),
		isRunnitTokensLoading () {
			if (!this.isClubTokens) return false;

			if (!this.runnitState) return true;

			if (this.runnitState.runnitsOwnerSelection === RUNNITS_OWNER_SELECTION.TEAM) {
				return !!this.loadingTeamBalanceAccount;
			}

			return !!this.loadingUserBalanceAccount;
		},
		localTokens () {
			if (!this.isClubTokens) return this.tokens || 0;
			return (this.balanceAccountRunnitTokensData.combinedTokens || 0);
		},
	},
	methods: {
		...mapActions([
			'setUpsellDialog',
		]),
		openRunnitPlanDetailsDialog () {
			this.setUpsellDialog({
				runnitsPlanDetailsData: {
					open: true,
					scrollToPrivateBalance: this.runnitState.runnitsOwnerSelection === RUNNITS_OWNER_SELECTION.USER,
					scrollToTeamBalance: this.runnitState.runnitsOwnerSelection === RUNNITS_OWNER_SELECTION.TEAM,
				},
			});
		},
	},
	components: {
		TokensSVG,
	},
});
