
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import { GTAG_EVENTS, SNACKBAR_STATUS } from '@/constants/constants';
import { db, functions } from '@/firebase';
import { loadStripe } from '@stripe/stripe-js';
import LoadingSVG from '@/assets/LoadingSVG.vue';
import {
	Team,
	User,
	STRIPE_PUBLISHABLE_KEY,
	CHECKOUT_RUNNITS_LIFE_SUPPORT_TOKENS_PACKAGE,
	CHECKOUT_ADD_FUNDS,
	CHECKOUT_ADD_FUNDS_TEAM,
	CHECKOUT_USER_SUBSCRIPTION,
	CHECKOUT_TEAM_PLAN_SUBSCRIPTION,
	STRIPE_TEAMS_PUBLISHABLE_KEY,
	STRIPE_RUNNITS_LIFE_SUPPORT_TOKENS_PACKAGE_PRICE_ID,
	ClubKey,
} from '@run-diffusion/shared';
import ConfirmPlanChangeDialog from '@/views/GoToPayment/ConfirmPlanChangeDialog.vue';

export default Vue.extend({
	name: 'GoToPayment',
	data () {
		return {
			loadingCheckOutPage: true,

			confirmPlanChangeDialogConfig: {
				open: false,
				loading: false,
				isPaymentFailed: false,
				paymentFailedData: null,
				isUpdateNotAllowed: false,
				updateNotAllowedData: null,
				isAnnual: false,
				previewData: null,
				club: null,
			},

			loadingPageText: '',
			successPageHeader: '',
			successPageText: '',
			hasInitPage: false,
		};
	},
	computed: {
		...mapState([
			'user',
			'team',
		]),
	},
	async created () {
		await this.goToCheckoutPage(this.user, this.team, false);
		setTimeout(() => {
			this.loadingPageText = 'This is taking a while... Please try again by refreshing or closing this window. You can reach out to report issues by clicking the support button in our top toolbar.';
		}, 15000);
	},
	watch: {
		async user (newVal: User) {
			await this.goToCheckoutPage(newVal, this.team, false);
		},
		async team (newVal: Team) {
			await this.goToCheckoutPage(this.user, newVal, false);
		},
	},
	methods: {
		...mapActions([
			'updateSnackbar',
		]),
		async onConfirmPlanChange () {
			try {
				this.confirmPlanChangeDialogConfig = {
					...this.confirmPlanChangeDialogConfig,
					loading: true,
				};
				await this.goToCheckoutPage(this.user, this.team, true);
			} catch (e) {
				console.error(e);
			} finally {
				this.confirmPlanChangeDialogConfig = {
					...this.confirmPlanChangeDialogConfig,
					loading: false,
				};
			}
		},
		async goToCheckoutPage (user: User, team: Team, doSubscriptionUpgrade: boolean) {
			const hasClubQueryVariable: boolean = !!this._get(this.$route.query, 'club', null);
			const hasReturnActionQueryVariable: boolean = !!this._get(this.$route.query, 'returnAction', null);
			const isSuccessReturnAction: boolean = this._get(this.$route.query, 'returnAction', null) === 'success';
			const isCancelReturnAction: boolean = this._get(this.$route.query, 'returnAction', null) === 'cancel';
			const isRunnitsLifeSupportTokensPackageCheckout: boolean = this._get(this.$route.query, 'checkoutAction', null) === CHECKOUT_RUNNITS_LIFE_SUPPORT_TOKENS_PACKAGE;
			const isAddFundsCheckout: boolean = this._get(this.$route.query, 'checkoutAction', null) === CHECKOUT_ADD_FUNDS;
			const isTeamAddFundsCheckout: boolean = this._get(this.$route.query, 'checkoutAction', null) === CHECKOUT_ADD_FUNDS_TEAM;
			const isUserSubscriptionCheckout: boolean = this._get(this.$route.query, 'checkoutAction', null) === CHECKOUT_USER_SUBSCRIPTION;
			const isTeamSubscriptionCheckout: boolean = this._get(this.$route.query, 'checkoutAction', null) === CHECKOUT_TEAM_PLAN_SUBSCRIPTION;

			if (hasReturnActionQueryVariable) {
				if (isSuccessReturnAction) {
					this.loadingCheckOutPage = false;
					if (isRunnitsLifeSupportTokensPackageCheckout) {
						this.successPageHeader = 'Tokens have been added successfully!';
						this.successPageText = 'You can now close this tab and your tokens will be available.';
					} else if (isAddFundsCheckout || isTeamAddFundsCheckout) {
						this.successPageHeader = 'Funds have been added successfully!';
						this.successPageText = 'You can now close this tab and your funds will be available.';
					} else if (isUserSubscriptionCheckout) {
						this.successPageHeader = `Plan purchased successfully!`;
						this.successPageText = `You can now close this tab. Your subscription and benefits will be active. Please check your email inbox for documentation regarding your new membership that was just sent to you.`;
					} else if (isTeamSubscriptionCheckout) {
						this.successPageHeader = `Team Plan purchased successfully!`;
						this.successPageText = `You can now close this tab. Your subscription and benefits will be active. Please check your email inbox for next steps.`;
					}
				} else if (isCancelReturnAction) {
					this.handleWindowClose();
				}
				return;
			}

			if (
				(!doSubscriptionUpgrade && this.hasInitPage) ||
				(isRunnitsLifeSupportTokensPackageCheckout && !user) ||
				(isAddFundsCheckout && !user) ||
				(isTeamAddFundsCheckout && (!team || !user)) ||
				(isUserSubscriptionCheckout && (!user || !hasClubQueryVariable)) ||
				(isTeamSubscriptionCheckout && (!team || !user))
			) {
				return;
			}

			this.hasInitPage = true;

			if (window.fpr && isUserSubscriptionCheckout) {
				window.fpr('referral', {
					email: this.user.email,
				});
			}

			if (this.user) {
				if (isRunnitsLifeSupportTokensPackageCheckout) {
					this.loadingPageText = 'Loading payment page...';
					await this.goToAddFundsCheckOutPage('runnits', null);
				} else if (isAddFundsCheckout || isTeamAddFundsCheckout) {
					this.loadingPageText = 'Loading payment page...';
					await this.goToAddFundsCheckOutPage('rd', isTeamAddFundsCheckout ? team : null);
				} else if (isUserSubscriptionCheckout || isTeamSubscriptionCheckout) {
					this.loadingPageText = 'Loading subscription page...';
					await this.goToBuySubscriptionPage(isTeamSubscriptionCheckout ? team : null, doSubscriptionUpgrade);
				}
			}
		},
		handleWindowClose () {
			console.log('handleWindowClose');
			window.close();
		},
		async goToAddFundsCheckOutPage (platform: 'rd' | 'runnits', teamForCheckout: Team) {
			let priceId: string = this._get(this.$route.query, 'priceId') || null;

			const onError: Function = (e: any) => {
				console.error(e);
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: 'Error loading payment page, please reach out to report issues by clicking the support button in the toolbar',
					show: true,
				});
			};

			try {
				this.$gtag.event(GTAG_EVENTS.ON_ADD_FUNDS_CLICK, {
					event_label: platform === 'runnits' ? 'On Purchase Tokens Click' : 'On Add Funds Click',
					screen_name: this.user.email,
				});

				if (teamForCheckout) {
					const publishableKey: string = STRIPE_TEAMS_PUBLISHABLE_KEY;

					const checkoutDocRef: any = db
						.collection(`stripeTeamsCustomers/${teamForCheckout.id}/checkout_sessions`)
						.doc();

					const functionRef = functions
						.httpsCallable('newAddFundsTeamsCheckout');
					const { error, checkoutSessionId } = (await functionRef({
						teamId: teamForCheckout.id,
						checkoutDocId: checkoutDocRef.id,
						priceId,
					})).data;

					await this.processCheckoutData(error, checkoutSessionId, publishableKey, onError);
				} else {
					const publishableKey: string = STRIPE_PUBLISHABLE_KEY;
					if (platform === 'runnits') priceId = priceId || STRIPE_RUNNITS_LIFE_SUPPORT_TOKENS_PACKAGE_PRICE_ID;

					const checkoutDocRef: any = db
						.collection(`stripeCustomers/${this.user.id}/checkout_sessions`)
						.doc();

					// Wait for the checkoutSession to get attached by the extension
					checkoutDocRef.onSnapshot(async (checkoutSnapshot) => {
						const { error, sessionId: checkoutSessionId } = checkoutSnapshot.data();
						await this.processCheckoutData(error, checkoutSessionId, publishableKey, onError);
					});

					const functionRef = functions
						.httpsCallable('newAddFundsCheckout');
					const { success } = (await functionRef({
						checkoutDocId: checkoutDocRef.id,
						priceId,
					})).data;
					if (!success) onError(new Error('newAddFundsCheckout returned not success'));
				}
			} catch (e) {
				onError(e);
			}
		},
		async goToBuySubscriptionPage (teamForCheckout: Team, doSubscriptionUpgrade: boolean) {
			const isAnnual: boolean = !!this._get(this.$route.query, 'annual', null);
			const club: ClubKey = this._get(this.$route.query, 'club', null);
			const tokenClubAddOnId: string = this._get(this.$route.query, 'tokenClubAddOnId', null);
			const limitClubAddOnId: string = this._get(this.$route.query, 'limitClubAddOnId', null);

			const onError: Function = (e: any) => {
				console.error(e);
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: 'Error loading subscription page, please reach out to report issues by clicking the support button in our top toolbar',
					show: true,
				});
			};

			try {
				if (teamForCheckout) {
					const publishableKey: string = STRIPE_TEAMS_PUBLISHABLE_KEY;

					const checkoutDocRef: any = db
						.collection(`stripeTeamsCustomers/${teamForCheckout.id}/checkout_sessions`)
						.doc();

					const functionRef = functions
						.httpsCallable('newSubscriptionTeamsCheckout');
					const { error, checkoutSessionId } = (await functionRef({
						teamId: teamForCheckout.id,
						checkoutDocId: checkoutDocRef.id,
						isAnnual,
					})).data;

					await this.processCheckoutData(error, checkoutSessionId, publishableKey, onError);
				} else {
					this.confirmPlanChangeDialogConfig = {
						...this.confirmPlanChangeDialogConfig,
						isPaymentFailed: false,
						paymentFailedData: null,
						isUpdateNotAllowed: false,
						updateNotAllowedData: null,
					};

					const publishableKey: string = STRIPE_PUBLISHABLE_KEY;

					const functionRef = functions
						.httpsCallable('getSubscriptionCheckout');
					const {
						success,
						checkoutData,
						previewData,
						paymentFailedData,
						updateNotAllowedData,
					} = (await functionRef({
						doUpgrade: !!doSubscriptionUpgrade,
						isAnnual,
						club,
						tokenClubAddOnId,
						limitClubAddOnId,
					})).data;
					if (success && previewData) {
						this.confirmPlanChangeDialogConfig = {
							open: true,
							loading: false,
							isPaymentFailed: false,
							paymentFailedData: null,
							isUpdateNotAllowed: false,
							updateNotAllowedData: null,
							isAnnual,
							previewData,
							club,
						};
					} else if (success && checkoutData) {
						await this.processCheckoutData(null, checkoutData.id, publishableKey, onError);
					} else if (!success && paymentFailedData) {
						this.confirmPlanChangeDialogConfig = {
							...this.confirmPlanChangeDialogConfig,
							open: true,
							isPaymentFailed: true,
							paymentFailedData,
							isUpdateNotAllowed: false,
							updateNotAllowedData: null,
						};
					} else if (!success && updateNotAllowedData) {
						this.confirmPlanChangeDialogConfig = {
							...this.confirmPlanChangeDialogConfig,
							open: true,
							isPaymentFailed: false,
							paymentFailedData: null,
							isUpdateNotAllowed: true,
							updateNotAllowedData,
						};
					} else if (success && doSubscriptionUpgrade) {
						this.confirmPlanChangeDialogConfig = {
							...this.confirmPlanChangeDialogConfig,
							open: false,
							loading: false,
							isPaymentFailed: false,
							paymentFailedData: null,
							isUpdateNotAllowed: false,
							updateNotAllowedData: null,
							isAnnual: false,
							previewData: null,
							club: null,
						};
						this.loadingCheckOutPage = false;
						this.successPageHeader = `Plan updated successfully!`;
						this.successPageText = `You can now close this tab. Your subscription and benefits will be active. Please check your email inbox for any documentation regarding your new membership that may have been sent to you.`;
					} else {
						onError(new Error('getSubscriptionCheckout returned not success'));
					}
				}
			} catch (e) {
				onError(e);
			}
		},
		async processCheckoutData (error: string, checkoutSessionId: string, publishableKey: string, onError: Function) {
			try {
				if (error) {
					onError(error);
				}

				if (checkoutSessionId) {
					// We have a session, let's redirect to Checkout
					// Init Stripe
					const stripe = await loadStripe(publishableKey);
					await stripe.redirectToCheckout({ sessionId: checkoutSessionId });
				}
			} catch (e) {
				onError(e);
			}
		},
	},
	components: {
		ConfirmPlanChangeDialog,
		LoadingSVG,
	},
});
