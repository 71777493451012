"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calcRunnitTokensToRdBalance = exports.calcRdBalanceToRunnitTokens = exports.determineNextFreeRunnitTokensTopUpAt = exports.determineRecurringRunnitTokensToAdd = exports.determineRecurringBalanceAmountToAdd = exports.DEFAULT_TEAM_RECURRING_CAP = exports.DEFAULT_TEAM_RECURRING_ALLOTMENT = exports.DEFAULT_CREATORS_CLUB_RECURRING_CAP = exports.DEFAULT_CREATORS_CLUB_RECURRING_ALLOTMENT = exports.processImmediateCorrectionsToFreeRunnitTokens = exports.calcRunnitTokensDataForBalanceAccount = exports.DAILY_FREE_RUNNIT_TOKENS_ALLOTMENT = exports.calcRealtimeBalanceInfo = void 0;
const lodash_1 = require("lodash");
const bignumber_js_1 = require("bignumber.js");
const moment = require('moment-timezone');
const calcRealtimeBalanceInfo = (balanceAccount) => {
    if (!balanceAccount) {
        return {
            realtimeBalance: 0,
            realtimeRecurringBalance: 0,
            realtimeRunnitTokens: 0,
            realtimeFreeRunnitTokens: 0,
        };
    }
    const propKeys = (0, lodash_1.keys)(balanceAccount);
    return propKeys.reduce((result, key) => {
        const delta = balanceAccount[key] || 0;
        if (key.startsWith('balance_delta_')) {
            return {
                ...result,
                realtimeBalance: result.realtimeBalance + delta
            };
        }
        if (key.startsWith('recurringBalance_delta_')) {
            return {
                ...result,
                realtimeRecurringBalance: result.realtimeRecurringBalance + delta
            };
        }
        if (key.startsWith('runnitTokens_delta_')) {
            return {
                ...result,
                realtimeRunnitTokens: result.realtimeRunnitTokens + delta
            };
        }
        if (key.startsWith('freeRunnitTokens_delta_')) {
            return {
                ...result,
                realtimeFreeRunnitTokens: result.realtimeFreeRunnitTokens + delta
            };
        }
        return result;
    }, {
        realtimeBalance: balanceAccount.balance || 0,
        realtimeRecurringBalance: balanceAccount.recurringBalance || 0,
        realtimeRunnitTokens: balanceAccount.runnitTokens || 0,
        realtimeFreeRunnitTokens: balanceAccount.freeRunnitTokens || 0,
    });
};
exports.calcRealtimeBalanceInfo = calcRealtimeBalanceInfo;
exports.DAILY_FREE_RUNNIT_TOKENS_ALLOTMENT = 20000;
const calcRunnitTokensDataForBalanceAccount = (balanceAccount, freemiumInfo, clubOffer) => {
    if (!balanceAccount) {
        return {
            combinedTokens: 0,
            runnitTokens: 0,
            freeRunnitTokens: 0,
            freeRunnitTokensTopUpAmount: 0,
            isFreemiumPassportValid: false,
            dailyFreeRunnitTokensAllotment: exports.DAILY_FREE_RUNNIT_TOKENS_ALLOTMENT,
        };
    }
    const { realtimeRunnitTokens, realtimeFreeRunnitTokens, } = (0, exports.calcRealtimeBalanceInfo)(balanceAccount);
    // Only factor in free tokens if freemium passport is active
    const isFreemiumPassportValid = !!((clubOffer && !clubOffer.isFreemium) ||
        (freemiumInfo && freemiumInfo.runnitFreemiumPassportId));
    const runnitTokens = realtimeRunnitTokens;
    const freeRunnitTokens = isFreemiumPassportValid ? realtimeFreeRunnitTokens : 0;
    const freeRunnitTokensTopUpAmount = isFreemiumPassportValid
        ? (0, lodash_1.clamp)(exports.DAILY_FREE_RUNNIT_TOKENS_ALLOTMENT - Math.max(runnitTokens, 0) - Math.max(freeRunnitTokens, 0), 0, exports.DAILY_FREE_RUNNIT_TOKENS_ALLOTMENT)
        : 0;
    return {
        combinedTokens: runnitTokens + freeRunnitTokens,
        runnitTokens,
        freeRunnitTokens,
        freeRunnitTokensTopUpAmount,
        isFreemiumPassportValid,
        dailyFreeRunnitTokensAllotment: exports.DAILY_FREE_RUNNIT_TOKENS_ALLOTMENT,
    };
};
exports.calcRunnitTokensDataForBalanceAccount = calcRunnitTokensDataForBalanceAccount;
/*
Immediate corrections to user's `freeRunnitTokens` if necessary (immediate, meaning not scheduling a top-up for tonight end of day)
*/
const processImmediateCorrectionsToFreeRunnitTokens = (userId, balanceAccount, freemiumInfo, clubOffer, freeRunnitTokensTopUpAt) => {
    const { freeRunnitTokens, freeRunnitTokensTopUpAmount, dailyFreeRunnitTokensAllotment, } = (0, exports.calcRunnitTokensDataForBalanceAccount)(balanceAccount, freemiumInfo, clubOffer);
    if (!freeRunnitTokensTopUpAt &&
        (freeRunnitTokensTopUpAmount ||
            freeRunnitTokens > dailyFreeRunnitTokensAllotment)) {
        // `freeRunnitTokens` corrected amount
        return freeRunnitTokensTopUpAmount || (dailyFreeRunnitTokensAllotment - freeRunnitTokens);
    }
    return 0;
};
exports.processImmediateCorrectionsToFreeRunnitTokens = processImmediateCorrectionsToFreeRunnitTokens;
exports.DEFAULT_CREATORS_CLUB_RECURRING_ALLOTMENT = 1000;
exports.DEFAULT_CREATORS_CLUB_RECURRING_CAP = 3000;
exports.DEFAULT_TEAM_RECURRING_ALLOTMENT = 2000;
exports.DEFAULT_TEAM_RECURRING_CAP = 6000;
const determineRecurringBalanceAmountToAdd = (balanceAccount, inputRecurringAllotment, // Usually `balanceAccount.recurringAllotment` is passed in
inputRecurringCap) => {
    if (!balanceAccount)
        return 0;
    const { realtimeRecurringBalance } = (0, exports.calcRealtimeBalanceInfo)(balanceAccount);
    const defaultRecurringAllotment = balanceAccount.type === 'TEAM' ? exports.DEFAULT_TEAM_RECURRING_ALLOTMENT : exports.DEFAULT_CREATORS_CLUB_RECURRING_ALLOTMENT;
    const defaultRecurringCap = balanceAccount.type === 'TEAM' ? exports.DEFAULT_TEAM_RECURRING_CAP : exports.DEFAULT_CREATORS_CLUB_RECURRING_CAP;
    const recurringAllotment = inputRecurringAllotment >= 0
        ? inputRecurringAllotment
        : defaultRecurringAllotment;
    const recurringCap = inputRecurringCap >= 0
        ? inputRecurringCap
        : defaultRecurringCap;
    return (0, lodash_1.clamp)(recurringCap - realtimeRecurringBalance, 0, recurringAllotment);
};
exports.determineRecurringBalanceAmountToAdd = determineRecurringBalanceAmountToAdd;
const determineRecurringRunnitTokensToAdd = (balanceAccount, recurringRunnitTokensAllotment, recurringRunnitTokensCap) => {
    const { realtimeRunnitTokens } = (0, exports.calcRealtimeBalanceInfo)(balanceAccount);
    const recurringAllotment = recurringRunnitTokensAllotment || 0;
    const recurringCap = recurringRunnitTokensCap || 0;
    return (0, lodash_1.clamp)(recurringCap - realtimeRunnitTokens, 0, recurringAllotment);
};
exports.determineRecurringRunnitTokensToAdd = determineRecurringRunnitTokensToAdd;
const determineNextFreeRunnitTokensTopUpAt = (deviceReportUserSignUp) => {
    const timeZone = (0, lodash_1.get)(deviceReportUserSignUp, 'timeZone') || 'America/Detroit';
    // Tonight at 11:59pm in the user's device's time zone
    return moment().tz(timeZone)
        .endOf('day')
        .subtract(1, 'minute');
};
exports.determineNextFreeRunnitTokensTopUpAt = determineNextFreeRunnitTokensTopUpAt;
const RUNNIT_TOKENS_CONVERSION_VALUE = 0.0005;
const calcRdBalanceToRunnitTokens = (rdBalance) => {
    return Math.floor((0, bignumber_js_1.default)(rdBalance || 0).div(RUNNIT_TOKENS_CONVERSION_VALUE).toNumber());
};
exports.calcRdBalanceToRunnitTokens = calcRdBalanceToRunnitTokens;
const calcRunnitTokensToRdBalance = (runnitTokens) => {
    return Math.floor((0, bignumber_js_1.default)(runnitTokens || 0).times(RUNNIT_TOKENS_CONVERSION_VALUE).toNumber());
};
exports.calcRunnitTokensToRdBalance = calcRunnitTokensToRdBalance;
