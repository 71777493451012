
import Vue from 'vue';
import pluralize from 'pluralize';
import TokensSVG from '@/assets/TokensSVG.vue';
import { DAILY_FREE_RUNNIT_TOKENS_ALLOTMENT } from '@run-diffusion/shared';

export default Vue.extend({
	name: 'TokenClubAddOnSelectItem',
	props: {
		item: { type: Object, required: true },
		clubOffer: { type: Object, required: true },
		highlightAsCurrentPlanItem: { type: Boolean, default: false },
	},
	data () {
		return {
			DAILY_FREE_RUNNIT_TOKENS_ALLOTMENT,
		};
	},
	computed: {
		tokens () {
			if (this.clubOffer.benefits && this.clubOffer.benefits.monthlyRunnitTokens) {
				return this.item.tokens;
			} else if (this.clubOffer.isFreemium) {
				return DAILY_FREE_RUNNIT_TOKENS_ALLOTMENT;
			}
			return null;
		},
		label () {
			const tokensStr: string = pluralize('token', this.tokens);
			if (this.clubOffer.benefits && this.clubOffer.benefits.monthlyRunnitTokens) {
				return `${tokensStr} per month`;
			} else if (this.clubOffer.isFreemium) {
				return `${tokensStr} per day`;
			}
			return null;
		},
	},
	components: {
		TokensSVG,
	},
});
