import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"runnit-tokens",style:({ background: _vm.background })},[_c('tokens-s-v-g',{staticStyle:{"margin":"0 6px 0 0"},attrs:{"width":_vm.tokenSize,"height":_vm.tokenSize}}),(_vm.isRunnitTokensLoading)?_c(VProgressCircular,{attrs:{"indeterminate":"","size":"12","width":"1"}}):_c('div',{staticClass:"full-width d-flex align-center justify-space-between"},[_c('span',{staticClass:"tokens-value"},[_vm._v(" "+_vm._s(_vm._f("currencyTokens")(_vm._f("hundredthsToTokens")(_vm.localTokens)))+" ")]),((
				_vm.isClubTokens &&
				_vm.runnitState.runnitsOwnerSelection === _vm.RUNNITS_OWNER_SELECTION.USER &&
				_vm.localTokens < Math.round(_vm.LIFE_SUPPORT_TOKENS_PACKAGE_AMOUNT / 2)
			))?_c('base-button',{staticStyle:{"height":"20px"},style:(_vm.needMoreBtnStyle),attrs:{"x-small":"","text":"","color":"primary"},on:{"click":_vm.openRunnitPlanDetailsDialog}},[_vm._v("Need tokens?")]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }