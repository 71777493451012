
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';
import DiscordSVG from '@/assets/DiscordSVG.vue';
import { DISCORD_INVITE_LINK } from '@/utils';
import { User } from '@run-diffusion/shared';

export default Vue.extend({
	name: 'JoinOurDiscord',
	props: {
		accountLinkOnly: { type: Boolean, default: false },
		inviteLinkRedirectOnly: { type: Boolean, default: false },
		isToolbarBtn: { type: Boolean, default: false },
		breakpointAtMd: { type: Boolean, default: false },
		callToAction: { type: Boolean, default: true },
		secondary: { type: Boolean, default: false },
		boldBtnText: { type: Boolean, default: true },
	},
	data () {
		return {
			dialogOpen: false,
		};
	},
	computed: {
		...mapState([
			'user',
		]),
		...mapGetters([
			'clubOfferBenefits',
		]),
		isAboveBreakpoint () {
			return this.breakpointAtMd ? this.$vuetify.breakpoint.mdAndUp : this.$vuetify.breakpoint.smAndUp;
		},
		isIconOnly () {
			return this.isToolbarBtn && !this.isAboveBreakpoint;
		},
		discordNotLinked () {
			return !!(
				this.user &&
				!this.user.discordUser
			);
		},
		showLinkYourDiscordMode () {
			return !!(
				!this.inviteLinkRedirectOnly && (this.discordNotLinked || this.accountLinkOnly)
			);
		},
	},
	watch: {
		user: {
			immediate: true,
			handler (newVal: User, oldVal: User) {
				const newDiscordUserId: string = this._get(newVal, 'discordUserId') || null;
				const oldDiscordUserId: string = this._get(oldVal, 'discordUserId') || null;
				if (newDiscordUserId && newDiscordUserId !== oldDiscordUserId) {
					this.dialogOpen = false;
				}
			},
		},
	},
	methods: {
		onClick () {
			if (this.showLinkYourDiscordMode) {
				this.dialogOpen = true;
				return;
			}
			this.goToServerInviteScreen();
		},
		goToServerInviteScreen () {
			window.open(DISCORD_INVITE_LINK, '_blank').focus();
		},
		onLinkDiscord () {
			if (this.user) {
				const applicationId: string = '1098056685727252510';
				const redirectUri: string = `${location.origin}/discord-callback`;
				const responseType: string = 'token';
				const scopes: string[] = ['identify'];
				const url: string = `https://discord.com/api/oauth2/authorize?client_id=${applicationId}&redirect_uri=${encodeURIComponent(redirectUri)}&response_type=${responseType}&scope=${scopes.join('+')}`;
				window.open(url, '_blank');
			}
		},
	},
	components: {
		DiscordSVG,
	},
});
