export const localPrompts: string[] = [
	"A detailed portrait of an elderly painter, with deep wrinkles and a thoughtful expression, surrounded by his art pieces and brushes, in a cozy, sunlit studio, digital oil painting style",
	"A young musician with neon blue hair and cybernetic enhancements playing an electric violin in a bustling cyberpunk cityscape at night, high contrast lighting, digital art",
	"A portrait of a Renaissance-era scholar, quill in hand, surrounded by ancient books and globes, candlelight casting soft shadows, in the style of Caravaggio",
	"A close-up portrait of an astronaut with a reflective visor, revealing the red Martian landscape, highly detailed, realism, under the glow of a distant Earth",
	"A whimsical portrait of a steampunk inventor in her workshop, surrounded by brass gadgets and gears, warm lighting, detailed illustration",
	"A regal portrait of a medieval queen, draped in velvet robes, seated on a throne in a grand hall, stained glass windows, oil painting style",
	"A mystical portrait of an elf with luminescent eyes and leafy attire, standing in an enchanted forest clearing, digital art with a focus on natural elements",
	"A vibrant portrait of a 1920s jazz singer mid-performance, microphone in hand, in a smoky club, capturing the era's energy, watercolor style",
	"A detailed portrait of a futuristic hacker, hooded, in front of multiple holographic screens, neon lighting effects, digital art",
	"A noble knight in full armor, holding a falcon, standing against a medieval castle backdrop, golden hour lighting, in the style of classical European paintings",
	"The eerie scenery of an abandoned amusement park, with rusting rides and overgrown paths, under a twilight sky, digital painting",
	"A vibrant underwater scene of a coral city bustling with marine life, sunbeams filtering through the water, digital art",
	"An expansive, mystical library with shelves reaching the ceiling and books floating mid-air, warm ambient lighting, digital illustration",
	"A bustling alien market scene on a distant planet, with a variety of extraterrestrial species and futuristic stalls, vibrant colors, digital art",
	"A foggy, gas-lit street scene in Victorian London, with shadowy figures and horse-drawn carriages, digital oil painting style",
	"A majestic frozen waterfall scene, surrounded by snow-covered trees under a clear blue winter sky, digital art",
	"The golden hour lighting over ancient ruins, with creeping vines and a backdrop of a setting sun, digital painting",
	"A sprawling futuristic cityscape viewed at dawn, with towering skyscrapers and flying vehicles, pastel sky, digital art",
	"A cozy witch's cottage in a dense forest, lit by the full moon, with magical plants and creatures visible, digital illustration",
	"A whimsical scene of an enchanted fairy village with glowing mushrooms and tiny houses nestled in tree roots, under a starry night sky, digital art",
	"A highly detailed close-up of an opened antique pocket watch, showcasing intricate gears and aged metal, photorealism",
	"A mystical crystal ball glowing with soft light, placed on a vintage wooden table with ancient runes, digital art",
	"A close view of steampunk goggles, detailed with brass gears and leather straps, on a rustic wooden background, digital painting",
	"An ancient spellbook open to a page with glowing magical symbols, surrounded by candles, digital art in a dark, mystical setting",
	"A concept design of a futuristic weapon with sleek lines and energy components, 3D rendering with metallic finishes",
	"A detailed illustration of an ornate key, adorned with jewels and intricate filigree, photorealistic with soft lighting",
	"A vintage camera on a wooden table, surrounded by scattered film rolls and photographs, sepia-toned, digital painting",
	"A collection of colorful magic potion bottles on a wooden shelf, each with unique shapes and contents, vibrant digital art",
	"A mythical sword embedded in a stone, captured at sunrise with rays illuminating the blade, digital art with a fantasy theme",
	"Concept art of a friendly robot companion with expressive eyes and a sleek design, digital painting in a futuristic setting",
	"A breathtaking landscape of a vast lavender field under a sunset sky, with soft pastel colors, digital oil painting",
	"A panoramic view of a majestic mountain range reflected in a crystal-clear lake at dawn, digital art with vibrant colors",
	"A serene landscape of an ancient forest path covered in autumn leaves, with light filtering through the canopy, digital painting",
	"A peaceful desert oasis scene under a starry night sky, with palm trees and calm waters reflecting the stars, digital art",
	"A sun-drenched landscape of the rolling hills of Tuscany, with vineyards and olive trees, digital oil painting style",
	"A vast icy tundra landscape under the aurora borealis, with shimmering colors in the night sky, digital art",
	"A tranquil tropical beach scene at sunset, with golden sand, palm trees, and a calm sea, vibrant digital painting",
	"A dramatic landscape of a rugged coastline with crashing waves during a storm, digital painting with dynamic lighting",
	"A picturesque landscape of a peaceful countryside village surrounded by blooming flowers and green fields, digital art",
	"A powerful landscape scene of a volcanic eruption at night, with lava flows and ash clouds under a starlit sky, digital painting",
	"A macro photograph capturing the intricate details of a dewdrop on a leaf, with a focus on reflection and texture",
	"A sepia-toned vintage photograph of an old train station, capturing the historical architecture and atmosphere",
	"A high-speed photograph capturing a hummingbird in mid-flight, showcasing its vibrant colors and rapid wing movement",
	"A black and white photograph capturing the expressive face of a street musician, with a shallow depth of field",
	"A breathtaking astronomy photograph capturing the Milky Way galaxy, with clear details of the star clusters and nebulae",
	"An underwater photograph showcasing the vibrant life of a coral reef, with a focus on the diversity of colors and species",
	"A long exposure photograph of a cityscape at night, capturing the trails of lights and the dynamic energy of the city",
	"A vibrant photograph capturing the colorful hot air balloons against the backdrop of a dawn sky",
	"A powerful wildlife photograph capturing a lion in the savanna, focusing on its majestic presence and the surrounding environment",
	"An aerial drone photograph showcasing the expansive view of a snow-capped mountain range, with a focus on the textures and patterns of the landscape",
	"A close-up portrait of a retro-futuristic pilot wearing aviator goggles and a leather flight jacket, against a backdrop of vintage aircraft, digital painting style",
	"A dark and detailed portrait of a gothic vampire, with piercing eyes, in the lavish interior of a Victorian mansion, candlelit ambiance, digital art",
	"An elaborate portrait of a Baroque composer playing the harpsichord, surrounded by ornate decorations and musical scores, in the style of Vermeer",
	"A mystical portrait of a modern witch holding a crystal pendulum, with tattoos and a boho-chic style, surrounded by plants and potions, digital illustration",
	"A thoughtful portrait of a space explorer in a futuristic suit, overlooking a window showing a breathtaking galactic view, digital painting",
	"A delicate portrait of a Regency-era lady in a flowing dress, standing amidst a blooming garden, soft pastel tones, digital oil painting",
	"A gritty portrait of a post-apocalyptic survivor, adorned with scavenged gear and weapons, against a desolate backdrop, digital art",
	"An elegant portrait of a dancer, with flowing hair and attire, surrounded by Art Nouveau decorative elements, vibrant colors, digital painting",
	"A detailed portrait of a Renaissance-inspired scientist, with curious gadgets and scrolls, in a candlelit study, digital art in the style of the Old Masters",
	"A dynamic portrait of a cybernetic engineer working on advanced robotics, surrounded by high-tech lab equipment, digital art with a focus on futuristic details",
	"A mystical scenery depicting an ancient stone observatory, silhouetted against a star-filled sky, digital painting with celestial details",
	"A lush scenery showing a hidden waterfall cascading into a clear blue pool, surrounded by a vibrant tropical forest, digital art",
	"A vibrant yet dystopian cityscape at night, illuminated by neon signs and towering skyscrapers, reflecting in rain-soaked streets, digital painting",
	"A cozy scenery of an enchanted winter village, snow-covered cottages under the mesmerizing northern lights, digital art",
	"A haunting scenery of a deserted space station orbiting a mysterious alien planet, with a view of the cosmos, digital painting",
	"A breathtaking scenery of a majestic castle perched on cliffs, overlooking the sea, at sunrise, digital oil painting style",
	"A bustling Renaissance market square, with vendors, musicians, and townsfolk, surrounded by historic architecture, digital painting",
	"A visionary scenery of a futuristic public park with lush bio-domes, integrating nature and advanced technology, digital art",
	"A detailed scenery of an old Western town, with wooden storefronts and dusty streets, under the high noon sun, digital painting",
	"A dramatic scenery of an abandoned lighthouse on a rugged coast, against the backdrop of a stormy night sky, digital art",
	"A detailed close-up of an ornate antique compass on an aged map, highlighting intricate designs, photorealistic style",
	"A concept design for a futuristic holographic display console, with sleek surfaces and interactive holograms, digital art",
	"A nostalgic close-up of a vintage typewriter with a blank page, emphasizing textures and mechanical details, digital painting",
	"A mystical object, an enchanted sword with glowing runes along the blade, set against a dark, moody background, digital art",
	"A detailed illustration of an opened Art Deco style jewel box, showcasing geometric patterns and precious gems, digital painting",
	"A complex steampunk time machine control panel, with brass dials and intricate gears, under warm lighting, digital art",
	"A photorealistic depiction of an ancient Egyptian artifact, surrounded by dim museum lighting and hieroglyphic inscriptions",
	"A close-up of a magic wand with a phoenix feather core, surrounded by magical sparks and ancient texts, digital painting",
	"A detailed illustration of a handcrafted leather journal, with embossed designs and aged pages, under soft lighting, digital art",
	"A concept art of an alien artifact with glowing symbols and unknown materials, set in a futuristic lab environment, digital painting",
	"A mesmerizing landscape of a sunrise illuminating the interior of a crystalline ice cave, with vibrant reflections, digital art",
	"A breathtaking landscape of an alien terrain with floating islands and exotic vegetation under a dual-moon sky, digital painting",
	"A serene landscape of misty highlands, with ancient stone circles visible through the fog, digital oil painting style",
	"A dramatic landscape showcasing a volcanic region with flowing rivers of lava under a twilight sky, digital painting",
	"A peaceful landscape of a rural countryside, with rolling fog and the first light of dawn illuminating fields and farmhouses, digital art",
	"A mysterious landscape showing ancient city ruins overtaken by a dense jungle, with sunlight filtering through, digital painting",
	"A stark yet beautiful landscape of the Arctic, with massive icebergs and polar bears visible on the ice, digital art",
	"An enchanting landscape of a fairy-tale forest, with trees and flowers that glow in the dark, creating a magical atmosphere, digital painting",
	"A majestic landscape of a dramatic canyon with a powerful river cutting through the rock formations, under clear skies, digital painting",
	"A magical landscape of a secluded beach at night, with bioluminescent waves gently crashing onto the shore, digital art",
	"A stunning macro photograph showing the unique patterns of snowflakes collected on a piece of colorful fabric",
	"A vibrant photograph capturing the energy and authenticity of a historical reenactment event, with participants in period costumes",
	"An intriguing aerial photograph of a labyrinth garden, showcasing the intricate patterns and greenery from above",
	"A captivating night photograph of a desert landscape, with long exposure star trails arcing across the sky",
	"A dynamic underwater photograph capturing a school of fish in motion, emphasizing the fluidity and patterns",
	"A haunting urban exploration photograph of an abandoned factory, highlighting textures and the passage of time",
	"An awe-inspiring wildlife photograph capturing the moment of birds in migration, showcasing the patterns and unity in nature",
	"A moody street photograph capturing pedestrians navigating a rainy day in the city, with reflections on wet pavements",
	"An impressive drone photograph of a serpentine mountain road, showcasing the dramatic twists and landscape",
	"A colorful photograph capturing the joy and vibrancy of a traditional festival, with dancers, costumes, and decorations",
	"A warm portrait of a whimsical baker surrounded by floating pastries and glowing ingredients in a magical kitchen, digital art with soft, inviting lighting",
	"An intricate portrait of a Victorian inventor in his workshop, surrounded by steam-powered machines and intricate blueprints, sepia-toned digital painting",
	"A mystical portrait of an ancient druid with a deep connection to nature, standing in a misty, enchanted forest, digital painting with ethereal lighting",
	"A dynamic portrait of a retro-futuristic space cadet equipped with a sleek jetpack, against the backdrop of a 1960s-inspired space colony, vibrant digital art",
	"A moody, noir-inspired portrait of a trench-coated detective, hat shadowing his eyes, in a dimly lit, rainy city setting, digital art with a focus on dramatic lighting",
	"A detailed portrait of a medieval alchemist in a cluttered study, surrounded by glowing elixirs, ancient scrolls, and mystical symbols, digital oil painting style",
	"A heartwarming portrait of a folk musician playing an ancient stringed instrument, surrounded by nature and wildlife, digital art with a focus on natural, warm colors",
	"A striking portrait of a futuristic rebel leader, charismatic and determined, with a dystopian cityscape and rebellion banners in the background, digital painting",
	"An opulent portrait of a Renaissance merchant, surrounded by exotic goods and treasures from distant lands, rich in color and detail, digital art in the style of the Old Masters",
	"A chilling portrait of a ghost pirate captain, translucent and eerie, with his cursed ship looming in the foggy background, digital art with ghostly effects",
	"A captivating scenery of the overgrown ruins of a future city, nature reclaiming skyscrapers and streets, with hints of former technology peeking through, digital painting",
	"A breathtaking scenery of surreal floating islands, connected by cascading waterfalls and lush vegetation, against a clear blue sky, vibrant digital art",
	"A historical scenery depicting an ancient Greek agora, bustling with activity, as the sun sets behind temples and statues, digital art with golden hour lighting",
	"A vibrant scenery of a neon-lit alley in a cyberpunk megacity, bustling with life and electronic billboards, digital art with a focus on neon colors",
	"A mystical scenery of the lost city of Atlantis, submerged and glowing with bioluminescent architecture, surrounded by marine life, digital painting",
	"A magical scenery of a witch's garden at midnight, with glowing plants, mystical creatures, and a quaint cottage, digital art with magical ambient lighting",
	"A poignant scenery of a post-apocalyptic highway, with nature reclaiming the space, wildflowers and trees growing through cracks, digital painting",
	"A lively scenery of a Victorian street market during a festival, with stalls, festive lights, and bustling crowds, digital art capturing the era's charm",
	"A futuristic scenery of a lunar colony, with habitats and vehicles, as Earth rises majestically in the background, digital painting",
	"A serene scenery of an enchanted autumn forest, with a hidden path leading through vibrant trees and falling leaves, digital art with warm autumnal colors",
	"A highly detailed close-up of an antique astronomer's astrolabe, brass and aged, under soft, focused lighting, photorealistic style",
	"A concept art of a steampunk-inspired mechanical bird, with intricate gears and steam mechanisms, set against a parchment background, digital painting",
	"A dazzling close-up of a crystal-encrusted magical staff, glowing with inner light and arcane symbols, digital art with a focus on mystical details",
	"A photorealistic depiction of an ancient warrior's helm, adorned with battle scars and stories of old, set against a dark, moody background",
	"A concept design of a futuristic data pad with a sleek design and a holographic interface, glowing with information, digital art",
	"A detailed illustration of a Victorian-inspired pocket watch with an open face, showcasing the elegant hands and engraved inner workings, digital painting",
	"A vibrant set of mystical potion vials, each filled with neon liquids and arcane symbols, set on an ancient wooden shelf, digital art",
	"A concept art of an ornate dagger imbued with elemental powers, flames and ice emanating from the blade, digital painting with dynamic effects",
	"A beautiful illustration of an Art Nouveau lamp, with intricate designs and colorful stained glass casting soft light, digital painting",
	"A photorealistic close-up of an ancient manuscript, open to pages with hidden maps and cryptic languages, set in a dimly lit study",
	"A dramatic landscape of a golden wheat field, with dark, stormy clouds approaching in the distance, digital painting with a focus on contrast",
	"A mesmerizing landscape of a frozen lake under the colorful dance of the Aurora Borealis, with clear reflections on the ice, digital art",
	"A serene landscape of a lush vineyard at sunrise in Tuscany, with rolling hills and a warm palette, digital oil painting style",
	"A breathtaking landscape of a secluded mountain monastery in the Himalayas, perched precariously on a cliff edge, digital painting with a focus on tranquility",
	"A vibrant landscape of a cascading tropical waterfall, hidden in a lush rainforest, with sunlight filtering through the canopy, digital painting",
	"A cozy landscape of a rustic cabin during a gentle snowfall at twilight, with warm light spilling from windows into the snow, digital art",
	"A mysterious landscape of ancient temple ruins, enveloped by a dense jungle and creeping vines, digital painting with a focus on exploration",
	"A dramatic landscape showing a fiery volcano eruption at night, illuminating the surrounding landscape with lava and ash, digital painting",
	"A tranquil landscape of a secluded desert oasis, with palm trees and a clear pool reflecting the sky, surrounded by dunes, digital art",
	"A picturesque landscape of a cliffside village overlooking the Mediterranean, with vibrant buildings and blooming bougainvillea, digital painting with vivid colors",
	"A stunning macro photograph capturing the delicate details of a butterfly perched on a vibrant wildflower",
	"A sepia-toned historic photograph capturing the moment a steam train crosses an old bridge, emphasizing the steam and architecture",
	"An eye-catching aerial photograph of a natural heart-shaped island, surrounded by crystal-clear waters",
	"A breathtaking night sky photograph capturing a vivid meteor shower, with clear details of the shooting stars and the Milky Way",
	"An adventurous underwater photograph showing a diver exploring ancient shipwrecks, emphasizing the eerie beauty and scale",
	"A vibrant street photograph capturing the energy and colors of a parade, despite being rain-soaked, with reflections on the wet street",
	"A powerful wildlife photograph capturing a wolf pack moving through a snowy landscape, focusing on their expressions and the environment",
	"An atmospheric long exposure photograph of a cityscape at twilight, capturing the movement of lights and the transition from day to night",
	"An aromatic-looking drone photograph of a lavender field",
	"Landscape of a Energizing Chibi Rocky Mountain National Park, At midday, Dramatic lighting. dramatic shadows, intricate, elegant, highly detailed, artstation, 20th century. painting, oil paint, vivid colors, gold accents. Rocks, sharp edges, cinematic lighting, bright, golden, sunlight, Trees, autumn leaves",
	"The War of Love, intricate, elegant, fine details, trending on artstation, masterpiece, rich colors, beautiful contrasts. Art galleries, hd quality, illustration, matte painting. Highly Detailed, dramatic lighting, soft light, intricate, hyper detailed, unreal engine 5 rendered, concept art, smooth, sharp focus.",
	"Concept art of a at atmospheric The Sierra Nevada. Landscape, amusing, fantasy, foggy, soft focus, midday sun, trending on pixiv, 8k, colorful, comic, high detail, science fiction, albumen, detailed, epic, funny, mid day, volumetric lighting.",
	"Anime, lone figure, white uniform, red cap, cute face, high-detail, expressive art, cinematic lighting. Landscape of a Motel, beautiful colors, 70s aesthetic, vintage cars, retro neon signs, glowing moon, orange trees",
	"landscape of a alluring hostel, sun, cyan blue light, gold accents, elegant, painted white walls, red tile roof, flowers, trees, by the lake. Scene: 05:00 pm lighting, colorful, beautiful flowers bloom, people, lively, bright colors, barbecue. Background: exquisite painting, realistic brush strokes, realistic trees, shimmering lake water, dramatic effect, sun, intricate, elegant, highly detailed",
	"A RAW photo, Raging iceberg, sharp details, dramatic lighting, Ornate, detailed, intricate, elegant, Art Nouveau, ornamental, Highly detailed, Decorative, Artstation, concept art, Cube shape, Glacier, frostbite, snow-covered",
	"A bench, intricate, ornate, wood grain. Ivy, green, forest. Sunlight, warm, dappled. Wildflowers, colorful, flower bed. Fantastical creatures, detailed, hatching eggs. Unrealistic trees, by Tiffany Haddington, by Greg Rutkowski, by Takashi Morimura, bright colors",
	"modernism art, colored hair, bronwyn, high contrast, 50s era, retro, matte white, cinematic 8k, highly detailed, very sharp focus, natural lighting, artistic trend, highly realistic, ultra high definition, masterpiece. painting by stanley lau, studio, 8k, very fine details, rich colors, glossy and vibrant",
	"A fan, black, tall and detailed, intricate, nu goth, 4 blade arms, art by Klimt, highly detailed, digital painting, concept art. moonlit, moody, dark, by greg rutkowski and makoto shinkai, dramatic lighting, high contrast, realistic shadows, full moon.",
	"Anime, portrait, cozy crossbow, glowing embers, detailed craftsmanship, intricate wood grain, shadowy atmosphere, by Yoshitaka Amano, Ghibli, Kawaii, beautiful lighting, cinematic depth of field, DSLR.",
	"portrait of a alluring Istanbul, highly detailed, close up view, triadic colours, clear skies, trending on artstation, hd, digital painting, 8k",
	"RAW photo, (lose myself in music : 1.1), pond, supernova, iphone x, depth of field, 270 mm, magnificent, cute, tiny, octane color, beautiful reflections, hyperrealistic, cinematic lighting, hd 4k, rule of thirds",
	"alluring Cosplayer Aerith Gainsborough, white hair, wide view, cinematic lighting, vivid colors. dramatic pose, golden dress, detailed face, hyperrealism, holographic background, shimmering lights, octane colors, dynamic lighting",
	"Art Nouvear, beautiful art, bobcat. organic design, sinuous lines, art nouveau style, long body, floral patterns, beautiful details, flower by artgallary, dramatic lighting, stunning details",
	"Artistic Concept art, middle aged woman (56), Asian descent, detailed face, intricate hairpiece, trending fashion style, 2000s color palette, 8k, high definition.",
	"soft light, realistic art, shallow depth of field. footsteps in sand, detailed, highly detailed, realistic, sharp focus. intricate, elegant, in the style of muybridge. digital painting. trending pixar style.",
	"A highly detailed landscape of a surreal, snowy desert: 1. 800mm lens, ultra high res, compact camera in hand. 2. Photorealism, perfect, cinematic lighting. 3. The Painted Desert, snowy and still, vegetation. 4. Surrealistic, surreal, art, concept, painting.",
	"Britpop, intricate, psychedelic art, trendy, late 90s, fashionable, band, microphone, colourful, patterned, street festival, atmosphere, light blue sky, bright red sun, beautiful, elegant, highly detailed, highly detailed, intricate, painted, artwork, geometric, sharp focus, concept art, smooth, soft lighting, art by artgerm and greg rutkowski and alphonse mucha and craig mullins",
	"Landscape of the Bryce Canyon Amphitheater, summer, calotype. Beautiful colors, red hues, blue background, cliffs, hoodoos, natural stones, soft shadows, intricate details, dramatic lighting, elegant, detailed, art by fox. Captivating view, highly detailed, sharp focus, bright sunny day, clear sky, fine detail, in the style of the calotype process.",
	"RAW photo, Filipino, polaroid. Forged by konami, colorful, golden hour. Dense trees, cinematic, intricate details. Action scene, rich colors, dramatic light and shadow, detailed, vivid, portrait photography.",
	"Portrait of a Landscape (close-up), winter fantasy, sci-fi, intricate gem city. Beautifully detailed gemstones, colorful and shiny, highly reflective, dramatic shading. Landscape of Seattle (interior) , art deco buildings, glowing lights, gemstone and crystal accents, intricately carved. Winter setting, snowflakes falling, winter trees with diamond leaves.",
	"intricate details, side-lit female sith, detailed face, elegant robes, dramatic lighting, elegant, highly ornate, masterfully crafted, voluminous, intricately, by Ruin Lake, moonlit, shimmering.",
	"a water surface, very capricious, disheartened mood, rule of thirds, trending with sunlight, intricate, polychromatic details. octane colors, brush strokes, soft focus, brushstrokes, clear, transparent, brushstrokes, clear, transparent, brushstroks (the painting: by Artgur)",
	"minimalist man, long hair, white armor, intricate, elegant, highly detailed, digital painting, art by wlop and greg rutkowski and alphonse mucha, 8k resolution, beautiful lighting, soft lighting, trending on artstation",
	"A large cutout figure of \"the aurora of longing\" dancer. Means, white mist, sharp focus, 100 years old. S. Eyes. Mean face, detailed, very ornate, austere, black, intricate, glowing red gems. Awe-inspiring. Monochromatic, highly detailed. Romanesque, swirling dark red, sharp, curved, very artistic by  Stanley Artgothic Studio.",
	"A photograph from side of a realistic shaded female figure named Eve. Sharp detail. In focus, realistic, trending on Samsung Galaxy. DSLR camera, depth of field 270mm, octagon shapes, sharp edges, soft background, cinematic aesthetic",
	"Concept art of 1.2 of the fantastic Federated States of Micronesia: mountain range, intricate, ornate details, cinematic lighting, dramatic composition. At sunrise, vibrant colors, gold accents, ray, sun beams, epic fantasy, by Ruan Jia and Zhao Lichao, digital artwork, painterly, matte painting.",
	"cinematic still, tranquil, gemstone, transparent, crystal clear, intricate, octagonal shape. Emotional, glowing face, detailed features, high tech, cyberpunk, aesthetic, sharp edges, hard focus. orange neon hue, masterful painting, concept art, glow, background, moody, atmosphere, illustration",
	"graphic print of a captivating aldhani, detailed eyes, intricate, elegant, highly detailed, artstyle, smooth, sharp focus, illustration by johan berghoi and robert mccabe and jin hwan. At twilight, vibrant colors, orange sunset, trending on artstation.",
	"A close-up of an Algerian welding machine. detailed. high contrast. intricate. glowing arc. spark. fumes. highly detailed. centered. sharp. focused. artwork, trending on 50mm. andromeda galaxy. far away. background. background. hazy. beautiful details. colorful. celestial bodies",
	"Medium shot of a good-looking turkey, fine detail, masterpiece artwork, intricate, sharp focus, volumetric lighting, elegant, smooth, iridescent. Rural background, autumn leaves, soft light, cinematic lighting, trending on artstation",
	"4k realistic render, trending on pixiv, artstation, deviantart, by greg rutkowski and tom bagshaw",
	"intricate details, glamourous sloth, wearing kanzu, sharp focus, intricate pattern, intricate artwork, highly detailed, symmetrical, beautiful details, trending on art, golden hour, sunrise, detailed illustration, matte painting, smooth, soft, very very much art by Wlop and Artgerm and Brom and Wayne, very detailed, very nice",
	"A serenade from the heavens, rain, rhythmic melody, horror, dramatic atmosphere. raindrops, splash, high-contrast, dramatic lighting, gothic, glowing lighting, spatter, baroque art. octane, splash, mist, dramatic, cinematic lighting, highly detailed, raindrop, dystopian, fantasy, very dark, trending on Lomography, highly detailed, dramatic composition",
	"A constructivism art lover, standing, minimalistic, intricate, elegant, highly detailed, digital painting, 8k resolution, sharp focus, illustration by Stanley Artgerm Lau, Masterpiece. at overcast, cinematic atmosphere, warm lighting, depth of field 270mm.",
	"Concept art of a relaxed, ultra detailed and intricate, Landscape of a Andalusia. Sun is high. K-pop music. Science fiction. Trending on the sky, vivid colors, sharp edges, highly detailed, artistic, smooth lines, trending by Jake Lee, K-pop, synthwave, style. By Akihiko Hosoi, Sakura Hanamiyama, Ultra detailed, volcanic mountain range, intricate, artwork, CG render, 3D design.",
	"A white and silver necrium vial, intricate, elegant, highly detailed, digital painting, smooth, 3D. Rendered art by Stanley Artgerm Lau, WGSN, Youtube, HDR, volumetric lighting. Shatter-resistant glass, Triton Alloy, metal plating, soft glowing lines, soft and hard edges. High contrast, hyper-realistic, smooth surface, HD quality.",
	"Unattractive (Commander Shepard), athletic, long coat robe, oriental, outdoor scene, daylight, sunny sky, wide view, tranquil, distressed, vintage, textured, punk rock vibe, cinematic angle, grunge style, dirty, noisy, amateurish.",
	"Divine Car, mauve and sage, holy symbol of faith. High priest, white robes, intricate design, soft glow. Beautiful landscape, mauve trees, sage forest. Holy artifact, divine power, radiant light.",
	"landscape of a Poland, dense forest, bathed in shadows, misty, blurred, cinematic atmosphere, golden hour, cinematic, dynamic, cinematography, forest, intricate, elegant, highly detailed, trees, golden autumn colors, octane, cinematic film, trending on artstation",
	"a skilled photographer, very thin white outfit, short white hair, trending onset smile, cinematic, adroid face, octane blue camera.",
	"portrait of a Saint martin, bathed in shadows, dramatic lighting, close-up, high key focus, epicentre, art by stanley lau andartgerm, peter mohrbach, tobrukh and wlop, masterful brushwork, smooth and sharp 800mm lens, fujocolor film, vibrant, cinematic lighting",
	"Grunge Art, Joyful, cinematic lighting. Nightingale, D&D, character portrait, beautiful details, dramatic composition, digital art by Stanley Artgerm Lau, WG and Jean Giraud and Greg Rutkowski, masterpiece, 800mm lens, sharp focus, trending on ArtStation",
	"A mundane photographer, common clothing, camera, bystander viewpoint, shallow depth of field. Ultraviolet, nighttime, infrared tone, highly detailed, intricate details, 80mm lens. Ostrich, ultraviolet tone, bright, vivid colours, realistic, hyperdetailed, sharp contrasts, dark details.",
	"mandala, highly detailed, cube, sharp edges, intricate, intricate, highly detailed, octagonal faces. Annam Chlumsky, pretty face, cinematic close up, long blonde hair, cinematic close up. Halter top, colorful, inspired by Hufflepuff. High contrast, colorful, bright. cinematic close up, long blonde hair, golden hour lighting. Large gold eyes, photorealistic. kodak gold 200 film photography, highly detailed, realistic, depth of field, 100mm. Cold colors, dramatic, cinematic, winter photography",
	"cinematic portrait of, dancer, in the club, glow disco lights. 85 mm lens, mid shot, high contrast, dramatic side lighting. 70 s art, red and gold tones. moody atmosphere, sharp focus, cinemascope, retro gritty.",
	"Portrait art by Conrad Roset, Glamorous, Glowing Dome lights, Artistic Wavy Hair. Taiga forest, Moving Sun, Glowing auburn and orange hues. Watercolour painting, Surrealism, Water drops, natural elements.",
	"Kali, bandanna, high priestess, bioluminescent, intricate, colorful patterns, art, black hair, elegant, detailed. Scene: temple ruins, glowing artifacts, bright colors, beautiful artwork by Yoshitomo Nara and Nathalie Djurberg, stunning artwork, 80s aesthetic. background: High Priestess Kali, soft skin, colorful eyes, long hair, trendy bandanna, intricate, elegant, highly detailed, artwork, by artgerm, Greg Rutkowski and Edgar Dela Pe\u00f1a.",
	"Botanistic artwork of an award winning, futuristic, x-wing. Joyful, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, cinematic lighting, warm and bright, highly detailed, Artgerm, Ilya Kuvshinov, WLOP",
	"Photograph, intricate, elegant, cute batman, bewildered, realistic, highly detailed, centered, illustrative, concept art, smooth, sharp focus, ambient lighting. Canon R5, cinematic, epic composition, dramatic lighting, aesthetic, artistic, high resolution, 100mm depth of field.",
	"3D model of Eevee, intricate, smooth details, fur texture, glowing eyes, beautiful shaded lighting, high contrast. Artstation, realistic, professional, matte, perfect,",
	"portrait, intricate, elegant, highly detailed, artstation, smooth, sharp focus, photorealistic, glowing eyes, luminescent, soft light. Through the veil of dreams, snowflakes fall, mist, by Makoto Shinkai, WLGHFJB-N, cinematic lighting, octane render. Sensual, highly detailed, concept art, oil paint, 2D",
	"Portrait, highly detailed, cinematic lighting, 3D, realistic, cgi art. The ship, sailing, ancient, detailed, golden, sails, shimmering, intricate, decoration. High waves, water, crystal clear, frothy white tops, sunset, blue, red, orange horizon.",
	"Alluring Anthropomorphic Train, highly detailed, realistic face, beautiful, colorful eyes, dramatic pose, shinning details. Sun, bright, bold colors, cinematic light. Bokeh, artistic, soft focus, blurred lines. Cyberpunk Art, intricate, epic detailing, glowing lights. High tech environment, artwork, by H. R. Giger and C. M. Koehler.",
	"Landscape of a (the Scottish Highlands), natural, flowers, Moon in the night, natural lighting, 50 mm, art by Makoto Shinkai and David Attenborough, trending on ArtStation, cinematic composition, vibrant colors, natural painting.",
	"A diagram of a landscape, dark, detailed, hd uhd 4k, starry night sky, intricate, sci-fi, trending on pinterest. Space garden, artistic, hyperdetailed, concept art, volumetric lighting, rays shimmering, flowers, neon blue, blooming, iridescent petals. Dusk, sunset colors, hd, 4k uhd, high contrast, intricate, cinematic, masterpiece, art by greg rutkowski",
	"landscape of The Galapagos Islands from inside of a Asmara, Beautifully lit, cinematic, dramatic cinestill. Huge windows, snowflakes, reflect on the glass, intricate, ornate details, elegant, white, blue hues, blue tranquil ocean, trending art nouveau, 3D, smooth, cinematic, artistic, golden ratio, scenery, dramatic lighting, cinemascope.",
	"a 3D Rendering, DSLR camera, cinematic, photorealistic, 8k, volumetric light, dynamic shadings. Red dwarf star, intricate, glowing details, detailed shadows, elegant, smooth, mist, beautiful composition. Inside Coral Castle, coral formations, glossy, detailed texture, art deco, fantasy, colorful lighting, bioluminescent, depth of field, soft focus",
	"a dark winner illustrator. standing in front of the city of El Dorado. highly detailed face. gloomy expression. intricate, elegant, white hair, cinematic lighting, blue hour. deep focus, depth of field, 270 mm. cinemascope format. cathode tube, Adobe Lightroom, masterpiece. dramatic lighting, trending on Artstation, award-winning, masterful, unseen in the whole galaxy, intricate, sharp, detail, HDR, masterpiece, octane render.",
	"conception art of a plump female fashion model, intricate, elegant, highly detailed, digital painting, artstation, smooth, sharp focus, concept art by wlop and ross tran",
	"Printcore, high detail 3d printer, glowing lights, cold lighting, cinematic lighting, cold blue tone, highly detailed, holographic design, 8k, ultra realistic. Landscape, landscape art, trending on Artstation, by J. D. Gafford, Tikal, Pyramids",
	"3D Rendering, glamorous nightingale, glowing feathers, shimmering night. fantasy art scene synthwave art by pete mohrbacher and ross tran",
	"A Baroque rendition of, Grandpa, opulent, ornate, dramatic lighting, underwater background, puny clay helmet. Dynamic, intricate, elegant, highly detailed, decorative, water bubbles. Trending on Artstation, masterpiece, octane render, smooth, sharp focus, illustration, art by Artgerman and greg rutkowski and alphonse mucha",
	"collaged surface, textured, layered, art paper, gears, 3D, deep shadows, detailed, concept art",
	"( highly emotional ) Japanese manga artist, baroque nu goth art, intricate, elegant, highly detailed, digital painting, trending pixiv, artstation, smooth gradients and shading. Illustrated artwork, beautiful face, by artgerm and Tom Bagshaw, WLOP, Stanley Artland, Randy Hujitake, Cygames, 4k",
	"expressionism art style, artist in action, (isengard:1.1) , beach, cinematic, dramatic lighting, high detail, movement, sharp lines, hard edges, contrasting, trending on pinterest",
	"a flawless (male) (klingon), body shot, cinematic lighting, masterpiece. high contrast, single color, 800mm, lensesharp focus. dramatic scene. hurricane, wind, detailed rain. wide view, highly detailed, digital background",
	"Landscape of a snowing Saint Peter Port. Creative digital art. Pixiv. Science Fiction. Pixelated trees, glowing orange and blue hues, sharp angles, soft white snow, digital brush strokes. Winter, sci-fi, glowing snowflakes, warm yellow light, 8k resolution, futuristic background, intricate, octane render.",
	"A highly detailed needlepoint portrait, ultra realistic, trending on artstation, hd, 8k",
	"3d isometric render low poly of a monstrous peacock. pixiv, scary, fantasy, dark lighting, digital art, trending in the artstation",
	"A Northern Chevrolet car, dark blue color, hooded lights, dashboard, gloomy light, dim lighting, interior, intricate, highly detailed, engine noise, cinematic angle, gritty, dirty, oil stains, dust, sunny day.",
	"cardinal, detailed realistic painting. Beautiful bird, soft colors, red-orange plumage, blue beak, sharp focus, intricate detail, art by Artgerm, cinematic lighting, 3D perspective, dramatic atmosphere, trending on pinterest.",
	"Anime, Anime, Patchwork Of a Snorlax, Anime style, colourful, intricate pattern, detailed stitching, glowing embers, soft, realistic texture, background: Cute, Anime style house, realistic, 4k hd, colorful lighting.",
	"a fantasy cybernetic style elderly figure of Aloy. holding a musical instrument, french braid hair, intricate, elegant, highly detailed, digital painting, artstation, smooth, sharp focus, synthwave, ray tracing, hd, 4k, by Gustave Dzivkovic and Alphonse Bujossab, artwork",
	"Portrait of a Large Girl, wearing robes, angry expression. Minimalist scene. Art by artgerm and greg rutkowski and alphonse mucha.",
	"A oil painting, portrait-style, dark color palette, brushstroke, trending on artstation, realistic, smooth, 3d effect. Artwork by Tom Bagshaw, Bold, artistic, cinematic, dramatic, octane rendering. Srebrna G\u00f3ra, mottled background, dramatic, cinematic lighting, misty, high contrast. Arctic scene, sharp details, misty landscape, concept art",
	"A balmy, sunny day. a young woman, Japanese Kimono, wind with hair, flower in hand. Scented wind. Oil painting style, artgoverdet, warm lighting, soft shadows, brush stroke, brush strokes. by James Tissot, Fujite Tints",
	"A very gloomy, glossy black squash, epicenter of scene. At Montreal. Wide shot. Hands holding. Sharp focus. Bokeh, intricate, elegant, highly detailed, digital painting, ilya belev, pincushion bokeh, smooth blur, red umbrellas. Scene, atmosphere, cinematic. High contrast. Rain, dark blue, sharp, glossy, raindrops, warm lighting, soft.",
	"A filigree of a syafiqah, sad face, eating garbanzo beans. Highly detailed, artstyle, very very very detailed, very intricate, hyper realistic. Rose pink, charcoal trench coat, cinematic light, midday sun, pretty, elegant, highly detailed, photorealistic, beautiful, highly detailed, beautiful, midday light, kodak ektar, trending on instagram",
	"A Encaustic painter, annoyed, bathed in shadows. Garden, textured surfaces, opulent, naturecore, intricate details, cinematic lighting, art studio. Wax medium, rich color saturation, luminous quality, mixed media elements, highly textured, cinematic environment, atmosphere depth, artistic and whimsical",
	"Concept Art, Dry, Absurd, Fantasy, Beautiful, elegant, intricate, highly detailed, digital painting, smooth, glossy finish, trending on artstation, featured on behance, hd, high resolution, 5k. scene: Artistic, conceptual, sharp focus, illustration,",
	"A concept art, smooth brushstrokes, detailed, cinematic composition. Landscape, 8k resolution, ultra realistic, trending on Artstation. High-details. Fusion. Split screen. Ethiopian terrain. Mountains, high mountains, peaks, intricate details, rich colors, natural lighting, beautiful scenery. Beautiful, landscape painting by Stanley Lauritzen and David Hockney and Wlodzislaw Stanis\u0142awski, very realistic, trending on ArtStation hq. Atmospheric, masterpiece, 4k",
	"art illustrating insane amounts of raging elemental energy turning into cozy, dreamlike, 8k, highly detailed avatar of elements, fantasy, surrealism, soft light, shallow depth of field. Magpie, golden details, high contrast, sharp focus, magical surrealism, wizardry, trending on artstation",
	"intricate background, highly detailed, trending on art station, award winning music producer female, flare pants, colorful, cinematic lighting, film grain",
	"artistic Photograph, \"The slaying of Medusa by Perseus\", Dynamic action painting, Photorealistic, Iphone X. High contrast poster art, highly detailed, dramatic pose, sharp focus, 270mm depth of field. Photorealistic image, intricate, cinematic lighting, elegant, artistic, highly detailed, by R. Magritte. Narcissus, pensive, golden face, perfect reflection, golden flowers, voluminous folds. Beautifully composed, cinematic, epic, masterpiece.",
	"Hieroglyphs of an elegant Suckerpunch, movie still. cinematic lighting. shaded lighting, dramatic, dark, deep shadows, contrasted, 4K, HDR. by Charles Rocket and JJ Abrams.",
	"Cinematic Still: beach, warm sand, shimmering sea, blue sky, \"Hey There Delilah\" playing, intricate, elegant, golden hour lighting, highly detailed, digital painting, trending on artstation, concept art, smooth, sharp focus",
	"Traditional malian female mermaid, smiling, white, pearl colored outfit. Golden jewels, fish scales, intricate, elegant, glowing pearls, hair in braids. Happy, smile on lips, white sea foam background, playful, wave, coral reefs, beautiful fish, underwater world, sunshine, blue water, colorful fish.",
	"Portrait, mouse, highly detailed, realistic, sharp focus, 3D, art by Klimt and Pazinich, dramatic lighting, masterpiece, glowing eyes, intricate details, detailed fur, trending on artstation, in style of peter mohrbacher and ross tran and peter garmand and beeple",
	"Geeky!, intricate, detailed, unfinished comic, artwork by Greg, colorful, trending on Instagram, high details, street art, urban decay, graffiti, red-brick, peeling, blistered, highly detailed. The Ruins of Death, sunny, dramatic lighting, beautiful, artistic, bright colors, street art",
	"pottery of a crowded gravekeeper's chief, intricate, glazed, glowing, bright colors, detailed, globs, sharp details. gloved hands, dark blue clothes, long cloak, worn leather boots, art by wlop, ryan hilbert, peter mohrbacher, lee mun shin",
	"Creative digital art, abstract shapes, intricate, elegant, highly detailed, centered, smooth, cinematic lighting, sci-fi, art by Stanley Laurrent and artgerm",
	"A detailed, masterpiece, grayscale, 80mm, cinematic, masterful, subjective, landscape of 2000's Mdina. majestic, symmetrical, architectural, elegant, artifacts, rain, thunderstorm, lightning.",
	"dark rhabarbs, glowing white aura, 45mm wide view lens. polaroid art, flat shading, cinematic contrast. orton effect, dramatic lighting, by harris nebulas. photography, rule of thirds, selective focus,",
	"From inside of a Laos, beautiful view, artistic style, perfect details, high quality, in the style of Burt Cecere, Stanley Artgerm Laurence, WLOP",
	"Photograph, portrait of a \"fair haven\", highly detailed, intricate, elegant, art by artg. 4k resolution, shallow depth of field, rich colors, vibrant, realistic, glowing lights, beautiful, smooth, sharp focus. Trending on ArtStation, HDR, masterpiece, digital painting, cinematic lighting, Art by xtremej and albertphotography",
	"A Gold Digital Age sweet peas, full body shot, golden hair, shimmery dress, trending outfit, bright colors, mid-shot, realistic shadows, photorealism, flower accessory. Photoshoot, sunny day, wide view, beautiful garden, artistic, bright flowers, sunlight, High Dynamic Range.",
	"photogram, sharp focus, high contrast lighting, harsh lighting, cinematic lighting, bright spot, dark shadows, cinema atmosphere, art deco, 3d, hyperrealism, hdr, wide angle lens, masterpiece",
	"Anime, detailed illustration, ornate, red and gold details, smooth, 3d render, shadowy atmosphere. Highly detailed, art by artgerm and greg rutkowski, 4k, cinematic, vibrant colors, dramatic lighting, trending on pixiv, stunning, octane render, very detailado, cinematic quality. 8k, hyper realistic, highly detailed, very detailed, very cinematic, art by wlop and greg rutkowski, hq",
	"animate, d&d, highly detailed, intricate, elegant, sharp focus, trending fanart, dramatic composition, art by artgerm and greg rutkowski, cinematic lighting, highly detailed, 4k",
	"A RAW photo. The main focus, beautiful woman, golden light, warm colors. Tender and Celestial, High Key. Fantastic Artstyle. Rococo, intricate, ornate, elegant, highly detailed, gold accents, digital painting. Hopeful, positive energy. Artstation, trending on artstation",
	"attractive (intricate) evil (dark) masterpiece (fantastic) glowing red lights (night), beautiful, detailed, intricate, elegant, sharp focus, illustration by Greg Rutkowski and Simon Stalenhag and Toi Kawabata and Artgerm and Wayland Games",
	"Japanese temple, wooden structure, ornate, intricate, painted red and green, hazy atmosphere. Japanese garden, trees, foliage, pond, artful stone, haze, warm tones.",
	"a Mean Dutch Male, At twilight, sharp focus, glitchcore, masculine, fine details, brush strokes, intricate, octane red, art by Artgerm, Bokeh, High contrast, highly detailed",
	"A quilted Umaibou, octopus creature, trending pixar esque look, sharp lines, perfect details, high contrast lighting, by Stanley Laumuka and Mauro Cordiano, studio ghibli, Japanese, art style. Under the dark atmosphere, beautiful atmosphere of tenebrism. Warm lighting, cinematic, glowing, golden, highly detailed, intricate, octopus, polygonal, studio ghibli",
	"digital art, retro focus, portrait, realistic, cybernetics, retro futurism, by james jean, craig mullins, brian tudor, greg rutkowski, huang zhangjun, trending on artstation, poster art, high contrast, intricate, elegant, sharp focus, detailed, hype fans, highly detailed, illustration, digital painting, by jake wujnovich, mark brooks, rob rey and artgerm, masterpiece, matte, smooth texture, bold colors, 80s",
	"epic Anime, noble Oyster, royal heroic protagonist, elegant, intricate, manga style, digital painting, golden lighting, cinematic, dramatic conditions, foggy conditions, shimmering pearlescent, dripping purple, realistic, perfect details",
	"Pictorialism Illustration, in focus, realistic, trending on Artstation, masterpiece, cinematic concept art. Sun, vibrant, realistic, soft edge lighting, realistic shading. Naturalistic lighting, dramatic effect, atmospheric haze, cinematic depth of field. Field, color palette, muted, blue tones, soft focus. Realistic, artistic interpretation, dramatic mood, concept art, smooth, cinematic atmosphere, trending on Artstation",
	"A Profound garden, highly detailed, nature and machine, organic, intricate, colorful, artstation, trending on artstation",
	"award winning, male philospher, elegant, oversaturated, white suit, black tie, black hair, 40's style glasses, highly detailed features. Beautiful art, gallery, soft lighting. Artwork, black and white, intricate brushstrokes, scenery, hazy atmosphere, high detail, beautiful, elegant",
	"historical man, writing, zen, sunset, oil lamp, paintbrush, brushstrokes, sharp focus, intricate, elegant, highly detailed, painting, art by artgerm and greg rutkowski and alphonse mucha",
	"grunge style, Bagrati Cathedral. At sunset, textured, detailed, distressed, vintage look, intricate, elegance, highly detailed, art, smooth focus, trending on art, Punk Rock vibe, grimy, dirty scene, broken bricks, rough surfaces, high contrast, retro lighting, noisy background, moody atmosphere",
	"A catalog shot of, fairy tale, nurse Joy, hand holding vanessa ferlito, white tunic, trendy details, fleshtones, golden lighting, beautiful, sharp, 70mm lens. Intricate, elegant, highly detailed, digital painting, artstation, matte, smooth background, clear focus on attire, dreamy, masterpiece, wlop, becky dampier, octane render, 4k, unreal engine",
	"A natural white and aqua blue bison, bizarre contrasting colors. anime artgamme, cinematic shot. Anime screencap. Nature and Poinsettia background, beautiful forest scene. Poinsettias, bright red leaves. A tree trunk, wood grain, smooth texture, trees, golden autumn colors.",
	"A barn, intricate, 3d rendering, highly detailed, by Greg Rutkowski, digital art, studio lighting, 4k resolution. In the foreground, door, open, warm colors, golden hour, soft light, cinematic depth of field. Hogwarts castle, background, majestic, stunning, elegant, beautiful, dramatic night scene, sunset, magic, glowing lights, art by Artgerm, Greg Rutkowski",
	"Anime, matured macanese aunt, highly detailed face, midshot, trendy high-waisted shorts, black, white stripes. realistic brunette hair, smooth skin, realistic blouse, blue, red, yellow, volumetric, cinematic lighting, masterpiece, elegant, realistic, beautiful details, trending on artstation, 4k, rpg, high detail",
	"Shoreline, cinematic lighting, masterpiece, perfect symmetry, beautiful contrast.",
	"concept art, blonde haired Robert Pattinson (stout, 1.2), hopeful expression, natural lighting, plain white background, group of animals, detailed, highly detailed, digital art, trending on artstation",
	"A jiggly puff, soft pink and white, glowing eyes, holding a digital camera. Thunderstorm, dramatic lightning, rainwater droplets, rainbow, splash.",
	"A Grisaille painting of, pleasant Westminster Abbey, detailed, realistic, tonal, baroque, highly detailed architecture. Cute, intricate, ornate, baroque, stylish, monochromatic, elegant, perfect lighting, masterpiece.",
	"A photograph, landscape of an alluring Jijiang (1.1), jungle, highly detailed, art by Ansel Adams and Greg MacWilliams, beautiful colors, rich color palette, vivid, stunning, masterpiece, soft focus, dslr, depth of field 100mm. Landscape, vivid color contrasts, red sunset, sky on fire, blue tones in background, golden light, hazy atmosphere, highly detailed, complex, artistic composition.",
	"Female gallery owner. Near Juba. At sunset. High key light, feminine, elegant, black and white, intricate, highly detailed face, digital art, painting, golden hour. Artwork, abstract shapes, geometric forms, art by artiticlaean-painters, art studio, oil on canvas.",
	"landscape of a Zanzibar, Fall. Post-punk, cold colors. In the style of Mona Lisa. 01. A woman's profile, detailed, cold eyes, in the style of Mona Lisa. Fall, cold trees, dark leaves. 02. Landscape of Zanzibar, old buildings, intricate patterns, golden accents, retro-futuristic vibe. Post-punk colors, sharp edges, fine details. 03. Background: Sharp focus, realistic trees, misty foggy atmosphere, soft light mist, cold mist, trending art style",
	"award winning, Abyssinian Cat(1.3), rafting, intricate, elegant, highly detailed, digital painting, art by Stanley Artgerm Lau, WLOP, award ceremony lighting, warm lighting, hippy style, intricate, soft focus, '70s influence, tiedye, fringe, trending on artstation, art by JG and Greg Rutkowski",
	"A complex male daedra, face, Al Pacino eyes, shrimp, blue-grey iris, shading, rim light, sunny orange hue, facial details, intricate, detailed, cinematic lighting, shaggy black hair, brown, volumetric fog.",
	"city street, landscape of a cute The Yggdrasil Tree, foggy dark hue, sweet goth, misty raindrops. Fantasy, mystical tree, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, Unreal Engine 5.",
	"A collectable toy action figure, plastic, highly detailed, intricate, epic details. portrait of a tribal monastery, intricately ornate, symmetrical, art by art, volumetric lighting, golden temple, red roofs. Depressing, background, grey light, white mist, autumn colors, bokeh, trees, mist, twilight, realistic.",
	"Monolith, highly detailed, intricate, elegant, artifact, glowing light. Scene: Surreal landscape, dramatic lighting, sharp contrast, volumetric lighting, misty foggy background. Background: Trending on Artstation, artstic",
	"professional trompe l'oeil artist, highly detailed, artstation, intricate, unreal engine, 3D illusion, kawaii anime style, sunny sky, art by artgerm and greg rutkowski and alphonse mucha",
	"portrait, highly detailed, realistic, intricate, epic, masterpiece, trending in pinterest, high contrast, golden hour, by greg rutkowski, stanley artgerm lau, wlop, jeremy lipking, intricately, sharp focus, details",
	"cinematic still, beautiful rickety hydroelectric power plant, intricate, elegant, highly detailed, ornate, art nouveau, digital painting, water, dynamic flow, light reflections. manners art, smooth, sharp focus, trending on artstation, 8k, deviantart",
	"fractal maiden, intricate, highly detailed, artstation, smooth, sharp focus, illustration, white background, holographic, concept art. Unreal, surreal atmosphere, art by Artgerm and Greg Rutkowski. Inside a Anime Bahla Fort, colorful, symmetrical, 4D design",
	"A detailed low poly viewpoint, intricate artifact, highly ornate, epicenter, polished stone, soft lighting, blue and green accent tones, gold leaf, geometric patterns, isometric perspective. From inside a home office, by jade-hued window, oil painting, detailed, shimmering lights, candlelight, highly detailed, intricate, sharp focus, cinematic lighting, trending art.",
	"3D Rendering, landscape of The Amazon Rainforest and Cable car. detailed artwork in the world. fall season. intricate, elegant, highly detailed, digital painting, trending on artstation, cinematic light, vibrant orange colors, reds, yellow leaves, golden hour. ornate, by Stanley Artgerm Lau, Wlop, Rossdraws, Jambred, Ted Simon, Andrei Svisivcii.",
	"A corporate branding style, woman. Trendy, sunny color palette. Professional setting, bright lighting. Clean, minimalist design. Sleek, minimalistic aesthetic. Business-oriented, minimalistic art. Highly detailed, 3D renderings. Minimalism, modern shapes and forms. Futuristic, glowing lines. Industrial elements, smooth, metal textures.",
	"a photograph, detailed, concept art by Greg Rutkowski and Wlop and Behance, high contrast, dark colors, cinestill, realistic style. Gwalior Fort, 10 p.m., realistic, atmospheric, gothic themes. Canon eos 5d mark 4, cinematic, dramatic lighting, 100mm, depth of field, sharp focus. Neutral hue, dramatic lighting, trending on artstation, highly detailed, digital art, concept art, symmetrical, vertical proportions",
	"a Misshapen Man. hip level shot. Depth of Field, 270mm. zen. snowy, landscape. white, snowflakes, cold hue, highly detailed, dramatic contrast. Art by Makoto Fujimura, Ilya Kuvshinov, Studio Ghibli, Fujicolor Fujichrome Velvia 100.",
	"cinematic still, \"The icon of macabre\", fantasy, movie still, dramatic, dark mood. Emotional face, red eye, sharp features, white beard. Monstrous creature, octopus, humanoid, highly detailed, glowing eyes. Fantasy kingdom, mystical forest, trees, colorful, intricate, elegant, highly detailed. cinematic lighting, gold rim lights, cinematic lens flares, epic composition, dramatic shadows, epic atmosphere",
	"Landscape of a Fall, award winning Gaborone, cinematic lighting, 6 wheeled vehicle, colorful leaves, crisp autumn, trending on social media. October, HDR, cinematic depth, artistic photography, trending on Instagram, art by Stanley Artgerm Lau, Wlopi Kgotla, Makuta mountain range.",
	"a chubby male khajit, bokoh blurred background, sunset orange color, colorful, intricate, artisan, detailed, painted on, glowing eyes, fairy-tale scene, highly detailed, beautiful, golden details, high contrast.",
	"horrid rainbow mountains, glowing clouds, toxic gas, twisted rock formations, colorful volcanic eruptions, blazing orange, yellow, blue, red, purple, pink, glittering shimmer, bioluminescent plants, glowing bright, green leaves, artemisia, sassafras trees, barren landscape, eerie, hyperrealistic, conceptual, cinematic landscape, painted by jordan grimm",
	"A professional dystopian style, Brussels, highly detailed interior, angled viewpoint, cinematic. Moon in the night, round glowing shape, golden light. Somber, post-apocalyptic, bleak atmosphere. corporate building, dark, glassy, glowing blue accents.",
	"3D Rendering, rotund Nigerian, man, action pose, highly detailed, intricate, smooth skin, soft lighting, warm, golden tones, ArtStation. Highly emotional, art by ArtStation and Pixabay, dramatic, cinematic, trending on ArtStation",
	"a testament to resilience and the triumph of the human spirit, intricate, elegant, highly detailed, digital footprints, artstation, concept art, smooth, sharp edges, ambient lighting, pastel, cinematic, art by artgerm, love",
	"A concept art, realistic style. Beautifully painted. Fancy. Made from zinc. Sharp lines and edges. Scratches. Huge pots and pans, steaming food. Astropunk, retro future, dramatic colors, fantasy, intricate, elegant, highly detailed, sci-fi, Art Deco.",
	"infrared photography, high shutter speed, sharp focus, 80mm lens, canon 5d mark 4, art deco, luxurious, glowing hot colors.",
	"fish, detailed, realistic, neon lights, artstation quality, photorealistic, award winning, 80 mm, cinematic concept art",
	"A detailed shooting star, glowing white lights, smooth edges, 3d effects. Ornate, glittering gold accents, highly detailed texture, intricate pattern, concept art by Takeshito Yuji",
	"concept art, realistic, intricate, elegant, highly detailed, digital painting, artstation, smooth, sharp focus, illustration, Art by Guweiz and Wlop and Rossdraws and Tom Bagshaw and Daniel Reid and Peter Mohrbacher",
	"movie concept art, cinematic view, dynamic, elegant, highly detailed, dramatic contrast lighting, high-impact shadows, smooth gradients, very detailed, very sharp focus, trending on artstation, cinematic 3d render, by H. Matsutomo",
	"A heroic figure, movie still, high detail, realistic shaded lighting, by Greg Rutkowski, Artgerm, Brom, Ilya Kuvshinov, trending on artstation, craig mullins style, 8k resolution",
	"caricature art of a hippopotamus, intricate details, highly detailed, sharp focus, digital painting, trending on artstation, highly detailed, masterpiece, vibrant, colorful, oil on canvas",
	"Bauhaus-style poster, sleek, dark hue, cinematic focus, bokeh, highly detailed, clean lines. Inside a chemical plant, shadowy, hyperdetailed, minimalist shapes. Pixelated grid, bright primary colors.",
	"miniature diorama of a landscape of a laos, at midday. 40 mm scale, realistic, intricate details, tropical vegetation, bamboo, palm trees, lush greenery. river, clear water, colorful fish, fine details. bright sunlight, blue sky, highly detailed sun position, rays, realistic lighting.",
	"A silhouette photo of (Maastricht:1.2), striking, flower field, bathed in shadows, side lit, long exposure, cinematic, dramatic scene, high contrast, backlighting, art by Fomapan 400, selective focus, absurd, elegant, mysterious, creative, exotic, smooth, intricate, trending on Artstation.",
	"Photograph, highly focused, woman wearing red scarf. Poker face, intricate, elegant, highly detailed. Artistic, matte painting, digital art by Gustave Courbet and Jacques-Louis David, WLO. Art Nouveau, intricate, gold, blue tones. Cards, cards, cards, close up, highly detailed, digital art by Charles Bellamy and Roy Lichtenstein. High shutter speed, 800mm lens, Fujifilm XT3. Cinematic, dramatic lighting, black background",
	"highly detailed, photorealistic, art by greg rutkowski, 8k, masterpiece, high contrast, black and white, rule of thirds, rich details, intricate, elegant, hdri, 8k, scenery, swiss, landscape",
	"professional silhouette, dramatic lighting, detailed shadows, shallow depth focus, moody atmosphere, cinematic composition. temple of literature, dramatic background, old architecture, shattered, realistic, elegant, hd, perfect details, smooth, sharp, crisp, art by jordan grimmer and artgerm",
	"chic, elegant, centered, dramatic lighting, 270mm depth of field. Beautiful, realistic face, smile, detailed clothing, artifact, cinematic mood, art by Artgerm and Greg Rutkowski and Alphonse Mucha, cinnamon buns in hair",
	"caring face, golden light, gentle eyes, realistic shaded skin, expressive face, dynamic lighting, hd, trending, artwork, by ilya kuvshinov and artgerm",
	"powerful artistic vision of expressionism, Expressionist artist, hand holding palette knife, dynamic brushstrokes, fine details, trending on artstation, illustration, hd, 4k, Artgerm, RPG",
	"( masterpiece , highly detailed : 1.3 ) , portrait of a Tommy Lee Jones , ( face : 1.2 ), floating hair, wind, cinematic light, shimmering sunlight",
	"A buff male, bald hair styled as ballerina bun. Photographing subject in bokeh effect. Fujifilm XT3 camera. Depth of field, highly detailed, 270mm lens, art by David G. The subject, blurred background, intricate, soft, trending, artistic.",
	"A detailed exoplanet, intricate, artistic, cinematic, trending on ArtStation, beautiful, detailed, smooth, 3D, digital art, painted by L USM, CineColor",
	"white dwarf, 800 mm lens, glowing ember, long exposure, highly detailed, intricate, dense atmosphere, atmospheric haze, photographed, shimmering white light, red, orange, purple tones, fine details, trending on artstation, dramatic lighting, masterpiece",
	"A miserable Jordan Ladd, feeling grateful, twirl pose. messy bun hair, delicate umbrella, bokeh lighting. dramatic rain, cold colors, gloomy weather.",
	"Artwork in the style of Andy Warhol, emo figurine, monochrome, swirling yellow, pop art.",
	"art style: trending, epic, realistic, sharp, details, masterpiece",
	"Landscape of a Quaint Media Room. At dusk. Gloomy. Auroracore. Horror. Darkened room, dramatic lighting, cinematic mood. Artistic, realistic, trending on artstation, realistic style. 8k resolution, smooth shaded, HD detail, ultra wide angle. Dusty, volumetric, glowing violet lighting, in the middle,",
	"( (Mata-Utu:1.1), Female figure, high-paced action, intricate, elegant, highly detailed, digital print, trending, fashionable, smooth, soft fabrics, Art by Stanley Artgerm Lau, WLOP, Rossdraws, Edmunds. Intricately beautiful, trendy, colorful, dazzling flowers, bright colors, vivid, detailed, perfect details, artwork, dramatic lighting. In the background, cinematic, dynamic, volumetric lighting, soft haze, digital effects, realistic, smooth, artistic, volumetric lighting, Artstation, WLOP, HD )",
	"Steampunk-inspired, fairy kei, turkey, at monkery, high detail, intricate, Victorian, elegant, 3d, gold, brass, gears, artifacts, ornate, elaborate. fantasy, art by wlop, epic, magical, sharp focus, illustration, trending on artstation, concept art, smooth, highlight, soft shadow.",
	"Hologram of a binary star, red and blue orbs, intricate, highly detailed. Rainbow light showers, ribbon shape, glowing, colorful, cinematic lighting, sci-fi. Highly emotional and epic fantasy. Beautiful fantasy concept art. Alhambresque, elegant, artistic, hyper realistic, art by Stanley Artgerm Lau, WLOP, RossdrawsJames.",
	"And pink neon hue, male draenei, dressed in robes, long eyes, slit pupils, intricate, detailed, highly detailed, painted, robe, sharp focus, painting by hand, brushstrokes. By the side of the road, trees, nighttime, forest, by tree trunks, hdqr golds, vvhg, green and pink leaves, soft glowing embers",
	"Illustration by Artgerm, beautiful, Masterpiece, Peaceful, Geometric style, L USM, Comics Cover, Superhero. Vivid, Colorful, Concept art, Zendo, Meditation, Radiant colors, 8-panel grid, Intricate, elegant, highly detailed, artstation, gouache and watercolor and digital painting, concept art, smooth transition between panels, cinematic, dynamic pose, highly detailed, perfect composition",
	"A Greek goddess, blue eyes, white dress, golden jewelry, dramatic pose, flowers, soft lighting, ancient temples, stone pillars, intricate artwork.",
	"Sticker of a 'I'm a slave for you, I cannot hold it, I cannot control it', blue hour, blue colour, atmospheric shaded, by artgraf, elegant, trending on artstation.",
	"charming Asteroid, small and round, blinding sunset light, bright orange and red, detailed, volcanic, fire, hot spots, glowing geological features. At Sunset, dramatic lighting, high contrast, sharp details, hues of orange and purple, intricate, highly detailed, 8k resolution, unreal, trending on artstation, epic, stunning composition, golden hour, award winning, masterpiece",
	"A Quake engine, intricate, elegant, highly detailed, digital renderings, artstation, concept art, smooth, matte grey and white. Art by Katrin Leska, Igor Kierylakh, WLOP, Stanley Artgerm Lau, Robert Valley. A Venetian Canis Major constellation, sharp focus, elegant lines, trending on ArtStation, HQ, masterpiece, hazy sky, background",
	"a mink, small, sleek body, beautiful fur, shiny black coat, pink stripes, sharp teeth, highly detailed eyes, meadow. flowers, colorful, blooming, white and blue flowers, animals, birds, bunnies, bright sun.",
	"intricate, elegant, highly detailed art, trending on artstation",
	"a plushy, black hole, intricate, artifact, \"Resurrection of Jesus\", highly detailed, yarn, anaglyph filter, art by Greg Rutkowski. in the void, soft lighting, dramatic, art by Stanislas De Guin and Moebius and Brolart and Wlop and Beeple.",
	"A elegant, gothic, 3d-art, realistic, very detailed, intricate, hyper realistic, smooth, sharp focus, highly ornamental, art nouveau, gothic decor, highly decorated, beautiful, gothic, beautiful, arouses, soft glowing lights. The Wellspring of Macabre, ancient, 3d modeled, dark and ominous, mysterious, mystical, ornate, detailed, glowing lights, golden decorations, red carpet, dramatic, epic lighting, dramatic, fantasy, dramatic, art by artgerm and greg rutkowski and alphonse mucha",
	"rear angle of a woman, woman's vibrant, vivid coloring, sharp focus, intricate, elegant, highly detailed, art by wlop, artstyle by artgerm, smooth skin, blue eyeshadow, gold hair, hd, 8k",
	"a chic anthropomorphic sphinx, dark, monochromatic, cinematic closeup, black-and-white, masterpiece, by greg rutkowski, 3d effect, dramatic lighting, trending on artstation",
	"A cozy Squirtle, blurry, smooth, furred, cinematic close up. Depth of field, beautiful flowers, soft lighting, dramatic bokeh. Fancy, highly detailed, concept art, artstation, illustration, octane render",
	"Graphite, Of a \"Vortex of Good\". Art by Rene Magritte. Scene: Selected, intricate, highly detailed, sharp focus, trending on ArtStation, cinematic lighting, vibrant, saturated, glowing, golden light. Trending art style: Masterpiece",
	"Charlotte Flair, cinematic lighting, Rule of thirds, WWE ring. Articulate face, articulated body, dynamic, cinematic pose. High contrast, warm lights, mid action. Shining, intricate, golden belt. Intricate, beautiful, shimmering, black hair, dramatic lighting, golden light. Rule of thirds, dramatic background, dark shades.",
	"art nouveau quasar, nerdy, cosy, at sunset, messy hair, twisted shapes, intricate details, highly detailed, smooth, sharp focus, cinematic lighting, golden hour",
	"A skinny Abdi, looking confused, dark shadows, sharp, detailed face. art by Parmo Ghosha and Marius Kinka and Tomek Baginski, double exposure, cinematic, high contrast, colorful, octane, intricate, elegant, highly detailed, digital painting, valkyrie, matte paint, 3d, sharp focus, illustration, art by Stanis\u0142aw Fiszer and Karol Bak and Micha\u0142 Dierka. Art by Makoto Shinkai and Studio Ghibli and Hayao Miyazaki, very detailed, emotional background lighting, very detailed, warm colors, dramatic shadows.",
	"concept art, model shoot style shot of a full-figured female siege engineer. nighttime, wide view, ultra realistic. Extremely detailed CG unity 8K wallpaper. Engineer, glowing eyes, intricate, elegant, highly detailed, digital painting, concept art smooth, sharp focus, illustration. night sky, cinematic, dramatic, octane render, volumetric lighting, 4k resolution, detailed, beautiful, cinematic composition",
	"A RAW photo, cinematic, subjective, trending on Pinterest, iPhone X, cinematography, dramatic, golden hour. Realistic, intricate, 50mm, luminous, cinematic light, highly detailed. Realism, art by Artgerm and Greg Rutkowski",
	"portrait of a glamourous venus, gold hair, shallow depth of field, cinematic lighting. close up, soft focus, volumetric lighting, elegant, intricate, highly detailed, digital painting, art by artgerm and greg rutkowski and alphonse mucha",
	"Cinematic still, close up photographic screenprint of a red square from inside a cienfuegos, snowy landscape, cinematic lighting, realistic shaded details, art by Mark Rothko, 1968, DSLR Canon 5D, dramatic composition, depth of field 270mm, octane white background.",
	"Anime, dramatic lighting, cinematic atmosphere, studio art, stunning detail, trending on artstation, beautiful by greg rutkowski and matthew ward",
	"Anime, peasant Alpha Centauri, selective focus, grim, moody lightning, realistic, unreal, artgerman, tatamibuki, puff-sleeves, cauliflower hair. Terrestrial planet background, 8K resolution, sharp, fine details, intricate, glowing sunset, cinematic depth.",
	"male office worker, decoupage, highly detailed, intricate, elegant, glossy paper, vivid colors, trending on pinterest. art by makoto shinkai, unrealistic perspective, sharp focus, 80's, retro style, pencil sharpening, stacking papers.",
	"A scratchboard, large and detailed, epic scene, intricate, highly detailed, digital painting, artstation, smooth, sharp focus, illustration, concept art, matte, white background, by Stanley Artgerm Lau and Wlop.",
	"Female Alien, shock, vogue pose, red lips, emotion, cinemascope, glowing light, transparent skin, black hair, purple eyes, high heel, intricate, elegant, volumetric lighting, sharp focus, holographic background",
	"Grass, bright sunlight, warm tones, golden light, 3D, realistic, volumetric shaded, soft blades. League of Legends splash art, art by artstation, 2021, trending on artstation",
	"Shiva, slender woman, expressive face, expressive eyes, expressive, highly detailed, artstation, digital painting, 800mm, intricate, elegant, highly detailed, photorealistic, 3D rendering, octane renderer",
	"Assemblage art of a Landscape of The Victoria Falls, from inside of a Castle. A castle interior, intricate, elegant, highly detailed, ornate, golden hour. Reeds, golden yellow color, trending on artstation, volumetric lighting, octane renderer, 4k. At Dusk, beautiful sunset, cinematic, vibrant colors, sharp focus, very detail, masterpiece, concept art",
	"female firebender, highly detailed, sharp focus, red hair, realistic, photorealism. inside a silo, dark, deep shadows, hard surfaces, real world, soft details, oil, intricate, dramatic light by artgrahp, fujifilm xT3, l usm",
	"photograph, low contrast, glowing light, intricate, elegant, highly detailed, digital camera. cozy male, muscular, bearded, wearing vintage clothing, oil lamp, old leather backpack. kalighat, dark, sharp, focused, very narrow, very long.",
	"Ecuadorian deep space, offset print, cinematic art by Stanley Laurie and Artgerm and Greg Rutkowski and Alfonso, cinematic lighting, dramatic contrast, sharp focus trending on Artstation",
	"cassiopeia constellation, made of toyota, intricate artwork, white plastic stars, highly detailed design, painted on hearthstone, soft, warm lighting, golden halo, 8-point pattern, intricate design, detailed, by todd kelley and studio, austrian, highly detailed, realistic, bright, clear sky, trending in art galleries, art by artgerm",
	"cyberpop, octopus, simple, clean, black, white, 3d, intricate, elegant, highly detailed, digital painting, artstation, smooth, sharp focus, illustration, pincushion.",
	"A \"Fang Dragon and Forktail Sweep\" cross-stitch, detailed intricate pattern, elegant, highly detailed, centered, digital painting, smooth, artstation concept art, octane render. Inside a parking garage, abandoned, broken vehicles, worn out textures, realistic lighting, cinematic atmosphere, soft light. Clear skies, warm color, wide view, perfect focus, masterpiece.",
	"pretty beautiful oak tree, highly detailed, intricate, elegant, highly detailed, artstic, concept art, smooth, sharp focus, autumn leaves, bright colors, vibrant, cinematic light. artwork by artist, tokyo wu, wlop, japanese, artgerm, greg rutkowski, behance",
	"A Masculine Galaxy Dragon, beautiful, intricate, highly detailed, soft lighting, glowing red eyes, strong, muscular body, white scales, black wings. At the citadel, vast, epic, elegant, perfect symmetry, glowing lights, art deco, gold accents, holograms.",
	"landscape of a new york city from inside of a caves raining dreamy trending on artstation, artwork by greg rutkowski",
	"cinematic still, intricate details. Cinemascope, highly detailed, elegant tiara, nighttime, smoking, high detail, concept art, cinematic pose, dramatic lighting, creative, octane, trending, dark, style of tenebrism",
	"A leaf, happy, joyful, colorless, intricate details. Depth of Field. 100 mm, dramatic focus, soft edges, cinematic effect, trending on artstation. Intricate details, in the style of Artgerm and Greg Rutkowski and Alphonse Mucha",
	"At twilight, detailed, beautiful, elegant, good-looking rococo, long dress, intricate pattern, pastel colors, flamboyant, elaborate detail, curved lines, good lighting, atmospheric lighting. Art by artisan, painter, beautiful, dramatic, portrait, studio, artistic brushstrokes, 8k resolution, realistic, sharp focus, trending on artstation",
	"A woman, pretty face, realistic features. , mid-twenty age, brown hair, pink lips, glowing face, attractive eyes. in a warm glow. lightbulbs. bulbs.",
	"dramatic Photograph, beautiful, DSLR, Canon RF, 80 mm lens, epic scene, cinematic lighting, dramatic composition. Trending on instagram, by artgazer, by Makoto, studio 3B",
	"Landscape. RAW photo, dainty, pretty details, artisanal pastries. BW, sharp focus, trending on instagram, austere elegance, masterpiece by Klimt by 2015, by David Loftus",
	"A photograph, detailed, beautiful, intricate, elegant, highly detailed, art by Makoto Hiroshi, artgraf, 1986, trending on artstation, art studio, 8k, digital art, sharp focus, art by Tooth Willy and Fang Woo",
	"3D Rendering, \"A Day in Beaumont\", Gorgeous, Good looking, Ambient light, Low contrast. Soft shaded, matte, artgerm, 8k resolution, smooth render. Narrow street, full bloom, flower petals, realistic, flowers. Realistic lighting.",
	"Guy, feudal lord, prominent eyebrows, casting magic pose, anime screencap, sharp focus, trending pixiv, matte brown robe. Unrealistic fantasy art by Akihiko and Hiroshi and studio, manga shaded coloring, golden hair, red blazing eye.",
	"Pretty shar pei, The ganges river, joyful, lighthearted, bright eyes, sharp focal length. Scene: Beautiful face, perfect face symmetry, bright eyes, soft fur, black ornate jewelry. Background: The Ganges River, intricate artwork, flower in hand, beautiful soft lighting, highly detailed and colorful environment, art by artgerm and alphonse mucha",
	"concept art of a sublime female, black hair styled as shaggy, realistic, trending on artstation, realistic holograms, glow-in-dark lighting, hd quality, masterpiece, concept art by stanley lau and ross tran, artgerm, concept hq",
	"A Photograph, detailed and intricate, high contrast, highly detailed, digital art, beautiful, 8k. Artistic concept, sharp focus, soft edges, soft background, by Artgerm, WLOP. Hidden in the woods, magical atmosphere, vibrant colors, detailed paintings, colorful books",
	"A breathtaking Abyssinian cat, highly detailed fur, shiny black stripes, cyan eyes, hyperrealism, sharp focus, artistic composition, intricate, elegant, highly detailed, art by Louie Mei Feng and Greg Tocci and Pawel Kadziolka. Artistic painting of a cat, golden light, trending on ArtStation, award-winning, 1080p",
	"Cinematic lighting, cold tone, smooth, sharp focus. Illuminated hieroglyphs, symmetrical, elegant, art deco style, inspired by Bauhaus",
	"abstract illusionist, long cloak, intricate, elegant, highly detailed, digital painting, art by artgerm and pixiv and alphonse mucha, cgsociety, uncanny hd, sharp focus. 8k",
	"Anime artwork illustrating Guy, highly emotional, best quality, highly detailed by Japanese anime studio, cinematic composition, intricate, highly detailed, sharp focus, glowing aura. Japanese artwork, anime studio, dramatic lighting, trending on ArtStation",
	"Landscape of The Wall of the Night's Watch, traditional setting, cold mountains, night sky. depth of field, cinematic, beautiful, 270mm lens, cold colours, snowy landscape.",
	"Photograph, blonde hair, woman, kodak portra 160. feminine expression, colorful impasto, by ross tranor, danny devito, 80mm lens, cinematic depth. feminine portrait, studio lighting, masterpiece, rich colors, highly detailed, vibrant colors, art galleries",
	"baby elephant, wide view, white, wrinkled skin, large eyes, bright pink lips, floppy ears, very wide legs, intricate, delicate, intricate, beautiful, flower field, golden sun, shadow. Baby elephant, trunk up, taking a close-looking at a rosebud, highly detailed, petals, golden yellow, white center.",
	"Anime, sphynx cat, fanged smile, fantasy, bright eyes, sharp focus, intricate details, highly detailed, digital painting, art by artgerm and greg rutkowski and alphonse mucha.",
	"Masterpiece, realistic portrait of a Oriental Shorthair cat, medium shot, expressive face, blue colored collar. Wearing detailed, intricate, elegant, fantasy, rpg concept art, trending on artstation, highly detailed, glowing iridescent eyes, dramatic lighting, side-lit, stylized, concept art",
	"a retro arcade style, Misshapen Woman, detailed illustration. Cigarette, hanging loose, cigarette paper white, perfect tranquil scene. Vibrant pixelated atmosphere, 8-bit, realistic, elegant, smooth edges, 80mm. Art by artgerm and greg rutkowski and alphonse mucha and william-adolphe bouguereau",
	"A beautiful tanzanian woman, happy, dyed hair, cinematic lighting, vibrant patterned background. Intricate, very detailed, by Greg Manchess, WLOP, 8k resolution. Artstation, smooth, sharp focus, trending in the art of Batik Style, 16:9 ratio",
	"A cinematic photograph of Woman, cute. Pocket square, deep orange, psychedelic hue. Dynamic, highly detailed, ultra defined, realistic face, 8K, dynamic lighting. Ornate, intricate, elegant, smooth, sharp focus, digital art, Grunge, HDR, illustration, Kodak Tri-X 400, art by Trevor Brown, 800mm lens",
	"a Concept art, fine details, highly detailed, intricate, sharp focus, octane colors, cinematic lighting, pancake trees, fluffy, soft, snow. Landscape, dramatic contrasts, high altitude, low atmosphere, trending on ArtStation, funny, whimsical, fantasy elements, beautiful, perfect painting, award-winning art",
	"A male, glow in the dark, highly detailed face, glowing white eyes, auburn hair styled as shaved sides. wearing beige headband, flower crown on head. Horror, rainy thunderstorm background, large raindrops, dark sky, mist and fog, cinematic, high detail, sharp focus, sharp details, very detailed, trending artstation, concept art",
	"inside of a oil rig, interior, engine room, warm colors, rust, oil barrels, detailed machinery, highly detailed, dramatic contrast. landscape, foggy conditions, dramatic lighting, low angle, sharp focus, high contrast, cinematic",
	"Asymmetrical male, high detailed face, intricate, elegant, highly detailed, painted art, red hair, asymmetrical eyes. Intricately decorated chamber, highly detailed, sharp focus, lighting, trending on pinterest, wanderlust, gold ornaments, symmetrical artwork, fantasy, digital painting, 8k resolution, concept art, illustration by artgerm",
	"a Photograph, realistic, artistic still life of a Corsican Male, kindhearted, wearing Polo shirt, ultra detailed, cold lighting, HDR, Fujichrome Provia 100F, 80m, highly detailed, arcane, cold, deep pink details. Arcane symbols, intricate, elegant, glowing, detailed, highly textured, octagonal, art by Makoto Shinkai, Iris, ultra realistic, hyper detail, 3D Rendering, highly detailed, masterpiece, art studio",
	"A man, dark, muscular, cape, twisted facial features, sharp eyes, detailed art by artgerm and greg rutkowski, macro, concept art, intricate, dark fantasy, high detail",
	"Anime, creepy female, maiden, long hair, rainbow swirl tabard, detailed, digital painting, glowing lights, 8k, sharp focus, illustration, highly detailed, artstation",
	"male, muscular torso, hyperdetailed, sharp definition, blinding electric colors, neon blue, hyperrealistic, soft focus, frayed denim shorts, high contrast, cinematic lighting, graphic t-shirt, photorealism, trending on artstation",
	"A unconventional traditional Lovecraftian female, standing, cyberpunk grungepunk, box braids, purple clothes, sharp cheekbones. graffiti print, portrait, trending, art by greg rutkowski and alphonse mucha.",
	"isometric style, attractive girl, sharp focus, intricate detail, symmetrical patterns, beautiful features, vivid color palette, cinematic quality, trending on artstation, masterpiece, extremely detailed, by stanley lau, wlop, ilya kuvshinov, 4k, 8k",
	"A lanky futuristic girl. Middle shot. Sling bikini. Tailored by Sabyasachi Mukherjee. Highlighted by intricate golden threading. Hydro-tinted hair tie, violet shading. Fantasy world. High-tech environment. Wide view. Artificial sunset glow, trendy neon accents, futuristic landscape, sharp focus, digital flowers, background.",
	"Photograph, western punk, papuan female, hurricane, relaxed, kodak ektachrome e100. feminine, intricate, elegant, highly detailed, digital painting, art by artgerm and greg rutkowski and alphonse mucha, 4k",
	"portrait of stormtrooper, dramatic lighting, masterpiece. 4k, digital art.",
	"A beefy male, cartographer. Manly face, long moustache, glasses, high resolution, sharp focus, intricate map, trending on artstation, holographic lines",
	"Female, Shieldmaiden, intricate, gold armor, highly detailed, 3d, digital illustration, concept art, single color, golden color. Wallpaper, smooth, background, sharp focus.",
	"a felt man, burly, cinecolor, intricate details, sharp features, art by artgerm, in the rain, very detailed, 4d, glowing, cinematic lighting, vivid, octane color palette",
	"A celestial woman salsa dancer, walking away, baroque ornaments, deep orange track pants. Celbrating, intricate, elegant, golden, twinkling stars, dramatic, highly detailed, digital art.",
	"a 3D rendering of an ultra detailed 1980's chubby female playing video games. girl with intricate details and fine details. detailed face, highly detailed body, elegant, attractive, by Gaming Chair, RGB lights, Pixel design, high resolution, hd, sharp focus, illustration art by Artgraf",
	"Gritty Girl, female, afrofuturism, 20s, midriff baring outfit, intricate, highly detailed, glowing, sharp focus eyes. Aries constellation, background, glowing, red and white stars, high contrast cinematic lighting, cinemascope, wide shot.",
	"Female, raincoat, blue jeans, black boots, white t-shirt, feminine features, red lipstick, umbrella, glamorous, red scarf, high heels. Focus, face, head up, in the storm, lightning, thunderstorm, oil painting, colorful, vibrant, bright colors.",
	"Needlepoint, man, glamorous, high detail, master craftsman, intricate design, white hair, trending on pinterest, artisan. Overcast, good-looking sun hat, golden brim, perfect shadow, 50mm, ultra sharp focus. Nuclear power plant, charming, exquisite, glowing light, detailed, complex machinery, cinematic, volumetric, octane render.",
	"Goblin tapestry technician of Guy. 3D render. Masculine, cinematic lighting. Highly detailed face. Soft focus. Background: intricate, elegant, highly detailed, art by Artgerm and Greg Rutkowski and Albert Dros and Edgar Drake,",
	"Lowborn pirate female, girl, intricate, elegant, highly detailed, painted face, smooth, hardy, white hair. Depth of field, 100 mm, cinematic, volumetric lighting, golden rays, colorful, vivid, trending on artstation, ocean blue, black, silver and gold, jigsaw, intricate, beautiful, treasure map",
	"animate, highly detailed, muscular, masculine, cinematic, high contrast, artwork, by ursuk dakk, stanislav, rich colors, soft lighting, hd, dramatic pose, 2d, sharp focus, cinematic lighting, trending on pixiv, cinematic, artstation.",
	"Portrait, intricate details, Indonesian man. Wearing bowler hat. Depth of field 100 mm. Kinemacolor. Elaborate, ornate, close-up. highly detailed face, artstation, trending pixiv, japanese, oil on canvas, high contrast, cinematic lighting, art by Togito, backdrop",
	"a man (Male:1.3), crown braid hair, abstract expressionist art, expressing joy, highly detailed, artistic, by Lara Fabian, pixilated, in focus, cityscape, 8k, sharp, high contrast, minimalistic, colorful, trending on Artstation, featured, Artgerm and Gustave Croulebarre,",
	"plump croatian female, beautiful face, exquisite eyes, detailed eye makeup, intricate, highly detailed, by luminous light. resting, elegant pose, 45 degree angle, art by greg rutkowski, zen garden, stunning colors, vibrant flowers",
	"Portrait, highly expressive guy, intricate details. Art by Artgerm and Greg Rutkowski and alphonse mucha style, trending on artstation, concept art, sharp focus,",
	"Model, exotic, female, cosy, monochromatic, electric green filter. 50s boudoir pose. Asian fan, silk kimono, rosy lips, gothic jewelry, high heels. Art deco studio, ornate, gold accents.",
	"Female, Sharp, Womanly, Hands Up Pose, Beautiful, dramatic lighting, 8k, soft dress, trendy, fashionable, realistic, volumetric, photorealistic, bright, beautiful, high details.",
	"Headshot of, a St. Patrick's Cathedral full of Females, fairy tale, professional, focused, clear, neutral background, Dreamy",
	"A psychotic traditional viking woman. Draped, fox fur coat, purple eye makeup, purple headpiece, long braided hair. At sunrise. Sunlight, golden and orange light, golden, red, green, blue, trending colours. thirstcore, eclectic art, bright, colorful patterns, mismatch, flowers, by japanese cherry blossoms.",
	"dressed in maxi dress and wedges, ultra detailed realistic, glamourous natural skin, manly samurai guy. intricate artisan pattern, trendy by greg rutkowski, shirtsleeve, sleeves rolled up, cinthol oil, subtle, sweet fragrance. feeling melancholic, dramatic forest setting, autumn trees, soft mist, raindrop.",
	"Portrait, Ming Dynasty Guy, bronzepunk, shallow depth of field. High-contrast lighting, face, sharp focus, cinematic, glowing, detailed intricate, highly detailed, golden, headpiece, traditional, Asian, fantasy, 1500s, dramatic, epic, by James Jean, WLOP, by Greg Rutkowski and Michael Gaydos.",
	"Athletic Guy, Enchanting, Chaplain, Remodernism, Manly, baroque, ecclesiastical, intricate, elegant, highly detailed, digital painting, smooth, sharp focus, illustration, art by Artgerm and Greg Rutkowski.",
	"Shot from side of a Spellbinding Filipino Guy, electric pink hair. Pipe. Asian background. Raining. Candid moment. Unposed. Natural. Spontaneous. Real-life situation.",
	"Male, burly, strolling pose, intricate, elegant, highly detailed, digital watch, artgraf, black, sharp focus, trending, street, sunny afternoon, red and blue umbrella, vibrant colors, hats, cafes.",
	"Product photo of a woman, wearing colorful clothes, holding an iPhone X, expressive face, colorful background. Field background, golden hour, lighting, landscape, flowers, blue sky, sunny.",
	"cinematic still, expressive man, body shot, emotional, highly detailed, dramatic, shaded, smooth, sharp focus, cinemascope ratio, 35mm film grain",
	"a portrait of a tall ( Guy : 1. 1 ), walking down a street , rule of thirds, superflat, blue hair, black pants, yellow jacket, white shoes, red umbrella, glowing sun, yellow streetlight, blue reflections, by Ruan Jia and Katsuhiro Otomo",
	"a meiji era guy, honorable, pottery, knee level, dramatic lighting, harsh, sharp focus, black and silver round glasses, cinematic, concept art, intricate, by greg rutkowski, b&w, highly detailed, cinematic atmosphere, octane render.",
	"Digital Art, Furious Male, Furry Anatomy, dark color palette, dramatic lighting, hyper realism, digital painting, brush strokes, cinematic lighting, trending on artstation by alpharat",
	"cute Woman, lovable face, curly hair, highly detailed art nouveau dress, intricate patterns, elegant, art by artgerm and greg rutkowski and alphonse mucha. art Nouveau, soft focus, high detail, smooth, sharp focussed 80mm, trending on artstation",
	"a highly detailed 3d rendering. Girl protagonist. dressed up as A2, from Nier character. intricate, smooth, glowing skin, highly detailed facial expression, sharp eyes, 8k resolution, photorealistic, hyper realistic. detailed face, detailed dress. ultra detailed bunny ears. colour scheme. deep details. very detailed. cosy atmosphere. trending artstation. style of stanley paran and rafael Grima",
	"artwork, glowing screen, by greg rutkowski, wlop, trending on artstation, 8k, masterpiece, beautiful, ultra detailed",
	"A Female:1.1, wearing bee-style suit, narrow traditional hipster weasel tail, cinematic, dark, dramatic lighting. Art, realistic, high detailed, high contrast, art by Tooth Watson and Ross Dollman and James Jean and Stanley Artgerm Lau and Greg Rutkowski",
	"artwork: fine details, highly detailed, intricate, elegant, complex, sharp focus, art by wlaf and beppe guitolla and juan gonzalez torres.",
	"muscular bosnian female empress, regal, intricate, detailed, elegant, highly detailed, ornate, golden tones, art by john flammer and greg rutkowski and thomas kinkade and alphonse mucha and stanley lau, mosaic background",
	"scene: samurai woman, subject in the middle",
	"upper body shot of an attractive senegalese man, snowy landscape, elegant intricate traditional clothing, highly detailed art, artwork by stanley artgerm lau, greg rutkowski, ross tran, james jean, jeremy lipkin and mike mitchell. trending on artstation, very detailed",
	"digital art, woman, low ponytail, high detail, realistic style, sharp focus, intricate, art by ilya kuvshinov, octane render. concept art, highly detailed, trending on artstation, deviantart, behance hd, digital art,",
	"three colors, cinemagraphic effect. Corporal girl, in trendy new wave art clothes. kawaii, glowing violet, electric blue. Dancing. looking at camera, smiley face, neon pink, high visibility. High energy background, mid-century modern lighting. Bright yellow, lightning, 80s retro effect, volumetric lights, smooth gradient, by art, highly detailed, illustration.",
	"Unique man, in focus, dramatic pose, single color, black and white lighting, intricate, detailed, art by artgerm. Artistic background, portrait, studio setting, elegant, highly detailed, digital painting, smooth, trending on artstation, hd, artstation",
	"Kingcore, handsome, good-looking man, intricate, sudanes art, elegant, black beret, colorful tunic, golden embroidery, cinematic, sunny, desert backdrop.",
	"Concept art, thick woman, intricate, elegant, highly detailed, dramatic light, by WLOP, artgerm, Rossdraws, James Jean, Guillermo Tocino. Artstation, Studio Ghibli, Pen & Trends",
	"Apocalyptic Female, soft focus, feminine, realistic detailed face, intricate, elegant, highly detailed, digital painting, art by Artgerm and Greg Rutkowski and Alphonse Mucha, octane render",
	"pirate girl, grumpy first sergeant. grumpy expression, slender, muscular, worn outfits, bandana, short sword, black boots, sharp gaze, intricate, highly detailed pirate ship, art by greg rutkowski and ilya kuvshinov, trending on artstation",
	"A space creature, \ud83d\ude12, anime style, illustration by Masamune Shirow and Makoto Shinkai and Osamu Tezuka and Takeshi Obata, glowing eyes and hands, dramatic pose and lighting, art by Akihiko Yoshida and Yuushi Miyawaki, intricate details, masterpiece of art.",
	"girl, white dress, standing, front view, in the middle of highly detailed color fields. intricate, elegant, highly saturated colors, smooth, sharp focus. painting, art studio, trending on art, flat composition, art by greg rutkowski and cindy squint, abstract, geometric",
	"Fairy tale, a Nucleus Silo, dark forest, gloomy mist, intricate, highly detailed, ornate, golden accents, digital readouts. Males in uniform, sharp focus, hand over shoulder, looking at golden hour, beautiful earbuds, glowing gemstone stones, Fujifilm Superia camera.",
	"Female, very playful, eating ice, smile, eyes close, tongue out, mouth, detailed, colorless, realistic, fine lines, structure. Focused on high detail art, digital painting, softness, smoothness, by Stanley Artgerm Lau, wlop, wlop, sound of ice and water, beautiful face, high detail, sharp focus, trending in Fujicolor artstation, by G. Hiduki.",
	"A female, long shot, black leather strap dress, elegant, white lace high heels, dramatic, highly detailed face, soft, intricate features, glamorous, twilight. woman, back view, beautiful, detailed hair, very beautiful and fashionable, portrait, elegant background, very nice, colorful, digital art.",
	"artstation, realistic, soft focus, sharp detail, trending, cinematic light, moody, volumetric lighting, cgi, artwork by Greg Rutkowski, cinematic, epic, dramatic, arthouse, masterpiece",
	"concept art, detailed face, painted by Greg Hildebrandt, highly detailed, 1990s woman, trendy fashion, glamour, trending on pinterest, red fur hat, ski helmet, reflective goggles. Depth of Field, sharp focus, cinematic, 270mm, beautiful lighting, landscape, mountain peaks, misty, frostbite.",
	"woman wearing armor, voluptuous, from playhouse, indoors. Girl. daring pose, elegant, intricate, golden, glowing gem accents, highly detailed, digital painting. art by Artgerm and Greg Rutkowski and alphonse mucha. 80mm",
	"photograph, radiant hefty male, cold lighting, dramatic shadow, detailed face, 50s camera, black, white film, byron, rufus sassafield, 4k, intricate details, highly detailed, art by james francis edwards",
	"cinematic still of a magnificent guy from the feudal era wearing bodycon dress with calico accessories, in focus, pretty, white and silver detailed eyes, intricate, elegant, highly detailed. foxglove buds, brown, 3d printed, artisan, trending at artisan market. dramatic lighting, golden hour, cinematic composition, matte painting, masterpiece",
	"Female science officer, braided pigtails hairstyle, white lab coat, scientific instruments. At the Parthenon, high contrast lighting, strong focus on artifacts, 3d rendered, intricate, elegant, highly detailed, art by Artgerm and Greg Rutkowski, b&w, holographic images, holographic data readings.",
	"Cute guy. Holding lenses. L series. 50 mm. Close up, intricate, detailed, elegant, focused, smooth bokeh, beautiful details, perfect composition, cinematic, sharp focus, trendy, art by Makoto Takahashi",
	"A obese man, standing, hands on hip, cinematic lighting, dramatic pose. Wearing a dirty jellaba, intricate, golden star wars pattern. Art style is gritty, smooth shaded, elegant, sharp edges, by greg rutkowski. Background: epic fantasy setting, mystical forest, trees, sunbeams and shadow, dramatic landscape.",
	"A muscular, handsome athlete, wearing fitted capri pants. cinematic, soft focus. high contrast, golden hour. art studio. soft light. trending on artstation. Medieval, ornate, soft brushstrokes. golden decorations, realistic intricate details. by Albert Diaz, Sergio Valinsky, Artgerm",
	"magnificent man, his hair light, smiling, intricate, elegant, highly detailed, art by Marko Djurdjevic. Smoky conditions, cinematic lighting, dramatic contrasts, art by Greg Rutkowski and Mike Mignola. Highly detailed, smooth focus, cinematic, trending on ArtStation, 8k, unreal engine",
	"Concept Art, trending on artstation, sharp focus, illustration, cinematic view, depth of field 100mm. Trending artwork, realistic, cinematic lighting, intricate, by Stanley Artgerm Lau, WGSN, hdri, HDR, dramatic shadow tones. Man, concept art, cinematic, masterpiece, perfect detail.",
	"cinematic still of a bavarian male, sharp focus, high definition, concept art, burly figure, intricate, elegant, highly detailed, jewelries, gold accents, d&d, 8k, artistic, highly coherent, emotional, movie still.",
	"Grisaille of a girl, biochemical ocean, glowing blue neon lights, highly detailed shaded pattern, digital lighting. Womanly, beautiful face, sharp details. Inside a biochemical ocean, intricate biomechanical environment, hyperrealistic design, soft, bioluminescent lighting.",
	"a high-class female, kawaii, style, bright eyes, cute, colorful, red hair, evil intentions. intricate, highly detailed, white dress, manga, smile, cherry blossoms, sunny skies, anime influence",
	"exquisite man, wooden armor, soft cloth, sharp contrast, by gears and stones, movie theater, intricate, highly detailed, golden lights, cinema screen, dramatic lighting, cinematic, matte painting, muted colors, warm light.",
	"thunderstorm, dynamic lighting, dramatic lighting, beautiful rain, soft mist, contrast between dark clouds and lightning",
	"contour rivalry drawing of a warlike male, white hair, dynamic pose, dramatic lighting, cinematic composition, sharp focus, masterful hands. art by Hidetsugu Yoshimoto and Rui Hojo. trending on artstation.",
	"A beautiful digital art, well-endowed guy. His hair is ice, hyper detailed, realistic shading. horror scene, blurry depth of field, intricate, elegant, highly detailed. Macabre, photorealistic, smooth, sharp focus, by Raphael Lacoste, Leslie, Ilsa, artgerm, Jinsun Kwon, and Alphonse Mucha, creepy, glamorous, eerie",
	"A girl of rot, slender figure, feminine, wilting dress, blooming flowers, crown of blackthorns. Moody lighting, highly detailed, dramatic shadows, sharp edges, cinematic contrast.",
	"fashion editorial of a male, mountain ridge, high contrast light, bright sunlight, midriff, futuristic clothes, colorful, reflective patterns, glaring sun, ultra realistic, trendy details, intricate, detailed, cinematic, depth of field, 100mm, dramatic lighting, masterpiece.",
	"stencil art of cyberpunk han dynasty guy, cinematic pose, intricate details, trending in street art style. Inside a pagoda. Sharp edges. Shadowy lighting, highly detailed, cinematic focus, bold graphic design, vibrant, sharp, contrasted elements. Sunny sky, intricate, beautiful, golden, art by greg rutkowski, luminous colors, synthwave, trending on twitter, japanese temples",
	"A 3D Rendering, 50mm, perfect symmetry, western woman, high detail, masterpiece. Expressive face, 50 years old, fine lines, expressive eyes, realistic, trending in ArtStation. Posture: stretch pose, side view, long hair, fine details, high definition. Famous by artstation, cinematic lighting, beautiful, 8k render, golden hour, oil paint",
	"girl, elegant silhouette, fine features, sharp focus. intricate, detailed, artstation, concept art, smooth, soft light, matte, highly saturated, cinematic lighting. golden hour, volumetric fog, trending on Flickr, ultra high res",
	"highly detailed, 4k, beautiful details, realistic skin, trending artstation, epic composition, masterpiece by Tom Bagshaw",
	"female, attractive, beautiful, detailed face, soft features. Art station, art studio, natural light. Paint brush, paint palette, high detail, colorful palette.",
	"A female, snowing, sci-fi. highly detailed and realistic face, cyberpunk makeup, glowing eyes, black clothing, blue fur, white wings. intricate, frosty landscape, shreds, snowflake, ornate, red sunset.",
	"A girl, girlhood, dress, short and white, night. Girl, long hair, black as shadow, short and long strands, fringes, by the river. low contrast background, shades of grey, trees in fog, water, moonlight",
	"A tall Pakistani adventurer guy, gray hairstreaks, intricate, highly detailed, 40s, 200s, trending, pinstripe coat. smoking pose, cigarette in hand, dramatic lighting, elegant, golden, art deco background. Smoking, oil painting, smooth, cinematic, glowing coal tip.",
	"Guy, long flowry rainbow hair, cinematic lighting, film grain, stormy weather. beautiful art, oil painting, wet street, umbrella.",
	"woman, cinemascope, high angle shot, golden wheatfield, sunset, beautiful, long dress, hand held by man, intricate, highly detailed, sharp focus. cinemascope, epic, dramatic lighting, trending on artstation, cinematic still, concept art, masterpiece, award winning, cinematic",
	"Male, tranquil, zen, stormy weather. shallow depth of focus. round glasses, highly detailed, transparent lens, thick black frame.",
	"A RAW photo, beautiful male, close-up, realistic, fantasy, intricate, elegant, highly detailed, digital painting, artstation, glowing light, soft focus, matte, sharp focus, illustration, 4k, HDR, Spring",
	"girl. caricature, female, exaggerated, cartoon, colorful, intricate, sharp features, detailed eyes, bold lines, highly detailed, by art, greg rutkowski, tom khana, craig mullins, unreal engine 5, cinematic artwork. octane render, trending on artstation, artgerm, deviantart, humorous, artistic style, very funny",
	"Guy, bald hair, tousled style. Making photographs. Wide view, cinematic perspective. Kodak Ektar 100, rich colors, cinematic tone. Male subject.",
	"Slim Guy, crying, vintage clothing, psychedelic art, detailed intricate lines, surrealism. Dingy alleyway, painted in neon lights, 80s, sharp, bold contrast. Extremely hyper aesthetic. Retro colors.",
	"portrait, highly detailed, male, dramatic lighting, elegant, digital photography, intricate, by artggerl, golden hour, beautiful face, trending, stylish, 50mm",
	"x-ray of girl with dress and ribbons. At dusk, soft glow. Knightcore background, neon colors. Hands holding fujicolor film, 80 mm, octopus film holder, retro style. girl's body. Soft light, hp5, high contrast, cgs color, very detailed, colorful, cinematic.",
	"Guy, hopeless, L USM, depressing, male, night, scuff shoes, brown clothes, torn jeans, long shadow",
	"animate, slender norse man, intricate, highly detailed, trending on pinterest, art by greg rutkowski and james jean, digital painting, sharp focus, cinematic lighting, masterpiece",
	"A male character, very homely appearance, pretty clothes, cinch waist, muted colors, soft, sharp, hyper realistic, intricate, highly detailed, dots and paisley pattern, smooth, trending, style by J Crew, Starbucks coffee cup, perfect hair, art deco background, warm tone, sun rays, volumetric lighting, beautiful flowers.",
	"A well-built man, cinematic lighting, dramatic pose, intricate, epic, hyper detailed, highly detailed, sharp focus, illustration, comic book art. Trending on ArtStation, epic art by Ilya Kuvshinov, realistic shaded tones, trending on pinterest, ArtGerm and PinoGiallo",
	"A graffiti style, portrait, woman, doing puzzles, with short gray hair, wearing maxi dress, overalls, sitting, under the street light, art, wall, intricate, colorful, elegant, sharp focus.",
	"folk art depiction of, intricate, highly detailed, art craftsmanship, trending on artstation, side profile, ultra realistic, highly detailed face, detailed eye, perfectly symmetrical, ultra detailed, perfect symmetry, perfect proportion, dramatic light, beautiful shaded highlights, beautiful detailed skin, extremely hyper aesthetic, traditional design, voluptuous woman, highly detailed, hyper realistic, decorative, vibrant colors, richly embellished.",
	"male, intellectual, dramatic, pose, dark background, warm lights, portrait, studio lightbox, intricate, elegant, highly detailed, digital painting, art, masterpiece, art studio",
	"A most beautiful artwork in the world, painting, epic composition, masterpiece, oil on paper. Petite greek female, feminine, elegant, artstation, peter pan collar, smooth skin, 8K",
	"biomechanical, glamorous male, asleep, zipline, cypress background. cybernetic, golden exoskeleton, glowing circuits, art deco, neon lights, synthwave, futuristic city, octane colours",
	"Anime, Playwright Guy, beautiful, inside a Mason jar. Fall, ultrarealistic, muted colors, contest winner. Studio anime, symmetrical, highly detailed, by Greg Rutkowski and Artgerm and Tom Bagshaw, masculine.",
	"Rear angle of a woman, empty eyes, sci-fi, d&d, futuristic outfit, metallic, silver accents, intricate, highly detailed, digital pattern, volumetric effect. Scene: Futuristic laboratory. Intricate machinery. Shiny surfaces, holographic controls. Hints of glowing circuits, noisy whirring sounds, background: Rear angle of a woman, in the middle of a laboratory, staring at a huge screen, gloved hand, red light, holographic image of a skeleton, rolling dice.",
	"woman, detailed clothing, realistic face, painted by watercolor, cinematic lighting, intricate, elegant, highly detailed, digital painting, trending on artstation, smooth, sharp focus, hd, rgb.",
	"man, wearing kilts, golden collar, epic fantasy, highly detailed, intricate, elegant, byzantium jewelry, tinkercore, gears, gems, glowing runes, artifact, forest, clearing, sun rays",
	"a girl sitting with crossed legs, face is a combination of Alessandra Ambrosio and Alex Morgan, flat papercut style, crimson and dark indigo hue, clean cuts, sharp edges. Idol, silhouette, minimalist, color block, female, modern, monochromatic",
	"clay model of a Masai man, evils grin, brown hair, primary colors. art studio, masterpiece, beautiful, sharp features, intricate, highly detailed. glowing evil eyes. vegetation, green, trees, leaves. background. stars, night sky, twinkling, bright, blue hue, beautiful lighting.",
	"man with red hair, detailed costume, intricate, in the style of Artgerm and Alena Aenami, dreamy, ethereal, in front of an otherworldly landscape.",
	"A brownie, humanoid character, female gender, fringe hair, red hue, ultra realistic, highly detailed, intricate, cinematic, epic, fantasy, very detailed face, digital painting, by Ilya Kuvshinov and Alphonse Mucha. Dreamy, dramatic atmosphere, fine details, photorealistic, highly detailed eyes, beautiful, stunning, 4k resolution, trending on artstation",
	"A magnificent slim woman, beautiful face, angelic lips, cute expression. Full body shot, intricate, shiny corset, at sunset. Monochromatic bronzefilter, lovely, detailed, gold and bronze accents. Beautiful woman, beautiful, trending on artstation, 8k",
	"a strong Arcadian man, idyllic, pastel colors, selective focus, intricate, whimsical, guy, intricately detailed, cinematic, 2D illustration. snowy, frost-covered trees, by T. Duncan. Art by William Blumhart and Greg Rutkowski.",
	"woman! cinematic scene, dramatic lighting, highly detailed, art by greg rutkowski, intricate, elegant, red lips, hyper realistic, smooth face, trending on artstation, portrait, cinematic concept art, by stanley artgerm lau, WLOP",
	"At dusk, evileyes guy, black hat, cape, detailed, high contrast lighting, dramatic shadows. At Dusk, misty landscape, trees, soft light, cinematic atmosphere, golden hour, dramatic clouds.",
	"A woman of a face made up of Bree Turner, Regina Hall and Mae West. Macrame, highly detailed, intricate pattern, art deco, ornate, soft focus. Ambient lighting, golden hour, 8k, warm tones.",
	"A dark, blue night, moonlight, white haze. Women\u2019s figure, wearing fine white blouse and pearlescent buttoned up blazer, detailed intricate pattern, trending pixie cut. Glowing moon and soft focus. Intricate lab setup, colorless, neutral tone, background, perfect lighting.",
	"woman surfer, expressive face, young age, hair in waves, neon colors, bikini, surfboard, glowing white eyes, expressions, detailed features, dramatic lighting. 70s style, volumous, big wave, highly detailed, bright, beautiful, by the beach, sunset, by moonlight",
	"temari of a plain muscular guy, battle pose, rainbow face painting, vivid, art by wlop and greg rutkowski and james jean and jeremy lipkin and frank frazetta, medium shot, intricate, highly detailed, dramatic lighting, 50mm, artstation, concept art, smooth, sharp focus",
	"good-looking romanian woman, one hand in pocket, ultra detailed, detailed illustration. lovely, elegant, fine detail, highly detailed features, cinematic, art by stanisaw, trending on artstation",
	"shot from behind of a Turkish female. arthouse, mosaic, concept art, intricate details. elegant, highly detailed, golden tones. artwork, digital painting, sharp focus, trending on artstation, deviantart, hd, ultra wide angle",
	"Shibori, Behance. Honorable Woman. Wide view. Instax. Textured fabric. Intricate patterns. Resist-dyeing technique. Indigo or vibrant colors. Organic and flowing designs. Japanese textile art. Cultural tradition. Tactile and visual interest. Vivid color palette.",
	"A 3D Rendering, femoe, cute, character, high detail, intricate, elegant, by Masamune Studio, digital art, smooth, sharp focus, 4K resolution.",
	"girl theologian, sitting, charming fangs, soft focus",
	"a seductive small male, high detailed, multicolor bangs styled as bangs. intricate background, wide view. synthpunk, elegant, gold, glowing accents, shiny black outfit, high heels. candles, soft light, flickering flames. fairy tale, high detail, realistic, sharp focus, illustration",
	"A girl with gray hair, glowing with water, by B&W movie still, high detail, dramatic cinematography, cinema poster, backlight, wet rainy forest, art deco details.",
	"cinematic still, man, black thobe, intricate, golden, black and white, high contrast, dramatic lighting. epic, movie still, epic scene, concept art, by greg rutkowski, 800mm lens, hyperrealism",
	"Digital art, male, fantasy, highly detailed, intricate, sharp focus, illustration, concept art, vivid colors, hyper detailed, art by artgerm and greg rutkowski and alphonse mucha. Male figure, smooth, sharp features, voluminous hair, bold lines, stunning composition. Cityscape, fantasy city, vibrant colors, stunning detail, art by artem demura and jin sachyi, masterpiece",
	"A male figure, elegant, highly detailed face, intricate, smooth skin, glowing undertones, digital art, hdri lighting, soft light, trending artstyle, in the style of 3D cinematic art, rendered in Houdini, concept art.",
	"A strong guy, masculine, intricate facial features, cinematic, light reflection, shart, glowing details. From inside the hazy conditions of the Horsquecreek bend. Remodernism. Industrial design. sharp focus. in the background.",
	"RAW photo, art focused, female, high detailed, masterpiece, beautiful face, bokeh, sharp focus, intricate details, art by art, golden hair, flower, high-quality, fantasy, realistic, soft light, beautiful, dramatic light, elegant, elegant, natural light, highly detailed, photorealism, photography, art by art, 50mm, high quality, by Peter Weyss and Wlodzislaw Jagielski",
	"3d rendering. male character. hope. mundane setting. emopunk, dark black, neon blue, purple. rain, oil on ground, green, hdri. trending art by wlop and artgerm and greg rutkowski",
	"made of felt fiber woman, sleeping pose, elegant airport, detailed, luxurious, cinematic lighting, cinematic composition, digital painting, highly detailed, photorealistic, sharp focus, cinematic atmosphere. Horror, intricate details, eerie, beautiful, unreal engine, art by Artgerm and Greg Rutkowski and Alphonse Mucha.",
	"A woman, intricate lines, soft curves, painted by hand. Running. Flesh, 3d perspective, elegant, smooth, highly detailed, trending on artstation. High contrast, lighting, cinematic effect. Fashionable clothing. Rotterdam",
	"Collagraph technique of a man, dressed up as Heihachi Mishima, very detailed and intricate. Ruby detailed eyes. Heihachi Meshima character. Kodak Ektar film tone. Manly, rugged face. Brush stroke accents.",
	"a Rugged Jamaican girl, standing, joyful, glowing cinematic light, Joy, high contrast shaded areas, trending art by Artgerm and Greg Rutkowski, yuan dynasty, Amphibian, intricate, ornate headgear, Golden eyes. Cinemascape, dramatic mountain view, sunset. background: highly detailed, trending on artstation, 80s sci-fi cyberspace",
	"A Woman, intricate detailed, long flowing robes, ultra detailed, ornate, oriental, soft lighting. highly detailed, digital art, elegant, digital painting, wlop, artstation, concept art, smooth, sharp focus. Art by ilya kuvshinov, Toshihiro Nakamura, Ingrid Sinclair, Fenton Demo, synthwave, electronic music, neon",
	"highly detailed art style, intricate, elegant, wlf hairstyle, art studio, black and white, masterpiece",
	"A traditional grunge man, hoodie and ripped jeans, torn sneaker, graffiti, intricate art, colorful, highly detailed, artistic, very grimy. Artful city, urban street, sharp focus, trending, vivid colors.",
	"an elderly woman camping, zen, purple round glasses, masterpiece, art by Artgerm, greg rutkowski, trending on pixiv and deviantart. Surrounded, lush greenery, golden sunset, sun, flowers, oil pastels, glistening, brush strokes.",
	"a digital sculpture of a Atomic Age woman, captivating pose, vivid colorful girl. Hillside, volumetric water effect. Focused expression, intricate, highly detailed, photorealistic, smooth, sharp focus, by art greg rutkowski and alan bk and ilya kuvshinov. cinematic lighting, soft glow.",
	"A faded poloroid photo, intricate, black and white, noble burlesque male demon hunter. Upper class, elegant, sharp, fine detailing, golden jewelry. Nobel, gold-tipped cane, 80mm, noble, masculine, daringly realistic pose, fine clothes, cinematic. Analog, warm atmosphere, by greg rutkowski, Flickr, art, trending on Flickr, 1960's style, intricate, highly detailed.",
	"preppy man, side lit. trendy hair, dusty rose, steel gray, mint green anklet, manly face. highly detailed, fashionable clothes. white dress shirt, perfect. khaki pants, creased, 3d. white sneakers, mid-top. trending colours.",
	"Guy, caveman, dusk, ambient lighting, cinematic focus, depth of field 270mm, man, high contrast, soft background, brush fur. Trending on fire, animal skin, sharp eyes, spear, furry loin cloth, dramatic lighting, hd, wilderness.",
	"cinematic still, woman (ugly) moustachioed, twisted body, intricate face, highly detailed, elegant, ornate, intricate, 3d, dramatic, lighting, contrast, high detail, masterpiece, trending on artstation, cinemascope, very detailed, golden ratio",
	"A highset man, ultra realistic, burly figure, dresses up, high-waisted shorts, intricate details, steampunk art fashion style.",
	"girl, red hair, long dress, pink flowers, intricate design, detailed, soft light, warm light, natural, autumnal leaves, sun rays, shaded by tree.",
	"A female figure, beautiful, dressed in neo rococo fashion, laying, eyes closed, elaborate decorative ornamentation, curved forms. At a pedestrian tunnel, arches, arches, naturalistic elements, gaudy, bright colors.",
	"Vegetation, black-haired woman. Highlighted in white, sharp focus. Glowing light, dramatic contrast. High details, brush strokes, pencil lines, thick strokes. Trending on artstation, HQP",
	"Amish guy, knee level view, sharp, focused, highly detailed intricate, artisan, by gregarious stallholder, 50mm, artstation, colorful, high contrast, unreal engine cinematic, art by greg rutkowski and ross tran",
	"Photograph, fantastical vicar. highly detailed face, intricate, elegant, glowing pink hair, artful, high contrast, dramatic lighting, shaded, cinematic, art by Mark Selby and Artgerm",
	"Portrait, female, girl with beige fox ears, intricate, elegant, highly detailed, digital painting, artstation, smooth, matte textures, glowing halo. Girl, face turned, head tilted, soft facial features, expressive eyes, beautiful details, symmetrical, 3D, realistic shading. Beige fox ears, realistic, sharp focus, illustration by Bexx and Rob, artgerm, beige, intricate, concept art",
	"A female, smiling, head tilted to the side, elegant pose, golden light, detailed Tribal tattoo on arms and legs, art by James Jean, intricate, highly detailed, illustration, concept art, smooth, sharp focus, trending in artstation, featured on behance.",
	"A woman, intricate, highly detailed, art by artgerm and studio ghibli, trending pinterest, illustration, artbook, beautiful, unrealistic smooth face. Artistic, hyperrealism, cinematic lighting, dramatic pose.",
	"portrait, hefty woman, 3d art. realistic face. sharp focus, intricate, elegant. highly detailed, digital painting, wavy hair, gold earring. art by greg rutkowski, loise boko and marc eloit andre, trending on artstation, cold lighting",
	"Anime, male, elegant monocle, zen, beautiful, smiling, intricate details, cinematic lighting, dramatic shading, masterpiece, realistic shaded background, highly detailed, art by WLOP and studio ghibli, cinematic atmosphere, epic, fantasy world",
	"A Photograph of a cinematic, hourglass figure Woman. Bokeh, artful, shallow depth, focus, smooth, sharp, cinematic art by artgerm and Tom Bagshaw and Greg Rutkowski",
	"Female, intuitive, deep pink fangs, Fujifilm Superia. scene: Beautiful woman with deep pink fangs, holding a Fujifilm camera. dramatic lighting, highly detailed portrait, realistic, 50s film photography, artstation, by Stanley Artgerm Lau, WGAT, Geoff Darrow, comic book style.",
	"Anime, Women, face is a combination of Anna Kournikova and Carole Bouquet. Algae, background, soft light, summer. Female character. High heels, long blonde hair, red bikini top, soft shapely lines, beautiful eyes, cute nose, glowing makeup, smile, fantasy, beautiful face, summer vibes. Women, pretty girl, by lake, perfect day, by water, by water lily, flowers, trending on artstation, by waterfall",
	"space, papuan man, sharp focus, 100 mm, depth of field. man in costume, dynamic pose, microphone, intricate, elegant, highly detailed, space set, futuristic, concept art.",
	"Woman as a botanist, standing, intricate elegant dress, cinestill 50, trending cinematic vibes, long exposure. Mushroom core, detailed, glowing, bioluminescent lumen, vibrant colors, voluminous, mushrooms, wild view.",
	"hyper detailed, (John Marston:1.2) , John Marston is dressed in a mauve off-the-shoulder top and skinny jeans, Sega design by Artgerm, Iliadon Boutique, realistic, sharp focus, lighting, trending on Pinterest, foliage, octane REDS, highly detailed, artstation, Warehouse, intricate, 3D model, CG art, cinematic lighting, nighttime",
	"An emotional hermione granger, portrait style, cinematic still, sky blue and lavender hair styled as a crown braid. Port, background, anaglyph effect.",
	"Elegant Hikaru Sulu, ginger hair, intricate, high-end, purple and black uniform, movie still. Fighting, close combat, daring pose, intense expression, red lighting, cinematic environment, sharp focus.",
	"Portrait of meek! yellow one eyed one, close up, detailed intricate elegant, sharp focus, illustration, by R. Crumb, B. Pen and ink, hard line and shading, beautiful lines, fine details, highly detailed, masterpiece, concept art, soft background",
	"Role-playing game (RPG) style fantasy. Apocalyptic Leslie Knope, leatherworker, forest clearing. Articulated, detailed, highly cinematic, high fantasy, intricate, volumetric lighting. Expressive face and hand gesture, dramatic lighting, trending on artstation, cinematic light effect, art by Ilya Kuvshinov, cinematic lighting, 8K resolution, dynamic lighting, hd, epic composition, ray tracing, realistic lighting, concept art, smooth, sharp focus, Ray-Hamden.",
	"Anime, valedictorian (the hulk of evil), awkward, garden, high detail, vivid color, art stationery, octane inks, 8 am, very detailed, sharp, 3 d render, sunlight",
	"A Nasty Ellen Ripley, hip level, detailed face, cinematic lighting. Taj Mahal, intricate details, rich colors. Natural, art by Artgerm and Greg Rutkowski",
	"Anime, cured moonface, intricate, sharp focus, in the air. Anime, female character, art by Yoshitaka Amano, BVG Hatsune Miku, high details, auburn hair styled as cornrows. Artstation, Ceres",
	"cinematic still, (gimli:1.1) swimming, frolicking in a trees, shallow depth of field, 50mm, hearthstone artwork, art by artgerm and alphonse mucha",
	"Ukrainian Cheerleader Sadness from Inside Out, crouching down in nightgown with robe, dark colour palette. Shallow depth of field. Background, horror scene, horror movie. Background, dark, twisted, psychedelic, 80's style, sharp shadows. Insanity. Blurred features. Instax film.",
	"Portrait of The Colonial Marines. At dawn, sun rising, orange and yellow hues, cinematic colors, cinematographic effect, detailed, intricate, expressive, epic lighting, dramatic, focused, cinematic atmosphere, 4k resolution, sharp focus, trending on Artstation, unreal engine 5, highly detailed, digital art, pixar 5",
	"Unicorn human, platinum hair, intricate, elegant, horned head, cinematic lighting, highly detailed, digital painting, artstyle by Greg rutkowski and ross tran, and trending on artstation, and trending on deviantart",
	"brigid: Goddess, glowing golden details, intricate, elegant, highly detailed. art by hayao miyazaki, matte painting, smooth, sharp focus, illustration by katsuhiro otomo, trending on artstation",
	"Millefleurs Tapestry of a Breathtaking Boromir, functional space, nighttime, atompunk, cinematic lighting, high-detailed, 8k resolution, intricate design, hd, digital painting, cinematic art, concept art. Illuminated, sharp focus, beautiful, art, elegant, by artgem, Dan Mumford, WLOP, Edgar Allan Ruck, Unreal Engine. Scene: art studio, bright spotlights, soft shadows, cinematic atmosphere, colorful lighting, trending on artstation.",
	"Anime artwork illustrating legolas paralegal, Japanese anime studio, trending on Artstation, sharp focus, 4K, dramatic lighting, artistic, emotional, intricate details. Dynamic pose, anime style, character design, in the style of Studio Ghibli, art by Makoto Shinkai and WLOP, elegant and detailed, masterpiece",
	"Charming Jonesy, smiling, handsome, white hair, blue eyes, artisanal, lush beach, fine details, cinematic lighting. Amulet, glowing, golden color, intricate, elegant, highly detailed, smooth, artifact, by Greg Rutkowski and David Mack, painted on, trending on ArtStation. Foggy conditions, soft colors, beautiful, mist, natural mist effect, natural light, photorealistic lighting, cinematic, hyper realistic.",
	"Awe, Doomguy, hero figure, dark armor, glowing green accents, archetypal male, highly detailed, high-contrast lighting. Cityscape, futuristic, highly detailed, dark lighting, dramatic shadows. Distant city blocks, dark, blue hues, sci-fi structures, very modern, geometric patterns, by R. M. 7.",
	"cinematic still, masterpiece, vibrant colors, sharp focus, cinemascope, in focus, colorful foliage, intricate, detailed, trending pixar art, stylish, digital painting, style by F. and F. studio, trending on artstation",
	"a Psychofrankenstein, scary monster, glowing eyes, dark, purple skin, glowing red eyes, black and white cape, flirty, dramatic pose. Scary, stormy night, rain, lightning, background by Stanley Artgerm Lau, gregarious, studio lighting, albumen, dark atmosphere.",
	"concept art, funny character, shaded, cinematic, winking smile, whimsical details. Shot from behind of a thin hula dancer, foggy, blurred, \ud83d\ude2b , detailed, fantasy, bright neon, clear, trending on ArtStation",
	"Coatlicue, intricate, 4 arms, volumetric light, natural lighting, nighttime, glowing eyes. 2nd face, highly detailed, art by artgerm and greg rutkowski and alphonse mucha, golden decorations, black clothes. Atmospheric rain.",
	"woody from toy story, detailed, highly ornate, intricate details, art studio, glowing light, voluminous painting by rembrandt, soft brushstrokes",
	"Portrait, upper body shot of a beefy Jonesy, baroque, fine details, intricate, elegant, highly detailed, digital painting, artstation, smooth, sharp focus, glowing amber eyes, illustration, art by Greg rutkowski and alphonse mucha, studio lighting, warm light.",
	"whimsical and playful, highly detailed, light-weight guardian ryu hayabusa, laughing, bright colors, cinematic, concept art, intricate details, cinematic, sunny, cinematic sky",
	"Gollum, intricate details, upper body, artifact, highly detailed, fantasy, glowing eyes, shimmering, dreamy, ethereal, dreamscape. Glowing mushrooms, dark, volcanic, glittering, gold accents, ornate, dream of the past, dramatic lighting, surrealism.",
	"heavyset croatian frankenstein's monster, grotesque, artstation, Unreal Engine, realistic shading, smooth, matte, 3D model, sharp edges, inky black, highly detailed",
	"city, Ornate Maori building, high details, misty conditions. Chibi Anime character, Adorable Chun-Li, sharp and in focus. Intricate details. Motion, Blurred background. CG Unity 8k wallpaper, extremely detailed. Adobe lightroom. High contrast foggy conditions. Anime style.",
	"Concept art, Maori niko bellic, trending on artstation, pretty, realistic lighting, sharp focus, soft glows, RPG, FF14, highly detailed, art by artgerm and greg rutkowski",
	"overweight agent 47, detailed face, high resolution, realistic skin, fine lines, masculine features, beautiful woman, dramatic pose, voluminous dress, elegant, detailed, art nouveau, lighting by greg rutkowski, moody, intricate, bright lights",
	"A digital art painting of an exhausted epimetheus, high contrast, gothic style, byzantine, intricate, elegant, beautiful details. Curious, ryan white hair, long face, gothic clothing, cinematic, raining, rays, colorful, shatter effect, soft focus, smooth shaded, 8k",
	"Ablaze with Astral Aura, Athena, blonde hair, vivid emotions. At the Alcazar of Segovia, monochromatic, atmospheric, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, Artgerm, DeviantArt",
	"A side-profile of The Morlocks, red and white hair, long side part. Wearing denim shorts, dramatic, 1800's style, art by Guillaume Robichon and Roy Crane, diagonal stripes images, natural light, Agra, natural lighting.",
	"Navajo Frigg, flawless, hiking, intricate, traditional clothing, cybernetic, elegant, highly detailed, digital, art by Artgerm and Greg Rutkowski and Alphonse Mucha, Iroha forest, wide view, dramatic lighting, masterpiece",
	"Surreal perspective of a disgraceful elegant, detailed, cinematic lighting, vibrant colors, f/2, f/8, intricate, fisheye lens, digital art, highly detailed, abstract interpretation, by Greg Rutkowski and J.J. Guanche",
	"Watercolor Paint Of a peggy olson, trendy spectacles, elegant, blue dress, cinematic atmosphere. Oriental eyeshadow, vibrant colors, shimmering, detailed, conceptual art. Artstation, colorful, bold, intricate, sharp focus.",
	"artistic vitrail of a rotund kane from nier, standing, voluptuous figure, highly detailed, intricate, gold accent, realistic, fine details, by greg rutkowski and jeremy blaustein, cg rendering. Underwater cave, trending on artstation",
	"Trevor Philips, crazy, emotional, highly detailed, bright, vibrant, colorful, high-contrast lighting, shaded areas, dramatic lighting. Near city street, night. Brick walls, graffiti art, intricate, detailed, trending, very detailed, highly detailed, photorealistic, ultra detail, very cohesive, cinematic, movie stills, realistic, by Tom and Tom, Rekoil, very detailed, Mashiro, Juvie",
	"The Big Bad Wolf, he is wearing traditional attire, tailored by Hedi Slimane. His hair is platinum blonde, ultra detailed face, realistic portrait, intricate, elegant, sharp focus, highly detailed, digital painting, artstation, concept art, smooth, soft lighting, noir illustration",
	"dramatic Ornament! Highly detailed, Grumpy Vulgar Junkie Mighty Mouse! standing, hand on hip. Intricate epic! epic detail, 3D, glowing gold, colorful, clutter! Digital mess, volumetric lighting, high contrast, trending art, scruffy, oil can, worn-out, old, oil can, cigarette",
	"futuristic amphitrite, deep blue ocean, high-tech, glowing trident, intricate, elegant, biomechanical, 40's, detailed, volumetric lighting, octane bubbles, underwater, glass-enclosed view, cybernetic, futurist, concept art, beautiful, fine details, highly detailed, artwork, illustration.",
	"A colorful and pudgy creature, trending as Kirby, surrounded by vivid pastel colors. Fantastical animalistic face, very short spiky hair, blue overalls, soft light features. In the background, art deco, wavy patterns, psychedelic swirly shapes. Huge rainbow clouds, highly detailed, concept art, sharp lines and smooth edges.",
	"Haitian WALL-E, holographic, glowing light, realistic face, high resolution details, shaggy hair, cinematic quality, realistic eyes, art style, high detail, trendy, retro, plastic clothing, geometric pattern, psychedelic colors, dynamic lighting.",
	"Dobby, small white house, front porch. Sitting in front, long legs, 1st class outfit. Shaved head. Hands holding a brush. Highlighted fine details. Golden shimmering blue shoes. Glowing blue lamp. Glasses. Sunset, by the house, bright sky. Rays, golden color, light, trending on rooftop, hazy day.",
	"Minion, cave, scaly skin, creepy smile, glowing eyes, long tail, intricate, red and blue, golden armor, jewels. Sitting in dark corner, rubbing gems, artifacts, glow in darkness, artwork, trending on pinterest, detailed, cinematic shot, dramatic lighting.",
	"A highly appealing berserker, muscular, scruffy face, black armor, intricate, elegant, gold accents, ornate, red eye, headpiece. The colonel marine, rugged, detailed face, high visibility outfit, camo details, 40 mm grenade, brown leather, red color pattern. Volcanic wasteland, glowing lava, active volcano, glowing hot embers. Berserker, fighting, alien creatures, grotesque, humanoid, sharp teeth, beady eyes, intricate armor.",
	"A kindhearted Hellenistic female fighter, bikini armor, golden-colored, intricate, elegant, highly detailed, jewelry, gold, Greek art, sculptures, realistic, smooth, highly detailed, realistic, trending on Artstagram, soft lighting, illustration, beautiful, dramatic, looking up.",
	"A short, high-pitched yuan dynasty team rocket, boyish face, highly detailed, sharp angles, glowing jewels, black and blue uniforms. in front of an old house. home, very very very detailed, intricate, elegant, red timber walls, by artisans, under the sun. caesar, long white hair, blue robe, highly detailed, intricately patterned, 4k, award winning, trending on hd.",
	"stout dracula, barber, white smock, white bib, blue and green striped barbershop pattern, blue glowing eyes, sharp teeth, long white hair, blue mist, old, rustic barber shop, vintage barber chair, high-quality razors, shiny, polished, blades.",
	"female version of Rex, humanoid robot, large size, brown eyes, red arms, intricate details, delicate, detailed features, cinematic lighting, colorful, artistic by ilya kuvshinov, very detailed, realistic shading, cinematic mood, trending on artstation",
	"gorgeous pretty gothic hermione granger walking down a street, 80s fashion, long white coat, purple, white, glam, trending, purple dress, white boots. Highly detailed, digital art, beautiful, shaded, colorful, dramatic lighting, intricate, elegant, by Stanley Artgerm Lau, WGSN, Hogwarts, gothic, dark fantasy, October, misty, romantic, atmosphere",
	"Vesta, longhaired, black eyes, glowing blue accent, cyan hair, military boots, black shorts, grey, faded, thriftcore chainmail, card games, colorful decks, artgames, black metal table and metal chairs",
	"Gorgeous Don Draper, sitting, light hair, braided, intricate, half-up half-down. Art Deco interior, by Art Graham, elegant, voluminous, golden light. Highly detailed, sharp focus, illustration.",
	"wearing gorget, large niko bellic, brown hair, golden light, shining, cinematic, beautiful golden face, detailed, intricate, elegant, highly detailed, sharp, photorealistic, cinematic, art by ilya kuvshinov, kentucky bourbon.",
	"Kyle Broflovski, sitting, round glasses, yellow sweater, bright yellow pants, blue face mask, yellow hat, taxi cap on back, red beard, short body, in front of a taxi, high-visibility vests, bright lights, detailed, ornate, golden details. Intricate, elegant, highly detailed, ornate, intricate, art deco, cityscape, city lights, blinking yellow streetlights, neon lights, rain.",
	"Apollo Belenus, hero in ancient roman helmet. intricate, golden, ornate, art, highly detailed, artwork, cinematic shot, dramatic lighting, dramatic shadows, golden hair, high contrast, by Artemis temple, beautiful architecture, by Tiber river, mist, misty, foggy atmosphere",
	"Elrond, handsome elf, leaning forward, french braid hair, artful, beautiful facial features. intricate, elegant, highly detailed, digital painting, artstation, smooth, sharp focus, illustration.",
	"Shinjiro, auburn haired, male figure, casual outfit, standing on street corner, long ponytail.",
	"Marianne, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, matte, sharp focus. illustration of a fashion designer, Trending on Artstation, HDR, Unreal Engine 5. Art by WLOP and Tom Bagshaw and Ross Tran",
	"Amani, golden hair, glowing, translucent figure, long flowing hair, sunny glow. Walking through ancient ruins, ruins of civilization, stone bricks, soft glow, red flowers, intricate, by artifact, eerie quietness, misty, cinematic lighting.",
	"Sardinian woman, elegant, highly detailed, realistic face, soft lighting, intricate, wavy, gold and silver, brow hair. mystic, glowing, beautiful, glowing, gold aureate crown, magic, mist, flowers, magical symbols, vines",
	"Alluring Theresa, red hair, white dress, voluminous skirt, gold accessories, glowing eyes, large black pupils, beach, sunset, palm trees, shimmering water, fine sand, perfect feet.",
	"A majestic french maid, woman with narrow waist. Fashion model, dress, elegant, trending by artgerm, intricate, elegant, highly detailed, digital painting, pixiv fanbox, concept art, smooth, matte finish, glamour, octane renderer.",
	"Fernanda, Garden, by Daisy, bloom flowers, intricate patterns, black hair, long skirt, colorful print, elegant, beautiful, flowers, flower bed, vibrant colors, blooming buds, watering can.",
	"Tiny polish zweli, smiling face, miniature, hyper detailed, artisan, intricate, 2d painting, fantasy character. Luminous forest, highly detailed, concept art, perfect details, glowing by Igor Karklin, Ultra Fine Art, trending on artstation",
	"A comely Venezuelan quang, wearing, colorful, lively, trending on Art Deco, intricate, 3d, art by Stanley Artgerm Lau, wlop, wlop, digital painting, concept art, smooth, sharp focus, illustration, hepato, 4k.",
	"A 1950s suburbia average shinjiro, blue denim overalls and white dress shirt, sun hat, sunglasses, short hair. landscape architect. concept drawing, long table, soft lighting, trending on pinterest, high detail, hand sketch, conceptual art, detailed sketch, in the style of Frank Lloyd Wright.",
	"Unique Hideous, she is wearing ruffled polera (glitch art) 3D printed clothing. Scene: Worship room, high contrast light, glow in the dark symbols. Foggy, atmospheric, sharp focus, concept art.",
	"Michal, laying in a bath, intricate, elegant, white and gold tub, detailed, ornate, gold faucet. Scene: Sunlight, golden rays, dramatic lighting, sunbeams, water, steam rising, by a window. Background: Blue and black, marble tiles, realistic, highly detailed, golden trim, oil painting on the wall.",
	"highly detailed, intricate, elegant, ornate, golden details, intricate, highly detailed, digital painting, art by Gustav Klimt and Art Nouveau, very symmetrical, richly colored, fine details, soft, sharp focus, illustration, octane renderer, masterpiece, award-winning, trending on artstation, beautiful woman",
	"Orc Shaman, standing tall and confident, intricate, highly detailed, ornate face painting, fur clothing. Digital art, concept art, smooth shaded, illustration, unreal engine 5.",
	"A skinny Sione, cool, smooth face, sunglasses, trending fashion, white sneakers, graphic t-shirt, colorful, graffiti background. Artwork, colorful, detailed, intricate, by H.R. Giger and David Finch. Street.",
	"Orly, short boy with messy hair, shorts and t-shirt, sweaty and determined expression. jumpsuit, long legs, detailed shoes. jumping, hand holding on to pipes. jungle background, wild forest, green trees, beautiful sunset, dramatic shadows. water tower, tall, dark grey, blue halo, intricate, elegant, rainwater dripping down.",
	"A rich, muscular rider, white hat, expensive suit, intricate leather boots, elegant horse. In front of the crowd, microphone, hd screens, raging cheers. Articulate, ornate, smooth, glittering jewels, shiny leather bag, high-value coins.",
	"Yumi, elegant woman, Japanese kimono, red color, golden obi belt, highly detailed, intricate, japanese, flower hairpin, bun, painted by Klimt, oil painting, style, gold accents.",
	"Pear-shaped figure Rabia, boyboyfriend jeans, midriff, faded, distressed, dark red, light blue, white detail. Drawn, inspired from movie, Dreamworks, Trendy, high waisted, cinched waist, midrise, distressed, frayed, intricate, detailed, high-fashion.",
	"athletic Winston, flawless, cybernetic arm, reimagined, Zombie Apocalypse, highly detailed, intricate, sharp, focused, combat boots. Running, barbed wire, abandoned city, grey sky, zombies, twisted, rotting, bloodlust, cinematic lighting",
	"Gritty Playful Dinesh, gondolier, heroic stance, large oval glasses, dark grey hair, worn white t-shirt, white leather gloves, blue vest, gold and white striped suspenders, intricate, decorative, highly detailed, ornate gondola. Gritty alleyway, gondolier's route, twinkling lights, colorful buildings, playful art, waterway, rainy weather, splashy, highly dynamic.",
	"A Hamid, dressed, blue shirt, grey pants, crossed legs, colored hairstyle, ponytail, golden earrings, ornate, highly detailed, golden jewelry. Fruits, intricate, gold bowl, highly detailed, beautiful, elegant, golden art by Makoto Hasegawa. Rooftop, Highly Detailed, Digital Art, Cube house, Trending Artistic Japanese Neighborhood",
	"A beastmaster, beard, wearing leather and furs, holding a large wooden staff, intricate, golden, lion crest, highly detailed, patterned, golden leather armor, high-detailed, dramatic lighting. Riding on back of an elephant, very detailed, elegant, curved, ivory tusks, intricate, artistic, highly ornate decorations, forest environment.",
	"A Yuliya, female samurai warrior, intricate, elegant, highly detailed, flower hairpiece, soft pastel colors, traditional Japanese armor, cincher belt. Under the blossom tree, cherry petals falling, realistic details, bright pink color, highly detailed petals. By Koto Riverbank, dramatic sunset, beautiful contrasts, realistic water, misty background",
	"A bold and rugged man, dark brown hair, muscular build, highly detailed chainmail armor. cinematic lighting, sharp focus, oil painting, intricate, octagonally shaped opal insets, translucent, red-orange glow.",
	"hacker, concept art, African female character, glasses, cybernetic hand, glowing screen, intricate details, digital world, colorful, neon colors, glowing blue accents, high-rise buildings, neon signs, blinking lights",
	"Asha, Norse hair styled as shoulder-length, volcano view, sunset, purple robe, soft fur lining, soft, golden, intricate, headpiece. Scene: goddess, serene pose, godly powers, waterfall, vibrant, bright rainbow. Background: forest, colorful, leaves, trees, wildlife, by artemis temple.",
	"1900's, realistic shading, intricate detail, symmetrical composition, trending aesthetic, concept art, glowing highlights, digital art, golden hour lighting. by ilya kupriy, d&d fan, aphostrophic, dramatic pose, volumetric light effect, octane render, oil painting, oilpainting, oilpainter.",
	"A 24 year old rider, dressed in leather outfit. black hair. blue eyes. riding helmet. large saddle. horse. pastoral landscape. flowers. colorful forest. autumn day.",
	"Floris, white skin, brown hair, 30s, dressed casual clothes, soft smile, hands in mutant's hand, long face, scars, intricate tattoos. Forest, green leaves, red trees, dusk, sunset, bright, warm, light.",
	"Paul, man, shirtless, muscular, intricate, golden tattoos, scruffy beard, dirty jeans. walking. High-detailed forest, vibrant trees, beautiful, bioluminescent, soft light, mist, shimmering water.",
	"Erika, breathtaking, beautiful face, white hair, glowing aura, violet eyes, long neck. scene: Sunset, by the sea shore, sandy beach, large waves, ocean. background: 100 years old shipwreck, glowing lights, full moon, beautiful flowers, volumious, detailed, elegant, golden, perfect symmetry, realistic, very pretty.",
	"A thin young woman, intricate, elegant, highly detailed, digital painting, art by artgerm and greg rutkowski and alphonse mucha, Masterpiece",
	"lovely jolien standing, dark brown eyes, caramel hued hair, detailed face, painted by art of life and age, dress, nomadic, tunic, roaring desert, wind, sand, caravan",
	"Elif, artstation, portrait, concept art, sharp focus, highly detailed, art by John Smith, Kaliart Studio. Artistic, 3D, smooth, high contrast lighting, shaded areas, dramatic highlights. Black hair, stylish, geometric, perfect lines, trendy, art by Greg Rutkowski and Tom Bagshaw",
	"stout Wai, maroon and turquoise spiky hair. High top bun, intricate, highly detailed, art deco.",
	"Ming, standing, wearing brown coat, long, furry hood. brown hat, colorful. brown pants, fur lining. brown gloves, ribbed texture. brown goggles, shaded lenses. brown ski boots, tall and shiny. Brown skis, short and wide.",
	"Mereoni, beautiful, good-looking, beautiful, elegant, fashionable, beautiful, beautiful. Beautiful, 8k, realistic, shaded, digital painting, trending on artstation, award winning, deviantart. Beautiful woman, smooth, sharp focus, high contrast lighting, intricate, elegant, highly detailed. Edwardian clothing, highly detailed, trending on pinterest, art by Greg Rutkowski",
	"keiko, woman, red dress, gold jewelry, long necklace, trendy shades, white sneakers, high ponytail, wlf. exotic, golden retriever, blue eyes, long ears.",
	"strong Budi of Love, in Hunting clothing, S-shape pose, honey dreadlocks, intricate, elegant, highly detailed, art by Artgerm and Greg rutkowski and alphonse mucha",
	"Nasty nana, standing, short, curly hair, long dress, apron, bright red dress, white collars, intricate, polka dots, colorful, detailed, flower, gardening, hoe, veggie, green, leaves, plants, vegetable garden.",
	"A beautiful young woman, suvannarat, looking over a flower, red hair, red lips, high detail, gorgeous face. Lovely dress on, elegant, highly detailed, art nouveau, chevron patterned, trending on art deco, 1900s",
	"An old wei, cinematic lighting, fang, long, sharp, golden light. Faced, eyes, aged, golden, wise, cinematic, detailed, hyper realistic, furry. Tiger, realistic, intricate details. Desert, sunny, shimmering, warm, dunes, windy, golden hues.",
	"Mahinda, handsome, royal face, dark hair, perfect white clothes, intricate golden ornaments, golden crown, art by David Tennant, beautiful landscape, riverbank, sunny day, trees, colorful flowers. Noble man, fine clothing, elegant posture, smiling to peasants, hand to peasants, reward, golden coins.",
	"Iranian Jasmin, kneeling, pretty face, dark brown hair, kimono, colorful pattern, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus",
	"Canadian Pieter of Power, tall figure, muscular, blue eyes, red hair, colorful hair decoration, symmetrical, detailed facial features, highly detailed intricate pleated skirt, volumetric, midi length, fuller lower half, detailed and soft fabric, intricate patterns, art style, beautiful, gold accents, sweater with highly detailed art, smooth, white, beautiful trimmings, full coverage, elegant, ornate, highly detailed, red.",
	"fatigued zinhle, sitting with hands around ankles, detailed face, long curly hair, soft lighting, colorful fringe top, intricate, paisley trimmings, artisanal, cinematic, golden hour. trending on pinterest, digital painting, soft focus, blotchy sun, 80's retro aesthetic",
	"good-looking Mexican Chia-Ying, short rainbow hair styled as short and slicked back, blue eyes, orange hair bangs, stylish outfit, trendy sneakers, intricate footstool.",
	"Mystical Japanese Taniaela, camping, rainbow hair styled as short and slicked back, colorful strands, glow with light, violet eyes, detailed, digital art. Artistic, concept artwork, trending on Pixiv. CG render, 3D, shaded by Ilya Kuvshinov, art by Makoto Shinkai and Studio Ghibli",
	"A detailed portrait of Ecuadorian Maximus, wearing fur, long hair, shaggy eyebrows, white mustache. Articulate fur, smooth texture, art deco, golden accents, fur, tweed, plaid pattern. Tweed cap, intricate, elegant, highly detailed, fur.",
	"houda, elegant, intricate, glowing fish scale dress, by Stanley Artgerm Lau, long flowing hair, waving, beautiful, gems, symmetrical face, pearl colored skin, underwater, bubbles, shimmering, colorful coral reefs, corals, artifacts, high detailed, intricate, octopus.",
	"Passionate Masaru, elegant kimono, highly detailed, intricate, gold and red accents, dramatic pose, white background, oil painting, beautiful face, black hair, highly detailed, artistic lighting. Painting, art gallery, museum, dramatic atmosphere, cinematic light.",
	"Ugly Saoirse, beautiful, fantasy, intricate, elegant, highly detailed, gold and silver, intricate, intricate, highly detailed, centered, artifact.",
	"Min-ho lance corporal, gritty face, white skin, pale grey eyes. Wearing intricate, highly detailed, cyberpunk armor. Glossy, smooth finish, white and black color scheme. Art deco, sharp focus, concept art style by greg rutkowski",
	"A Sardinian sandini, beautiful face, black hair, bright blue eyes. playing music, cello, bow in hand, red hair, beautiful dress, golden necklace, highly detailed, intricate, elegant, ornate, forest clearing, sunny, cinematic light, trees, flowers, birds in background.",
	"macho ji-yon, realistic painting, naturalistic lighting, cinematic, intricate, elegant, highly detailed, digital painting, by wlop, ross tran, artgerm, pixiv",
	"A Cozy Ahmad, robe, colorful, intricate, elegant, highly detailed, golden, purple, cinematic lighting, art by Artgerm, masterpiece, golden chandelier, warm, inviting, fireplace. Royal Pose, d&d, highly detailed, gold, crown, sceptre, throne, rich velvet, intricate pattern, ornate, detailed, smooth, polished wood",
	"radiant elf, glowing skin, sparkling eyes, delicate features, highly detailed, intricate, elegant, leaf crown, forest clearing, bright light, soft shadows, blue rays, magical creatures, golden, highly detailed, illustration, by greg rutkowski and alphonse mucha.",
	"Xochitl, woman, large smile, gold tooth, long hair, golden red, shiny dress, glowing jewels, beautiful face.",
	"River, depth of field, high detail, cinematic shot, golden hour, shimmering water, sunlight, colorful fish.",
	"pretty Ashwin, dancing, elegant pose, colorful costume, soft lighting, highly detailed, intricate, gold accents, golden hour, ornate, luxurious. At the Palace of Knossos, white marble walls, mosaic tile, ancient artifacts, statues, gold.",
	"Justinian, middle age, bald, trending at the sides, white hair, detailed brush work, symmetric brush stroke, art by greg rutkowski, brush by wlop and studio 8",
	"Aoife, intricate, dance costume, white and red, soft fabric, art deco patterns, trendy, by Art Nouveau decor, voluminous dress, baroque, highly detailed, elegant, intricate, face pale skin, raven hair, sharp features. secret lover, dark figures, handsome, good looking, red eyes, black outfit. vampiric. night sky, blue-black, cold, atmosphere, soft glowing, no stars, very dark.",
	"Marvelous character, intricate mustache, voluminous hair, colorful clothing, white suit, yellow accents, red tie, intricate pattern. Artistic art by Flemish, Louvre Museum, Paris. oil painting, masterpiece. art gallery, detailed, ornate, 1900's.",
	"Tarsa, young woman, realistic, smooth skin, natural dress, glowing eyes, trending color palette, shades of yellow and red, realistic eyes and hands, concept art, by Greg Rutkowski, Igorette, illustration, fantasy art, intricate, highly detailed, 3d, sharp focus, photorealistic lighting.",
	"warlike kameli, barren desert, dark blue, orange accents, muscular, detailed facial features, intricate, detailed, armour, art deco, ornate, elegant, beautiful, hammers, glowing hot, sharp edges, fine details, detailed, highly detailed, intricate, artifact.",
];

export const localDefaultRunnitImages = [
	'https://storage.googleapis.com/image-ai-cb35d.appspot.com/assets/rd-webapp/runnit-defaults/1024/rdlogo-0-1024.webp',
	'https://storage.googleapis.com/image-ai-cb35d.appspot.com/assets/rd-webapp/runnit-defaults/1024/rdlogo-1-1024.webp',
	'https://storage.googleapis.com/image-ai-cb35d.appspot.com/assets/rd-webapp/runnit-defaults/1024/rdlogo-104-1024.webp',
	'https://storage.googleapis.com/image-ai-cb35d.appspot.com/assets/rd-webapp/runnit-defaults/1024/rdlogo-105-1024.webp',
	'https://storage.googleapis.com/image-ai-cb35d.appspot.com/assets/rd-webapp/runnit-defaults/1024/rdlogo-106-1024.webp',
	'https://storage.googleapis.com/image-ai-cb35d.appspot.com/assets/rd-webapp/runnit-defaults/1024/rdlogo-110-1024.webp',
	'https://storage.googleapis.com/image-ai-cb35d.appspot.com/assets/rd-webapp/runnit-defaults/1024/rdlogo-112-1024.webp',
	'https://storage.googleapis.com/image-ai-cb35d.appspot.com/assets/rd-webapp/runnit-defaults/1024/rdlogo-113-1024.webp',
	'https://storage.googleapis.com/image-ai-cb35d.appspot.com/assets/rd-webapp/runnit-defaults/1024/rdlogo-115-1024.webp',
	'https://storage.googleapis.com/image-ai-cb35d.appspot.com/assets/rd-webapp/runnit-defaults/1024/rdlogo-116-1024.webp',
	'https://storage.googleapis.com/image-ai-cb35d.appspot.com/assets/rd-webapp/runnit-defaults/1024/rdlogo-119-1024.webp',
	'https://storage.googleapis.com/image-ai-cb35d.appspot.com/assets/rd-webapp/runnit-defaults/1024/rdlogo-121-1024.webp',
	'https://storage.googleapis.com/image-ai-cb35d.appspot.com/assets/rd-webapp/runnit-defaults/1024/rdlogo-125-1024.webp',
	'https://storage.googleapis.com/image-ai-cb35d.appspot.com/assets/rd-webapp/runnit-defaults/1024/rdlogo-13-1024.webp',
	'https://storage.googleapis.com/image-ai-cb35d.appspot.com/assets/rd-webapp/runnit-defaults/1024/rdlogo-131-1024.webp',
	'https://storage.googleapis.com/image-ai-cb35d.appspot.com/assets/rd-webapp/runnit-defaults/1024/rdlogo-135-1024.webp',
	'https://storage.googleapis.com/image-ai-cb35d.appspot.com/assets/rd-webapp/runnit-defaults/1024/rdlogo-138-1024.webp',
	'https://storage.googleapis.com/image-ai-cb35d.appspot.com/assets/rd-webapp/runnit-defaults/1024/rdlogo-139-1024.webp',
	'https://storage.googleapis.com/image-ai-cb35d.appspot.com/assets/rd-webapp/runnit-defaults/1024/rdlogo-141-1024.webp',
	'https://storage.googleapis.com/image-ai-cb35d.appspot.com/assets/rd-webapp/runnit-defaults/1024/rdlogo-142-1024.webp',
	'https://storage.googleapis.com/image-ai-cb35d.appspot.com/assets/rd-webapp/runnit-defaults/1024/rdlogo-143-1024.webp',
	'https://storage.googleapis.com/image-ai-cb35d.appspot.com/assets/rd-webapp/runnit-defaults/1024/rdlogo-146-1024.webp',
	'https://storage.googleapis.com/image-ai-cb35d.appspot.com/assets/rd-webapp/runnit-defaults/1024/rdlogo-147-1024.webp',
	'https://storage.googleapis.com/image-ai-cb35d.appspot.com/assets/rd-webapp/runnit-defaults/1024/rdlogo-151-1024.webp',
	'https://storage.googleapis.com/image-ai-cb35d.appspot.com/assets/rd-webapp/runnit-defaults/1024/rdlogo-152-1024.webp',
	'https://storage.googleapis.com/image-ai-cb35d.appspot.com/assets/rd-webapp/runnit-defaults/1024/rdlogo-156-1024.webp',
	'https://storage.googleapis.com/image-ai-cb35d.appspot.com/assets/rd-webapp/runnit-defaults/1024/rdlogo-159-1024.webp',
	'https://storage.googleapis.com/image-ai-cb35d.appspot.com/assets/rd-webapp/runnit-defaults/1024/rdlogo-162-1024.webp',
	'https://storage.googleapis.com/image-ai-cb35d.appspot.com/assets/rd-webapp/runnit-defaults/1024/rdlogo-163-1024.webp',
	'https://storage.googleapis.com/image-ai-cb35d.appspot.com/assets/rd-webapp/runnit-defaults/1024/rdlogo-167-1024.webp',
	'https://storage.googleapis.com/image-ai-cb35d.appspot.com/assets/rd-webapp/runnit-defaults/1024/rdlogo-175-1024.webp',
	'https://storage.googleapis.com/image-ai-cb35d.appspot.com/assets/rd-webapp/runnit-defaults/1024/rdlogo-179-1024.webp',
	'https://storage.googleapis.com/image-ai-cb35d.appspot.com/assets/rd-webapp/runnit-defaults/1024/rdlogo-18-1024.webp',
	'https://storage.googleapis.com/image-ai-cb35d.appspot.com/assets/rd-webapp/runnit-defaults/1024/rdlogo-181-1024.webp',
	'https://storage.googleapis.com/image-ai-cb35d.appspot.com/assets/rd-webapp/runnit-defaults/1024/rdlogo-186-1024.webp',
	'https://storage.googleapis.com/image-ai-cb35d.appspot.com/assets/rd-webapp/runnit-defaults/1024/rdlogo-188-1024.webp',
	'https://storage.googleapis.com/image-ai-cb35d.appspot.com/assets/rd-webapp/runnit-defaults/1024/rdlogo-19-1024.webp',
	'https://storage.googleapis.com/image-ai-cb35d.appspot.com/assets/rd-webapp/runnit-defaults/1024/rdlogo-191-1024.webp',
	'https://storage.googleapis.com/image-ai-cb35d.appspot.com/assets/rd-webapp/runnit-defaults/1024/rdlogo-193-1024.webp',
	'https://storage.googleapis.com/image-ai-cb35d.appspot.com/assets/rd-webapp/runnit-defaults/1024/rdlogo-20-1024.webp',
	'https://storage.googleapis.com/image-ai-cb35d.appspot.com/assets/rd-webapp/runnit-defaults/1024/rdlogo-202-1024.webp',
	'https://storage.googleapis.com/image-ai-cb35d.appspot.com/assets/rd-webapp/runnit-defaults/1024/rdlogo-207-1024.webp',
	'https://storage.googleapis.com/image-ai-cb35d.appspot.com/assets/rd-webapp/runnit-defaults/1024/rdlogo-21-1024.webp',
	'https://storage.googleapis.com/image-ai-cb35d.appspot.com/assets/rd-webapp/runnit-defaults/1024/rdlogo-218-1024.webp',
	'https://storage.googleapis.com/image-ai-cb35d.appspot.com/assets/rd-webapp/runnit-defaults/1024/rdlogo-220-1024.webp',
	'https://storage.googleapis.com/image-ai-cb35d.appspot.com/assets/rd-webapp/runnit-defaults/1024/rdlogo-223-1024.webp',
	'https://storage.googleapis.com/image-ai-cb35d.appspot.com/assets/rd-webapp/runnit-defaults/1024/rdlogo-229-1024.webp',
	'https://storage.googleapis.com/image-ai-cb35d.appspot.com/assets/rd-webapp/runnit-defaults/1024/rdlogo-231-1024.webp',
	'https://storage.googleapis.com/image-ai-cb35d.appspot.com/assets/rd-webapp/runnit-defaults/1024/rdlogo-235-1024.webp',
	'https://storage.googleapis.com/image-ai-cb35d.appspot.com/assets/rd-webapp/runnit-defaults/1024/rdlogo-238-1024.webp',
	'https://storage.googleapis.com/image-ai-cb35d.appspot.com/assets/rd-webapp/runnit-defaults/1024/rdlogo-242-1024.webp',
	'https://storage.googleapis.com/image-ai-cb35d.appspot.com/assets/rd-webapp/runnit-defaults/1024/rdlogo-243-1024.webp',
	'https://storage.googleapis.com/image-ai-cb35d.appspot.com/assets/rd-webapp/runnit-defaults/1024/rdlogo-25-1024.webp',
	'https://storage.googleapis.com/image-ai-cb35d.appspot.com/assets/rd-webapp/runnit-defaults/1024/rdlogo-250-1024.webp',
	'https://storage.googleapis.com/image-ai-cb35d.appspot.com/assets/rd-webapp/runnit-defaults/1024/rdlogo-255-1024.webp',
	'https://storage.googleapis.com/image-ai-cb35d.appspot.com/assets/rd-webapp/runnit-defaults/1024/rdlogo-256-1024.webp',
	'https://storage.googleapis.com/image-ai-cb35d.appspot.com/assets/rd-webapp/runnit-defaults/1024/rdlogo-258-1024.webp',
	'https://storage.googleapis.com/image-ai-cb35d.appspot.com/assets/rd-webapp/runnit-defaults/1024/rdlogo-26-1024.webp',
	'https://storage.googleapis.com/image-ai-cb35d.appspot.com/assets/rd-webapp/runnit-defaults/1024/rdlogo-262-1024.webp',
	'https://storage.googleapis.com/image-ai-cb35d.appspot.com/assets/rd-webapp/runnit-defaults/1024/rdlogo-267-1024.webp',
	'https://storage.googleapis.com/image-ai-cb35d.appspot.com/assets/rd-webapp/runnit-defaults/1024/rdlogo-269-1024.webp',
	'https://storage.googleapis.com/image-ai-cb35d.appspot.com/assets/rd-webapp/runnit-defaults/1024/rdlogo-275-1024.webp',
	'https://storage.googleapis.com/image-ai-cb35d.appspot.com/assets/rd-webapp/runnit-defaults/1024/rdlogo-280-1024.webp',
	'https://storage.googleapis.com/image-ai-cb35d.appspot.com/assets/rd-webapp/runnit-defaults/1024/rdlogo-284-1024.webp',
	'https://storage.googleapis.com/image-ai-cb35d.appspot.com/assets/rd-webapp/runnit-defaults/1024/rdlogo-287-1024.webp',
	'https://storage.googleapis.com/image-ai-cb35d.appspot.com/assets/rd-webapp/runnit-defaults/1024/rdlogo-288-1024.webp',
	'https://storage.googleapis.com/image-ai-cb35d.appspot.com/assets/rd-webapp/runnit-defaults/1024/rdlogo-29-1024.webp',
	'https://storage.googleapis.com/image-ai-cb35d.appspot.com/assets/rd-webapp/runnit-defaults/1024/rdlogo-293-1024.webp',
	'https://storage.googleapis.com/image-ai-cb35d.appspot.com/assets/rd-webapp/runnit-defaults/1024/rdlogo-31-1024.webp',
	'https://storage.googleapis.com/image-ai-cb35d.appspot.com/assets/rd-webapp/runnit-defaults/1024/rdlogo-33-1024.webp',
	'https://storage.googleapis.com/image-ai-cb35d.appspot.com/assets/rd-webapp/runnit-defaults/1024/rdlogo-36-1024.webp',
	'https://storage.googleapis.com/image-ai-cb35d.appspot.com/assets/rd-webapp/runnit-defaults/1024/rdlogo-38-1024.webp',
	'https://storage.googleapis.com/image-ai-cb35d.appspot.com/assets/rd-webapp/runnit-defaults/1024/rdlogo-39-1024.webp',
	'https://storage.googleapis.com/image-ai-cb35d.appspot.com/assets/rd-webapp/runnit-defaults/1024/rdlogo-4-1024.webp',
	'https://storage.googleapis.com/image-ai-cb35d.appspot.com/assets/rd-webapp/runnit-defaults/1024/rdlogo-43-1024.webp',
	'https://storage.googleapis.com/image-ai-cb35d.appspot.com/assets/rd-webapp/runnit-defaults/1024/rdlogo-44-1024.webp',
	'https://storage.googleapis.com/image-ai-cb35d.appspot.com/assets/rd-webapp/runnit-defaults/1024/rdlogo-51-1024.webp',
	'https://storage.googleapis.com/image-ai-cb35d.appspot.com/assets/rd-webapp/runnit-defaults/1024/rdlogo-53-1024.webp',
	'https://storage.googleapis.com/image-ai-cb35d.appspot.com/assets/rd-webapp/runnit-defaults/1024/rdlogo-57-1024.webp',
	'https://storage.googleapis.com/image-ai-cb35d.appspot.com/assets/rd-webapp/runnit-defaults/1024/rdlogo-61-1024.webp',
	'https://storage.googleapis.com/image-ai-cb35d.appspot.com/assets/rd-webapp/runnit-defaults/1024/rdlogo-62-1024.webp',
	'https://storage.googleapis.com/image-ai-cb35d.appspot.com/assets/rd-webapp/runnit-defaults/1024/rdlogo-7-1024.webp',
	'https://storage.googleapis.com/image-ai-cb35d.appspot.com/assets/rd-webapp/runnit-defaults/1024/rdlogo-70-1024.webp',
	'https://storage.googleapis.com/image-ai-cb35d.appspot.com/assets/rd-webapp/runnit-defaults/1024/rdlogo-72-1024.webp',
	'https://storage.googleapis.com/image-ai-cb35d.appspot.com/assets/rd-webapp/runnit-defaults/1024/rdlogo-77-1024.webp',
	'https://storage.googleapis.com/image-ai-cb35d.appspot.com/assets/rd-webapp/runnit-defaults/1024/rdlogo-79-1024.webp',
	'https://storage.googleapis.com/image-ai-cb35d.appspot.com/assets/rd-webapp/runnit-defaults/1024/rdlogo-86-1024.webp',
	'https://storage.googleapis.com/image-ai-cb35d.appspot.com/assets/rd-webapp/runnit-defaults/1024/rdlogo-9-1024.webp',
	'https://storage.googleapis.com/image-ai-cb35d.appspot.com/assets/rd-webapp/runnit-defaults/1024/rdlogo-94-1024.webp',
];
