
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import firebase from 'firebase/app';
import { shouldRedirectFromEmailNotVerified } from '@/router/utils';
import { SNACKBAR_STATUS } from '@/constants/constants';
import { auth } from "@/firebase";

export default Vue.extend({
	name: 'EmailVerification',
	data () {
		return {
			checkVerificationManualTimeout: null,
			checkVerificationInterval: null,
			checkingVerification: false,
			showManualCheckVerification: false,
		};
	},
	computed: {
		...mapState([
			'authUser',
		]),
	},
	created () {
		this.checkVerificationInterval = setInterval(this.handleCheckVerification, 5000);

		this.checkVerificationManualTimeout = setTimeout(() => {
			// If after a few minutes, stop automatically checking
			this.showManualCheckVerification = true;
			if (this.checkVerificationInterval) clearInterval(this.checkVerificationInterval);
			this.checkVerificationInterval = null;
		}, 240000);
	},
	destroyed () {
		if (this.checkVerificationInterval) clearInterval(this.checkVerificationInterval);
		if (this.checkVerificationManualTimeout) clearTimeout(this.checkVerificationManualTimeout);
	},
	methods: {
		...mapActions([
			'updateSnackbar',
		]),
		async handleCheckVerification () {
			if (!this.checkingVerification) {
				try {
					this.checkingVerification = true;
					await this.authUser.reload();
					await this.authUser.getIdTokenResult(true); // This will trigger `onIdTokenChanged` handler
					const unsubscribe: firebase.Unsubscribe = auth.onIdTokenChanged((authUser: firebase.User) => {
						if (shouldRedirectFromEmailNotVerified(this.$route, authUser)) {
							this.routerReplace(this.$route, this.$router, this.$route.query.redirect || { name: 'Sessions' });
							this.updateSnackbar({
								status: SNACKBAR_STATUS.SUCCESS,
								message: 'Email verified!',
								show: true,
								timeout: 10000,
							});
						} else if (this.showManualCheckVerification) {
							this.updateSnackbar({
								status: SNACKBAR_STATUS.WARN,
								message: 'Not verified yet. Please check your email to verify your email address.',
								show: true,
								timeout: 10000,
							});
						}
						unsubscribe();
						this.checkingVerification = false;
					}, (e) => {
						console.error(e);
						this.checkingVerification = false;
					});
				} catch (e) {
					console.error(e);
				}
			}
		},
	},
});
