import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"runnits-pages-router"},[(_vm._get(_vm.$route, 'meta.isRunnitsSideNavPage'))?_c('div',{staticClass:"floating-account-btns-container",style:({
			...(!_vm.toolbar.runnitsSideNavOpen && _vm.$vuetify.breakpoint.smAndUp && {
				padding: '0 12px',
				margin: '8px 8px 0 0',
			}),
			...(!_vm.toolbar.runnitsSideNavOpen && _vm.$vuetify.breakpoint.xsOnly && {
				padding: '0 12px',
				margin: '8px 38px 0 0',
			}),
			...(_vm.toolbar.runnitsSideNavOpen && {
				backgroundColor: 'var(--grey-925)',
				padding: '0 12px',
				margin: '8px 8px 0 0',
			}),
		})},[_c('get-support-btn',{key:"get-support-btn",attrs:{"large":_vm.$vuetify.breakpoint.smAndUp,"inside-runnits":"","btn-text-color":"white"}}),_c('account-menu-btn',{key:"account-menu-btn",attrs:{"large":_vm.$vuetify.breakpoint.smAndUp,"btn-text-color":"white"}})],1):_vm._e(),_c(VContainer,{staticClass:"py-0",class:{
			'px-0': _vm.shouldRemoveSideMargins,
		},attrs:{"fluid":""}},[_c(VRow,{staticClass:"my-0",class:{
				'mx-0': _vm.shouldRemoveSideMargins,
			}},[_c(VCol,{staticClass:"py-0",class:{
					'px-0': _vm.shouldRemoveSideMargins,
				},attrs:{"cols":"12"}},[(_vm.hideRouterViewForTeam)?_c('empty-state',{attrs:{"row-class":"mx-auto my-12 py-12","icon":"mdi-alert-box-outline"},scopedSlots:_vm._u([{key:"message",fn:function(){return [_c('div',{staticClass:"d-flex align-center justify-center flex-column",staticStyle:{"max-width":"400px"}},[_c('span',{staticClass:"text-center"},[_vm._v(" Your RunDiffusion team \""+_vm._s(_vm.team.name)+"\" does not have an active subscription. You can preview early-access Runnit team features, as long as you have an active RunDiffusion team subscription. ")]),_c('div',{staticClass:"mt-6 full-width d-flex justify-center align-center"},[_c('span',{staticClass:"mr-6 text-right"},[_vm._v("Switch accounts")]),_c('div',{staticClass:"pa-1",staticStyle:{"background-color":"var(--grey-900)","border-radius":"var(--global-border-radius)"}},[_c('runnit-account-selection-dialog',{attrs:{"is-side-nav-design":""}})],1)]),_c('div',{staticClass:"mt-6 full-width d-flex flex-column justify-center align-center"},[_c('span',{staticClass:"mb-6 text-center"},[_vm._v("Or go to your team management page to reactivate your RunDiffusion subscription")]),_c('base-button',{attrs:{"call-to-action":""},on:{"click":_vm.goToRdTeamPage}},[_vm._v(" Go to RunDiffusion Team Page "),_c(VIcon,{staticClass:"ml-1",staticStyle:{"font-size":"18px"}},[_vm._v("mdi-open-in-new")])],1)],1)])]},proxy:true}],null,false,2956165405)}):_c('router-view')],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }