
import Vue from 'vue';
import { db } from '@/firebase';
import { mapActions, mapState } from 'vuex';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';
import _debounce from 'lodash/debounce';
import _isNil from 'lodash/isNil';
import _keys from 'lodash/keys';
import RunnitDynamicField from '@/views/Runnits/RunnitSettings/RunnitDynamicField.vue';
import LoadingState from '@/components/states/LoadingState.vue';
import {
	RunnitNode,
	RunnitNodeField,
	RunnitNodeFieldGroup,
	RunnitNodeRun,
	RunnitNodeRunInputValue,
	RunnitNodeStaticFields,
	RunnitNodeStaticFieldsKey,
	RunnitNodeDef,
	RUNNIT_NODE_RUN_MODE,
	RUNNIT_NODE_RUN_STATE,
	RUNNIT_NODE_FIELDS_SOURCE,
	RUNNIT_NODE_STATIC_FIELDS_SOURCE,
	RUNNIT_NODE_DEF_TOOL_APP_TYPE,
	RUNNIT_NODE_DEF_TAG_TYPE,
} from '@run-diffusion/shared';
import { mapFirebaseTimestampToDate } from '@/utils';
import { ModelCRUDMixin } from "@/mixins";

export default Vue.extend({
	name: 'AutoSaveRunnitNodeRunContainer',
	props: {
		node: { type: Object, required: true },
		inputValues: { type: Object, required: true },
		staticInputValues: { type: Object, required: true }, // These are controlled externally from this component
		incrementAutoSaveTrigger: { type: Number, default: 0 },
		allowSave: { type: Boolean, default: true },
	},
	mixins: [
		ModelCRUDMixin,
	],
	data () {
		return {
			debouncedDoSaveDraft: () => { },
			debouncedClearQueryParamsInputValues: () => { },
			debounceCreated: false,
			hasChangesToSave: false,
			isSavingChanges: false,
			inputValuesQueryParam: null,
		};
	},
	computed: {
		...mapState([
			'user',
			'draftRunnitNodeRun',
			'boundPropsDraftRunnitNodeRun',
			'loadingDraftRunnitNodeRun',
			'runnitState',
			'modelsState',
			'runnitNodesMap',
			'runnitNodes',
			'publicRunnitNodeDefTagsMap',
		]),
		shouldRenderFields () {
			return !(this.loadingDraftRunnitNodeRun || this.loadingDraftRunnitNodeRun === null);
		},
		isNodeRunQueuing () {
			return !!(
				this.draftRunnitNodeRun &&
				this.node.id === this.draftRunnitNodeRun.nodeId &&
				this.runnitState.isQueuingDraftRunnitNodeRun
			);
		},
	},
	watch: {
		node: {
			immediate: true,
			handler (newVal: RunnitNode, oldVal: RunnitNode) {
				const newId: string = this._get(newVal, 'id') || null;
				const oldId: string = this._get(oldVal, 'id') || null;
				if (newId !== oldId) {
					this.keepDebounceFunctionInFlight();
				}
				if (newId && newId !== oldId) {
					this.emitNodeRunDefaults(this.draftRunnitNodeRun, newVal);
				}
			},
		},
		draftRunnitNodeRun: {
			immediate: true,
			handler (newVal: RunnitNodeRun, oldVal: RunnitNodeRun) {
				const newId: string = this._get(newVal, 'id') || null;
				const oldId: string = this._get(oldVal, 'id') || null;
				if (newId !== oldId) {
					this.keepDebounceFunctionInFlight();
				}
				if (newId && newId !== oldId) {
					this.emitNodeRunDefaults(newVal, this.node);
				}
			},
		},
		incrementAutoSaveTrigger: {
			immediate: false,
			handler (newVal: number, oldVal: number) {
				if (newVal !== oldVal) {
					this.beginAutoSave(this.inputValues, this.staticInputValues);
				}
			},
		},
		'$route.query.inputValues': {
			immediate: true,
			handler (newVal) {
				if (newVal) {
					this.inputValuesQueryParam = newVal;
					this.emitNodeRunDefaults(null, this.node);
					this.beginAutoSave(this.runnitState.inputValues, this.runnitState.staticInputValues)
				}
			}
		}
	},
	created () {
		this.initDebouncedMethods();
		this.inputValuesQueryParam = this.$route.query.inputValues;
		this.initModelDraft();
	},
	destroyed () {
		this.updateModelState({
			modelDraft: null,
		});
	},
	methods: {
		...mapActions([
			'updateModelState',
		]),
		initDebouncedMethods () {
			if (!this.debounceCreated) {
				this.debounceCreated = true;
				this.debouncedDoSaveDraft = _debounce(this.doSaveDraft, 1000);
				this.debouncedClearQueryParamsInputValues = _debounce(this.clearQueryParamsInputValues, 1000);
			}
		},
		keepDebounceFunctionInFlight () {
			/*
			Resetting this to a fresh _debounce function every time the props to this component changes,
			will keep the last in-flight debounced function call active,
			and won't override it when called for this new RunnitNode.id
			 */
			this.initDebouncedMethods();
		},
		determineFields (node: RunnitNode) {
			return (
				this._get(node, 'fieldsSource') === RUNNIT_NODE_FIELDS_SOURCE.NODE
					? this._get(node, 'fields')
					: this._get(node, 'nodeDef.fields')
			) || [];
		},
		determineStaticFields (node: RunnitNode) {
			return (
				this._get(node, 'staticFieldsSource') === RUNNIT_NODE_STATIC_FIELDS_SOURCE.NODE
					? this._get(node, 'staticFields')
					: this._get(node, 'nodeDef.staticFields')
			) || {};
		},
		beginAutoSave (inputValues: Record<string, RunnitNodeRunInputValue>, staticInputValues: Record<RunnitNodeStaticFieldsKey, any>) {
			if (!this.shouldRenderFields) return;
			this.hasChangesToSave = true;
			this.debouncedDoSaveDraft(
				this.draftRunnitNodeRun,
				this.node,
				inputValues,
				staticInputValues,
			);
		},
		async doSaveDraft (
			draftRunnitNodeRun: RunnitNodeRun,
			node: RunnitNode,
			inputValues: Record<string, RunnitNodeRunInputValue>,
			staticInputValues: Record<RunnitNodeStaticFieldsKey, any>,
		) {
			this.hasChangesToSave = false;

			if (!this.allowSave) return;
			if (!node) return;
			if (
				this.isNodeRunQueuing &&
				draftRunnitNodeRun &&
				this.draftRunnitNodeRun &&
				draftRunnitNodeRun.id === this.draftRunnitNodeRun.id
			) return;
			if (_isEmpty(inputValues) && _isEmpty(staticInputValues)) return;
			if (
				draftRunnitNodeRun &&
				(
					draftRunnitNodeRun.state !== RUNNIT_NODE_RUN_STATE.DRAFT || // not draft state
					(
						_isEqual(draftRunnitNodeRun.inputs, inputValues) && // no diff
						_isEqual(draftRunnitNodeRun.staticInputs, staticInputValues) // no diff
					)
				)
			) return;

			try {
				this.isSavingChanges = true;

				// Value mappings
				const mappedInputValues: Record<string, RunnitNodeRunInputValue> = {};
				const mappedStaticInputValues: Record<RunnitNodeStaticFieldsKey, any> = {};
				_keys(inputValues).forEach((key: string) => {
					mappedInputValues[key] = mapFirebaseTimestampToDate(inputValues[key]);
				});
				_keys(staticInputValues).forEach((key: RunnitNodeStaticFieldsKey) => {
					mappedStaticInputValues[key] = mapFirebaseTimestampToDate(staticInputValues[key]);
				});

				if (draftRunnitNodeRun) {
					await db
						.collection(`runnitNodeRunDrafts`)
						.doc(draftRunnitNodeRun.id)
						.update({
							inputs: mappedInputValues,
							staticInputs: mappedStaticInputValues,
						});
				} else {
					const nowDate: Date = new Date();
					await db
						.collection(`runnitNodeRunDrafts`)
						.add({
							createdAt: nowDate,
							deletedAt: null,
							draftAt: nowDate,
							userId: this.user.id,
							runnitId: node.runnitId,
							nodeId: node.id,
							mode: RUNNIT_NODE_RUN_MODE.MANUAL,
							state: RUNNIT_NODE_RUN_STATE.DRAFT,
							inputs: mappedInputValues,
							staticInputs: mappedStaticInputValues,
						});
				}
			} catch (e) {
				console.error(e);
			} finally {
				this.isSavingChanges = false;
			}
		},
		onFieldInput (field: RunnitNodeField, value: any) {
			if (!field || value === undefined) return;

			const changes: Record<string, RunnitNodeRunInputValue> = {
				...this.inputValues,
				[field.fieldDefUuid]: value,
			};
			this.emitInputValuesChanges(changes);
			this.beginAutoSave(changes, this.staticInputValues);
		},
		emitInputValuesChanges (changes: Record<string, RunnitNodeRunInputValue>) {
			if (_isEmpty(changes)) return;

			this.$emit('on-input-values-input', changes);
		},
		emitStaticInputValuesChanges (changes: Record<RunnitNodeStaticFieldsKey, any>) {
			if (_isEmpty(changes)) return;

			this.$emit('on-static-input-values-input', changes);
		},
		emitNodeRunDefaults (draftRunnitNodeRun: RunnitNodeRun, node: RunnitNode) {
			if (
				!node ||
				(draftRunnitNodeRun && draftRunnitNodeRun.nodeId !== node.id)
			) {
				return;
			}

			const determinedFields: (RunnitNodeFieldGroup | RunnitNodeField)[] = this.determineFields(node);
			const determinedStaticFields: RunnitNodeStaticFields = this.determineStaticFields(node);

			/*
			Default values from input fields
			 */
			const defaultInputValuesMap: Record<string, RunnitNodeRunInputValue> = {};
			const queryParamInputValuesMap: Record<string, RunnitNodeRunInputValue> = {};
			const extractDefaultValuesFromNodeFields: Function = (fields: (RunnitNodeFieldGroup | RunnitNodeField)[]): void => {
				(fields || []).forEach((groupOrField: RunnitNodeFieldGroup | RunnitNodeField) => {
					const group: RunnitNodeFieldGroup = groupOrField as RunnitNodeFieldGroup;
					const field: RunnitNodeField = groupOrField as RunnitNodeField;
					if (group.__rgroup) {
						extractDefaultValuesFromNodeFields(group.fields);
					} else if (field.__rfield) {
						if (this.inputValuesQueryParam) {
							const inputValuesObj = JSON.parse(decodeURIComponent(this.inputValuesQueryParam));
							if (inputValuesObj[field.fieldDefUuid]) {
								queryParamInputValuesMap[field.fieldDefUuid] = inputValuesObj[field.fieldDefUuid];
							}
							defaultInputValuesMap[field.fieldDefUuid] = field.defaultValue;
						} else if (!_isNil(field.defaultValue)) {
							defaultInputValuesMap[field.fieldDefUuid] = field.defaultValue;
						}
					}
				});
			};
			extractDefaultValuesFromNodeFields(determinedFields);
			this.inputValuesQueryParam = null;

			/*
			Default values from static input fields
			 */
			const defaultStaticInputValuesMap: Record<RunnitNodeStaticFieldsKey | string, any> = {};
			_keys(determinedStaticFields).forEach((key: RunnitNodeStaticFieldsKey) => {
				const staticField: any = determinedStaticFields[key];
				if (!_isNil(staticField.defaultValue)) {
					defaultStaticInputValuesMap[key] = staticField.defaultValue;
				}
			});

			this.emitInputValuesChanges(this.convertUndefinedValueToNull({
				...defaultInputValuesMap,
				...(draftRunnitNodeRun && draftRunnitNodeRun.inputs),
				...queryParamInputValuesMap,
			}));
			this.emitStaticInputValuesChanges(this.convertUndefinedValueToNull({
				...defaultStaticInputValuesMap,
				...(draftRunnitNodeRun && draftRunnitNodeRun.staticInputs),
			}));
			this.debouncedClearQueryParamsInputValues();
		},
		convertUndefinedValueToNull (obj) {
			return Object.keys(obj).reduce((o, key) => {
				o[key] = obj[key] === undefined ? null : obj[key]
				return o;
			}, {})
		},
		clearQueryParamsInputValues () {
			// Note: this method is used debounced since switching between tools can cause the drawer to open and close multiple times
			//	which was causing an issue of clearing the form unless we waited until it was fully initialized.
			const newQuery = { ...this.$route.query };
			delete newQuery.inputValues;
			this.routerReplace(this.$route, this.$router, { query: newQuery });
		},
		async initModelDraft() {
			const selectedNodeId = this.runnitState.selectedNodeId ? this.runnitState.selectedNodeId : this.runnitNodes[0]?.id;
			const nodeDef: RunnitNodeDef = (this.runnitNodesMap && selectedNodeId) ? this.runnitNodesMap[selectedNodeId]?.nodeDef : null;

			if (nodeDef?.appType === RUNNIT_NODE_DEF_TOOL_APP_TYPE.TRAINER && !this.modelsState.modelDraft) {
				const trainingTypeTag = Object.keys(nodeDef.tags).find((tagId: string) => this.publicRunnitNodeDefTagsMap[tagId]?.type === RUNNIT_NODE_DEF_TAG_TYPE.TRAINING_TYPE);
				const trainingQualityTag = Object.keys(nodeDef.tags).find((tagId: string) => this.publicRunnitNodeDefTagsMap[tagId]?.type === RUNNIT_NODE_DEF_TAG_TYPE.TRAINING_QUALITY);

				const modelDraft = await this.createModelDraft();
				await this.updateModelState({
					modelDraft: {
						...modelDraft,
						tagTypes: {
							...(modelDraft.tagTypes || {}),
							[RUNNIT_NODE_DEF_TAG_TYPE.TRAINING_TYPE]: true,
							[RUNNIT_NODE_DEF_TAG_TYPE.TRAINING_QUALITY]: true,
						},
						tags: {
							...(modelDraft.tags || {}),
							[trainingTypeTag]: true,
							[trainingQualityTag]: true,
						},
					},
				});
			}
		}
	},
	components: {
		RunnitDynamicField,
		LoadingState,
	},
});
