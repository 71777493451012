
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import { db, storage } from '@/firebase';
import {
	Avatar,
	RunnitUpload,
	UploadFile,
	AVATAR_USE_CASE,
	IMAGE_UPLOAD_MODE,
	UPLOAD_FILE_TYPE,
} from '@run-diffusion/shared';
import { SNACKBAR_STATUS } from '@/constants/constants';
import { ImageUploadMixin } from '@/mixins/ImageUploadMixin';
import { v4 as uuidv4 } from 'uuid';

export default Vue.extend({
	name: 'ImageInput',
	props: {
		mode: {
			type: String,
			required: true,
			validator: (val: string) => Object.keys(IMAGE_UPLOAD_MODE).includes(val),
		},
		avatarUseCase: {
			type: String,
			default: AVATAR_USE_CASE.RUNNIT,
			validator: (val: string) => Object.keys(AVATAR_USE_CASE).includes(val),
		},
		multiple: { type: Boolean, default: false },
		disabled: { type: Boolean, default: false },
		allowTextFiles: { type: Boolean, default: false },
	},
	mixins: [
		ImageUploadMixin,
	],
	data () {

		return {
			FILE_SIZE_LIMIT: 4194304, // 4 MB in bytes

			isUploading: false,
			isBeingDraggedOver: false,
		};
	},
	computed: {
		...mapState([
			'runnitState',
			'user',
		]),
		acceptedFileTypes() {
			return this.allowTextFiles ? 'image/*, text/plain' : 'image/*';
		},
	},
	watch: {
	},
	methods: {
		...mapActions([
			'updateRunnitState',
			'updateSnackbar',
		]),
		onUploadComplete (val: RunnitUpload[]) {
			const filesToEmit = this.multiple ? val : val[0];
			this.$emit('on-upload-complete', filesToEmit);
		},
		handleDragOver (ev) {
			if (this.isUploading || this.disabled) return;
			this.isBeingDraggedOver = true;
			ev.preventDefault();
		},
		handleDragLeave (ev) {
			if (this.isUploading || this.disabled) return;
			this.isBeingDraggedOver = false;
			ev.preventDefault();
		},
		async handleDrop (ev) {
			if (this.isUploading || this.disabled) return;
			this.isBeingDraggedOver = false;
			let dt = ev.dataTransfer;
			let files = dt.files;
			await this.handleFiles(files);
		},
		async onHiddenFileInputChange (ev) {
			if (this.isUploading) return;
			this.isBeingDraggedOver = false;
			await this.handleFiles(ev.target.files);

			// Clear the selected file from the input
			this.$refs.fileInput.value = ''; // Reset the value of the input element
		},
		openFileDialog () {
			if (this.isUploading || this.disabled) return;
			this.$refs.fileInput.click();
		},
		async handleFiles (fileList: FileList) {
			this.isBeingDraggedOver = false;

			const files = Array.from(fileList);
			if (!files || !files.length) return;

			// Size check files
			if (files.some(file => file.size >= this.FILE_SIZE_LIMIT)) {
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: `Image to upload must be less than 4 megabytes`,
					show: true,
				});
				return;
			}

			// Do uploading
			try {
				this.$emit('on-upload-start');
				this.isUploading = true;
				const upload: UploadFile[] = await Promise.all(files.map(file => this.uploadFile(file)));
				this.onUploadComplete(upload);
			} catch (e) {
				console.error(e);
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: `Error uploading image`,
					show: true,
				});
			} finally {
				this.isUploading = false;
				this.$emit('on-upload-end');
			}
		},
		async uploadFile (file) {
			if (this.mode === 'RUNNIT_UPLOAD') {
				return this.handleRunnitUpload(file);
			} else if (this.mode === 'AVATAR') {
				return this.handleAvatar(file);
			}
		},
		async handleRunnitUpload (file) {

			let upload: RunnitUpload = null;

			// Calculate hash of the file data
			const fileData = await file.arrayBuffer();
			const hashBuffer = await crypto.subtle.digest('SHA-256', fileData);
			const hashArray = Array.from(new Uint8Array(hashBuffer));
			const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');

			// Check if hash exists already
			const uploadsRef = db.collection('runnitUploads')
				.where('hashHex', '==', hashHex)
				.where('userId', '==', this.user.id)
				.where('deletedAt', '==', null)
				.limit(1);
			(await uploadsRef.get()).forEach(async (doc: any) => {
				upload = {
					...doc.data(),
					get id () { return doc.id },
				} as RunnitUpload;
			});

			if (!upload) {
				// Upload file
				const { name, contentType, size, width, height } = await this.getImageMetaData(file);
				const nameSplit: string[] = name.split('.');
				const uniqName: string = `${uuidv4()}.${nameSplit[nameSplit.length - 1]}`;
				const fileRef = storage.ref(`runnitUploads/users/${this.user.id}/uploads/${uniqName}`);
				await fileRef.put(file);

				// Record upload file in database
				const nowDate: Date = new Date();
				const addedUploadRef = await db.collection('runnitUploads')
					.add({
						createdAt: nowDate,
						deletedAt: null,
						userId: this.user.id,
						teamId: null,
						type: UPLOAD_FILE_TYPE.IMG,
						name: uniqName,
						contentType,
						size,
						width,
						height,
						hashHex,
					});
				upload = {
					...(await addedUploadRef.get()).data(),
					get id () { return addedUploadRef.id },
				} as RunnitUpload;
			}

			return upload;
		},
		async handleAvatar (file) {
			// NOTE: the firebase size extension will be making this 256x256 and then deleting the originals
			let avatar: Avatar = null;

			// Calculate hash of the file data
			const fileData = await file.arrayBuffer();
			const hashBuffer = await crypto.subtle.digest('SHA-256', fileData);
			const hashArray = Array.from(new Uint8Array(hashBuffer));
			const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');

			// Check if hash exists already
			const avatarsRef = db.collection('avatars')
				.where('hashHex', '==', hashHex)
				.where('userId', '==', this.user.id)
				.where('deletedAt', '==', null)
				.limit(1);
			(await avatarsRef.get()).forEach(async (doc: any) => {
				avatar = {
					...doc.data(),
					get id () { return doc.id },
				} as Avatar;
			});

			if (!avatar) {
				// avatar file
				const { name, contentType, size, width, height } = await this.getImageMetaData(file);
				const nameSplit: string[] = name.split('.');
				const uniqName: string = `${uuidv4()}.${nameSplit[nameSplit.length - 1]}`;
				const fileRef = storage.ref(`avatars/users/${this.user.id}/uploads/${uniqName}`);
				await fileRef.put(file);

				// Record avatar file in database
				const nowDate: Date = new Date();
				const addedAvatarRef = await db.collection('avatars')
					.add({
						createdAt: nowDate,
						deletedAt: null,
						userId: this.user.id,
						teamId: null,
						type: UPLOAD_FILE_TYPE.IMG,
						name: uniqName,
						contentType,
						size,
						width,
						height,
						hashHex,
						useCase: this.avatarUseCase || AVATAR_USE_CASE.RUNNIT,
					});
				avatar = {
					...(await addedAvatarRef.get()).data(),
					get id () { return addedAvatarRef.id },
				} as Avatar;
			}

			return avatar;
		}
	},
	components: {
	},
});
