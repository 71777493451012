"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RUNNIT_NODE_DEF_TOOL_APP_TYPE = exports.MODEL_BASE_NODE_DEF_MAP = exports.MODEL_ARCHITECTURE_CREATE_NODE_DEF_ID_MAP = exports.MODEL_BASE_NODE_DEF_TYPE = exports.TEAM_ROLE = exports.IMAGE_UPLOAD_MODE = exports.RUNNIT_MASK_USE_CASE = exports.AVATAR_USE_CASE = exports.RUNNIT_NODE_DEF_PRICING_TYPE = exports.RUNNIT_NODE_DEF_PROVIDER = exports.RUNNIT_NODE_DEF_TAG_TYPE_ICON = exports.RUNNIT_NODE_DEF_TAG_TYPE_SORT = exports.READABLE_RUNNIT_NODE_DEF_TAG_TYPE = exports.RUNNIT_NODE_DEF_TAG_TYPE = exports.RUNNIT_NODE_DEF_TOOL_TYPE = exports.RUNNIT_NODE_STATIC_FIELDS_KEY = exports.RUNNIT_NODE_RUN_MODE = exports.RUNNIT_NODE_RUN_STATE = exports.RUNNIT_NODE_RUN_RESULT_TYPE = exports.UPLOAD_FILE_TYPE = exports.RUNNIT_NODE_STATIC_FIELDS_SOURCE = exports.RUNNIT_NODE_FIELDS_SOURCE = exports.RUNNIT_NODE_DEF_FIELD_MAP_BUILDER_VALUE_TYPE = exports.RUNNIT_NODE_DEF_FIELD_MAP_TYPE = exports.RUNNIT_NODE_FIELD_TYPE = exports.RUNNIT_TYPE = exports.RUNNITS_ACCESS_LEVEL = exports.MODEL_ARCHITECTURE = exports.MODEL_TYPE = exports.MODEL_CREATE_METHOD = exports.SURVEY_KEY = exports.WORKSHOP_SESSION_QUEUE_ITEM_ADMISSION = exports.APP_OFFER_RESOURCE_TYPE = exports.APP_OFFER_TYPE = exports.RELEASE_STATUS = exports.STORAGE_OPTION = exports.ANNOUNCEMENT_TYPE = exports.SESSION_BACKGROUND_TASK_STATUS = exports.SESSION_BACKGROUND_TASK_TYPE = exports.STOP_CODE = exports.SESSION_TYPE = exports.SESSION_STATE = exports.SOFTWARE_KEY = exports.HARDWARE_KEY = exports.APP_KEY = exports.EXTENSION_OFFER_TYPE = exports.CLUB_KEY = void 0;
exports.CLUB_KEY = {
    CC_001: 'CC-001',
    CC_002: 'CC-002',
    FREE_001: 'FREE-001',
    RUNHOB_001: 'RUNHOB-001',
    RUNPRO_001: 'RUNPRO-001',
};
exports.EXTENSION_OFFER_TYPE = {
    TEXT: 'TEXT',
    TOGGLE: 'TOGGLE',
};
exports.APP_KEY = {
    FILE_001: 'FILE-001',
    AUTO_001: 'AUTO-001',
    INVK_001: 'INVK-001',
    VLAD_001: 'VLAD-001',
    CMFY_001: 'CMFY-001',
    LINX_001: 'LINX-001',
    ACFT_001: 'ACFT-001',
    ADUI_001: 'ADUI-001',
    EZSD_001: 'EZSD-001',
    FCUS_001: 'FCUS-001',
    FCUS_API_001: 'FCUS-API-001',
    KOYA_001: 'KOYA-001',
    OOBA_001: 'OOBA-001',
    MKYO_001: 'MKYO-001',
    RTMY_001: 'RTMY-001',
    ENFG_001: 'ENFG-001',
    MANI_001: 'MANI-001',
};
exports.HARDWARE_KEY = {
    CPU_001: 'CPU-001',
    SM_001: 'SM-001',
    MD_001: 'MD-001',
    LG_001: 'LG-001',
    LG_002: 'LG-002',
    XL_001: 'XL-001',
};
exports.SOFTWARE_KEY = {
    SD1_001: 'SD1-001',
    SD2_001: 'SD2-001',
    SDXL_001: 'SDXL-001',
    SDALL_001: 'SDALL-001',
    DB_001: 'DB-001',
};
exports.SESSION_STATE = {
    PURCHASE: 'PURCHASE',
    INIT: 'INIT',
    BOOT: 'BOOT',
    READY: 'READY',
    EXTEND: 'EXTEND',
    STOP: 'STOP',
    RECONCILE: 'RECONCILE',
    STOPPED: 'STOPPED',
};
exports.SESSION_TYPE = {
    RD: 'RD',
    SBUD_API: 'SBUD_API',
    INT_API_WORKER: 'INT_API_WORKER',
};
exports.STOP_CODE = {
    MANUAL: 'MANUAL',
    SCHEDULED: 'SCHEDULED',
    FAILED_PURCHASE: 'FAILED_PURCHASE',
    FAILED_INIT: 'FAILED_INIT',
    FAILED_STOP: 'FAILED_STOP',
};
exports.SESSION_BACKGROUND_TASK_TYPE = {
    DOWNLOAD_MODEL: 'DOWNLOAD_MODEL',
    ALERT: 'ALERT',
};
exports.SESSION_BACKGROUND_TASK_STATUS = {
    IN_PROGRESS: 'IN_PROGRESS',
    DONE: 'DONE',
    ERROR: 'ERROR',
    INFO: 'INFO',
    WARNING: 'WARNING',
    DEBUG: 'DEBUG',
};
exports.ANNOUNCEMENT_TYPE = {
    ALERT: 'ALERT',
    WARNING: 'WARNING',
    INFO: 'INFO',
    NEWS: 'NEWS',
    NO_NEW_SESSIONS: 'NO_NEW_SESSIONS',
};
exports.STORAGE_OPTION = {
    PUBLIC: 'PUBLIC',
    PRIVATE: 'PRIVATE',
};
exports.RELEASE_STATUS = {
    OLD: 'OLD',
    CURRENT: 'CURRENT',
    BETA: 'BETA',
};
exports.APP_OFFER_TYPE = {
    IMAGE_GEN: 'IMAGE_GEN',
    IMAGE_TRAIN: 'IMAGE_TRAIN',
    AUDIO: 'AUDIO',
    FILE: 'FILE',
    LLM: 'LLM',
};
exports.APP_OFFER_RESOURCE_TYPE = {
    YOUTUBE: 'YOUTUBE',
    LINK: 'LINK',
};
exports.WORKSHOP_SESSION_QUEUE_ITEM_ADMISSION = {
    INIT: 'INIT',
    ACCEPT: 'ACCEPT',
};
exports.SURVEY_KEY = {
    NEW_USER_1: 'NEW_USER_1',
    USER_USAGE_1: 'USER_USAGE_1',
    NEW_TEAM_1: 'NEW_TEAM_1',
};
exports.MODEL_CREATE_METHOD = {
    UPLOAD: 'UPLOAD',
    RUNNIT: 'RUNNIT',
};
exports.MODEL_TYPE = {
    CHECKPOINT: 'CHECKPOINT',
    LORA: 'LORA',
};
exports.MODEL_ARCHITECTURE = {
    FLUX: 'FLUX',
    SDXL: 'SDXL',
};
exports.RUNNITS_ACCESS_LEVEL = {
    PRIVATE: 'PRIVATE',
    SHARED: 'SHARED',
};
exports.RUNNIT_TYPE = {
    WORKFLOW: 'WORKFLOW',
    SINGLE_TOOL: 'SINGLE_TOOL',
};
exports.RUNNIT_NODE_FIELD_TYPE = {
    HEADER: 'HEADER',
    HTML: 'HTML',
    TEXT_AREA: 'TEXT_AREA',
    TEXT: 'TEXT',
    NUM: 'NUM',
    BOOLEAN: 'BOOLEAN',
    SINGLE_SELECT: 'SINGLE_SELECT',
    MULTI_SELECT: 'MULTI_SELECT',
    MODEL_SINGLE_SELECT: 'MODEL_SINGLE_SELECT',
    PROMPT: 'PROMPT',
    NEG_PROMPT: 'NEG_PROMPT',
    CFG: 'CFG',
    WIDTH_HEIGHT: 'WIDTH_HEIGHT',
    IMG: 'IMG',
    STEPS: 'STEPS',
    SEED: 'SEED',
    SLIDER: 'SLIDER',
    IMGS: 'IMGS',
};
exports.RUNNIT_NODE_DEF_FIELD_MAP_TYPE = {
    JSON: 'JSON',
    NUM: 'NUM',
    STR: 'STR',
    BOOL: 'BOOL',
    IMGS: 'IMGS',
    IMG: 'IMG',
    IMG_MASK: 'IMG_MASK',
    MODEL: 'MODEL',
};
exports.RUNNIT_NODE_DEF_FIELD_MAP_BUILDER_VALUE_TYPE = {
    STR_MUSTACHE: 'STR_MUSTACHE',
};
exports.RUNNIT_NODE_FIELDS_SOURCE = {
    DEF: 'DEF',
    NODE: 'NODE',
};
exports.RUNNIT_NODE_STATIC_FIELDS_SOURCE = {
    DEF: 'DEF',
    NODE: 'NODE',
};
exports.UPLOAD_FILE_TYPE = {
    IMG: 'IMG',
};
exports.RUNNIT_NODE_RUN_RESULT_TYPE = {
    IMG: 'IMG',
    VID: 'VID',
    MODEL: 'MODEL',
    TEXT: 'TEXT',
};
exports.RUNNIT_NODE_RUN_STATE = {
    DRAFT: 'DRAFT',
    INIT: 'INIT',
    NSFW: 'NSFW',
    QUEUED: 'QUEUED',
    ERROR: 'ERROR',
    DONE: 'DONE',
};
exports.RUNNIT_NODE_RUN_MODE = {
    MANUAL: 'MANUAL',
};
exports.RUNNIT_NODE_STATIC_FIELDS_KEY = {
    numResults: 'numResults',
};
exports.RUNNIT_NODE_DEF_TOOL_TYPE = {
    CORE: 'CORE',
    USER_CREATED: 'USER_CREATED',
    TEAM: 'TEAM',
};
exports.RUNNIT_NODE_DEF_TAG_TYPE = {
    TEAM: 'TEAM',
    HOME_PAGE: 'HOME_PAGE',
    INDUSTRY: 'INDUSTRY',
    USE_CASE: 'USE_CASE',
    MEDIA: 'MEDIA',
    ARCHITECTURE: 'ARCHITECTURE',
    TRAINING_TYPE: 'TRAINING_TYPE',
    TRAINING_QUALITY: 'TRAINING_QUALITY',
};
exports.READABLE_RUNNIT_NODE_DEF_TAG_TYPE = {
    TEAM: 'Team',
    HOME_PAGE: 'Home Page',
    INDUSTRY: 'Industry',
    USE_CASE: 'Use Case',
    MEDIA: 'Media',
    ARCHITECTURE: 'Model',
    TRAINING_TYPE: 'Training Type',
    TRAINING_QUALITY: 'Training Quality',
};
exports.RUNNIT_NODE_DEF_TAG_TYPE_SORT = {
    TEAM: 0,
    HOME_PAGE: 1,
    INDUSTRY: 2,
    USE_CASE: 3,
    MEDIA: 4,
    ARCHITECTURE: 5,
    TRAINING_TYPE: 6,
    TRAINING_QUALITY: 7,
};
exports.RUNNIT_NODE_DEF_TAG_TYPE_ICON = {
    TEAM: 'mdi-account-multiple-outline',
    HOME_PAGE: 'mdi-home-outline',
    INDUSTRY: 'mdi-domain',
    USE_CASE: 'mdi-folder-table-outline',
    MEDIA: 'mdi-multimedia',
    ARCHITECTURE: 'mdi-hammer-wrench',
    TRAINING_TYPE: 'mdi-group',
    TRAINING_QUALITY: 'mdi-group',
};
exports.RUNNIT_NODE_DEF_PROVIDER = {
    RUNNIT_IMG: 'RUNNIT_IMG',
    RD: 'RD',
    OCTOAI: 'OCTOAI',
    RUNPOD: 'RUNPOD',
    IDEOGRAM: 'IDEOGRAM',
    FALAI: 'FALAI',
    NICHETENSOR: 'NICHETENSOR',
    RUNWAY: 'RUNWAY',
    LUMAAI: 'LUMAAI',
    RUNWARE: 'RUNWARE',
};
exports.RUNNIT_NODE_DEF_PRICING_TYPE = {
    COST: 'COST',
    INFERENCE: 'INFERENCE',
};
exports.AVATAR_USE_CASE = {
    RUNNIT: 'RUNNIT',
    RUNNIT_LIB: 'RUNNIT_LIB',
    RUNNIT_NODE_DEF: 'RUNNIT_NODE_DEF',
    MODEL: 'MODEL',
};
exports.RUNNIT_MASK_USE_CASE = {
    INPAINTING: 'INPAINTING',
};
exports.IMAGE_UPLOAD_MODE = {
    RUNNIT_UPLOAD: 'RUNNIT_UPLOAD',
    AVATAR: 'AVATAR',
    MODEL: 'MODEL',
};
exports.TEAM_ROLE = {
    ADMIN: 'ADMIN',
    CREATOR: 'CREATOR',
};
exports.MODEL_BASE_NODE_DEF_TYPE = {
    FLUX_BASE: 'FLUX_BASE',
    FLUX_FILL: 'FLUX_FILL',
    SDXL_BASE: 'SDXL_BASE',
};
exports.MODEL_ARCHITECTURE_CREATE_NODE_DEF_ID_MAP = {
    FLUX_BASE: 'k6uTFLWZ5OBxh7ZGfy4E',
    FLUX_FILL: 'Zo7U5EEzIX7zodh7bwPL',
    SDXL_BASE: 'TowPq1VRuRzLxct4GRNJ',
};
exports.MODEL_BASE_NODE_DEF_MAP = {
    FLUX: [
        exports.MODEL_BASE_NODE_DEF_TYPE.FLUX_BASE,
        exports.MODEL_BASE_NODE_DEF_TYPE.FLUX_FILL,
    ],
    SDXL: [
        exports.MODEL_BASE_NODE_DEF_TYPE.SDXL_BASE,
    ],
};
exports.RUNNIT_NODE_DEF_TOOL_APP_TYPE = {
    RUNNIT: 'RUNNIT',
    TRAINER: 'TRAINER',
};
