<template>
	<div>
		<svg
			v-if="$vuetify.breakpoint.smAndUp"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 1200 160"
		>
			<defs>
				<!-- Create a clip path for the rounded corners -->
				<clipPath id="rounded-corners">
					<rect width="1200" height="160" rx="12" />
				</clipPath>

				<!-- Grid pattern -->
				<pattern id="grid" width="40" height="40" patternUnits="userSpaceOnUse">
					<path d="M 40 0 L 0 0 0 40" fill="none" stroke="#333" stroke-width="0.5"/>
				</pattern>
			</defs>

			<!-- Main container with clipping -->
			<g clip-path="url(#rounded-corners)">
				<!-- Background -->
				<rect width="1200" height="160" fill="#1a1a1a" />

				<!-- Grid overlay -->
				<rect width="1200" height="160" fill="url(#grid)" />

				<!-- Accent shapes -->
				<rect x="800" y="0" width="400" height="160" fill="rgb(160, 170, 236)" opacity="0.1" />
				<circle cx="900" cy="80" r="100" fill="rgb(160, 170, 236)" opacity="0.05" />
			</g>

			<!-- Content -->
			<text x="60" y="65" fill="white" font-family="Arial" font-size="28" font-weight="bold">RunDiffusion Master Classes</text>
			<text x="60" y="95" fill="#bbb" font-family="Arial" font-size="16">Join the Next Generation of AI Artists</text>

			<!-- Features with consistent spacing -->
			<g>
				<!-- First feature -->
				<circle cx="60" cy="125" r="3" fill="rgb(160, 170, 236)"/>
				<text x="75" y="130" fill="#fff" font-family="Arial" font-size="14">1 free month Creator's Club</text>
			</g>

			<g>
				<!-- Second feature -->
				<circle cx="275" cy="125" r="3" fill="rgb(160, 170, 236)"/>
				<text x="290" y="130" fill="#fff" font-family="Arial" font-size="14">Try 1st module free</text>
			</g>

			<g>
				<!-- Third feature -->
				<circle cx="440" cy="125" r="3" fill="rgb(160, 170, 236)"/>
				<text x="455" y="130" fill="#fff" font-family="Arial" font-size="14">Hands-on Challenges</text>
			</g>

			<!-- CTA with hover effect -->
			<g class="cta-button" @click="handleClick">
				<rect x="950" y="55" width="180" height="50" rx="25" fill="rgb(160, 170, 236)" />
				<text x="1040" y="85" fill="#1b1b1b" font-family="Arial" font-size="16" text-anchor="middle" font-weight="bold">Try Course</text>
			</g>
		</svg>
		<svg
			v-else
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 400 200"
		>
			<defs>
				<!-- Create a clip path for the rounded corners -->
				<clipPath id="rounded-corners-mobile">
					<rect width="400" height="200" rx="12" />
				</clipPath>

				<!-- Grid pattern -->
				<pattern id="grid-mobile" width="30" height="30" patternUnits="userSpaceOnUse">
					<path d="M 30 0 L 0 0 0 30" fill="none" stroke="#333" stroke-width="0.5"/>
				</pattern>
			</defs>

			<!-- Main container with clipping -->
			<g clip-path="url(#rounded-corners-mobile)">
				<!-- Background -->
				<rect width="400" height="200" fill="#1a1a1a" />

				<!-- Grid overlay -->
				<rect width="400" height="200" fill="url(#grid-mobile)" />

				<!-- Accent shapes -->
				<rect x="0" y="140" width="400" height="60" fill="rgb(160, 170, 236)" opacity="0.1" />
				<circle cx="350" cy="50" r="80" fill="rgb(160, 170, 236)" opacity="0.05" />
			</g>

			<!-- Content -->
			<text x="30" y="45" fill="white" font-family="Arial" font-size="20" font-weight="bold">RunDiffusion</text>
			<text x="30" y="70" fill="white" font-family="Arial" font-size="20" font-weight="bold">Master Classes</text>
			<text x="30" y="95" fill="#bbb" font-family="Arial" font-size="14">Join the Next Generation of AI Artists</text>

			<!-- Features as a vertical stack -->
			<circle cx="30" cy="120" r="3" fill="rgb(160, 170, 236)"/>
			<text x="40" y="125" fill="#fff" font-family="Arial" font-size="12">1 free month Creator's Club</text>

			<circle cx="30" cy="140" r="3" fill="rgb(160, 170, 236)"/>
			<text x="40" y="145" fill="#fff" font-family="Arial" font-size="12">Try 1st module free</text>

			<circle cx="30" cy="160" r="3" fill="rgb(160, 170, 236)"/>
			<text x="40" y="165" fill="#fff" font-family="Arial" font-size="12">Hands-on Challenges</text>

			<!-- CTA -->
			<g class="cta-button" @click="handleClick">
				<rect x="210" y="130" width="160" height="45" rx="22.5" fill="rgb(160, 170, 236)" />
				<text x="290" y="158" fill="#1b1b1b" font-family="Arial" font-size="14" text-anchor="middle" font-weight="bold">Try Course</text>
			</g>
		</svg>
	</div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
	name: 'MasterClassesPromoBanner',
	methods: {
		handleClick () {
			window.open('https://masterclass.rundiffusion.com/', '_blank', 'noopener,noreferrer');
		},
	},
	components: {
	},
});
</script>

<style scoped lang="scss">
	.cta-button {
		cursor: pointer;
		transition: opacity 0.2s ease;
	}
	.cta-button:hover {
		opacity: 0.8;
	}
</style>
