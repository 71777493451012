import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"runnit-shared-page full-height",style:({
		padding: _vm.$vuetify.breakpoint.xsOnly ? '0 15px' : '0 25px',
	})},[_vm._m(0),(_vm.loadingRunnits)?_c('div',{staticClass:"full-width d-flex align-center justify-center"},[_c('loading-state')],1):_c('div',{staticClass:"runnits-container"},[_c('div',{class:{
				'full-width': _vm.$vuetify.breakpoint.xsOnly,
			}},[_c(VTooltip,{attrs:{"top":"","open-delay":"500","nudge-top":-80},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('div',_vm._g({staticClass:"add-another-runnit",class:{
							'mb-7': !!_vm.runnits.length,
							'mr-7': _vm.$vuetify.breakpoint.smAndUp && !!_vm.runnits.length,
						},style:({
							width: _vm.$vuetify.breakpoint.xsOnly ? '100%' : '300px',
						}),on:{"click":_vm.onAddRunnitClick}},on),[_c(VIcon,{staticClass:"add-another-runnit-icon",staticStyle:{"font-size":"55px"}},[_vm._v("mdi mdi-plus-circle-multiple")])],1)]}}])},[_c('div',{staticClass:"tooltip-max-width"},[_vm._v(" Add a new Runnit Board ")])])],1),_vm._l((_vm.runnits),function(runnit){return [_c('offering-card',{key:runnit.id,staticClass:"mb-7",class:{
					'mr-7': _vm.$vuetify.breakpoint.smAndUp,
				},attrs:{"select-icon":"mdi-open-in-new","select-text":"Open","show-edit-btn":"","edit-text":"Configure settings or delete Runnit Board","disabled":!_vm.runnitsLimitMap[runnit.id],"disabled-text":"Limit reached!","disabled-cta-text":"Learn More","disabled-cta-props":{ secondaryPrimary: true },"title":runnit.title,"avatar":runnit.avatar,"description":runnit.description,"tool-type":_vm.RUNNIT_NODE_DEF_TOOL_TYPE.USER_CREATED},on:{"click":function($event){return _vm.onOfferingCardClick(runnit.id, null)},"disabled-cta-click":function($event){return _vm.setUpsellDialog({ runnitsIsAtNumRunnitsLimit: true })},"edit-click":function($event){return _vm.onEditRunnitSettings(runnit)}}})]})],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"runnit-page-title"},[_c('span',{staticClass:"gradient-text"},[_vm._v("Shared")]),_vm._v(" Runnit Boards ")])
}]

export { render, staticRenderFns }