
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import { MyBalanceMixin, TeamUserMixin } from '@/mixins';
import ListItem from '@/components/base/ListItem.vue';
import PaymentsHistoryDialog from '@/components/Toolbar/UserAccount/PaymentsHistoryDialog.vue';
import { ROUTER } from '@/router/constants';
import TokensSVG from '@/assets/TokensSVG.vue';
import moment, { Moment } from 'moment-timezone';
import pluralize from 'pluralize';
import {
	hundredthsToTokens,
	tokensToHundredths,
	centsToDollars,
	dollarsToCents,
	LIFE_SUPPORT_TOKENS_PACKAGE_AMOUNT,
	calcRdBalanceToRunnitTokens,
	calcRunnitTokensToRdBalance,
} from '@run-diffusion/shared';
import AddBalanceFundsButton from '@/components/AddBalanceFundsButton.vue';
import UnlockRunnitsFreemium from '@/components/UnlockRunnitsFreemium.vue';
import ConfirmDialog from '@/components/base/ConfirmDialog.vue';
import GetSupportBtn from '@/components/Toolbar/GetSupportBtn/GetSupportBtn.vue';
import TeamPicker from '@/components/TeamPicker.vue';
import BaseSlider from '@/components/base/BaseSlider.vue';
import BaseToggleButton from '@/components/base/BaseToggleButton.vue';
import { SNACKBAR_STATUS } from '@/constants/constants';
import { functions } from '@/firebase';

export default Vue.extend({
	name: 'MyRunnitsPlanDetailsDialog',
	mixins: [
		MyBalanceMixin,
		TeamUserMixin,
	],
	props: {
		value: { type: Boolean, default: false },
		scrollToPrivateBalance: { type: Boolean, default: false },
		scrollToTeamBalance: { type: Boolean, default: false },
	},
	data () {
		return {
			LIFE_SUPPORT_TOKENS_PACKAGE_AMOUNT,
			paymentsHistoryDialogOpen: false,
			successDialogConfig: {
				open: false,
				invalidatedOtherUsers: false,
			},
			successDialogTitle: 'Your account has been activated!',
			convertTeamTokensDialogConfig: {
				open: false,
				submittingConvertTeamTokens: false,
				conversionMode: false,
				runnitTokensSliderVal: 0,
				runnitTokensDiffPreview: 0,
				rdBalanceSliderVal: 0,
				rdBalanceDiffPreview: 0,
			},
		};
	},
	computed: {
		...mapState([
			'realTimeMoment',
			'team',
			'user',
			'userBalanceAccount',
			'teamBalanceAccount',
		]),
		isOnPaidPlanButTemporarilyOnDailyRewardSchedule () {
			return !!(
				!this.user.clubOffer.isFreemium &&
				this.userBalanceAccountRunnitTokensData.combinedTokens <= this.userBalanceAccountRunnitTokensData.dailyFreeRunnitTokensAllotment
			);
		},
		freeTokensTopUpAtShortText () {
			if (!this.userBalanceAccount || !this.userBalanceAccount.freeRunnitTokensTopUpAt) return 'every night around midnight';
			return moment(this.userBalanceAccount.freeRunnitTokensTopUpAt.toMillis())
				.endOf('hour')
				.add(1, 'minutes')
				.format('[on] MMM D, YYYY [at approximately] hh a');
		},
		freeTokensTopUpBenefitDisplayHtml () {
			if (!this.userBalanceAccount || !this.userBalanceAccount.freeRunnitTokensTopUpAt) return null;
			const topUpMoment: Moment = moment(this.userBalanceAccount.freeRunnitTokensTopUpAt.toMillis());

			const diffHours: number = Math.max(topUpMoment.diff(this.realTimeMoment, 'hours'), 0);
			if (diffHours < 1) {
				return '<b>Next reward</b> top-up will happen within the next hour, please be patient';
			}

			return `<b>Next reward</b> top-up will happen in about ${topUpMoment.fromNow(true)}`;
		},
		paidTokensTopUpBenefitDisplayHtml () {
			if (!this.user || !this.user.monthlyBenefitsRenewAt) return null;
			const topUpMoment: Moment = moment(this.user.monthlyBenefitsRenewAt.toMillis());

			const diffHours: number = Math.max(topUpMoment.diff(this.realTimeMoment, 'hours'), 0);
			if (diffHours < 1) {
				return '<b>Next reward</b> top-up will happen within the next hour, please be patient';
			}

			const diffDays: number = Math.max(topUpMoment.diff(this.realTimeMoment, 'days'), 0);
			if (diffDays < 1) {
				if (this.realTimeMoment.isSameOrAfter(topUpMoment, 'day')) {
					return `<b>Next reward</b> top-up will happen today (in about ${diffHours} ${pluralize('hour', diffHours)})`;
				}
				return `<b>Next reward</b> top-up will happen on ${topUpMoment.format('MMM D, YYYY')} (in about ${diffHours} ${pluralize('hour', diffHours)})`;
			}

			return `<b>Next reward</b> top-up will happen on ${topUpMoment.format('MMM D, YYYY')} (in ${diffDays} ${pluralize('day', diffDays)})`;
		},
	},
	watch: {
		value: {
			immediate: true,
			handler (newVal: boolean, oldVal: boolean) {
				if (newVal && newVal !== oldVal) {
					if (this.scrollToPrivateBalance) {
						setTimeout(() => {
							this.$scrollTo('#private-runnit-balance-details-scroll-anchor', 800, {
								container: '#runnit-balance-details-dialog-scroll-container',
							});
						}, 100);
					} else if (this.scrollToTeamBalance) {
						setTimeout(() => {
							this.$scrollTo('#team-runnit-balance-details-scroll-anchor', 800, {
								container: '#runnit-balance-details-dialog-scroll-container',
								offset: -15,
							});
						}, 100);
					}
				}
			},
		},
	},
	methods: {
		calcRdBalanceToRunnitTokens,
		calcRunnitTokensToRdBalance,
		hundredthsToTokens,
		tokensToHundredths,
		centsToDollars,
		dollarsToCents,
		...mapActions([
			'updateSnackbar',
		]),
		onInput (val: boolean) {
			this.$emit('input', !!val);
		},
		onUpgrade () {
			this.routerPush(this.$route, this.$router, { name: ROUTER.RUNNITS_SUBSCRIPTIONS });
			this.onInput(false);
		},
		setSuccessDialogConfig (open: boolean, invalidatedOtherUsers: boolean) {
			this.successDialogConfig = {
				open: !!open,
				invalidatedOtherUsers: !!invalidatedOtherUsers,
			};
		},
		onUnlockRunnitsFreemiumSuccess (invalidatedOtherUsers: boolean) {
			this.setSuccessDialogConfig(true, invalidatedOtherUsers);
		},
		onRdBalanceSliderValInput (val: number) {
			const valInCents: number = dollarsToCents(val);
			const rdBalanceDelta: number = Math.max(valInCents - this.teamBalance, 0);
			const runnitTokensSliderVal: number = this.teamBalanceAccountRunnitTokensData.runnitTokens - calcRdBalanceToRunnitTokens(rdBalanceDelta);

			this.setConvertTeamTokensDialogConfig({
				runnitTokensSliderVal: runnitTokensSliderVal,
				rdBalanceSliderVal: valInCents,
			});
		},
		onRunnitTokensSliderValInput (val: number) {
			const valInHundredths: number = tokensToHundredths(val);
			const runnitTokensDelta: number = Math.max(valInHundredths - this.teamBalanceAccountRunnitTokensData.runnitTokens, 0);
			const rdBalanceSliderVal: number = this.teamBalance - calcRunnitTokensToRdBalance(runnitTokensDelta);

			this.setConvertTeamTokensDialogConfig({
				runnitTokensSliderVal: valInHundredths,
				rdBalanceSliderVal: rdBalanceSliderVal,
			});
		},
		onConvertTeamTokensClick () {
			this.setConvertTeamTokensDialogConfig({
				open: true,
				conversionMode: false,
				runnitTokensSliderVal: this.teamBalanceAccountRunnitTokensData.runnitTokens,
				rdBalanceSliderVal: this.teamBalance,
			});
		},
		async onSubmitConvertTeamTokens (closeAndResetCallback: Function) {
			const tokensDiff: number = Math.floor(this.convertTeamTokensDialogConfig.runnitTokensSliderVal - this.teamBalanceAccountRunnitTokensData.runnitTokens);
			if (
				!this.isTeamAdmin ||
				this.convertTeamTokensDialogConfig.submittingConvertTeamTokens ||
				!tokensDiff
			) {
				return;
			}

			const onError: Function = (e) => {
				console.error('Error submitting your balance conversion: ', e);
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: 'Error submitting your balance conversion, please contact support of issue persists',
					show: true,
				});
			}
			this.setConvertTeamTokensDialogConfig({ submittingConvertTeamTokens: true });
			try {
				const functionRef = functions
					.httpsCallable('convertBalanceToRunnitTokens');
				const { success } = (await functionRef({
					teamId: this.team.id,
					tokensDiff,
				})).data;
				if (!success) {
					onError(new Error('convertBalanceToRunnitTokens returned unsuccessful response'));
				} else {
					this.updateSnackbar({
						status: SNACKBAR_STATUS.SUCCESS,
						message: `Success! Balance conversion submitted`,
						show: true,
					});
				}
			} catch (e) {
				onError(e);
			} finally {
				this.setConvertTeamTokensDialogConfig({ submittingConvertTeamTokens: false });
				if (closeAndResetCallback) {
					closeAndResetCallback();
				}
			}
		},
		setConvertTeamTokensDialogConfig (changes: any) {
			this.convertTeamTokensDialogConfig = {
				open: this.convertTeamTokensDialogConfig.open,
				submittingConvertTeamTokens: this.convertTeamTokensDialogConfig.submittingConvertTeamTokens,
				conversionMode: this.convertTeamTokensDialogConfig.conversionMode,
				runnitTokensSliderVal: this.convertTeamTokensDialogConfig.runnitTokensSliderVal,
				rdBalanceSliderVal: this.convertTeamTokensDialogConfig.rdBalanceSliderVal,
				...changes,
			};
			this.convertTeamTokensDialogConfig = {
				...this.convertTeamTokensDialogConfig,
				runnitTokensDiffPreview: this.convertTeamTokensDialogConfig.runnitTokensSliderVal - this.teamBalanceAccountRunnitTokensData.runnitTokens, // computed values
				rdBalanceDiffPreview: this.convertTeamTokensDialogConfig.rdBalanceSliderVal - this.teamBalance, // computed values
			};
		},
		onConvertTeamTokensDialogTokensModeInput (conversionMode: boolean) {
			this.setConvertTeamTokensDialogConfig({
				conversionMode: !!conversionMode,
				runnitTokensSliderVal: conversionMode !== this.convertTeamTokensDialogConfig.conversionMode
					? this.teamBalanceAccountRunnitTokensData.runnitTokens
					: this.convertTeamTokensDialogConfig.runnitTokensSliderVal,
				rdBalanceSliderVal: conversionMode !== this.convertTeamTokensDialogConfig.conversionMode
					? this.teamBalance
					: this.convertTeamTokensDialogConfig.rdBalanceSliderVal,
			});
		},
	},
	components: {
		BaseToggleButton,
		BaseSlider,
		TeamPicker,
		GetSupportBtn,
		ConfirmDialog,
		UnlockRunnitsFreemium,
		AddBalanceFundsButton,
		TokensSVG,
		PaymentsHistoryDialog,
		ListItem,
	},
});
