
import Vue from 'vue';
import { mapState } from 'vuex';
import {
	RunnitsCRUDMixin,
} from '@/mixins';
import { RUNNIT_NODE_DEF_TOOL_APP_TYPE, RunnitNodeDef } from '@run-diffusion/shared';
import { RUNNITS_OWNER_SELECTION } from '@/views/Runnits/constants';
import OfferingCard from '@/components/OfferingCard.vue';
import { UserRunnitNodeDefInfo, RUNNIT_NODE_DEF_TAG_TYPE_SORT, RUNNIT_TYPE } from '@run-diffusion/shared';
import _isEmpty from 'lodash/isEmpty';
import _sortBy from 'lodash/sortBy';
import RunnitCarousel from './RunnitCarousel.vue';
import { ROUTER } from '@/router/constants';

export default Vue.extend({
	name: 'RunnitToolsGallery',
	mixins: [
		RunnitsCRUDMixin,
	],
	props: {},
	data () {
		return {
			RUNNITS_OWNER_SELECTION,
			RUNNIT_TYPE,

			publicToolsByTagMap: {},
			teamToolsByTagMap: {},
		};
	},
	watch: {
		publicRunnitNodeDefs: {
			immediate: true,
			handler (newVal: RunnitNodeDef[], oldVal: RunnitNodeDef[]) {
				this.mapToolsToTag((newVal || []).filter((runnitNodeDef: RunnitNodeDef) => !!runnitNodeDef.publishedAt), 'public');
			}
		},
		teamRunnitNodeDefs: {
			immediate: true,
			handler (newVal: RunnitNodeDef[], oldVal: RunnitNodeDef[]) {
				this.mapToolsToTag((newVal || []).filter((runnitNodeDef: RunnitNodeDef) => !!runnitNodeDef.publishedAt), 'team');
			}
		},
	},
	computed: {
		...mapState([
			'runnitState',
			'teamRunnitNodeDefs',
			'publicRunnitNodeDefs',
			'recentUserRunnitNodeDefInfos',
			'team',
			'publicRunnitNodeDefTagsMap',
			'teamRunnitNodeDefTagsMap',
		]),
		visibleRunnitNodeDefInfos () {
			return this.recentUserRunnitNodeDefInfos.filter((toolInfo: UserRunnitNodeDefInfo) => !toolInfo.nodeDef.isDeleted &&
				(
					this.user &&
					toolInfo.nodeDef.appType === RUNNIT_NODE_DEF_TOOL_APP_TYPE.RUNNIT &&
					(
						this.user.isAdmin ||
						toolInfo.nodeDef.isPublished
					)) &&
				(
					!toolInfo.nodeDef.teamIds || // No team means it's available to anyone
					(
						this.runnitState.runnitsOwnerSelection === RUNNITS_OWNER_SELECTION.TEAM &&
						this.team &&
						toolInfo.nodeDef.teamIds[this.team.id]
					))
			).slice(0, 5);
		},
		sortedTeamTagType () {
			return _sortBy(Object.keys(this.teamToolsByTagMap), [
				(tagId) => { return RUNNIT_NODE_DEF_TAG_TYPE_SORT[this._get(this.tagsById, `[${tagId}].type`)] },
				(tagId) => { return this._get(this.tagsById, `[${tagId}].sortOrder`) },
				(tagId) => { return this._get(this.tagsById, `[${tagId}].label`) },
			]);
		},
		sortedPublicTagType () {
			return _sortBy(Object.keys(this.publicToolsByTagMap), [
				(tagId) => { return RUNNIT_NODE_DEF_TAG_TYPE_SORT[this._get(this.tagsById, `[${tagId}].type`)] },
				(tagId) => { return this._get(this.tagsById, `[${tagId}].sortOrder`) },
				(tagId) => { return this._get(this.tagsById, `[${tagId}].label`) },
			]);
		},
		tagsById () {
			return {
				'uncategorized': { label: 'Uncategorized', id: 'uncategorized', type: 'uncategorized' },
				...this.publicRunnitNodeDefTagsMap,
				...this.teamRunnitNodeDefTagsMap,
			}
		},
	},
	methods: {
		async mapToolsToTag (tools, type) {
			this[`${type}ToolsByTagMap`] = (tools || []).reduce((map, tool) => {
				Object.keys(tool.tags || []).map((tagId) => {
					if (!map[tagId]) {
						map[tagId] = [];
					}
					map[tagId].push(tool);
				})
				if (!tool.tags || _isEmpty(tool.tags)) {
					if (!map['uncategorized']) {
						map['uncategorized'] = [];
					}
					map['uncategorized'].push(tool);
				}
				return map;
			}, {});
		},
		goTo (tagId: string) {
			this.routerPush(this.$route, this.$router, { name: ROUTER.RUNNITS_ALL_TOOLS, params: { tag: tagId } });
		},
	},
	components: {
		OfferingCard,
		RunnitCarousel,
	},
});
