
import Vue from 'vue';
import { mapState } from "vuex";
import {
	TRAINING_TYPE_CARDS

} from "@/views/Runnits/constants";
import {
	RUNNIT_NODE_DEF_TAG_TYPE,
	RunnitNodeDef
} from "@run-diffusion/shared";

export default Vue.extend({
	name: 'TrainerUploadEmptyState',
	computed: {
		...mapState([
			'modelsState',
			'runnitState',
			'publicRunnitNodeDefTagsMap',
			'runnitNodesMap',
		]),
		categoryTitle() {
			if (this.runnitNodesMap && this.runnitState.selectedNodeId) {
				const nodeDef: RunnitNodeDef = this.runnitNodesMap[this.runnitState.selectedNodeId]?.nodeDef;

				if (nodeDef) {
					const trainingTypeTag = Object.keys(nodeDef.tags).find((tagId: string) => this.publicRunnitNodeDefTagsMap[tagId]?.type === RUNNIT_NODE_DEF_TAG_TYPE.TRAINING_TYPE);
					return TRAINING_TYPE_CARDS[trainingTypeTag]?.title || 'Model';
				}
			}

			return 'Model';
		},
		imagePaths() {
			if (this.runnitNodesMap && this.runnitState.selectedNodeId) {
				const nodeDef: RunnitNodeDef = this.runnitNodesMap[this.runnitState.selectedNodeId]?.nodeDef;

				if (nodeDef) {
					const trainingTypeTag = Object.keys(nodeDef.tags).find((tagId: string) => this.publicRunnitNodeDefTagsMap[tagId]?.type === RUNNIT_NODE_DEF_TAG_TYPE.TRAINING_TYPE);
					return TRAINING_TYPE_CARDS[trainingTypeTag]?.imagePaths || [];
				}
			}

			return [];
		},
	},
});
