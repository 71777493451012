
import Vue from 'vue';
import {
	DEFAULT_SHOW_TEXT_COUNTER_AT,
	DEFAULT_TEXT_MAX_LENGTH,
	getTextMaxLengthRule,
} from '@/utils';
import _trim from 'lodash/trim';
import _uniq from 'lodash/uniq';
import _truncate from 'lodash/truncate';

export default Vue.extend({
	name: 'StringArrayField',
	props: {
		label: { type: String, required: true },
		value: { type: Array, default: () => [] },
		disabled: { type: Boolean, default: false },
	},
	data () {
		return {
			DEFAULT_SHOW_TEXT_COUNTER_AT,
			DEFAULT_TEXT_MAX_LENGTH,
			newStrVal: null,
		};
	},
	methods: {
		getTextMaxLengthRule,
		_truncate,
		onRemoveVal (val: string) {
			this.$emit('input', (this.value || []).filter(v => v !== val));
		},
		onMoveItem (fromIndex: number, toIndex: number) {
			if (toIndex <= (this.value || []).length - 1 && toIndex >= 0) {
				const item: string = this.value[fromIndex];
				let newArray: string[] = this.value.filter((val: string) => val !== item);
				newArray.splice(toIndex, 0, item);
				this.$emit('input', newArray);
			}
		},
		onAddAnother () {
			this.$emit('input', _uniq([ ...(this.value || []), ...(_trim(this.newStrVal) ? [_trim(this.newStrVal)] : []) ]));
			this.newStrVal = null;
		},
	},
});
