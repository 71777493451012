import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"primary-gradient-card",style:(_vm.styles)},[_c('div',{staticClass:"d-flex align-start justify-space-between"},[_c('div',{staticClass:"primary-gradient-card-title"},[_c(VIcon,{staticStyle:{"color":"white","font-size":"20px","margin":"0 8px 0 0","top":"-2px"}},[_vm._v("mdi-cash-multiple")]),_vm._v(" Balance ")],1),((
				_vm.isUserRecurringBalanceActive ||
				_vm.isTeamRecurringBalanceActive ||
				_vm.userRecurringBalance ||
				_vm.teamRecurringBalance
			))?_c('glass-button',{attrs:{"small":""},on:{"click":function($event){return _vm.openDetailsDialog(false, false)}}},[_c(VIcon,{staticStyle:{"font-size":"18px"},attrs:{"left":""}},[_vm._v("mdi-help-circle-outline")]),_vm._v(" Details ")],1):_vm._e()],1),_c('save-your-recurring-balance-alert'),_c('div',{staticClass:"primary-gradient-card-inner-card global-glass-background-light full-width",style:({
			justifyContent: _vm.shouldShowLabels ? 'flex-start' : 'center',
		})},[_c('div',{staticClass:"d-flex flex-column full-width"},[_c('div',{staticClass:"d-flex align-center full-width"},[_c('div',{staticClass:"d-flex flex-column full-width",staticStyle:{"flex":"1"}},[(_vm.shouldShowLabels)?_c('div',{staticClass:"section-label"},[_vm._v(" Personal"),_c(VIcon,{staticStyle:{"color":"rgba(255, 255, 255, 0.7)","font-size":"14px"},attrs:{"right":""},on:{"click":function($event){return _vm.openDetailsDialog(true, false)}}},[_vm._v("mdi-help-circle-outline")])],1):_vm._e(),_c('div',{staticClass:"d-flex align-center"},[(_vm.userBalanceAccount)?_c('b',{class:{
								'gradient-text-90': !_vm.teamSelected && _vm.selectionEnabled,
							},style:({
								fontSize: _vm.shouldShowLabels || _vm.$vuetify.breakpoint.xsOnly ? '18px' : '28px',
							})},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm._f("centsToDollars")(_vm.computedUserBalance)))+" ")]):_vm._e(),(!_vm.shouldShowLabels)?_c(VIcon,{staticStyle:{"color":"rgba(255, 255, 255, 0.7)","font-size":"14px"},attrs:{"right":""},on:{"click":function($event){return _vm.openDetailsDialog(true, false)}}},[_vm._v("mdi-help-circle-outline")]):_vm._e()],1)]),_c('div',{staticClass:"d-flex justify-end",staticStyle:{"flex":"3"}},[_c('add-balance-funds-button',{attrs:{"type":"private","platform":"rd","btnProps":{ small: true, callToAction: true }}},[_vm._v(" Add Funds"),(_vm.shouldShowLabels)?_c('span',[_vm._v(" - "),_c('span',{staticStyle:{"font-weight":"bold","padding":"0 0 0 3px"}},[_vm._v("Personal")])]):_vm._e(),_c(VIcon,{attrs:{"right":"","small":""}},[_vm._v("mdi-open-in-new")])],1)],1)]),(_vm.team && !_vm.isOnboardingTeam)?_c('div',{staticClass:"d-flex align-center full-width mt-2"},[_c('div',{staticClass:"d-flex flex-column full-width",staticStyle:{"flex":"1"}},[_c('div',{staticClass:"section-label"},[_vm._v(" Team"),_c(VIcon,{staticStyle:{"color":"rgba(255, 255, 255, 0.7)","font-size":"14px"},attrs:{"right":""},on:{"click":function($event){return _vm.openDetailsDialog(false, true)}}},[_vm._v("mdi-help-circle-outline")])],1),_c('div',{staticClass:"d-flex align-center"},[_c('b',{class:{
								'gradient-text-90': _vm.teamSelected && _vm.selectionEnabled,
							},staticStyle:{"font-size":"18px","font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm._f("centsToDollars")(_vm.computedTeamBalance)))+" ")])])]),_c('div',{staticClass:"d-flex justify-end",staticStyle:{"flex":"3"}},[_c('add-balance-funds-button',{attrs:{"type":"team","platform":"rd","btnProps":{ small: true, callToAction: true }}},[_vm._v(" Add Funds - "),_c('span',{staticStyle:{"font-weight":"bold","padding":"0 0 0 3px"}},[_vm._v("Team")]),_c(VIcon,{attrs:{"right":"","small":""}},[_vm._v("mdi-open-in-new")])],1)],1)]):_vm._e()])]),_c('balance-details-dialog',{attrs:{"value":_vm.detailsDialogData.open,"scrollToPrivateBalance":_vm.detailsDialogData.scrollToPrivateBalance,"scrollToTeamBalance":_vm.detailsDialogData.scrollToTeamBalance},on:{"input":_vm.closeDetailsDialog}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }