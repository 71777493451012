import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (!_vm.$route.meta.hideAddFunds && _vm.authUser && _vm.authUser.emailVerified)?_c('div',[(_vm.isTeamBtn && !_vm.isTeamAdmin)?_c('div',{staticClass:"info-container"},[_c(VIcon,{staticStyle:{"color":"rgba(255, 255, 255, 0.7)","margin":"0 5px 0 0"},attrs:{"small":""}},[_vm._v("mdi-information-outline")]),_vm._v(" "+_vm._s(_vm.adminWarningText)+" ")],1):_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":_vm.onBtnContainerClick}},[_c('base-button',_vm._g(_vm._b({attrs:{"disabled":_vm.btnDisabled},on:{"click":_vm.onAddFundsClick}},'base-button',_vm.btnProps,false),_vm.$listeners),[(_vm.isRdBtn)?_vm._t("default",function(){return [_vm._v(" Add Funds "),_c(VIcon,{attrs:{"right":"","small":""}},[_vm._v("mdi-open-in-new")])]}):(_vm.isRunnitsBtn)?_vm._t("default",function(){return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(" Top-Up "),_c('span',[_c('tokens-s-v-g',_vm._b({staticStyle:{"margin":"0 3px"},attrs:{"width":12,"height":12}},'tokens-s-v-g',{
								...(_vm.btnDisabled && {
									fill: 'var(--grey-600)',
								}),
							},false)),_c('span',{staticStyle:{"font-family":"monospace"}},[_vm._v(_vm._s(_vm._f("currencyTokens")(_vm._f("hundredthsToTokens")(_vm.LIFE_SUPPORT_TOKENS_PACKAGE_AMOUNT))))])],1)])]}):_vm._e()],2)],1),(_vm.isRdBtn)?_c('private-balance-pricing-table-dialog',{model:{value:(_vm.privateBalancePricingTableDialogOpen),callback:function ($$v) {_vm.privateBalancePricingTableDialogOpen=$$v},expression:"privateBalancePricingTableDialogOpen"}}):_vm._e(),_c('confirm-dialog',_vm._b({attrs:{"hide-cancel":"","confirm-text":"OK"},on:{"on-confirm":function($event){_vm.balanceTooHighDialogOpen = false}},model:{value:(_vm.balanceTooHighDialogOpen),callback:function ($$v) {_vm.balanceTooHighDialogOpen=$$v},expression:"balanceTooHighDialogOpen"}},'confirm-dialog',{
			...(_vm.isRdBtn && {
				title: 'Your balance is too high',
				bodyText: 'Try again after you have used up more of your balance.',
			}),
			...(_vm.isRunnitsBtn && {
				title: 'You can purchase an early top-up only if you are running low on tokens',
				bodyText: `Try again after your tokens balance is lower than ${_vm.currencyTokens(_vm.hundredthsToTokens(_vm.LIFE_SUPPORT_TOKENS_PACKAGE_AMOUNT))}.`,
			}),
		},false))],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }