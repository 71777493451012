
import Vue from 'vue';
import _sortBy from 'lodash/sortBy';
import _isEmpty from 'lodash/isEmpty';
import { mapActions, mapState } from 'vuex';
import {
	FEATURED_RUNNIT_NODE_DEF_IDS,
	RUNNITS_OWNER_SELECTION,
} from '@/views/Runnits/constants';
import {
	RUNNITS_PUBLISHED_STATE,
	RunnitsPublishedState,
} from '@/constants/constants';
import {
	RunnitNodeDef,
	RunnitNodeDefToolType,
	UserRunnitNodeDefInfo,
	RUNNIT_NODE_DEF_TAG_TYPE,
	RUNNIT_NODE_DEF_TAG_TYPE_SORT,
	RUNNIT_NODE_DEF_TOOL_TYPE,
	RUNNIT_TYPE,
	RUNNIT_NODE_DEF_TOOL_APP_TYPE,
} from '@run-diffusion/shared';
import OfferingCard from '@/components/OfferingCard.vue';
import GlassButton from '@/components/base/GlassButton.vue';
import LoadingState from '@/components/states/LoadingState.vue';
import EmptyStateCircleBackground from '@/components/states/EmptyStateCircleBackground.vue';
import { RunnitsCRUDMixin } from '@/mixins';
import RunnitTagTypeTabs from '../RunnitTagTypeTabs.vue';
import RunnitDuplicateEditNodeDefInternalEditor from '../RunnitSettings/internalAdminOnly/RunnitDuplicateEditNodeDefInternalEditor.vue';
import RunnitTagFilter from '../RunnitTagFilter.vue';
import { APP_BAR_HEIGHT } from '@/components/Toolbar/constants';

export default Vue.extend({
	name: 'RunnitAllToolsPage',
	props: {},
	mixins: [
		RunnitsCRUDMixin,
	],
	data () {
		return {
			APP_BAR_HEIGHT,
			RUNNITS_PUBLISHED_STATE,
			RUNNIT_NODE_DEF_TOOL_TYPE,
			FEATURED_RUNNIT_NODE_DEF_IDS,
			toolSearchValue: '',
			selectedCategory: 'ALL',

			filteredRunnitNodeDefs: [],

			nodeDefInternalEditorDialog: {
				open: false,
				selectedNodeDef: null,
			},

			publishedStateFilter: RUNNITS_PUBLISHED_STATE.PUBLISHED,

			selectedTagsFilterMap: {},

			nodeDefsByTagMap: {},
		};
	},
	mounted () {
		const initialTagId = this.$route.params.tag;
		if (initialTagId) {
			const initialTag = this.tagsById[this.$route.params.tag];
			if (initialTag) {
				const initializeFiltersAfterRunnitsLoaded = () => {
					if (this.loadingRunnits === false) {
						this.handleCategoryChange(initialTag.type);
						this.toggleTagFilter(initialTag);
					} else {
						setTimeout(() => {
							initializeFiltersAfterRunnitsLoaded()
						}, 100);
					}
				}
				initializeFiltersAfterRunnitsLoaded();
			}
		}
	},
	computed: {
		...mapState([
			'user',
			'runnits',
			'loadingRunnits',
			'loadingRecentUserRunnitNodeDefInfos',
			'recentUserRunnitNodeDefInfos',
			'runnitState',
			'publicRunnitNodeDefs',
			'loadingPublicRunnitNodeDefs',
			'teamRunnitNodeDefs',
			'loadingTeamRunnitNodeDefs',
			'publicRunnitNodeDefTagsMap',
			'teamRunnitNodeDefTagsMap',
		]),
		visibleRunnitNodeDefInfos () {
			return this.recentUserRunnitNodeDefInfos.filter((toolInfo: UserRunnitNodeDefInfo) => !toolInfo.nodeDef.isDeleted &&
				(
					this.user &&
					toolInfo.nodeDef.appType === RUNNIT_NODE_DEF_TOOL_APP_TYPE.RUNNIT &&
					(
						this.user.isAdmin ||
						toolInfo.nodeDef.isPublished
					)) &&
				(
					!toolInfo.nodeDef.teamIds || // No team means it's available to anyone
					(
						this.runnitState.runnitsOwnerSelection === RUNNITS_OWNER_SELECTION.TEAM &&
						this.team &&
						toolInfo.nodeDef.teamIds[this.team.id]
					))
			).slice(0, 5);
		},
		visibleRunnitNodeDefs () {
			return _sortBy(this.filteredRunnitNodeDefs.filter((nodeDef) => {
				return this.filteredTagTypes.some(tag => {
					return !nodeDef.tags || nodeDef.tags[tag];
				})
			}), 'title');
		},
		tagsOnSelectedType () {
			return this.sortedTagType.map((tagId) => (this.tagsById[tagId])).filter(t => t);
		},
		noFilterTagsSelected () {
			return !Object.keys(this.selectedTagsFilterMap).length ||
				Object.keys(this.selectedTagsFilterMap).every(tagId => !this.selectedTagsFilterMap[tagId]);
		},
		filteredTagTypes () {
			return this.sortedTagType.filter(tagId => this.noFilterTagsSelected ||
				this.selectedTagsFilterMap[tagId]);
		},
		sortedTagType () {
			return _sortBy(Object.keys(this.nodeDefsByTagMap), [
				(tagId) => { return RUNNIT_NODE_DEF_TAG_TYPE_SORT[this._get(this.tagsById, `[${tagId}].type`)] },
				(tagId) => { return this._get(this.tagsById, `[${tagId}].sortOrder`) },
				(tagId) => { return this._get(this.tagsById, `[${tagId}].label`) }])
				.filter(tagId => this._get(this.tagsById, `[${tagId}].type`) === this.selectedCategory || tagId === 'uncategorized' || this.selectedCategory === RUNNIT_NODE_DEF_TAG_TYPE.TEAM || this.selectedCategory === 'ALL');
		},
		tagsById () {
			return {
				'uncategorized': { label: 'Uncategorized', id: 'uncategorized', type: 'uncategorized' },
				...this.publicRunnitNodeDefTagsMap,
				...this.teamRunnitNodeDefTagsMap,
			}
		},
	},
	watch: {
		filteredRunnitNodeDefs: {
			immediate: true,
			handler (newVal: RunnitNodeDef[]) {
				this.mapNodeDefsToTag(newVal);
			}
		},
		loadingPublicRunnitNodeDefs: {
			immediate: true,
			handler (newVal) {
				if (newVal === false && this.loadingTeamRunnitNodeDefs === false) {
					this.applyFilters();
				}
			}
		},
		loadingTeamRunnitNodeDefs: {
			immediate: true,
			handler (newVal) {
				if (newVal === false && this.loadingPublicRunnitNodeDefs === false) {
					this.applyFilters();
				}
			}
		},
	},
	methods: {
		...mapActions([
			'updateSnackbar',
		]),
		handleCategoryChange (selectedCategory: RunnitNodeDefToolType) {
			this.selectedCategory = selectedCategory;
			this.selectedTagsFilterMap = {};
			this.applyFilters();
		},
		handleSearchInput (searchValue) {
			this.toolSearchValue = searchValue;
			this.applyFilters();
		},
		handlePublishedFilterChange (publishedState: RunnitsPublishedState) {
			this.publishedStateFilter = publishedState;
			this.applyFilters();
		},
		applyFilters () {
			this.filteredRunnitNodeDefs = [];
			[
				...this.publicRunnitNodeDefs,
				...this.teamRunnitNodeDefs,
			].forEach((runnitNodeDef: RunnitNodeDef) => {
				let matchesSearch: boolean, matchesPublishedState: boolean, matchesSelectedTypes: boolean, matchesTeam: boolean;

				if (this.toolSearchValue) {
					matchesSearch = runnitNodeDef.title.toLowerCase().includes(this.toolSearchValue.toLowerCase());
				} else {
					matchesSearch = true;
				}

				if (this.publishedStateFilter === RUNNITS_PUBLISHED_STATE.PUBLISHED) {
					matchesPublishedState = !!runnitNodeDef.publishedAt;
				} else if (this.publishedStateFilter === RUNNITS_PUBLISHED_STATE.NOT_PUBLISHED) {
					matchesPublishedState = !runnitNodeDef.publishedAt;
				} else {
					matchesPublishedState = false;
				}

				if (this.selectedCategory && this.selectedCategory !== 'ALL') {
					// if there are no set tag types then it is uncategorized and should be visible with any tag filtering
					matchesSelectedTypes = (
						// For the team filter we also check the nodeDef type because it matches the team tag
						this.selectedCategory !== RUNNIT_NODE_DEF_TAG_TYPE.TEAM ||
						runnitNodeDef.type === this.selectedCategory
					) &&
						(
							!runnitNodeDef.tagTypes ||
							_isEmpty(runnitNodeDef.tagTypes) ||
							Object.values(runnitNodeDef.tagTypes).every(applied => !applied) ||
							(
								this.selectedCategory === RUNNIT_NODE_DEF_TAG_TYPE.TEAM ||
								runnitNodeDef.tagTypes[this.selectedCategory]
							)
						);

				} else {
					matchesSelectedTypes = true;
				}

				const hasTeam = runnitNodeDef.teamIds && Object.keys(runnitNodeDef.teamIds).some((teamId) => runnitNodeDef.teamIds[teamId]);
				if (hasTeam && this.runnitState.runnitsOwnerSelection === RUNNITS_OWNER_SELECTION.TEAM && this.team) {
					matchesTeam = runnitNodeDef.teamIds[this.team.id];
				} else {
					matchesTeam = !hasTeam;
				}

				if (matchesSearch && matchesPublishedState && matchesSelectedTypes && matchesTeam) {
					this.filteredRunnitNodeDefs.push(runnitNodeDef);
				}
			});

			this.filteredRunnitNodeDefs = _sortBy(this.filteredRunnitNodeDefs, 'sortOrder');
		},
		async onToolCardClick (runnitNodeDef: RunnitNodeDef) {
			await this.onAddRunnitClick(runnitNodeDef, RUNNIT_TYPE.SINGLE_TOOL);
		},
		toggleAdminNodeDefEditor (selectedNodeDef: RunnitNodeDef) {
			this.nodeDefInternalEditorDialog = {
				...this.nodeDefInternalEditorDialog,
				open: true,
				selectedNodeDef,
			};
		},
		onEditDialogClose (open: boolean) {
			this.nodeDefInternalEditorDialog = {
				...this.nodeDefInternalEditorDialog,
				open: !!open,
			};
			this.applyFilters();
		},
		toggleTagFilter (tag) {
			this.selectedTagsFilterMap = {
				...this.selectedTagsFilterMap,
				[tag.id]: !this.selectedTagsFilterMap[tag.id],
			};
		},
		async mapNodeDefsToTag (nodeDefs) {
			this.nodeDefsByTagMap = (nodeDefs || []).reduce((map, nodeDef) => {
				Object.keys(nodeDef.tags || []).map((tagId) => {
					if (!map[tagId]) {
						map[tagId] = [];
					}
					map[tagId].push(nodeDef);
				})
				if (!nodeDef.tags || _isEmpty(nodeDef.tags)) {
					if (!map['uncategorized']) {
						map['uncategorized'] = [];
					}
					map['uncategorized'].push(nodeDef);
				}
				return map;
			}, {});
		},
	},
	components: {
		GlassButton,
		LoadingState,
		OfferingCard,
		EmptyStateCircleBackground,
		RunnitTagTypeTabs,
		RunnitDuplicateEditNodeDefInternalEditor,
		RunnitTagFilter,
	},
});
