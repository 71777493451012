
import Vue from 'vue';
import { ROUTER } from "@/router/constants";
import RunnitDialog from "@/views/Runnits/base/RunnitDialog.vue";
import { ModelCRUDMixin } from "@/mixins/ModelCRUDMixin";
import OptionCard from "@/views/Runnits/base/OptionCard.vue";

export default Vue.extend({
	name: 'ModelCreateOptionsDialog',
	mixins: [
		ModelCRUDMixin
	],
	props: {
		value: { type: Boolean, default: false },
	},
	computed: {
		canAccessTrainer() {
			return this.user?.isAdmin || this.user?.features?.modelTraining;
		}
	},
	data () {
		return {
			open: false,
		};
	},
	watch: {
		value (newVal: boolean) {
			this.setOpen(newVal);
		},
		open (newVal: boolean) {
			this.$emit('input', newVal);
		},
	},
	methods: {
		setOpen (val: boolean) {
			this.open = !!val;
			if (this.open !== this.value) {
				this.$emit('input', this.open);
			}
		},
		async trainModel() {
			if (!this.canAccessTrainer) {
				return;
			}

			this.setOpen(false);
			await this.routerPush(this.$route, this.$router, { name: ROUTER.RUNNITS_TRAIN_MODEL_OPTIONS });
		},
		openUploadDialog () {
			this.setOpen(false);
			this.onAddModelClick();
		},
	},
	components: {
		OptionCard,
		RunnitDialog,
	},
});
