import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import { db } from '@/firebase';
import { removeTeamId, setTeamId } from '@/utils/localStorage';
import { CURR_SESSION_STATES } from '@/constants/constants';
import {
	Announcement,
	ClubOffer,
	AppOffer,
	BalanceAccount,
	ExtensionOffer,
	HardwareOffer,
	Session,
	SessionBackgroundTask,
	SetupSessionPrefill,
	SoftwareOffer,
	StableDiffusionModel,
	StableDiffusionOffer,
	StripeCustomer,
	Team,
	User,
	UserRunnitNodeDefInfo,
	Workspace,
	Runnit,
	RunnitNode,
	RunnitNodeDef,
	RunnitNodeRun,
	Workshop,
	WorkshopSession,
	WorkshopSessionQueueItem,
	RunnitNodeDefTag,
	Model,
	RUNNITS_ACCESS_LEVEL,
	RUNNIT_TYPE,
	RUNNIT_NODE_DEF_TOOL_TYPE,
	RUNNIT_NODE_DEF_TOOL_APP_TYPE,
} from '@run-diffusion/shared';
import _isArray from 'lodash/isArray';

export const get$bindFirestoreOptions: Function = (options: any = { reset: false }): any => options;
export const buildNewVuefireBindingObj: Function = (val: any, otherProps: any): Object | null => {
	return val ? {
		...val,
		...otherProps,
		get id () { return val.id },
	} : null;
};
export const buildNewVuefireBindingArray: Function = (val: any[] | null): any[] => {
	if (!_isArray(val)) return [];
	return [...val];
};

export const VuexFirestoreBindingsMixin = Vue.extend({
	data () {
		return {
			vuefireClubOffers: [],
			vuefireAppOffers: [],
			vuefireHardwareOffers: [],
			vuefireSoftwareOffers: [],
			vuefireExtensionOffers: [],
			vuefireStableDiffusionOffers: [],
			vuefireWorkspaces: [],
			vuefireRunnits: [],
			vuefireRunnitNodes: [],
			vuefireTrainerNodeDefs: [],
			vuefirePublicRunnitNodeDefs: [],
			vuefireTeamRunnitNodeDefs: [],
			vuefirePublicRunnitNodeDefTags: [],
			vuefireTeamRunnitNodeDefTags: [],
			vuefireDraftRunnitNodeRuns: [],
			vuefireWorkshops: [],
			vuefireWorkshopSessions: [],
			vuefireWorkshopSessionQueueItems: [],
			vuefireLobbyWorkshop: null,
			vuefireLobbyWorkshopSession: null,
			vuefireLobbyWorkshopSessionQueueItem: null,
			vuefireAnnouncements: [],
			vuefireUser: null,
			vuefireUserBalanceAccount: null,
			vuefireTeam: null,
			vuefireTeamBalanceAccount: null,
			vuefireStripeCustomer: null,
			vuefireStripeTeamsCustomer: null,
			vuefireCurrSessions: [],
			vuefireCurrSessionBackgroundTasks: [],
			vuefireAdminRegularSessions: [],
			vuefireStableDiffusionModels: [],
			vuefireSetupSessionPrefill: null,
			vuefireRecentUserRunnitNodeDefInfos: [],
			vuefireModels: [],
		};
	},
	computed: {
		...mapState([
			'user',
			'boundPropsClubOffers',
			'boundPropsAppOffers',
			'boundPropsHardwareOffers',
			'boundPropsSoftwareOffers',
			'boundPropsExtensionOffers',
			'boundPropsStableDiffusionOffers',
			'boundPropsWorkspaces',
			'boundPropsRunnits',
			'boundPropsRunnitNodes',
			'boundPropsTrainerNodeDefs',
			'boundPropsPublicRunnitNodeDefs',
			'boundPropsTeamRunnitNodeDefs',
			'boundPropsPublicRunnitNodeDefTags',
			'boundPropsTeamRunnitNodeDefTags',
			'boundPropsDraftRunnitNodeRun',
			'boundPropsWorkshops',
			'boundPropsWorkshopSessions',
			'boundPropsWorkshopSessionQueueItems',
			'boundPropsLobbyWorkshop',
			'boundPropsLobbyWorkshopSession',
			'boundPropsLobbyWorkshopSessionQueueItem',
			'boundPropsAnnouncements',
			'boundPropsUser',
			'boundPropsUserBalanceAccount',
			'boundPropsTeam',
			'boundPropsTeamBalanceAccount',
			'boundPropsStripeCustomer',
			'boundPropsStripeTeamsCustomer',
			'boundPropsCurrSessions',
			'boundPropsCurrSessionBackgroundTasks',
			'boundPropsAdminRegularSessions',
			'boundPropsStableDiffusionModels',
			'boundPropsSetupSessionPrefill',
			'boundPropsRecentUserRunnitNodeDefInfos',
			'boundPropsModels',
		]),
	},
	watch: {
		/*
		BoundProps
		 */
		boundPropsClubOffers: {
			immediate: true,
			async handler () {
				this.setLoadingClubOffers(true);
				const query = db.collection('clubOffers')
					.where('publishedAt', '!=', null);

				try {
					const newResult: ClubOffer[] = await this.$bind(
						'vuefireClubOffers',
						query,
						get$bindFirestoreOptions(),
					);
					if (!newResult) this.resultClubOffers(newResult);
				} catch (error) {
					console.error('Failed to load ClubOffers:', error);
					this.resultClubOffers([]); // Set to empty array in case of error
				} finally {
					this.setLoadingClubOffers(false);
				}
			},
		},
		boundPropsAppOffers: {
			immediate: true,
			async handler ({ user }) {
				if (user) {
					this.setLoadingAppOffers(true);
					let appOffersRef: any = db.collection('appOffers');
					if (!user.isAdmin && !user.isDev) {
						// index created = appOffers: publishedAt Ascending sortOrder Ascending __name__ Ascending
						appOffersRef = appOffersRef
							.where('publishedAt', '!=', null);
					}

					const newResult: AppOffer[] = await this.$bind(
						'vuefireAppOffers',
						appOffersRef,
						get$bindFirestoreOptions(),
					);
					this.setLoadingAppOffers(false);
					if (!newResult) this.resultAppOffers(newResult);
				} else {
					if (this._firestoreUnbinds['vuefireAppOffers']) {
						this.$unbind('vuefireAppOffers');
					}
				}
			},
		},
		boundPropsHardwareOffers: {
			immediate: true,
			async handler ({ user }) {
				if (user) {
					this.setLoadingHardwareOffers(true);
					let hardwareOffersRef: any = db.collection('hardwareOffers');
					if (!user.isAdmin && !user.isDev) {
						// index created = hardwareOffers: publishedAt Ascending sortOrder Ascending __name__ Ascending
						hardwareOffersRef = hardwareOffersRef
							.where('publishedAt', '!=', null);
					}

					const newResult: HardwareOffer[] = await this.$bind(
						'vuefireHardwareOffers',
						hardwareOffersRef,
						get$bindFirestoreOptions(),
					);
					this.setLoadingHardwareOffers(false);
					if (!newResult) this.resultHardwareOffers(newResult);
				} else {
					if (this._firestoreUnbinds['vuefireHardwareOffers']) {
						this.$unbind('vuefireHardwareOffers');
					}
				}
			},
		},
		boundPropsSoftwareOffers: {
			immediate: true,
			async handler ({ user }) {
				if (user) {
					this.setLoadingSoftwareOffers(true);
					let softwareOffersRef: any = db.collection('softwareOffers');
					if (!user.isAdmin && !user.isDev) {
						// index created = softwareOffers: publishedAt Ascending sortOrder Ascending __name__ Ascending
						softwareOffersRef = softwareOffersRef
							.where('publishedAt', '!=', null);
					}

					const newResult: SoftwareOffer[] = await this.$bind(
						'vuefireSoftwareOffers',
						softwareOffersRef,
						get$bindFirestoreOptions(),
					);
					this.setLoadingSoftwareOffers(false);
					if (!newResult) this.resultSoftwareOffers(newResult);
				} else {
					if (this._firestoreUnbinds['vuefireSoftwareOffers']) {
						this.$unbind('vuefireSoftwareOffers');
					}
				}
			},
		},
		boundPropsExtensionOffers: {
			immediate: true,
			async handler ({ user }) {
				if (user) {
					this.setLoadingExtensionOffers(true);
					let extensionOffersRef: any = db.collection('extensionOffers');
					if (!user.isAdmin) {
						// index created = extensionOffers: publishedAt Ascending sortOrder Ascending __name__ Ascending
						extensionOffersRef = extensionOffersRef
							.where('publishedAt', '!=', null);
					}

					const newResult: ExtensionOffer[] = await this.$bind(
						'vuefireExtensionOffers',
						extensionOffersRef,
						get$bindFirestoreOptions(),
					);
					this.setLoadingExtensionOffers(false);
					if (!newResult) this.resultExtensionOffers(newResult);
				} else {
					if (this._firestoreUnbinds['vuefireExtensionOffers']) {
						this.$unbind('vuefireExtensionOffers');
					}
				}
			},
		},
		boundPropsStableDiffusionOffers: {
			immediate: true,
			async handler ({ user }) {
				if (user) {
					this.setLoadingStableDiffusionOffers(true);
					let stableDiffusionOffersRef: any = db.collection('stableDiffusionOffers');
					if (!user.isAdmin) {
						// index created = stableDiffusionOffers: publishedAt Ascending sortOrder Ascending __name__ Ascending
						stableDiffusionOffersRef = stableDiffusionOffersRef
							.where('publishedAt', '!=', null);
					}

					const newResult: StableDiffusionOffer[] = await this.$bind(
						'vuefireStableDiffusionOffers',
						stableDiffusionOffersRef,
						get$bindFirestoreOptions(),
					);
					this.setLoadingStableDiffusionOffers(false);
					if (!newResult) this.resultStableDiffusionOffers(newResult);
				} else {
					if (this._firestoreUnbinds['vuefireStableDiffusionOffers']) {
						this.$unbind('vuefireStableDiffusionOffers');
					}
				}
			},
		},
		boundPropsWorkspaces: {
			immediate: true,
			async handler ({ teamId }) {
				if (teamId) {
					this.setLoadingWorkspaces(true);
					// TODO: Add pagination
					const newResult: Workspace[] = await this.$bind(
						'vuefireWorkspaces',
						// index created: workspaces: deletedAt Ascending teamId Ascending createdAt Ascending __name__ Ascending
						db.collection(`workspaces`)
							.where('teamId', '==', teamId)
							.where('deletedAt', '==', null)
							.orderBy('createdAt', 'asc')
							.limit(100),
						get$bindFirestoreOptions(),
					);
					this.setLoadingWorkspaces(false);
					if (!newResult) this.resultWorkspaces(newResult);
				} else {
					if (this._firestoreUnbinds['vuefireWorkspaces']) {
						this.$unbind('vuefireWorkspaces');
					}
				}
			},
		},
		boundPropsRunnits: {
			immediate: true,
			async handler ({ userId, teamId, accessLevel }) {
				if (!teamId && !userId) {
					if (this._firestoreUnbinds['vuefireRunnits']) {
						this.$unbind('vuefireRunnits');
					}
					return;
				}

				// TODO: Add pagination
				this.setLoadingRunnits(true);
				let newResult: Runnit[] = [];
				if (teamId && userId) {
					// index created: runnits accessLevel Ascending deletedAt Ascending teamId Ascending createdAt Ascending __name__ Ascending --- TODO delete this after the release
					// index created: runnits - accessLevel Ascending deletedAt Ascending teamId Ascending type Ascending createdAt Descending __name__ Descending
					let runnitsRef: any = db.collection(`runnits`)
						.where('teamId', '==', teamId)
						.where('accessLevel', '==', accessLevel)
						.where('deletedAt', '==', null)
						.where('type', '==', RUNNIT_TYPE.WORKFLOW)
						.orderBy('createdAt', 'desc')
						.limit(500);
					if (accessLevel !== RUNNITS_ACCESS_LEVEL.SHARED) {
						// index created: runnits - accessLevel Ascending deletedAt Ascending teamId Ascending userId Ascending createdAt Descending __name__ Descending
						runnitsRef = runnitsRef.where('userId', '==', userId);
					}
					newResult = await this.$bind(
						'vuefireRunnits',
						runnitsRef,
						get$bindFirestoreOptions(),
					);
				} else if (userId) {
					// index created: runnits - deletedAt Ascending teamId Ascending userId Ascending createdAt Descending __name__ Descending --- TODO delete this after the release
					// index created: runnits - deletedAt Ascending teamId Ascending type Ascending userId Ascending createdAt Descending __name__ Descending
					const runnitsRef: any = db.collection(`runnits`)
						.where('userId', '==', userId)
						.where('teamId', '==', null)
						.where('deletedAt', '==', null)
						.where('type', '==', RUNNIT_TYPE.WORKFLOW)
						.orderBy('createdAt', 'desc')
						.limit(100);
					newResult = await this.$bind(
						'vuefireRunnits',
						runnitsRef,
						get$bindFirestoreOptions(),
					);
				}

				this.setLoadingRunnits(false);
				if (!newResult) this.resultRunnits(newResult);
			},
		},
		boundPropsRunnitNodes: {
			immediate: true,
			async handler ({ runnitId }) {
				if (!runnitId) {
					if (this._firestoreUnbinds['vuefireRunnitNodes']) {
						this.$unbind('vuefireRunnitNodes');
					}
					return;
				}

				this.setLoadingRunnitNodes(true);
				let newResult: RunnitNode[] = [];
				// TODO: Add pagination
				// index created: runnitNodes deletedAt Ascending sortOrder Ascending __name__ Ascending
				newResult = await this.$bind(
					'vuefireRunnitNodes',
					db.collection(`runnits/${runnitId}/runnitNodes`)
						.where('deletedAt', '==', null)
						.orderBy('sortOrder', 'asc')
						.limit(100),
					get$bindFirestoreOptions(),
				);

				this.setLoadingRunnitNodes(false);
				if (!newResult) this.resultRunnitNodes(newResult);
			},
		},
		boudPropsTrainerNodeDefs: {
			immediate: true,
			async handler () {
				this.setLoadingTrainerNodeDefs(true);
				let query = db.collection('runnitNodeDefs')
					.where('appType', '==', RUNNIT_NODE_DEF_TOOL_APP_TYPE.TRAINER)
					.where('isDeleted', '==', false)
					.where('teamIds', '==', null);

				// Apply filter for non-admin users
				if (this.user && !this.user.isAdmin) {
					query = query.where('isPublished', '==', true);
				}

				try {
					const newResult: RunnitNodeDef[] = await this.$bind(
						'vuefireTrainerNodeDefs',
						query,
						get$bindFirestoreOptions(),
					);
					if (!newResult) await this.resultTrainerNodeDefs(newResult);
				} catch (error) {
					console.error('Failed to load TrainerNodeDefs:', error);
					this.resultTrainerNodeDefs([]); // Set to empty array in case of error
				} finally {
					this.setLoadingTrainerNodeDefs(false);
				}
			}
		},
		boundPropsPublicRunnitNodeDefs: {
			immediate: true,
			async handler () {
				this.setLoadingPublicRunnitNodeDefs(true);
				let query = db.collection('runnitNodeDefs')
					.where('appType', '==', RUNNIT_NODE_DEF_TOOL_APP_TYPE.RUNNIT)
					.where('type', '==', RUNNIT_NODE_DEF_TOOL_TYPE.CORE)
					.where('isDeleted', '==', false)
					.where('teamIds', '==', null);

				// Apply filter for non-admin users
				if (this.user && !this.user.isAdmin) {
					query = query.where('isPublished', '==', true);
				}

				try {
					const newResult: RunnitNodeDef[] = await this.$bind(
						'vuefirePublicRunnitNodeDefs',
						query,
						get$bindFirestoreOptions(),
					);
					if (!newResult) this.resultPublicRunnitNodeDefs(newResult);
				} catch (error) {
					console.error('Failed to load PublicRunnitNodeDefs:', error);
					this.resultPublicRunnitNodeDefs([]); // Set to empty array in case of error
				} finally {
					this.setLoadingPublicRunnitNodeDefs(false);
				}
			},
		},
		boundPropsTeamRunnitNodeDefs: {
			immediate: true,
			async handler ({ teamId }) {
				if (!teamId) {
					if (this._firestoreUnbinds['vuefireTeamRunnitNodeDefs']) {
						this.$unbind('vuefireTeamRunnitNodeDefs');
					}
					return;
				}

				this.setLoadingTeamRunnitNodeDefs(true);
				let query = db.collection('runnitNodeDefs')
					.where('appType', '==', RUNNIT_NODE_DEF_TOOL_APP_TYPE.RUNNIT)
					.where('type', '==', RUNNIT_NODE_DEF_TOOL_TYPE.TEAM)
					.where('isDeleted', '==', false)
					.where(`teamIds.${teamId}`, '==', true);

				// Apply filter for non-admin users
				if (this.user && !this.user.isAdmin) {
					query = query.where('isPublished', '==', true);
				}

				try {
					const newResult: RunnitNodeDef[] = await this.$bind(
						'vuefireTeamRunnitNodeDefs',
						query,
						get$bindFirestoreOptions(),
					);
					if (!newResult) this.resultTeamRunnitNodeDefs(newResult);
				} catch (error) {
					console.error('Failed to load TeamRunnitNodeDefs:', error);
					this.resultTeamRunnitNodeDefs([]); // Set to empty array in case of error
				} finally {
					this.setLoadingTeamRunnitNodeDefs(false);
				}
			},
		},
		boundPropsPublicRunnitNodeDefTags: {
			immediate: true,
			async handler () {
				this.setLoadingPublicRunnitNodeDefTags(true);
				const query = db.collection('runnitNodeDefTags')
					.where('isDeleted', '==', false)
					.where('teamId', '==', null);

				try {
					const newResult: RunnitNodeDef[] = await this.$bind(
						'vuefirePublicRunnitNodeDefTags',
						query,
						get$bindFirestoreOptions(),
					);
					if (!newResult) this.resultPublicRunnitNodeDefTags(newResult);
				} catch (error) {
					console.error('Failed to load PublicRunnitNodeDefTags:', error);
					this.resultPublicRunnitNodeDefTags([]); // Set to empty array in case of error
				} finally {
					this.setLoadingPublicRunnitNodeDefTags(false);
				}
			},
		},
		boundPropsTeamRunnitNodeDefTags: {
			immediate: true,
			async handler ({ teamId }) {
				if (!teamId) {
					if (this._firestoreUnbinds['vuefireTeamRunnitNodeDefTags']) {
						this.$unbind('vuefireTeamRunnitNodeDefTags');
					}
					return;
				}

				this.setLoadingTeamRunnitNodeDefTags(true);
				const query = db.collection('runnitNodeDefTags')
					.where('isDeleted', '==', false)
					.where(`teamId`, '==', teamId);

				try {
					const newResult: RunnitNodeDef[] = await this.$bind(
						'vuefireTeamRunnitNodeDefTags',
						query,
						get$bindFirestoreOptions(),
					);
					if (!newResult) this.resultTeamRunnitNodeDefTags(newResult);
				} catch (error) {
					console.error('Failed to load TeamRunnitNodeDefTags:', error);
					this.resultTeamRunnitNodeDefTags([]); // Set to empty array in case of error
				} finally {
					this.setLoadingTeamRunnitNodeDefTags(false);
				}
			},
		},
		boundPropsDraftRunnitNodeRun: {
			immediate: true,
			async handler ({ userId, nodeId }) {
				if (userId && nodeId) {
					this.setLoadingDraftRunnitNodeRun(true);
					// index created = runnitNodeRunDrafts: deletedAt Ascending nodeId Ascending userId Ascending draftAt Descending __name__ Descending
					const newResult: RunnitNodeRun[] = await this.$bind(
						'vuefireDraftRunnitNodeRuns',
						db.collection(`runnitNodeRunDrafts`)
							.where('userId', '==', userId)
							.where('nodeId', '==', nodeId)
							.where('deletedAt', '==', null)
							.orderBy('draftAt', 'desc')
							.limit(1),
						get$bindFirestoreOptions(),
					);
					this.setLoadingDraftRunnitNodeRun(false);
					if (!newResult) this.resultDraftRunnitNodeRun(newResult);
				} else {
					if (this._firestoreUnbinds['vuefireDraftRunnitNodeRuns']) {
						this.$unbind('vuefireDraftRunnitNodeRuns');
					}
				}
			},
		},
		boundPropsWorkshops: {
			immediate: true,
			async handler ({ teamId }) {
				if (teamId) {
					this.setLoadingWorkshops(true);
					// TODO: Add pagination
					// index created: workshops: deletedAt Ascending teamId Ascending createdAt Ascending __name__ Ascending
					const newResult: Workshop[] = await this.$bind(
						'vuefireWorkshops',
						db.collection(`workshops`)
							.where('teamId', '==', teamId)
							.where('deletedAt', '==', null)
							.orderBy('createdAt', 'asc')
							.limit(100),
						get$bindFirestoreOptions(),
					);
					this.setLoadingWorkshops(false);
					if (!newResult) this.resultWorkshops(newResult);
				} else {
					if (this._firestoreUnbinds['vuefireWorkshops']) {
						this.$unbind('vuefireWorkshops');
					}
				}
			},
		},
		boundPropsWorkshopSessions: {
			immediate: true,
			async handler ({ workshopId }) {
				if (workshopId) {
					this.setLoadingWorkshopSessions(true);
					// TODO: Add pagination
					const newResult: WorkshopSession[] = await this.$bind(
						'vuefireWorkshopSessions',
						db.collection(`workshops/${workshopId}/workshopSessions`)
							.orderBy('displayName', 'asc')
							.limit(500),
						get$bindFirestoreOptions(),
					);
					this.setLoadingWorkshopSessions(false);
					if (!newResult) this.resultWorkshopSessions(newResult);
				} else {
					if (this._firestoreUnbinds['vuefireWorkshopSessions']) {
						this.$unbind('vuefireWorkshopSessions');
					}
				}
			},
		},
		boundPropsWorkshopSessionQueueItems: {
			immediate: true,
			async handler ({ workshopId, workshopSessionId }) {
				if (workshopId && workshopSessionId) {
					this.setLoadingWorkshopSessionQueueItems(true);
					// TODO: Add pagination
					const newResult: WorkshopSessionQueueItem[] = await this.$bind(
						'vuefireWorkshopSessionQueueItems',
						db.collection(`workshops/${workshopId}/workshopSessions/${workshopSessionId}/workshopSessionQueue`)
							.orderBy('createdAt', 'asc')
							.limit(1000),
						get$bindFirestoreOptions({ reset: false, maxRefDepth: 0 }),
					);
					this.setLoadingWorkshopSessionQueueItems(false);
					if (!newResult) this.resultWorkshopSessionQueueItems(newResult);
				} else {
					if (this._firestoreUnbinds['vuefireWorkshopSessionQueueItems']) {
						this.$unbind('vuefireWorkshopSessionQueueItems');
					}
				}
			},
		},
		boundPropsLobbyWorkshop: {
			immediate: true,
			async handler ({ workshopId }) {
				if (workshopId) {
					this.setLoadingLobbyWorkshop(true);
					const newResult: Workshop = await this.$bind(
						'vuefireLobbyWorkshop',
						db.doc(`workshops/${workshopId}`),
						get$bindFirestoreOptions(),
					);
					this.setLoadingLobbyWorkshop(false);
					if (!newResult) this.resultLobbyWorkshop(newResult);
				} else {
					if (this._firestoreUnbinds['vuefireLobbyWorkshop']) {
						this.$unbind('vuefireLobbyWorkshop');
					}
				}
			},
		},
		boundPropsLobbyWorkshopSession: {
			immediate: true,
			async handler ({ workshopId, workshopSessionId }) {
				if (workshopId && workshopSessionId) {
					this.setLoadingLobbyWorkshopSession(true);
					const newResult: WorkshopSession = await this.$bind(
						'vuefireLobbyWorkshopSession',
						db.doc(`workshops/${workshopId}/workshopSessions/${workshopSessionId}`),
						get$bindFirestoreOptions(),
					);
					this.setLoadingLobbyWorkshopSession(false);
					if (!newResult) this.resultLobbyWorkshopSession(newResult);
				} else {
					if (this._firestoreUnbinds['vuefireLobbyWorkshopSession']) {
						this.$unbind('vuefireLobbyWorkshopSession');
					}
				}
			},
		},
		boundPropsLobbyWorkshopSessionQueueItem: {
			immediate: true,
			async handler ({ workshopId, workshopSessionId, user }) {
				if (workshopId && workshopSessionId && user) {
					this.setLoadingLobbyWorkshopSessionQueueItem(true);
					const newResult: WorkshopSession = await this.$bind(
						'vuefireLobbyWorkshopSessionQueueItem',
						db.doc(`workshops/${workshopId}/workshopSessions/${workshopSessionId}/workshopSessionQueue/${user.id}`),
						get$bindFirestoreOptions(),
					);
					this.setLoadingLobbyWorkshopSessionQueueItem(false);
					if (!newResult) this.resultLobbyWorkshopSessionQueueItem(newResult);
				} else {
					if (this._firestoreUnbinds['vuefireLobbyWorkshopSessionQueueItem']) {
						this.$unbind('vuefireLobbyWorkshopSessionQueueItem');
					}
				}
			},
		},
		boundPropsAnnouncements: {
			immediate: true,
			async handler () {
				this.setLoadingAnnouncements(true);
				const newResult: Announcement[] = await this.$bind(
					'vuefireAnnouncements',
					db.collection('announcements')
						.where('show', '==', true)
						.limit(3),
					get$bindFirestoreOptions(),
				);
				this.setLoadingAnnouncements(false);
				if (!newResult) this.resultAnnouncements(newResult);
			},
		},
		boundPropsUser: {
			immediate: true,
			async handler ({ authUser }) {
				if (authUser) {
					this.setLoadingUser(true);
					const newResult: User = await this.$bind(
						'vuefireUser',
						db.doc(`users/${authUser.uid}`),
						get$bindFirestoreOptions(),
					);
					this.setLoadingUser(false);
					if (!newResult) this.resultUser(newResult);
				} else {
					if (this._firestoreUnbinds['vuefireUser']) {
						this.$unbind('vuefireUser');
					}
				}
			},
		},
		boundPropsUserBalanceAccount: {
			immediate: true,
			async handler ({ user }) {
				if (user) {
					this.setLoadingUserBalanceAccount(true);
					const newResult: BalanceAccount = await this.$bind(
						'vuefireUserBalanceAccount',
						db.doc(`balanceAccounts/${user.id}`),
						get$bindFirestoreOptions(),
					);
					this.setLoadingUserBalanceAccount(false);
					if (!newResult) this.resultUserBalanceAccount(newResult);
				} else {
					if (this._firestoreUnbinds['vuefireUserBalanceAccount']) {
						this.$unbind('vuefireUserBalanceAccount');
					}
				}
			},
		},
		boundPropsTeam: {
			immediate: true,
			async handler ({ teamId }) {
				if (teamId) {
					this.setLoadingTeam(true);
					setTeamId(teamId);
					const newResult: Team = await this.$bind(
						'vuefireTeam',
						db.doc(`teams/${teamId}`),
						get$bindFirestoreOptions(),
					);
					this.setLoadingTeam(false);
					if (!newResult) this.resultTeam(newResult);
				} else {
					if (this._firestoreUnbinds['vuefireTeam']) {
						removeTeamId();
						this.$unbind('vuefireTeam');
					}
				}
			},
		},
		boundPropsTeamBalanceAccount: {
			immediate: true,
			async handler ({ teamId }) {
				if (teamId) {
					this.setLoadingTeamBalanceAccount(true);
					const newResult: BalanceAccount = await this.$bind(
						'vuefireTeamBalanceAccount',
						db.doc(`balanceAccounts/${teamId}`),
						get$bindFirestoreOptions(),
					);
					this.setLoadingTeamBalanceAccount(false);
					if (!newResult) this.resultTeamBalanceAccount(newResult);
				} else {
					if (this._firestoreUnbinds['vuefireTeamBalanceAccount']) {
						this.$unbind('vuefireTeamBalanceAccount');
					}
				}
			},
		},
		boundPropsStripeCustomer: {
			immediate: true,
			async handler ({ user }) {
				if (user) {
					this.setLoadingStripeCustomer(true);
					const newResult: StripeCustomer = await this.$bind(
						'vuefireStripeCustomer',
						db.doc(`stripeCustomers/${user.id}`),
						get$bindFirestoreOptions(),
					);
					this.setLoadingStripeCustomer(false);
					if (!newResult) this.resultStripeCustomer(newResult);
				} else {
					if (this._firestoreUnbinds['vuefireStripeCustomer']) {
						this.$unbind('vuefireStripeCustomer');
					}
				}
			},
		},
		boundPropsStripeTeamsCustomer: {
			immediate: true,
			async handler ({ teamId }) {
				if (teamId) {
					this.setLoadingStripeTeamsCustomer(true);
					const newResult: StripeCustomer = await this.$bind(
						'vuefireStripeTeamsCustomer',
						db.doc(`stripeTeamsCustomers/${teamId}`),
						get$bindFirestoreOptions(),
					);
					this.setLoadingStripeTeamsCustomer(false);
					if (!newResult) this.resultStripeTeamsCustomer(newResult);
				} else {
					if (this._firestoreUnbinds['vuefireStripeTeamsCustomer']) {
						this.$unbind('vuefireStripeTeamsCustomer');
					}
				}
			},
		},
		boundPropsCurrSessions: {
			immediate: true,
			async handler ({ user }) {
				if (user) {
					this.setLoadingCurrSessions(true);
					const newResult: Session[] = await this.$bind(
						'vuefireCurrSessions',
						// index created: sessions: state Ascending userId Ascending createdAt Descending __name__ Descending
						db.collection('sessions')
							.where('state', 'in', CURR_SESSION_STATES)
							.where('userId', '==', user.id)
							.orderBy('createdAt', 'desc'),
						get$bindFirestoreOptions(),
					);
					this.setLoadingCurrSessions(false);
					if (!newResult) this.resultCurrSessions(newResult);
				} else {
					if (this._firestoreUnbinds['vuefireCurrSessions']) {
						this.$unbind('vuefireCurrSessions');
					}
				}
			},
		},
		boundPropsCurrSessionBackgroundTasks: {
			immediate: true,
			async handler ({ session }) {
				if (session) {
					this.setLoadingCurrSessionBackgroundTasks(true);
					const newResult: SessionBackgroundTask[] = await this.$bind(
						'vuefireCurrSessionBackgroundTasks',
						db.collection('sessions')
							.doc(session.id)
							.collection('backgroundTasks')
							.orderBy('createdAt', 'desc')
							.limit(100),
						get$bindFirestoreOptions(),
					);
					this.setLoadingCurrSessionBackgroundTasks(false);
					if (!newResult) this.resultCurrSessionBackgroundTasks(newResult);
				} else {
					if (this._firestoreUnbinds['vuefireCurrSessionBackgroundTasks']) {
						this.$unbind('vuefireCurrSessionBackgroundTasks');
					}
				}
			},
		},
		boundPropsAdminRegularSessions: {
			immediate: true,
			async handler ({ user }) {
				if (user && user.isAdmin) {
					this.setLoadingAdminRegularSessions(true);
					const newResult: Session[] = await this.$bind(
						'vuefireAdminRegularSessions',
						db.collection('sessions')
							.where('state', 'in', CURR_SESSION_STATES),
						get$bindFirestoreOptions(),
					);
					this.setLoadingAdminRegularSessions(false);
					if (!newResult) this.resultAdminRegularSessions(newResult);
				} else {
					if (this._firestoreUnbinds['vuefireAdminRegularSessions']) {
						this.$unbind('vuefireAdminRegularSessions');
					}
				}
			},
		},
		boundPropsStableDiffusionModels: {
			immediate: true,
			async handler () {
				this.setLoadingStableDiffusionModels(true);
				const newResult: StableDiffusionModel[] = await this.$bind(
					'vuefireStableDiffusionModels',
					db.collection('stableDiffusionModels')
						.orderBy('name', 'asc'),
					get$bindFirestoreOptions(),
				);
				this.setLoadingStableDiffusionModels(false);
				if (!newResult) this.resultStableDiffusionModels(newResult);
			},
		},
		boundPropsSetupSessionPrefill: {
			immediate: true,
			async handler ({ authUser, appKey }) {
				if (authUser && appKey) {
					this.setLoadingSetupSessionPrefill(true);
					const newResult: SetupSessionPrefill = await this.$bind(
						'vuefireSetupSessionPrefill',
						db.doc(`users/${authUser.uid}/setupSessionPrefills/${appKey}`),
						get$bindFirestoreOptions(),
					);
					this.setLoadingSetupSessionPrefill(false);
					if (!newResult) this.resultSetupSessionPrefill(newResult);
				} else {
					if (this._firestoreUnbinds['vuefireSetupSessionPrefill']) {
						this.$unbind('vuefireSetupSessionPrefill');
					}
				}
			},
		},
		boundPropsRecentUserRunnitNodeDefInfos: {
			immediate: true,
			async handler ({ user }) {
				if (user) {
					this.setLoadingRecentUserRunnitNodeDefInfos(true);
					// index created = runnitNodeDefInfos: 	appType Ascending lastOpenAt Descending __name__ Descending
					const newResult: UserRunnitNodeDefInfo[] = await this.$bind(
						'vuefireRecentUserRunnitNodeDefInfos',
						db.collection(`users/${user.id}/runnitNodeDefInfos`)
							.orderBy('lastOpenAt', 'desc')
							.limit(15),
						get$bindFirestoreOptions(),
					);
					this.setLoadingRecentUserRunnitNodeDefInfos(false);
					if (!newResult) this.resultRecentUserRunnitNodeDefInfos(newResult);
				} else {
					if (this._firestoreUnbinds['vuefireRecentUserRunnitNodeDefInfos']) {
						this.$unbind('vuefireRecentUserRunnitNodeDefInfos');
					}
				}
			},
		},
		boundPropsModels: {
			immediate: true,
			async handler ({ userId, teamId, accessLevel }) {
				if (userId || teamId) {
					this.setLoadingModels(true);
					let newResult: Model[] = [];

					if (teamId && userId) {
						// index created: models - teamId Ascending accessLevel Ascending isDeleted Ascending createdAt Descending __name__ Descending
						let modelsRef: any = db.collection(`models`)
							.where('teamId', '==', teamId)
							.where('accessLevel', '==', accessLevel)
							.where('isDeleted', '==', false)
							.orderBy('createdAt', 'desc')
							.limit(500);
						if (accessLevel !== RUNNITS_ACCESS_LEVEL.SHARED) {
							// index created: models - accessLevel Ascending deletedAt Ascending teamId Ascending userId Ascending createdAt Descending __name__ Descending
							modelsRef = modelsRef.where('userId', '==', userId);
						}
						newResult = await this.$bind(
							'vuefireModels',
							modelsRef,
							get$bindFirestoreOptions(),
						);
					} else if (userId) {
						// index created: models - userId Ascending teamId Ascending accessLevel Ascending isDeleted Ascending createdAt Descending __name__ Descending
						const modelsRef: any = db.collection(`models`)
							.where('userId', '==', userId)
							.where('teamId', '==', null)
							.where('accessLevel', '==', accessLevel)
							.where('isDeleted', '==', false)
							.orderBy('createdAt', 'desc')
							.limit(100);
						newResult = await this.$bind(
							'vuefireModels',
							modelsRef,
							get$bindFirestoreOptions(),
						);
					}

					this.setLoadingModels(false);
					if (!newResult) this.resultModels(newResult);
				} else {
					if (this._firestoreUnbinds['vuefireModels']) {
						this.$unbind('vuefireModels');
					}
				}
			},
		},

		/*
		Actual bindings
		 */
		vuefireClubOffers: {
			immediate: false,
			deep: false, // true only on objects, not arrays
			handler (newVal: ClubOffer[]) {
				this.resultClubOffers(newVal);
			},
		},
		vuefireAppOffers: {
			immediate: false,
			deep: false, // true only on objects, not arrays
			handler (newVal: AppOffer[]) {
				if (newVal && newVal.length) {
					const newValArray: AppOffer[] = Array.from(newVal || [])
						.filter(({ publishedAt, isDev }) => !!(
							publishedAt ||
							(this.user && this.user.isAdmin) ||
							(this.user && this.user.isDev && isDev)
						));
					this.resultAppOffers(newValArray);
					return;
				}

				this.resultAppOffers(newVal);
			},
		},
		vuefireHardwareOffers: {
			immediate: false,
			deep: false, // true only on objects, not arrays
			handler (newVal: HardwareOffer[]) {
				if (newVal && newVal.length) {
					const newValArray: HardwareOffer[] = Array.from(newVal || [])
						.filter(({ publishedAt, isDev }) => !!(
							publishedAt ||
							(this.user && this.user.isAdmin) ||
							(this.user && this.user.isDev && isDev)
						));
					this.resultHardwareOffers(newValArray);
					return;
				}

				this.resultHardwareOffers(newVal);
			},
		},
		vuefireSoftwareOffers: {
			immediate: false,
			deep: false, // true only on objects, not arrays
			handler (newVal: SoftwareOffer[]) {
				if (newVal && newVal.length) {
					const newValArray: SoftwareOffer[] = Array.from(newVal || [])
						.filter(({ publishedAt, isDev }) => !!(
							publishedAt ||
							(this.user && this.user.isAdmin) ||
							(this.user && this.user.isDev && isDev)
						));
					this.resultSoftwareOffers(newValArray);
					return;
				}

				this.resultSoftwareOffers(newVal);
			},
		},
		vuefireExtensionOffers: {
			immediate: false,
			deep: false, // true only on objects, not arrays
			handler (newVal: ExtensionOffer[]) {
				this.resultExtensionOffers(newVal);
			},
		},
		vuefireStableDiffusionOffers: {
			immediate: false,
			deep: false, // true only on objects, not arrays
			handler (newVal: StableDiffusionOffer[]) {
				this.resultStableDiffusionOffers(newVal);
			},
		},
		vuefireWorkspaces: {
			immediate: false,
			deep: false, // true only on objects, not arrays
			handler (newVal: Workspace[]) {
				this.resultWorkspaces(newVal);
			},
		},
		vuefireRunnits: {
			immediate: false,
			deep: false, // true only on objects, not arrays
			handler (newVal: Runnit[]) {
				this.resultRunnits(newVal);
			},
		},
		vuefireRunnitNodes: {
			immediate: false,
			deep: false, // true only on objects, not arrays
			handler (newVal: RunnitNode[]) {
				this.resultRunnitNodes(newVal);
			},
		},
		vuefireTrainerNodeDefs: {
			immediate: false,
			deep: false, // true only on objects, not arrays
			handler (newVal: RunnitNodeDef[]) {
				this.resultTrainerNodeDefs(newVal);
			},
		},
		vuefirePublicRunnitNodeDefs: {
			immediate: false,
			deep: false, // true only on objects, not arrays
			handler (newVal: RunnitNodeDef[]) {
				this.resultPublicRunnitNodeDefs(newVal);
			},
		},
		vuefireTeamRunnitNodeDefs: {
			immediate: false,
			deep: false, // true only on objects, not arrays
			handler (newVal: RunnitNodeDef[]) {
				this.resultTeamRunnitNodeDefs(newVal);
			},
		},
		vuefirePublicRunnitNodeDefTags: {
			immediate: false,
			deep: false, // true only on objects, not arrays
			handler (newVal: RunnitNodeDefTag[]) {
				this.resultPublicRunnitNodeDefTags(newVal);
			},
		},
		vuefireTeamRunnitNodeDefTags: {
			immediate: false,
			deep: false, // true only on objects, not arrays
			handler (newVal: RunnitNodeDefTag[]) {
				this.resultTeamRunnitNodeDefTags(newVal);
			},
		},
		vuefireDraftRunnitNodeRuns: {
			immediate: false,
			deep: false, // true only on objects, not arrays
			handler (newVal: RunnitNodeRun[]) {
				this.resultDraftRunnitNodeRun(newVal);
			},
		},
		vuefireWorkshops: {
			immediate: false,
			deep: false, // true only on objects, not arrays
			handler (newVal: Workshop[]) {
				this.resultWorkshops(newVal);
			},
		},
		vuefireWorkshopSessions: {
			immediate: false,
			deep: false, // true only on objects, not arrays
			handler (newVal: WorkshopSession[]) {
				this.resultWorkshopSessions(newVal);
			},
		},
		vuefireWorkshopSessionQueueItems: {
			immediate: false,
			deep: false, // true only on objects, not arrays
			handler (newVal: WorkshopSessionQueueItem[]) {
				this.resultWorkshopSessionQueueItems(newVal);
			},
		},
		vuefireLobbyWorkshop: {
			immediate: false,
			deep: true, // true only on objects, not arrays
			handler (newVal: Workshop) {
				this.resultLobbyWorkshop(newVal);
			},
		},
		vuefireLobbyWorkshopSession: {
			immediate: false,
			deep: true, // true only on objects, not arrays
			handler (newVal: WorkshopSession) {
				this.resultLobbyWorkshopSession(newVal);
			},
		},
		vuefireLobbyWorkshopSessionQueueItem: {
			immediate: false,
			deep: true, // true only on objects, not arrays
			handler (newVal: WorkshopSessionQueueItem) {
				this.resultLobbyWorkshopSessionQueueItem(newVal);
			},
		},
		vuefireAnnouncements: {
			immediate: false,
			deep: false, // true only on objects, not arrays
			handler (newVal: Announcement[]) {
				this.resultAnnouncements(newVal);
			},
		},
		vuefireUser: {
			immediate: false,
			deep: true, // true only on objects, not arrays
			handler (newVal: User) {
				this.resultUser(newVal);
			},
		},
		vuefireUserBalanceAccount: {
			immediate: false,
			deep: true, // true only on objects, not arrays
			handler (newVal: BalanceAccount) {
				this.resultUserBalanceAccount(newVal);
			},
		},
		vuefireTeam: {
			immediate: false,
			deep: true, // true only on objects, not arrays
			handler (newVal: Team) {
				this.resultTeam(newVal);
			},
		},
		vuefireTeamBalanceAccount: {
			immediate: false,
			deep: true, // true only on objects, not arrays
			handler (newVal: BalanceAccount) {
				this.resultTeamBalanceAccount(newVal);
			},
		},
		vuefireStripeCustomer: {
			immediate: false,
			deep: true, // true only on objects, not arrays
			handler (newVal: StripeCustomer) {
				this.resultStripeCustomer(newVal);
			},
		},
		vuefireStripeTeamsCustomer: {
			immediate: false,
			deep: true, // true only on objects, not arrays
			handler (newVal: StripeCustomer) {
				this.resultStripeTeamsCustomer(newVal);
			},
		},
		vuefireCurrSessions: {
			immediate: false,
			deep: false, // true only on objects, not arrays
			handler (newVal: Session[]) {
				this.resultCurrSessions(newVal);
			},
		},
		vuefireCurrSessionBackgroundTasks: {
			immediate: false,
			deep: false, // true only on objects, not arrays
			handler (newVal: SessionBackgroundTask[]) {
				this.resultCurrSessionBackgroundTasks(newVal);
			},
		},
		vuefireAdminRegularSessions: {
			immediate: false,
			deep: false, // true only on objects, not arrays
			handler (newVal: Session[]) {
				this.resultAdminRegularSessions(newVal);
			},
		},
		vuefireStableDiffusionModels: {
			immediate: false,
			deep: false, // true only on objects, not arrays
			handler (newVal: StableDiffusionModel[]) {
				this.resultStableDiffusionModels(newVal);
			},
		},
		vuefireSetupSessionPrefill: {
			immediate: false,
			deep: true, // true only on objects, not arrays
			handler (newVal: SetupSessionPrefill) {
				this.resultSetupSessionPrefill(newVal);
			},
		},
		vuefireRecentUserRunnitNodeDefInfos: {
			immediate: false,
			deep: false, // true only on objects, not arrays
			handler (newVal: UserRunnitNodeDefInfo[]) {
				this.resultRecentUserRunnitNodeDefInfos(newVal);
			},
		},
		vuefireModels: {
			immediate: false,
			deep: false, // true only on objects, not arrays
			handler (newVal: Model[]) {
				this.resultModels(newVal);
			},
		},

	},
	methods: {
		...mapActions([
			// result
			'resultClubOffers',
			'resultAppOffers',
			'resultHardwareOffers',
			'resultSoftwareOffers',
			'resultExtensionOffers',
			'resultStableDiffusionOffers',
			'resultWorkspaces',
			'resultRunnits',
			'resultRunnitNodes',
			'resultTrainerNodeDefs',
			'resultPublicRunnitNodeDefs',
			'resultTeamRunnitNodeDefs',
			'resultPublicRunnitNodeDefTags',
			'resultTeamRunnitNodeDefTags',
			'resultDraftRunnitNodeRun',
			'resultWorkshops',
			'resultWorkshopSessions',
			'resultWorkshopSessionQueueItems',
			'resultLobbyWorkshop',
			'resultLobbyWorkshopSession',
			'resultLobbyWorkshopSessionQueueItem',
			'resultAnnouncements',
			'resultUser',
			'resultUserBalanceAccount',
			'resultTeam',
			'resultTeamBalanceAccount',
			'resultStripeCustomer',
			'resultStripeTeamsCustomer',
			'resultCurrSessions',
			'resultCurrSessionBackgroundTasks',
			'resultAdminRegularSessions',
			'resultStableDiffusionModels',
			'resultSetupSessionPrefill',
			'resultRecentUserRunnitNodeDefInfos',
			'resultModels',

			// setLoading
			'setLoadingClubOffers',
			'setLoadingAppOffers',
			'setLoadingHardwareOffers',
			'setLoadingSoftwareOffers',
			'setLoadingExtensionOffers',
			'setLoadingStableDiffusionOffers',
			'setLoadingWorkspaces',
			'setLoadingRunnits',
			'setLoadingRunnitNodes',
			'setLoadingTrainerNodeDefs',
			'setLoadingPublicRunnitNodeDefs',
			'setLoadingTeamRunnitNodeDefs',
			'setLoadingPublicRunnitNodeDefTags',
			'setLoadingTeamRunnitNodeDefTags',
			'setLoadingDraftRunnitNodeRun',
			'setLoadingWorkshops',
			'setLoadingWorkshopSessions',
			'setLoadingWorkshopSessionQueueItems',
			'setLoadingLobbyWorkshop',
			'setLoadingLobbyWorkshopSession',
			'setLoadingLobbyWorkshopSessionQueueItem',
			'setLoadingAnnouncements',
			'setLoadingUser',
			'setLoadingUserBalanceAccount',
			'setLoadingTeam',
			'setLoadingTeamBalanceAccount',
			'setLoadingStripeCustomer',
			'setLoadingStripeTeamsCustomer',
			'setLoadingCurrSessions',
			'setLoadingCurrSessionBackgroundTasks',
			'setLoadingAdminRegularSessions',
			'setLoadingStableDiffusionModels',
			'setLoadingSetupSessionPrefill',
			'setLoadingRecentUserRunnitNodeDefInfos',
			'setLoadingModels',
		]),
	},
});
