
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';

export default Vue.extend({
	name: 'OptionCard',
	props: {
		icon: { type: String, required: true, },
		title: { type: String, required: true, },
		description: { type: String, required: true, },
		buttonText: { type: String, default: '', },
		comingSoon: { type: Boolean, default: false, },

		selectable: { type: Boolean, default: false, },
		selected: { type: Boolean, default: false, },

		iconContainer: { type: Boolean, default: false, },

		disabled: { type: Boolean, default: false, },
	},
	data() {
		return {};
	},
	created() {
	},
	computed: {
		...mapState([
			'user',
		]),
	},
	methods: {
		...mapActions([
			'updateSnackbar',
		]),
	},
	components: {}
});
